import { TableProps } from "@epignosis_llc/gnosis";
import { endOfDay, format, startOfDay } from "date-fns";

import { mapTableToSelectSorting } from "@utils/helpers/view";

import { QueryFilter } from "types/common";
import {
  CalendarExportParams,
  CalendarFilters,
  CatalogCoursesFilters,
  CertificateFilters,
  CoursesFilters,
  MessageFilters,
  ValidCourseSortingField,
} from "types/entities";
import { Pagination } from "types/responses/index";

export type SearchQueryOptions<T> = {
  pagination: Pagination;
  sorting: T[];
  filters?: QueryFilter[];
  shouldEncode?: boolean;
};

export type SearchQueryNoPaginationOptions<T> = {
  sorting: T[];
  filters?: QueryFilter[];
  shouldEncode?: boolean;
};

// Basic functions used to build API queries
const buildAPIPagination = ({ number, size }: Pagination): string => {
  return `page[number]=${number}&page[size]=${size}`;
};

const buildAPISorting = <T>(options: T[]): string => {
  return `&sort=${options.join(",")}`;
};

export const buildAPIFilters = (filters: QueryFilter[], shouldEncode = true): string => {
  return filters.reduce((query, { key, value }): string => {
    const val = shouldEncode ? encodeURIComponent(value) : value;
    query += `&filter${key}=${val}`;
    return query;
  }, "");
};

// Main function that should be used in most API request that need url parameters
export const buildPaginatedSearchQuery = <T>({
  pagination,
  sorting,
  filters,
  shouldEncode = true,
}: SearchQueryOptions<T>): string => {
  let query = `?${buildAPIPagination(pagination)}`;

  // Add sorting to query
  if (sorting.length > 0) {
    query += buildAPISorting(sorting);
  }

  // Add search to query
  if (filters) {
    query += buildAPIFilters(filters, shouldEncode);
  }

  return query;
};

// This will be used for infinite queries and will not include pagination
export const buildSearchQueryWithNoPagination = <T>({
  sorting,
  filters,
  shouldEncode = true,
}: SearchQueryNoPaginationOptions<T>): string => {
  let query = "";

  // Add sorting to query
  if (sorting.length > 0) {
    query += buildAPISorting(sorting);
  }

  // Add search to query
  if (filters) {
    query += buildAPIFilters(filters, shouldEncode);
  }

  return query;
};

export const buildCertificateTemplateQuery = ({
  pagination,
  backgroundName,
  includeBackground = true,
  shouldEncode = true,
}: {
  pagination: Pagination;
  backgroundName: string;
  includeBackground?: boolean;
  shouldEncode?: boolean;
}): string => {
  let query = `?${buildAPIPagination(pagination)}`;

  if (includeBackground && backgroundName) {
    const encodedBackgroundName = shouldEncode
      ? encodeURIComponent(backgroundName)
      : backgroundName;
    query += `&background_name=${encodedBackgroundName}`;
  }

  return query;
};

/** ***************************************************************************/
/* TODO: all other functions should be replaced by buildPaginatedSearchQuery */
/** ***************************************************************************/

type Sorting = TableProps["sorting"];

type SearchQueryOptionsWithFilters = Pick<
  SearchQueryOptions<ValidCourseSortingField>,
  "pagination" | "sorting"
> & {
  filters: CatalogCoursesFilters;
  search: string;
};

const buildAPICoursesFilters = (filters: CatalogCoursesFilters): string => {
  const { category, bundle } = filters;

  const categoryFilter = category.length > 0 ? `&filter[category][in]=${category}` : "";
  const bundleFilter = bundle
    ? `&filter[bundle_type][eq]=${bundle.type}&filter[bundle][eq]=${bundle.id}`
    : "";

  return `${categoryFilter}${bundleFilter}`;
};

const buildCoursesSearchQuery = (filters: CoursesFilters): string => {
  let query = "";

  if (filters.branch_id) {
    query += `&filter[branch_id]=${filters.branch_id}`;
  }

  if (filters.group_id) {
    query += `&filter[group_id]=${filters.group_id}`;
  }

  if (filters.status) {
    query += `&filter[status]=${filters.status}`;
  }

  return query;
};

export const buildCoursesQuery = ({
  pagination,
  sorting,
  filters,
  search,
}: {
  pagination: Pagination;
  sorting: Sorting;
  filters: CoursesFilters;
  search: string;
}): string => {
  let query = `?${buildAPIPagination(pagination)}`;

  // Add sorting to query
  if (sorting?.column) {
    const sortingArray = [];
    sortingArray.push(`${sorting.isDescending ? "-" : ""}${sorting.column}`);
    query += `&${buildAPISorting(sortingArray)}`;
  }

  // Add filters to query
  const filtersQuery = buildCoursesSearchQuery(filters);
  if (filtersQuery) {
    query += `${filtersQuery}`;
  }

  // Add search to query
  if (search) {
    query += `&filter[name][like]=${encodeURIComponent(search)}`;
  }

  return query;
};

export const buildCatalogCoursesSearchQuery = ({
  pagination,
  sorting,
  filters,
  search,
}: SearchQueryOptionsWithFilters): string => {
  let query = `?${buildAPIPagination(pagination)}&${buildAPISorting(
    sorting,
  )}${buildAPICoursesFilters(filters)}`;

  // Add search to query
  if (search) {
    query += `&filter[keyword][like]=${encodeURIComponent(search)}`;
  }

  return query;
};

// Build query string for messages
const buildAPIMessagesFilters = (filters: MessageFilters): string => {
  let query = "";

  // TODO: uncomment when the endpoint to get senders is ready
  // If (filters.sender) {
  // Query += `${query ? "" : "&"}filter[sender][eq]=${filters.sender}`;
  // }

  if (filters.dateFrom) {
    query += `&filter[sent_at][gte]=${format(
      startOfDay(filters.dateFrom),
      "yyyy-MM-dd'T'HH:mm:ss'%2B'00:00",
    )}`;
  }

  if (filters.dateUntil) {
    query += `&filter[sent_at][lte]=${format(
      endOfDay(filters.dateUntil),
      "yyyy-MM-dd'T'HH:mm:ss'%2B'00:00",
    )}`;
  }

  if (["0", "1"].indexOf(filters.attachment) > -1) {
    query += `&filter[attachment][eq]=${filters.attachment}`;
  }

  return query;
};

export const buildMessagesSearchQuery = ({
  pagination,
  sorting,
  filters,
}: {
  pagination: Pagination;
  sorting: Sorting;
  filters: MessageFilters;
}): string => {
  let query = `?${buildAPIPagination(pagination)}`;

  // Add sorting to query
  if (sorting?.column) {
    const sortingArray = [];
    sortingArray.push(`${sorting.isDescending ? "-" : ""}${sorting.column}`);
    query += `&${buildAPISorting(sortingArray)}`;
  }

  // Add filters to query
  const filtersQuery = buildAPIMessagesFilters(filters);
  if (filtersQuery) {
    query += `${filtersQuery}`;
  }

  return query;
};

export const buildApiCertificatesFilters = (filters: CertificateFilters): string => {
  let query = "";

  if (filters.dateFromIssueAt) {
    query += `&filter[issued_at][gte]=${format(
      startOfDay(filters.dateFromIssueAt),
      "yyyy-MM-dd'T'HH:mm:ss'%2B'00:00",
    )}`;
  }

  if (filters.dateUntilIssueAt) {
    query += `&filter[issued_at][lte]=${format(
      endOfDay(filters.dateUntilIssueAt),
      "yyyy-MM-dd'T'HH:mm:ss'%2B'00:00",
    )}`;
  }

  if (filters.dateFromExpiresAt) {
    query += `&filter[expires_at][gte]=${format(
      startOfDay(filters.dateFromExpiresAt),
      "yyyy-MM-dd'T'HH:mm:ss'%2B'00:00",
    )}`;
  }

  if (filters.dateUntilExpiresAt) {
    query += `&filter[expires_at][lte]=${format(
      endOfDay(filters.dateUntilExpiresAt),
      "yyyy-MM-dd'T'HH:mm:ss'%2B'00:00",
    )}`;
  }

  return query;
};

export const buildCertificatesSearchQuery = ({
  pagination,
  sorting,
  filters,
}: {
  pagination: Pagination;
  sorting: Sorting;
  filters: CertificateFilters;
}): string => {
  const sortingValue = [mapTableToSelectSorting(sorting)];

  let query = buildPaginatedSearchQuery({ pagination, sorting: sortingValue });

  // Add filters to query
  const filtersQuery = buildApiCertificatesFilters(filters);
  if (filtersQuery) {
    query += `${filtersQuery}`;
  }
  return query;
};

export const buildApiBranchesSearchQuery = ({
  pagination,
  sorting,
  search,
}: {
  pagination: Pagination;
  sorting: string[];
  search: string;
}): string => {
  let query = buildPaginatedSearchQuery({ pagination, sorting });

  // Add search to query
  if (search) {
    query += `&filter[name][like]=${encodeURIComponent(search)}`;
  }

  return query;
};

// TODO: create fn to add filters in query
export const buildApiFilesSearchQuery = ({
  pagination,
  sorting,
  search,
}: {
  pagination: Pagination;
  sorting: string[];
  search: string;
}): string => {
  let query = buildPaginatedSearchQuery({ pagination, sorting });

  // Add search to query
  if (search) {
    query += `&filter[keyword][like]=${encodeURIComponent(search)}`;
  }

  return query;
};

export const buildCalendarEventsQuery = (filters: CalendarFilters): string => {
  let query = `?datetime_start=${format(
    startOfDay(filters.datetimeStart),
    "yyyy-MM-dd'T'HH:mm:ss'%2B'00:00",
  )}`;

  query += `&datetime_end=${format(
    endOfDay(filters.datetimeEnd),
    "yyyy-MM-dd'T'HH:mm:ss'%2B'00:00",
  )}`;

  return query;
};

export const buildCalendarExportParamsQuery = ({ id, type }: CalendarExportParams): string => {
  if (!id && !type) {
    return "";
  }

  let query = "";

  if (id) {
    const idParam = `id=${id}`;
    query += query.length === 0 ? `?${idParam}` : `&${idParam}`;
  }

  if (type) {
    const typeParam = `type=${type}`;
    query += query.length === 0 ? `?${typeParam}` : `&${typeParam}`;
  }

  return query;
};

export const isValidPathname = (pathname: string, pathnamesArray: string[]): boolean => {
  return pathnamesArray.some((path) => path === pathname);
};

export function joinDomainWithPath(domain: string, path: string): string {
  const sanitizedDomain = domain.endsWith("/") ? domain.slice(0, -1) : domain;
  const sanitizedPath = path.startsWith("/") ? path : `/${path}`;

  return `${sanitizedDomain}${sanitizedPath}`;
}

export function getCurrentDomainWithSchemeAndPort(): string | undefined {
  if (typeof window !== "undefined") {
    const protocol = window.location.protocol;
    const host = window.location.host;

    return `${protocol}//${host}`;
  }

  return undefined;
}
