import { css } from "@emotion/react";

import { mq } from "@utils/breakpoints";

export const tabWidget = (isGamificationDrawer) => css`
  height: 100%;
  padding: 1rem;

  ${mq["md"]} {
    padding: 1rem 1rem 0 1rem;
  }

  ${mq["lg"]} {
    max-height: ${isGamificationDrawer ? "100%" : "700px"};
  }
`;
