import React, { FC } from "react";

import { loginFormContainer } from "@views/SignIn/styles";

import { useApplyTranslations } from "@hooks";
import { useUIStore } from "@stores";
import useConfigurationStore from "@stores/configuration";

import Divider from "@components/ReusableComponents/Divider/Divider";
import ForgotPasswordLink from "@components/SignIn/ForgotPasswordLink";
import LDAPSignInForm from "@components/SignIn/LDAPSignInForm";
import LoginType from "@components/SignIn/LoginType";
import SamlOicdSignIn from "@components/SignIn/SamlOicdSignIn";
import SignInForm from "@components/SignIn/SignInForm";
import SocialSignIn from "@components/SignIn/SocialSignIn/SocialSignIn";

const FormContainer: FC = () => {
  const { t } = useApplyTranslations();
  const { domainSettings } = useConfigurationStore();
  const { getLoginType } = useUIStore();
  const loginType = getLoginType();
  const integrationType = domainSettings?.sso.integration_type ?? "";
  const showSamlOidcLogIn = ["saml", "oidc"].includes(integrationType);
  const hasSocialLogin = domainSettings && domainSettings.social_login?.length > 0;
  const canLoginSocial = domainSettings?.can_login_with_social;
  const showDivider = (canLoginSocial && hasSocialLogin) || integrationType;

  return (
    <div css={loginFormContainer}>
      {integrationType === "ldap" && loginType === "ldap" ? <LDAPSignInForm /> : <SignInForm />}
      {loginType === "direct" && <ForgotPasswordLink />}
      {showDivider && <Divider className="form-container" text={t("general.or").toLowerCase()} />}
      <div className="login-links-container">
        {showSamlOidcLogIn && <SamlOicdSignIn />}
        {(integrationType === "ldap" || integrationType === "") && <LoginType />}
        {loginType === "direct" && canLoginSocial && hasSocialLogin && <SocialSignIn />}
      </div>
    </div>
  );
};

export default FormContainer;
