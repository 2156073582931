import React, { FC, useRef, useState } from "react";

import { Button, Text } from "@epignosis_llc/gnosis";
import { useToggle } from "ahooks";

import { TicketSubmissionSVG } from "@assets/images";

import { MainMenuDrawer } from "@layouts/DashboardLayout/components";

import { useApplyTranslations } from "@hooks";

import { SupportForm } from "@components/Drawers/HelpCenter/components";
import { TabHandle } from "@components/ReusableDrawers/types";
import EmptyState from "@views/WidgetsDashboard/components/Widgets/components/EmptyState";

type AccountManagerDrawerProps = {
  isOpen: boolean;
  toggleDrawer: () => void;
};
const AccountManagerDrawer: FC<AccountManagerDrawerProps> = ({ isOpen, toggleDrawer }) => {
  const { t } = useApplyTranslations();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isAccountManagerSubmissionDrawer, { toggle: toggleIsAccountManagerSubmissionDrawer }] =
    useToggle(false);

  const accountManagerFormRef = useRef<TabHandle>(null);
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleApply = (): void => {
    accountManagerFormRef.current?.handleApply();
  };

  const onClose = (): void => {
    toggleIsAccountManagerSubmissionDrawer();
    toggleDrawer();
  };

  return (
    <>
      <MainMenuDrawer
        id="account-manager-drawer"
        isOpen={isOpen}
        headerTitle={t("helpCenter.accountManager")}
        size="lg"
        onClose={(): void => {
          toggleDrawer();
        }}
        customFooter={
          <Button
            disabled={isButtonDisabled || !isValid}
            isLoading={isLoading}
            onClick={handleApply}
          >
            {t("general.send")}
          </Button>
        }
        showFooter
        hasBackButton
      >
        <SupportForm
          type="accountManager"
          setIsButtonDisabled={setIsButtonDisabled}
          ref={accountManagerFormRef}
          onValidChange={(isValid: boolean): void => {
            setIsValid(isValid);
          }}
          onLoadingChange={(isLoading: boolean): void => {
            setIsLoading(isLoading);
          }}
          onSubmissionSuccess={(): void => {
            toggleIsAccountManagerSubmissionDrawer();
          }}
        />
      </MainMenuDrawer>

      {isAccountManagerSubmissionDrawer && (
        <MainMenuDrawer
          id="empty-state-account-manager-drawer"
          isOpen={isAccountManagerSubmissionDrawer}
          headerTitle={t("helpCenter.accountManager")}
          size="lg"
          onClose={onClose}
          hasBackButton
          showFooter={false}
        >
          <EmptyState
            icon={<TicketSubmissionSVG />}
            title={
              <Text
                fontSize="lg"
                weight="700"
                dangerouslySetInnerHTML={{ __html: t("helpCenter.accountManagerSuccessfully") }}
              />
            }
            footer={
              <Button variant="link" onClick={onClose} noGutters>
                {t("helpCenter.backToHelp")}
              </Button>
            }
          />
        </MainMenuDrawer>
      )}
    </>
  );
};

export default AccountManagerDrawer;
