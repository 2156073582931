import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";

import { CalendarEventType } from "@views/Calendar/types";
import {
  CalendarEventsRes,
  CalendarEventTypeRes,
  CalendarExportRes,
  CalendarStatisticsRes,
  CalendarSyncRes,
  PostCalendarEventRes,
  SpecificAudienceRes,
} from "types/responses";

export const getCalendarEvents = async (queryStr = ""): Promise<CalendarEventsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.calendar.events}${queryStr}`);

  return res.data;
};

export const postCalendarEvent = async (
  eventData: CalendarEventType,
): Promise<PostCalendarEventRes> => {
  const res = await HttpClient.post(ENDPOINTS.calendar.events, eventData);

  return res.data;
};

export const getCalendarEvent = async (eventId: number): Promise<CalendarEventTypeRes> => {
  const res = await HttpClient.get(ENDPOINTS.calendar.event(eventId));

  return res.data;
};

export const updateCalendarEvent = async (
  eventData: CalendarEventType,
  eventId: number,
): Promise<CalendarEventTypeRes> => {
  const res = await HttpClient.put(ENDPOINTS.calendar.event(eventId), eventData);

  return res.data;
};

export const deleteCalendarEvent = async (eventId: number): Promise<void> => {
  const res = await HttpClient.delete(ENDPOINTS.calendar.event(eventId));

  return res.data;
};

export const getCalendarExport = async (queryStr = ""): Promise<CalendarExportRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.calendar.export}${queryStr}`);

  return res.data;
};

export const getCalendarSync = async (): Promise<CalendarSyncRes> => {
  const res = await HttpClient.get(ENDPOINTS.calendar.sync);

  return res.data;
};

export const getCalendatStatistics = async (): Promise<CalendarStatisticsRes> => {
  const res = await HttpClient.get(ENDPOINTS.calendar.statistics);

  return res.data;
};

export const postCalendarSync = async (): Promise<CalendarSyncRes> => {
  const res = await HttpClient.post(ENDPOINTS.calendar.sync);

  return res.data;
};

export const getSpecificAudience = async (queryStr = ""): Promise<SpecificAudienceRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.calendar.specificAudience}${queryStr}`);

  return res.data;
};
