import React, { FC, useState } from "react";

import { Row, Text } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";

import { PaginatedState } from "@hooks/usePaginatedStateReducer";
import { useConfigurationStore } from "@stores";
import { getFormattedUserName } from "@utils/helpers";

import {
  ActionsContainer,
  ApproveAction,
  CustomTable,
  DateWithTimeTooltip,
  DeclineAction,
  TextWithStatus,
} from "@components";
import { CustomTableProps } from "@components/CustomTable/CustomTable";

import { EnrollmentRequest, RequestResponse } from "@views/Course/CourseUsers/types";
import { PaginationRes } from "types/responses";

type EnrollmentRequestsTableProps = CustomTableProps & {
  data: EnrollmentRequest[];
  paginationRes: PaginationRes | undefined;
  handleProcessRequest: (requestId: string, action: RequestResponse) => void;
  handleRestoreDefault: () => void;
  onSortingChanged: (sorting: PaginatedState["sorting"]) => void;
  onPageChange: (value: number) => void;
  onPageSizeChange: (value: number) => void;
  handleRowSelect: (selectedRows: Row[]) => void;
};

const EnrollmentRequestsTable: FC<EnrollmentRequestsTableProps> = ({
  tableProps,
  tableStatus,
  data,
  paginationRes,
  tableRef,
  handleRestoreDefault,
  onSortingChanged,
  onPageChange,
  onPageSizeChange,
  handleProcessRequest,
  handleRowSelect,
  handleExportToCSV,
}) => {
  const [hoveredRow, setHoveredRow] = useState<Row | null>(null);
  const { sm } = useResponsive();
  const { domainSettings } = useConfigurationStore();
  const userFormat = domainSettings?.user_format as string;
  const usernameColumnTitle = userFormat === "username" ? "login" : "surname";

  const headerWidth = tableProps?.columns.find(
    (column) => column.accessor === usernameColumnTitle,
  )?.headerWidth;

  const rowData = data.map((entry) => {
    const { name, surname, login, id, request_date } = entry;

    const isHovered = hoveredRow?.id === entry.id && sm;

    const userName = getFormattedUserName({
      name,
      surname,
      login,
    });

    return {
      ...entry,
      id: id,
      formatted_name: (): JSX.Element => (
        <TextWithStatus
          content={
            <Text
              as="div"
              className="overflow-text"
              fontSize="sm"
              style={{ maxWidth: headerWidth ? `${headerWidth}px` : "auto" }}
            >
              {userName}
            </Text>
          }
        />
      ),
      request_date: (): JSX.Element => <DateWithTimeTooltip date={request_date} />,
      actions: (): JSX.Element => {
        const actions = [
          <ApproveAction
            entity={userName}
            onClick={(event): void => {
              event.stopPropagation();
              handleProcessRequest(id.toString(), RequestResponse.Approved);
            }}
            key="approve-action"
          />,
          <DeclineAction
            entity={userName}
            onClick={(event): void => {
              event.stopPropagation();
              handleProcessRequest(id.toString(), RequestResponse.Declined);
            }}
            key="decline-action"
          />,
        ];

        return <ActionsContainer actions={actions} isHovered={isHovered} />;
      },
    };
  });

  const handleRowHover = (row: Row | null): void => {
    setHoveredRow(row);
  };

  const newTableProps = {
    ...tableProps,
    rows: rowData,
    onHoveredRowChange: handleRowHover,
    onSortingChanged,
    handleRestoreDefault,
    onRowSelect: handleRowSelect,
  };

  return (
    <CustomTable
      tableProps={newTableProps}
      tableStatus={tableStatus}
      paginationProps={{
        paginationRes,
        onPageChange,
        onPageSizeChange,
      }}
      tableRef={tableRef}
      handleExportToCSV={handleExportToCSV}
    />
  );
};

export default EnrollmentRequestsTable;
