import React, { FC, useState } from "react";

import { Grid } from "@epignosis_llc/gnosis";

import { useApplyTranslations } from "@hooks";
import { useConfigurationStore } from "@stores";
import { formatSelectedDate, getDomainDateFormat } from "@utils/helpers";

import DateInput from "@components/FormElements/DateInput/DateInput";

type CompleteMassActionModalBodyProps = {
  handleEnrollmentDate: (date: string) => void;
  handleCompletionDate: (date: string) => void;
};

const CompleteMassActionModalBody: FC<CompleteMassActionModalBodyProps> = ({
  handleEnrollmentDate,
  handleCompletionDate,
}) => {
  const { t } = useApplyTranslations();

  const { userProfileData } = useConfigurationStore();
  const timezone = userProfileData?.timezone ?? "UTC";

  const [enrollmentDate, setEnrollmentDate] = useState<Date | null>(null);
  const [completionDate, setCompletionDate] = useState<Date>(new Date());

  const dateFormat = getDomainDateFormat();

  return (
    <Grid templateColumns={1} rowGap={1}>
      <Grid.Item>
        <DateInput
          id="enrollment-date"
          value={enrollmentDate}
          dateFormat={dateFormat}
          label={t("general.enrollmentDate")}
          tooltipContent={t("courses.massActions.completeEnrollmentDateTooltip")}
          maxDate={completionDate}
          onChange={(selectedDate: Date | null): void => {
            if (!selectedDate) return;

            const formatted = formatSelectedDate(selectedDate, timezone);
            if (!formatted) return;

            setEnrollmentDate(selectedDate);
            handleEnrollmentDate(formatted);
          }}
        />
      </Grid.Item>
      <Grid.Item>
        <DateInput
          id="completion-date"
          value={completionDate}
          dateFormat={dateFormat}
          label={t("general.completionDate")}
          minDate={enrollmentDate ?? new Date()}
          onChange={(selectedDate: Date | null): void => {
            if (!selectedDate) return;

            const formatted = formatSelectedDate(selectedDate, timezone);
            if (!formatted) return;

            setCompletionDate(selectedDate);
            handleCompletionDate(formatted);
          }}
        />
      </Grid.Item>
    </Grid>
  );
};

export default CompleteMassActionModalBody;
