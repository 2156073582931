import React, { FC, useEffect, useRef, useState } from "react";

import { useTheme } from "@emotion/react";
import { Text } from "@epignosis_llc/gnosis";
import { m, Transition, useReducedMotion, Variants } from "framer-motion";

import { passwordStrengthBarContainer } from "@components/FormElements/PasswordStrengthBar/styles";

import { useApplyTranslations } from "@hooks";

export type PasswordStrengthBarProps = {
  password: string;
  className?: string;
  onStrengthChange?: (isStrong: boolean) => void;
};

type StregnthLabel = "weak" | "medium" | "strong";

const transition = (shouldReduceMotion: boolean): Transition => ({
  duration: shouldReduceMotion ? 0 : 0.2,
  ease: "easeInOut",
});

const strongRegex = new RegExp(`^.*(?=.{8,})(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*$`);
const mediumRegex = new RegExp("^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{6,}$");

const variants: Variants = {
  initial: ({ width }) => ({
    background: "#f1f1f1",
    width: `${width}%`,
  }),
  animate: ({ width, shouldReduceMotion, bgColor }) => ({
    width: `${width}%`,
    background: bgColor.current,
    transition: transition(shouldReduceMotion),
  }),
};

type PasswordStrengthState = {
  label: string;
  width: number;
  strength: StregnthLabel;
};

const PasswordStrengthBar: FC<PasswordStrengthBarProps> = ({
  password,
  className,
  onStrengthChange,
}) => {
  const { t } = useApplyTranslations();
  const { passwordStrengthBar } = useTheme();
  const shouldReduceMotion = useReducedMotion();
  const [passwordStrengthState, setPasswordStrengthState] = useState<PasswordStrengthState>({
    label: t("general.weak"),
    width: 0,
    strength: "weak",
  });
  const bgColor = useRef(passwordStrengthBar.background);
  const { label, width, strength } = passwordStrengthState;

  useEffect(() => {
    if (strongRegex.test(password)) {
      bgColor.current = "#41BD54";
      setPasswordStrengthState({ label: t("general.strong"), width: 100, strength: "strong" });
    } else if (mediumRegex.test(password)) {
      bgColor.current = "#CC6600";
      setPasswordStrengthState({ label: t("general.medium"), width: 66, strength: "medium" });
    } else {
      bgColor.current = "#640F0D";
      setPasswordStrengthState({ label: t("general.weak"), width: 33, strength: "weak" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, setPasswordStrengthState]);

  useEffect(() => {
    if (onStrengthChange) {
      onStrengthChange(strength === "strong");
    }
  }, [onStrengthChange, strength]);

  return (
    <section css={passwordStrengthBarContainer} className={className}>
      <div className="bar-wrapper">
        <m.div
          variants={variants}
          className="bar"
          initial="initial"
          animate={password ? "animate" : "initial"}
          custom={{ width, shouldReduceMotion, bgColor }}
        />
      </div>

      {password && (
        <Text fontSize="xs" id="password-label" data-testid="password-strength-indication">
          {label}
        </Text>
      )}
    </section>
  );
};

export default PasswordStrengthBar;
