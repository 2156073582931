/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC } from "react";

import { Heading, Tooltip } from "@epignosis_llc/gnosis";
import { InfoCircledSVG } from "@epignosis_llc/gnosis/icons";

import { gamificationInfoContainer } from "@components/Drawers/Gamification/styles";

import { useRtlArrowIcons } from "@hooks";
import { useConfigurationStore } from "@stores";
import { t } from "@utils/i18n";

type GamificationInfoProps = {
  selectedTab: string;
  closeInfo: () => void;
};

const translationsMapping = {
  points: {
    login_points: "loginPoints",
    unit_completion_points: "unitCompletionPoints",
    course_completion_points: "courseCompletionPoints",
    certification_points: "certificationPoints",
    test_completion_points: "testCompletionPoints",
    assignment_completion_points: "assignmentCompletionPoints",
    ilt_completion_points: "iltCompletionPoints",
    discussion_topic_comment_points: "discussionTopicCommentPoints",
    discussion_upvote_comment_points: "discussionUpvoteCommentPoints",
  },
  levels: {
    upgrade_level_points: "upgradeLevelPoints",
    upgrade_level_completed_courses: "upgradeLevelCompletedCourses",
    upgrade_level_badges: "upgradeLevelBadges",
  },
};

const tooltipTranslationsMapping = {
  points: {
    test_completion_points: "testCompletionPoints",
    assignment_completion_points: "assignmentCompletionPoints",
    ilt_completion_points: "iltCompletionPoints",
  },
};

const getTranslation = (selectedTab: string, translationKey: string, points: number): string => {
  const translationObjectKey = selectedTab === "points" ? "collectPoints" : "levelUp";

  return t(`gamification.${translationObjectKey}.${translationKey}`, {
    count: points,
    number: points,
  });
};

const GamificationInfo: FC<GamificationInfoProps> = ({ selectedTab, closeInfo }) => {
  const { gamificationSettings } = useConfigurationStore();
  const Icon = useRtlArrowIcons();

  const pointsKeyWithValue = Object.keys(
    translationsMapping[selectedTab as keyof typeof translationsMapping],
  ).filter((key) => {
    return (gamificationSettings as any)?.[selectedTab]?.[key];
  });

  return (
    <article css={gamificationInfoContainer}>
      <Heading size="lg" className="info-header">
        {t(`gamification.${selectedTab === "points" ? "collectMorePoints" : "howToLevelUp"}`)}
      </Heading>

      <ul>
        {pointsKeyWithValue.map((key) => (
          <li key={`points-${key}`}>
            <div className="info-item">
              {getTranslation(
                selectedTab,
                (translationsMapping as any)[selectedTab][key],
                (gamificationSettings as any)?.[selectedTab][key],
              )}
              {tooltipTranslationsMapping[selectedTab as keyof typeof tooltipTranslationsMapping] &&
                (tooltipTranslationsMapping as any)[selectedTab][key] && (
                  <Tooltip
                    as="span"
                    content={t(
                      `gamification.tooltips.${
                        (tooltipTranslationsMapping as any)[selectedTab][key]
                      }`,
                    )}
                  >
                    <InfoCircledSVG height={20} />
                  </Tooltip>
                )}
            </div>
          </li>
        ))}
      </ul>

      <a role="button" className="back-btn" onClick={closeInfo}>
        <Icon height={24} className="arrow" />
        {t("general.back")}
      </a>
    </article>
  );
};

export default GamificationInfo;
