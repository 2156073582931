import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";

import { CommentPostData, DiscussionPostData } from "types/entities";
import {
  CommentPostDataRes,
  DiscussionAudienceRes,
  DiscussionPostDataRes,
  DiscussionRepliesRes,
  DiscussionRes,
  DiscussionsActiveRes,
  DiscussionsRes,
  FileRes,
  TableCSVExportRes,
} from "types/responses";

const formContentType = {
  "Content-Type": "multipart/form-data",
};

export const getDiscussions = async (queryStr = ""): Promise<DiscussionsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.discussions.discussions}${queryStr}`);

  return res.data;
};

export const getAudience = async (): Promise<DiscussionAudienceRes> => {
  const res = await HttpClient.get(ENDPOINTS.discussions.audience);

  return res.data;
};

export const getDiscussionById = async (id: number): Promise<DiscussionRes> => {
  const res = await HttpClient.get(ENDPOINTS.discussions.singleDiscussion(id));

  return res.data;
};

export const getDiscussionReplies = async (
  discussionId: number,
  queryStr = "",
): Promise<DiscussionRepliesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.discussions.replies(discussionId)}${queryStr}`);

  return res.data;
};

export const getActiveDiscussions = async (): Promise<DiscussionsActiveRes> => {
  const res = await HttpClient.get(ENDPOINTS.discussions.active);

  return res.data;
};

export const postDiscussion = async (
  newDiscussion: DiscussionPostData,
): Promise<DiscussionPostDataRes> => {
  const res = await HttpClient.post(ENDPOINTS.discussions.createDiscussion, newDiscussion);

  return res.data;
};

export const postAttachmentFile = async (file: File): Promise<FileRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  const res = await HttpClient.post(ENDPOINTS.discussions.attachment, bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const postDiscussionComment = async (
  discussionId: number,
  comment: CommentPostData,
): Promise<CommentPostDataRes> => {
  const res = await HttpClient.post(ENDPOINTS.discussions.comment(discussionId), comment);

  return res.data;
};

export const postReplyDiscussionComment = async (
  discussionId: number,
  comment: CommentPostData,
): Promise<CommentPostDataRes> => {
  const res = await HttpClient.post(ENDPOINTS.discussions.commentReply(discussionId), comment);

  return res.data;
};

export const putDiscussion = async (
  discussionId: number,
  editDiscussion: DiscussionPostData,
): Promise<void> => {
  await HttpClient.put(ENDPOINTS.discussions.edit(discussionId), editDiscussion);
};

export const putSubscribe = async (discussionId: number): Promise<void> => {
  await HttpClient.put(ENDPOINTS.discussions.subscribe(discussionId));
};

export const putUnsubscribe = async (discussionId: number): Promise<void> => {
  await HttpClient.put(ENDPOINTS.discussions.unsubscribe(discussionId));
};

export const putDiscussionCommentReply = async (
  commentId: number,
  editComment: CommentPostData,
): Promise<void> => {
  await HttpClient.put(ENDPOINTS.discussions.editCommentReply(commentId), editComment);
};

export const putLike = async (discussionId: number): Promise<void> => {
  await HttpClient.put(ENDPOINTS.discussions.like(discussionId));
};

export const putUnlike = async (discussionId: number): Promise<void> => {
  await HttpClient.put(ENDPOINTS.discussions.unlike(discussionId));
};

export const deleteDiscussion = async (discussionId: number): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.discussions.delete(discussionId));
};

export const deleteReply = async (discussionId: number): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.discussions.deleteComment(discussionId));
};

export const discussionsExport = async (queryStr = ""): Promise<TableCSVExportRes> => {
  const res = await HttpClient.post(`${ENDPOINTS.tableExports.discussionsExport}${queryStr}`);
  return res.data;
};
