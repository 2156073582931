import React, { FC } from "react";

import { useApplyTranslations } from "@hooks";

import { ActionDrawer } from "@components";
import EnrollUsersTable from "@views/Course/CourseUsers/components/EnrollUsersTable";

import { DrawerSize } from "types/common";

type EnrollUsersDrawerProps = {
  isDrawerOpen: boolean;
  canMassEnrollUsers: boolean;
  size?: DrawerSize;
  hasBackButton?: boolean;
  openDrawerFullscreen?: boolean;
  toggleDrawer: () => void;
  handleInvalidateQuery: () => void;
};

const EnrollUsersDrawer: FC<EnrollUsersDrawerProps> = ({
  isDrawerOpen,
  canMassEnrollUsers,
  size = "md",
  hasBackButton = false,
  openDrawerFullscreen = false,
  toggleDrawer,
  handleInvalidateQuery,
}) => {
  const { t } = useApplyTranslations();
  return (
    <ActionDrawer
      isOpen={isDrawerOpen}
      headerTitle={t("users.courses.enrollToCourse")}
      onClose={toggleDrawer}
      fullscreen={openDrawerFullscreen}
      size={size}
      hasBackButton={hasBackButton}
      showFooter={false}
    >
      <EnrollUsersTable
        handleInvalidateQuery={handleInvalidateQuery}
        canMassEnrollUsers={canMassEnrollUsers}
      />
    </ActionDrawer>
  );
};

export default EnrollUsersDrawer;
