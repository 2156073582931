import Color from "color";

import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["terms"] => {
  return {
    background: Color(colors.primary.lightest).alpha(0.25).string(),
    contentBackground: colors.white,
  };
};
