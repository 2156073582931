import Color from "color";

import { ThemePlusTypes } from "@styles/types";
import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["courseEdit"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    boxShadowColor: "#00000029",
    certificatePreview: {
      backgroundColor: colors.secondary.lighter,
      borderColor: colors.secondary.lighter,
    },
    courseImageOverlayBackground: Color(colors.secondary.darker).alpha(0.4).string(),
    courseCertificateOverlayBackground: Color(colors.primary.darker).alpha(0.4).string(),
    error: {
      color: colors.white,
      backgroundColor: colors.red.base,
    },
    footer: {
      border: colors.secondary.base,
    },
    grayBase: colors.secondary.base,
    linkedUnit: {
      tagBackground: Color(colors.primary.lightest).alpha(0.25).string(),
      tagTextColor: colors.primary.base,
    },
    note: colors.secondary.darker,
    pathItem: {
      dotColor: colors.primary.base,
      dotBorderColor: colors.primary.base,
      emptyDotColor: colors.white,
      lineColor: colors.primary.base,
    },
    topBarBackgroundColor: colors.secondary.lightest,
    verticalLine: colors.black,
    uploadFile: {
      borderColor: Color(colors.primary.lightest).alpha(0.25).string(),
      textColor: colors.primary.light,
      textHoverColor: protectColorContrast("darker", colors.primary.lighter),
    },
    weights: {
      borderColor: colors.secondary.lighter,
      percentageColor: colors.black,
      percentageBackgroundColor: colors.secondary.lighter,
    },
  };
};
