import { GnosisTheme } from "@epignosis_llc/gnosis";

import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): GnosisTheme["dropdown"] => ({
  boxShadowColor: colors.secondary.light,
  backgroundColor: colors.white,
  emptyStateColor: colors.secondary.base,
  hoverBackgroundColor: colors.secondary.lighter,
  borderBottomColor: colors.secondary.lighter,
  textColor: colors.black,
  disabledColor: colors.secondary.lighter,
});
