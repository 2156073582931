import React, { FC } from "react";

import classNames from "classnames";

import { tocListContainerStyles } from "@components/ToC/components/styles";

export type ListContainerProps = {
  isOpen: boolean;
  children?: React.ReactNode;
};

const className = (isOpen: boolean): string =>
  classNames("scrollable-container", { hidden: !isOpen });

const ListContainer: FC<ListContainerProps> = ({ isOpen, children }) => (
  <div className={className(isOpen)} css={tocListContainerStyles} tabIndex={0}>
    {children}
  </div>
);

export default ListContainer;
