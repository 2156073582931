import { css } from "@emotion/react";
import Color from "color";

export const ToolbarStyles = ({ pdfPreview }, isRtl) => css`
  position: absolute;
  inset-inline-start: 50%;
  bottom: 0.5rem;
  transform: translateX(${isRtl ? "50%" : "-50%"});
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem;
  background-color: ${Color(pdfPreview.toolbar.background).alpha(0.95).string()};
  border: 1px solid ${Color(pdfPreview.toolbar.background).alpha(0.2).string()};
  border-radius: 5px;
  z-index: 2;
  transition: opacity 0.5s linear;
  opacity: 0;

  .action-btn {
    background-color: transparent;

    svg {
      color: ${Color(pdfPreview.toolbar.color).alpha(0.7).string()};
    }

    &:hover {
      background-color: transparent;

      svg {
        color: ${pdfPreview.toolbar.color};
      }
    }
  }

  .current-page-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${pdfPreview.toolbar.color};
  }
`;
