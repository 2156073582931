import { css } from "@emotion/react";

import { mq } from "@utils/breakpoints";

export const optionMenuItem = ({ sidebar, sidebarExtended }, { isCollapsed }) => css`
  // Workaround for the help center item.
  &.help-center {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 1px solid ${sidebarExtended.helpBorderColor};
    border-radius: 8px;

    & .nav-item {
      margin-left: 0;
      margin-right: 0;

      .title {
        font-size: 0.875rem;
      }

      & .help-icon {
        border-radius: 0.3125rem;
        background: ${sidebarExtended.helpIconBackground};
      }
    }

    & .selected {
      background: none;
    }
  }

  // Main Navigation LI item.
  margin: 4px 12px;
  padding: ${!isCollapsed ? "4px 8px 4px 12px" : "4px 12px"};
  border-radius: 5px;
  cursor: pointer;

  .title {
    overflow: inherit;
  }

  // Main link item styles.
  .nav-link {
    width: 100%;
  }

  .main-nav-item {
    .title {
      color: ${sidebarExtended.dynamicTextColor};
    }

    .icon-container {
      color: ${sidebarExtended.dynamicTextColor};
    }

    .arrow-right {
      color: ${sidebarExtended.dynamicTextColor};
    }

    .link-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .icon-after-container {
      display: flex;
      align-items: center;
      color: ${sidebarExtended.dynamicTextColor};
    }
  }

  // Main Link item ACTIVE styles
  &.active {
    background: ${sidebarExtended.backgroundActive};

    .main-nav-item {
      width: 100%;

      .title {
        color: ${sidebarExtended.dynamicTextColorOnActive} !important;
      }

      .icon-container {
        color: ${sidebarExtended.dynamicTextColorOnActive} !important;
      }
    }
    .arrow-right {
      color: ${sidebarExtended.dynamicTextColorOnActive} !important;
    }

    .icon-after-container {
      color: ${sidebarExtended.dynamicTextColorOnActive} !important;
    }
  }

  // Main Link item HOVER styles
  &:hover {
    background: ${sidebar.backgroundHover};

    .main-nav-item {
      .title {
        color: ${sidebarExtended.dynamicTextColorOnHover};
      }

      .icon-container {
        color: ${sidebarExtended.dynamicTextColorOnHover};
      }
    }
    .arrow-right {
      color: ${sidebarExtended.dynamicTextColorOnHover};
    }

    .icon-after-container {
      color: ${sidebarExtended.dynamicTextColorOnHover};
    }
  }

  ${mq["md"]} {
    position: relative;

    &:hover,
    &.is-active-submenu {
      &.force-hide {
        .sub-menu-container {
          display: none !important;
        }
      }

      .sub-menu-container {
        display: block;

        &.profile {
          .sub-menu {
            max-height: 30rem;
          }
        }
      }
    }
  }

  // PROFILE SUB-MENU
  .profile-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;

    .close-menu {
      background: none;
      border: none;
    }

    .user-info {
      display: flex;
      align-items: center;
      gap: 8px;
      .user-name {
        word-break: break-word;
      }
      .user-email {
        width: 13.75rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${sidebarExtended.secondaryBase};
      }
    }
  }

  .profile-menu-section {
    &:not(:last-of-type) {
      margin-bottom: 0.75rem;
      padding-bottom: 0.75rem;
      border-bottom: 1px solid ${sidebarExtended.backgroundHover};
    }

    &.switch-branch {
      span {
        display: block;
        margin-bottom: 0.5rem;
      }
      .title {
        display: inline-block !important;
        max-width: 15rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  // NEW SUB-MENU IMPLEMENTATION
  .main-nav-item {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .arrow-right {
      line-height: 0;
    }

    div.nav-item {
      padding: 0;

      &:hover {
        background: none;
      }
    }
  }

  .sub-menu-container {
    display: none;
    position: absolute;
    top: 0;
    inset-inline-start: 100%;
    color: ${sidebarExtended.black};
    // Main link item styles.

    .sub-nav-link {
      width: 100%;
      .title {
        color: black;
      }

      .icon-container {
        color: black !important;
      }
    }

    &:active {
      display: block;
    }
    // When we hit the bottom of the window we want to flip the sub-menu
    &.upwards {
      top: auto;
      bottom: calc(100% - 40px);
    }

    &.mobile {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100%;
      background: ${sidebarExtended.white};
      z-index: 9999;
      cursor: auto;

      &.is-active {
        display: block;

        .sub-menu {
          margin-inline-start: 0;
          height: 100%;
        }
      }
    }
  }

  .sub-menu {
    width: 100%;
    padding: 1rem;
    margin-inline-start: 20px;
    border-radius: 8px;
    background: ${sidebarExtended.white};
    box-shadow: 0 3px 6px 0 ${sidebarExtended.boxShadow};
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 30rem;

    ${mq["md"]} {
      min-width: 15.125rem;
      max-width: 20rem;
    }

    .sub-menu-mobile-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid ${sidebarExtended.backgroundHover};
      margin-bottom: 1.125rem;
      padding: 1rem 0;

      h3 {
        margin: 0;
      }

      .close-menu {
        background: none;
        border: none;
      }
    }

    .nav-item {
      color: ${sidebarExtended.textColor};
      border-radius: 5px;
      padding: 0;

      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }

      &:hover {
        background: ${sidebarExtended.backgroundHover};
        color: ${sidebarExtended.black};
      }
    }
  }
`;
