import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["signInPage"] => {
  return {
    formBackground: colors.white,
    border: colors.secondary.light,
    borderColor: colors.secondary.lighter,
    headerBackground: colors.white,
    background: colors.secondary.lighter,
    secondaryText: colors.secondary.base,
  };
};
