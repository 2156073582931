import Color from "color";

import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["imageSelector"] => {
  return {
    color: colors.black,
    hoverColor: colors.primary.lighter,
    backgroundOverlayHoverColor: Color(colors.primary.darker).alpha(0.5).string(),
    wrapperHoverColor: Color(colors.primary.lightest).alpha(0.25).string(),
  };
};
