import { GnosisTheme } from "@epignosis_llc/gnosis";
import Color from "color";

import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): GnosisTheme["sidebar"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    background: colors.primary.base,
    backgroundHover: protectColorContrast(
      "darker",
      Color(colors.primary.lightest).alpha(0.4).string(),
      0.4,
    ),
    backgroundHoverShadow: protectColorContrast("darkest", colors.primary.darker),
    color: protectColorContrast("black", colors.white),
    colorHover: protectColorContrast("darkest", colors.white),
    hamburgerBackground: colors.white,
    border: protectColorContrast("darker", colors.primary.base),
  };
};
