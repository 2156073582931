import React, { FC, useState } from "react";
import { useMutation } from "react-query";

import { Button } from "@epignosis_llc/gnosis";
import { PreviewIconSVG } from "@epignosis_llc/gnosis/icons";
import { AxiosError } from "axios";

import { CountMassActionModalBody } from "@components/ReusableComponents/MassActions/styles";

import { handlePreviewCertificateTemplateErrors } from "@errors";
import { TemplatePreview } from "@views/CourseEdit";

import { getCertificateTemplatePreview } from "@api/certificates";
import { useApplyTranslations } from "@hooks";

import queryKeys from "@constants/queryKeys";

import ItemSelect from "@components/ReusableComponents/MassActions/components/ItemSelect";
import { MassActionEnum } from "@components/ReusableComponents/MassActions/types";

import { GroupedOption, SelectOption } from "types/common";

type ModalBodyProps = {
  total: number;
  type: MassActionEnum;
  confirmationBodyTitle: string;
  isCountLoading: boolean;
  shouldTriggerCount: boolean;
  itemNameLabel?: string;
  handleItemSelect: (selectedItems: SelectOption | SelectOption[]) => void;
  calculateSelectOptions: () => SelectOption[] | GroupedOption[];
};

const ModalBody: FC<ModalBodyProps> = ({
  total,
  type,
  confirmationBodyTitle,
  isCountLoading,
  shouldTriggerCount,
  itemNameLabel,
  handleItemSelect,
  calculateSelectOptions,
}) => {
  const { t } = useApplyTranslations();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [certificateId, setCertificateId] = useState<number | null>(null);

  const {
    mutate: certificateTemplatePreviewMutation,
    isLoading: isLoadingCertificateTemplatePreview,
  } = useMutation([queryKeys.certificates.templatePreview], getCertificateTemplatePreview, {
    onSuccess: (res) => {
      setPreviewUrl(res._data.preview_url);
    },
    onError: (err) => {
      const error = err as AxiosError;
      handlePreviewCertificateTemplateErrors(error);
    },
  });

  const shouldShowConfirmationBodyTitle =
    total > 0 ||
    type === MassActionEnum.add_users_to_group_courses ||
    type === MassActionEnum.remove_users_from_group_courses ||
    type === MassActionEnum.custom_reports_delete_users;
  if (shouldTriggerCount) {
    if (isCountLoading) {
      return <></>;
    }
    return shouldShowConfirmationBodyTitle ? (
      <div
        dangerouslySetInnerHTML={{
          __html: t(confirmationBodyTitle, { count: total, itemName: itemNameLabel }),
        }}
      />
    ) : (
      <></>
    );
  }

  const showPreviewButton = type === MassActionEnum.assign_certification && certificateId;

  const handleCertificationSelection = (option: SelectOption | SelectOption[]): void => {
    handleItemSelect(option);
    setCertificateId(Number((option as SelectOption).value));
    setPreviewUrl(null);
  };

  const handlePreviewClick = (): void => {
    if (certificateId) certificateTemplatePreviewMutation(certificateId.toString());
  };

  return type === MassActionEnum.assign_certification ? (
    <div css={CountMassActionModalBody}>
      <div className="certification-container">
        <div className="item-select-container">
          <ItemSelect
            type={type}
            selectOptions={calculateSelectOptions()}
            handleSelect={handleCertificationSelection}
          />
        </div>
        {showPreviewButton && (
          <div className="preview-button-container">
            <Button
              variant="ghost"
              iconBefore={PreviewIconSVG}
              onClick={handlePreviewClick}
              isLoading={isLoadingCertificateTemplatePreview}
            >
              {t("general.preview")}
            </Button>
          </div>
        )}
      </div>
      {previewUrl && (
        <TemplatePreview
          url={previewUrl}
          minHeight={235}
          onClose={(): void => setPreviewUrl(null)}
        />
      )}
    </div>
  ) : (
    <ItemSelect
      type={type}
      selectOptions={calculateSelectOptions()}
      handleSelect={handleItemSelect}
    />
  );
};

export default ModalBody;
