import { useMutation } from "react-query";

import { processEvent } from "@api/processEvent";

import queryKeys from "@constants/queryKeys";
import { WATCH_DEMO_URL } from "@layouts/DashboardLayout/components/TopBarMenu/components/WatchDemoButton/constants";

const useWatchDemo = (): { handleWatchDemoClick: () => void } => {
  const { mutate: trackWatchVideoEventMutation } = useMutation(
    [queryKeys.analytics.trackEvent],
    () => processEvent("watch_demo"),
  );

  const handleWatchDemoClick = (): void => {
    window.open(WATCH_DEMO_URL, "_blank");
    trackWatchVideoEventMutation();
  };

  return { handleWatchDemoClick };
};

export default useWatchDemo;
