import useAuth from "@hooks/useAuth";

import localStorageKeys from "@constants/localStorageKeys";

export const useIsPublicCourse = (): boolean => {
  const token = localStorage.getItem(localStorageKeys.PUBLIC_COURSE);
  const { isAuthenticated } = useAuth();

  return !isAuthenticated && Boolean(token);
};

export default useIsPublicCourse;
