import React, { FC, PropsWithChildren } from "react";

import { Grid } from "@epignosis_llc/gnosis";

import { tabFormFieldStyles } from "@views/CourseEdit/components/CourseOptions/components/styles";

import { TAB_WRAPPER_TEMPLATE_COLUMNS } from "@views/CourseEdit/components/CourseOptions/constants";

import { Divider, TextWithIcon } from "@components";
import Note from "@views/CourseEdit/components/CourseOptions/components/Note";

import { IconType, ResponsiveValues } from "types/common";

type TabFormFieldProps = {
  text: string;
  icon: IconType;
  note?: string;
  id?: string;
  colSpan?: ResponsiveValues | "auto" | undefined;
  hasDivider?: boolean;
};

const TabFormField: FC<PropsWithChildren & TabFormFieldProps> = ({
  text,
  icon: Icon,
  note,
  id,
  colSpan = 1,
  hasDivider = true,
  children,
}) => {
  return (
    <>
      <Grid.Item id={id} colSpan={TAB_WRAPPER_TEMPLATE_COLUMNS} css={tabFormFieldStyles}>
        <div className="text-container">
          <TextWithIcon icon={<Icon height={32} />} label={text} noGap />
          {note && <Note text={note} className="form-field-note" />}
        </div>

        <div className="form-field-children">
          <Grid templateColumns={TAB_WRAPPER_TEMPLATE_COLUMNS} gap={1}>
            <Grid.Item colSpan={colSpan}>{children}</Grid.Item>
          </Grid>
        </div>
      </Grid.Item>

      {hasDivider && (
        <Grid.Item colSpan={TAB_WRAPPER_TEMPLATE_COLUMNS}>
          <Divider />
        </Grid.Item>
      )}
    </>
  );
};

export default TabFormField;
