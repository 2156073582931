import React, { FC, PropsWithChildren } from "react";

import SkeletonAccordion, { SkeletonAccordionProps } from "@components/Skeleton/SkeletonAccordion";
import SkeletonBox from "@components/Skeleton/SkeletonBox";
import SkeletonBoxWidget from "@components/Skeleton/SkeletonBoxWidget";
import SkeletonCard from "@components/Skeleton/SkeletonCard";
import SkeletonCategory from "@components/Skeleton/SkeletonCategory";
import SkeletonGeneral from "@components/Skeleton/SkeletonGeneral";
import SkeletonIlts, { SkeletonIltsProps } from "@components/Skeleton/SkeletonIlts";
import SkeletonListItem, { SkeletonListItemProps } from "@components/Skeleton/SkeletonListItem";
import SkeletonLoader, { SkeletonLoaderProps } from "@components/Skeleton/SkeletonLoader";
import SkeletonPieChart from "@components/Skeleton/SkeletonPieChart";
import SkeletonTable, { SkeletonTableProps } from "@components/Skeleton/SkeletonTable";
import SkeletonTabWidget from "@components/Skeleton/SkeletonTabWidget";
import SkeletonTitle from "@components/Skeleton/SkeletonTitle";

export type SkeletonCompoundProps = {
  Card: FC;
  Loader: FC<SkeletonLoaderProps>;
  TabWidget: FC;
  Box: FC;
  WidgetBox: FC;
  General: FC;
  Title: FC;
  ListItem: FC<SkeletonListItemProps>;
  Table: FC<SkeletonTableProps>;
  PieChart: FC;
  Category: FC;
  Accordion: FC<SkeletonAccordionProps>;
  Ilts: FC<SkeletonIltsProps>;
};

const Skeletons: FC<PropsWithChildren> & SkeletonCompoundProps = ({ children }) => (
  <div className="skeleton-container">{children}</div>
);

Skeletons.Card = SkeletonCard;
Skeletons.Loader = SkeletonLoader;
Skeletons.TabWidget = SkeletonTabWidget;
Skeletons.General = SkeletonGeneral;
Skeletons.Title = SkeletonTitle;
Skeletons.ListItem = SkeletonListItem;
Skeletons.Table = SkeletonTable;
Skeletons.Box = SkeletonBox;
Skeletons.PieChart = SkeletonPieChart;
Skeletons.WidgetBox = SkeletonBoxWidget;
Skeletons.Category = SkeletonCategory;
Skeletons.Accordion = SkeletonAccordion;
Skeletons.Ilts = SkeletonIlts;

export default Skeletons;
