import { config, getBaseUrl } from "@config";

import { URLS } from "@constants/urls";

import { Pagination } from "types/responses";

export const SKILL_NAME_FILTER_KEY = "[skill_name][like]";
export const SKILL_NAME_LENGTH_MIN = 2;
export const SKILL_FILTER_MIN_SEARCH_VALUE_LENGTH = SKILL_NAME_LENGTH_MIN;
export const PAGINATION: Pagination = { number: 1, size: 20 };
export const PAGINATION_FOR_DRAWER: Pagination = { number: 1, size: 10 };

const originUrl = window.location.origin;
const baseUrl = config.isDev() ? "" : getBaseUrl();
export const SKILLS_SETTINGS_URL = `${originUrl}${baseUrl}${URLS.accountAndSettings.accountAndSettings}?tab=skillSettings`;

export const MAX_ALLOWED_SKILLS_USERS_ASSOCIATIONS = 25;
