import React from "react";
import { FieldError, FieldErrors } from "react-hook-form";

import { InputError } from "@epignosis_llc/gnosis";

import { t } from "@utils/i18n";

export const handleEmailErrors = (
  errors: FieldErrors,
  fieldName: string,
): JSX.Element | undefined => {
  const errorsArray = errors[fieldName];
  if (!errorsArray) return undefined;

  if (typeof errorsArray.message === "string") {
    return <InputError>{errorsArray.message}</InputError>;
  }

  if (Array.isArray(errorsArray)) {
    const filteredArray = errorsArray.filter((error) => error !== undefined);
    const uniqueErrorMessages = [
      ...new Set(errorsArray.map((error: FieldError) => error.message)),
    ].filter((message) => message !== undefined);

    if (uniqueErrorMessages.length === 1 && filteredArray.length > 1) {
      return (
        <InputError>{t("profileSettings.validationMessages.emailFormat", { count: 2 })}</InputError>
      );
    }

    return (
      <>
        {errorsArray.map((error, index) => (
          <InputError key={index}>{error.message}</InputError>
        ))}
      </>
    );
  }

  return undefined;
};
