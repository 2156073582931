import yup from "@utils/validation/yup-extended";

export const PortalSettingsSchema = yup.object().shape({
  site_name: yup.string().max(40),
  site_description: yup.string().max(255),
  announcement_internal: yup.lazy((value) => {
    switch (typeof value) {
      case "string":
        return yup.string().max(3000); // Schema for string
      default:
        return yup.mixed(); // Here you can decide what is the default
    }
  }),
  announcement_external: yup.lazy((value) => {
    switch (typeof value) {
      case "string":
        return yup.string().max(3000); // Schema for string
      default:
        return yup.mixed(); // Here you can decide what is the default
    }
  }),
});
