import { Column, DropdownItem, TableProps } from "@epignosis_llc/gnosis";

import { t } from "@utils/i18n";

import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

export enum CourseUsersFilters {
  group = "[group_id]",
  branch = "[branch_id]",
}

export const PAGINATION: IPagination = { number: 1, size: 10 };
const SORTING: TableProps["sorting"] = { column: "formatted_name", isDescending: false };
export const DEFAULT_FILTERS: QueryFilter[] = [];

export const DEFAULT_STATE = {
  pagination: PAGINATION,
  sorting: SORTING,
  filters: DEFAULT_FILTERS,
};

export const courseUsersWordings = {
  unenroll: {
    progressHeader: "courses.massActions.unenrollModalTitle",
    progressMessage: "courses.massActions.unenrollProgress",
    confirmationHeader: "courses.massActions.unenrollModalTitle",
    confirmationButton: "general.unenroll",
    successMessage: "courses.massActions.unenrollSuccess",
    confirmationBodyTitle: "courses.massActions.unenrollWarning",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  reset: {
    progressHeader: "courses.massActions.resetModalTitle",
    progressMessage: "courses.massActions.resetProgress",
    confirmationHeader: "courses.massActions.resetModalTitle",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
    confirmationBodyTitle: "courses.massActions.resetWarning",
    successMessage: "courses.massActions.resetSuccess",
  },
  sync: {
    progressHeader: "courses.massActions.syncModalTitle",
    progressMessage: "courses.massActions.syncProgress",
    confirmationHeader: "courses.massActions.syncModalTitle",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
    confirmationBodyTitle: "courses.massActions.syncWarning",
    successMessage: "courses.massActions.syncSuccess",
    preventSyncCompleted: "courses.massActions.syncPreventCompleted",
  },
  set_expiration_date: {
    progressHeader: "users.courses.changeExpirationDate",
    progressMessage: "courses.massActions.setExpirationDateProgress",
    confirmationHeader: "users.courses.changeExpirationDate",
    confirmationButton: "general.save",
    successMessage: "courses.massActions.setExpirationDateSuccess",
    confirmationBodyTitle: "courses.massActions.setExpirationDateWarning",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  complete: {
    progressHeader: "courses.massActions.completeModalTitle",
    progressMessage: "courses.massActions.completeProgress",
    confirmationHeader: "courses.massActions.completeModalTitle",
    confirmationButton: "general.save",
    successMessage: "courses.massActions.completeSuccess",
    confirmationBodyTitle: "courses.massActions.completeWarning",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  update_certificates: {
    progressHeader: "users.courses.massActionProgress",
    progressMessage: "users.courses.updatingCertificates",
    confirmationHeader: "users.courses.updateCertificate",
    confirmationButton: "general.update",
    successMessage: "users.courses.massUpdateCertificatesSuccess",
    confirmationBodyTitle: "users.courses.massUpdateCertificateWithWarning",
    confirmationBodyText: "users.courses.certificatesAffected",
  },
};

export const courseApprovalRequestsWordings = {
  approve: {
    progressHeader: "general.approve",
    progressMessage: "courses.massActions.approveProgress",
    confirmationHeader: "general.approve",
    confirmationButton: "general.approve",
    successMessage: "courses.massActions.approveSuccess",
    confirmationBodyTitle: "courses.massActions.approveWarning",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  decline: {
    progressHeader: "general.decline",
    progressMessage: "courses.massActions.declineProgress",
    confirmationHeader: "general.decline",
    confirmationButton: "general.decline",
    successMessage: "courses.massActions.declineSuccess",
    confirmationBodyTitle: "courses.massActions.declineWarning",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
};

export const getCourseUsersInitialColumns = (): Column[] => {
  return [
    {
      accessor: "formatted_name",
      cell: t("messages.recipient.users_one"),
      classNames: ["formatted_name"],
      headerWidth: 250,
    },
    {
      accessor: "role_in_course",
      cell: t("general.role"),
      sortOrder: "desc",
      classNames: ["role_in_course"],
    },
    {
      accessor: "progress_status",
      cell: t("general.progressStatus"),
      classNames: ["progress_status"],
      sortOrder: "desc",
    },
    {
      accessor: "enrolled",
      cell: t("general.enrollmentDate"),
      sortOrder: "desc",
      classNames: ["enrolled"],
    },
    {
      accessor: "completion_date",
      cell: t("general.completionDate"),
      classNames: ["completion_date"],
      sortOrder: "desc",
    },
    {
      accessor: "expiration_date",
      cell: t("general.expirationDate"),
      classNames: ["expiration_date"],
      sortOrder: "desc",
    },
    {
      accessor: "actions",
      cell: "",
      classNames: ["hidden"],
      sortableHeader: false,
    },
  ];
};

export const getEnrollmentRequestsColumns = (): Column[] => {
  return [
    {
      accessor: "formatted_name",
      cell: t("users.user"),
      classNames: ["user"],
      headerWidth: 200,
    },
    {
      accessor: "request_date",
      cell: t("general.requestDate"),
      classNames: ["request_date"],
      sortOrder: "desc" as Column["sortOrder"],
    },
    {
      accessor: "actions",
      cell: "",
      classNames: ["hidden"],
      sortableHeader: false,
    },
  ];
};

export const getMassActionsOptionsForCourseApproval = (): DropdownItem[] => {
  return [
    {
      label: t("general.approve"),
      value: "approve",
    },
    {
      label: t("general.decline"),
      value: "decline",
    },
  ];
};
