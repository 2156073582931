import React, { FC, ReactElement, SyntheticEvent } from "react";
import { Link } from "react-router-dom";

import { Text } from "@epignosis_llc/gnosis";
import { ArrowRightChevronSVG } from "@epignosis_llc/gnosis/icons";

import { userPagesStyles } from "@layouts/DashboardLayout/components/TopBarMenu/components/ProfileMenu/components/UserPages/UserPages.styles";

import { useApplyTranslations } from "@hooks";

type PagesItemProps = {
  key: string;
  title: string;
  url?: string;
  icon: ReactElement;
};

type MenuPagesProps = {
  pageItems: PagesItemProps[];
  hasSubMenu?: boolean;
  handleCallback?: () => void;
  handleCloseMenu?: () => void;
};

const MenuPages: FC<MenuPagesProps> = ({
  pageItems,
  hasSubMenu = false,
  handleCloseMenu,
  handleCallback,
}) => {
  const MAX_ITEM_WIDTH = 230;
  const { t } = useApplyTranslations();
  // Calculate menu item width in order to show title tooltip.
  const menuItem = React.useRef<HTMLParagraphElement>(null);
  const menuItemExceedsLimit = menuItem.current && menuItem.current?.clientWidth >= MAX_ITEM_WIDTH;

  const handlePageClick = (e: SyntheticEvent): void => {
    if (handleCallback) {
      e.preventDefault();
      handleCallback();
      return;
    }

    handleCloseMenu && handleCloseMenu();
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>): void => {
    if (e.key === "Enter" || e.key === " ") {
      handlePageClick(e);
    }
  };

  return (
    <div css={userPagesStyles} className="user-pages">
      {pageItems.map(({ key, title, icon, url = "" }) => (
        <Link
          data-testid={`${key}-menu-item`}
          key={title}
          to={url ? url : "#"}
          className="menu-item"
          onKeyDown={(e): void => handleOnKeyDown(e)}
          onClick={(e): void => handlePageClick(e)}
        >
          <div
            ref={menuItem}
            className="menu-page-item"
            title={menuItemExceedsLimit ? t(title) : ""}
          >
            <span className="menu-icon">{icon}</span>
            <Text as="p" fontSize="md" weight="400">
              {t(title)}
            </Text>
          </div>

          {hasSubMenu && <ArrowRightChevronSVG height={32} />}
        </Link>
      ))}
    </div>
  );
};

export default React.memo(MenuPages);
