import { Column, TableProps } from "@epignosis_llc/gnosis";

import { t } from "@utils/i18n";

import { QueryFilter, SelectOption } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

export const getBranchesColumns = (): Column[] => {
  return [
    {
      accessor: "name",
      cell: t("branches.branch"),
      classNames: ["name"],
      headerWidth: 200,
    },
    {
      accessor: "description",
      cell: t("branches.description"),
      classNames: ["description"],
      sortOrder: "desc" as const,
      headerWidth: 400,
      maxWidth: 400,
    },
  ];
};

export const actionsColumn = {
  accessor: "actions",
  cell: "",
  classNames: ["hidden"],
  sortableHeader: false,
};

export const getMassActionsOptions = (): SelectOption[] => {
  return [
    { label: t("branches.addCourseToAllBranches"), value: "add_course_to_all_branches" },
    { label: t("branches.removeCourseFromAllBranches"), value: "remove_course_from_all_branches" },
  ];
};

export const branchesMassActionWordings = {
  add_course_to_all_branches: {
    progressHeader: "branches.addCourseToAllBranches",
    progressMessage: "branches.addingCourseToAllBranches",
    confirmationBodyText: "branches.branchesAffectedByMassAction",
    confirmationHeader: "branches.addCourseToAllBranches",
    confirmationButton: "general.actions.add",
    successMessage: "branches.addCourseToAllBranchesSuccess",
  },
  remove_course_from_all_branches: {
    progressHeader: "branches.removeCourseFromAllBranches",
    progressMessage: "branches.removingCourseFromAllBranches",
    confirmationHeader: "branches.removeCourseFromAllBranches",
    confirmationButton: "general.actions.remove",
    successMessage: "branches.removeCourseFromAllBranchesSuccess",
    confirmationBodyText: "branches.branchesAffectedByMassAction",
  },
};

export const SORTING: TableProps["sorting"] = { column: "name", isDescending: false };
export const PAGINATION: IPagination = { number: 1, size: 10 };
export const DEFAULT_FILTERS: QueryFilter[] = [];
export const signUpOptions = ["direct", "manual"];
export const ssoTypes = ["oidc", "ldap", "saml"];
export const verificationOptions = [
  "none",
  "captcha",
  "captcha_email",
  "captcha_admin",
  "email",
  "admin",
];
export const subscriptionIntervalOptions = ["monthly", "annually"];
export const ecommerceProcessorOptions = ["none", "stripe", "paypal"];
export const ecommerceProcessorLabelMapping = {
  stripe: "Stripe",
  paypal: "PayPal",
};
