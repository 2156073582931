import React, { ReactNode } from "react";

import { Tooltip } from "@epignosis_llc/gnosis";
import { FeatureLockSVG } from "@epignosis_llc/gnosis/icons";

type LockedFeatureIconProps = {
  tooltipContent?: ReactNode | string;
  className?: string;
};

const LockedFeatureIcon: React.FC<LockedFeatureIconProps> = ({
  tooltipContent,
  className,
}): JSX.Element => {
  const icon = (
    <div className="settings-tooltip">
      <FeatureLockSVG />
    </div>
  );

  return (
    <div className={className}>
      {tooltipContent ? (
        <Tooltip content={tooltipContent} parentProps={{ "aria-label": tooltipContent }}>
          {icon}
        </Tooltip>
      ) : (
        icon
      )}
    </div>
  );
};

export default LockedFeatureIcon;
