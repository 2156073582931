import { ENDPOINTS } from "@api/endpoints";
import { PatchUserGroupSchema } from "@api/group";
import HttpClient from "@api/HttpClient";

import { marketoQueryParam } from "@constants/queryParameters";

import { MassActionParam } from "@components/ReusableComponents";

import { ProfileFormSchema, ProfilePatchSchema } from "@views/Settings/types";
import {
  AssignableUserTypesRes,
  BranchesRes,
  CountMassActionResponse,
  CustomFieldRes,
  FileRes,
  FilesRes,
  GroupsRes,
  MassActionResultResponse,
  TableCSVExportRes,
  UserFilesRes,
  UserGroupsRes,
  UserIntegrationsRes,
  UserRes,
  UserRewardsRes,
  UsersRes,
  UserStatisticsRes,
} from "types/responses";

const formContentType = {
  "Content-Type": "multipart/form-data",
};

export type EditUserFileBodyData = Partial<{
  name: string;
  tags: string[];
  shared: boolean;
}>;

export const getUser = async (userId: string): Promise<UserRes> => {
  const res = await HttpClient.get(ENDPOINTS.users.user(userId));

  return res.data;
};

export const getAssignableTypes = async (): Promise<AssignableUserTypesRes> => {
  const res = await HttpClient.get(ENDPOINTS.users.assignableTypes);

  return res.data;
};

export const getUsers = async (queryStr = ""): Promise<UsersRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.users.users}${queryStr}`);

  return res.data;
};

export const getUserStatistics = async (): Promise<UserStatisticsRes> => {
  const res = await HttpClient.get(ENDPOINTS.user.statistics);

  return res.data;
};

export const getUserRewards = async (): Promise<UserRewardsRes> => {
  const res = await HttpClient.get(ENDPOINTS.user.rewards);

  return res.data;
};

export const getCustomFields = async (): Promise<CustomFieldRes> => {
  const res = await HttpClient.get(ENDPOINTS.user.customFields);

  return res.data;
};

export const getMyFiles = async (queryStr = ""): Promise<FilesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.user.files}${queryStr}`);
  return res.data;
};

// Export my files to CSV
export const myFilesExport = async (queryStr = ""): Promise<TableCSVExportRes> => {
  const res = await HttpClient.post(`${ENDPOINTS.tableExports.myFilesExport}${queryStr}`);

  return res.data;
};

export const getUserIntegrations = async (): Promise<UserIntegrationsRes> => {
  const res = await HttpClient.get(ENDPOINTS.user.integrations);

  return res.data;
};

export const postUserPassword = async (data: {
  password: string;
  current_password: string;
}): Promise<void> => {
  // By calling this method, a Marketo event will be automatically triggered.
  await HttpClient.post(`${ENDPOINTS.user.password}?${marketoQueryParam}`, data);
};

export const createUser = async (data: ProfileFormSchema): Promise<UserRes> => {
  const formData = new FormData();

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key as keyof ProfileFormSchema];

      if (key === "avatarFile" && value instanceof File) {
        formData.append("avatar", value);
      } else if (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean" ||
        value instanceof Blob
      ) {
        formData.append(key, value.toString());
      }
    }
  }

  // By calling this method, a Marketo event will be automatically triggered.
  const res = await HttpClient.post(`${ENDPOINTS.users.users}?${marketoQueryParam}`, formData);

  return res.data;
};

export const postUserAvatar = async (file: File, userId?: number): Promise<FilesRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("avatar", file);

  const endpoint = userId ? ENDPOINTS.users.avatar(userId.toString()) : ENDPOINTS.user.avatar;

  const res = await HttpClient.post(endpoint, bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const patchUserProfile = async (data: ProfilePatchSchema): Promise<void> => {
  // By calling this method, a Marketo event will be automatically triggered.
  await HttpClient.patch(`${ENDPOINTS.user.updateProfile}?${marketoQueryParam}`, data);
};

export const deleteUserAvatar = async (userId?: number): Promise<void> => {
  const endpoint = userId ? ENDPOINTS.users.avatar(userId.toString()) : ENDPOINTS.user.avatar;

  await HttpClient.delete(endpoint);
};

export const updateUser = async (userId: string, data: ProfileFormSchema): Promise<void> => {
  // By calling this method, a Marketo event will be automatically triggered.
  await HttpClient.patch(`${ENDPOINTS.users.user(userId)}?${marketoQueryParam}`, data);
};

export const deleteUserWithId = async (userId: string): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.user.singleUser(userId));
};

export const getMyBranches = async (queryStr = ""): Promise<BranchesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.user.branches}${queryStr}`);

  return res.data;
};

export const getUserBranches = async (queryStr = "", userId: string): Promise<BranchesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.users.branches(userId)}${queryStr}`);

  return res.data;
};

export const getMyGroups = async (queryStr = ""): Promise<GroupsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.user.groups}${queryStr}`);

  return res.data;
};

// Export my groups to CSV
export const myGroupsExport = async (queryStr = ""): Promise<TableCSVExportRes> => {
  const res = await HttpClient.post(`${ENDPOINTS.tableExports.myGroupsExport}${queryStr}`);

  return res.data;
};

export const putUserGroup = async (data: PatchUserGroupSchema): Promise<void> => {
  await HttpClient.put(ENDPOINTS.user.joinGroup, data);
};

export const deleteUserProfile = async (): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.user.profile);
};

export const deleteUser = async (userId: string): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.users.user(userId));
};

export const getGroups = async (userId: string, queryStr = ""): Promise<UserGroupsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.users.groups(userId)}${queryStr}`);

  return res.data;
};

/** *** USER FILES endpoints *****/
export const getUserFiles = async (userId: string, queryStr = ""): Promise<UserFilesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.users.files(userId)}${queryStr}`);

  return res.data;
};

export const editUserFile = async ({
  userId,
  fileId,
  data,
}: {
  userId: string;
  fileId: string;
  data: EditUserFileBodyData;
}): Promise<void> => {
  await HttpClient.patch(ENDPOINTS.users.filesEdit(userId, fileId), data);
};

export const postUserFile = async ({
  userId,
  file,
}: {
  userId: string;
  file: File;
}): Promise<FileRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  const endpoint = `${ENDPOINTS.users.filesUpload(userId)}`;

  const res = await HttpClient.post(endpoint, bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const postUserFileURL = async ({
  userId,
  fileURL,
}: {
  userId: string;
  fileURL: string;
}): Promise<FileRes> => {
  const endpoint = `${ENDPOINTS.users.filesUploadURL(userId)}`;
  const body = { url: fileURL };

  const res = await HttpClient.post(endpoint, body);

  return res.data;
};

export const deleteUserFile = async ({
  userId,
  fileId,
}: {
  userId: string;
  fileId: string;
}): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.users.filesEdit(userId, fileId));
};

export const usersMassActionsCount = async (
  type: string,
  userIds: number[],
  data?: MassActionParam | null,
): Promise<CountMassActionResponse> => {
  const body = { user_ids: userIds, ...data };
  let endpoint = "";

  switch (type) {
    case "activate":
      endpoint = ENDPOINTS.users.activateCount;
      break;
    case "deactivate":
      endpoint = ENDPOINTS.users.deactivateCount;
      break;
    case "delete":
      endpoint = ENDPOINTS.users.deleteCount;
      break;
    case "add_to_groups":
      endpoint = ENDPOINTS.users.addUsersToGroupsCount;
      break;
    case "remove_from_groups":
      endpoint = ENDPOINTS.users.removeUsersFromGroupsCount;
      break;
    case "add_to_branches":
      endpoint = ENDPOINTS.users.addUsersToBranchesCount;
      break;
    case "remove_from_branches":
      endpoint = ENDPOINTS.users.removeUsersFromBranchesCount;
      break;
    default:
      break;
  }

  const res = await HttpClient.post(endpoint, body);

  return res.data;
};

export const usersMassActions = async (
  type: string,
  userIds: number[],
  data?: MassActionParam | null,
): Promise<MassActionResultResponse> => {
  const body = { user_ids: userIds, ...data };
  let endpoint = "";
  let method: "put" | "post";

  switch (type) {
    case "activate":
      endpoint = ENDPOINTS.users.activateMassAction;
      method = "put";
      break;
    case "deactivate":
      endpoint = ENDPOINTS.users.deactivateMassAction;
      method = "put";
      break;
    case "delete":
      endpoint = ENDPOINTS.users.deleteMassAction;
      method = "post";
      break;
    case "add_to_groups":
      endpoint = ENDPOINTS.users.addUsersToGroups;
      method = "put";
      break;
    case "remove_from_groups":
      endpoint = ENDPOINTS.users.removeUsersFromGroups;
      method = "post";
      break;
    case "add_to_branches":
      endpoint = ENDPOINTS.users.addUsersToBranches;
      method = "put";
      break;
    case "remove_from_branches":
      endpoint = ENDPOINTS.users.removeUsersFromBranches;
      method = "post";
      break;
    default:
      throw new Error("Invalid mass action type provided");
  }

  const res = await HttpClient[method](endpoint, body);

  return res.data;
};
