import { ThemePlusTypes } from "@styles/types";
import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["fileCard"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    detailsColor: colors.secondary.darker,
    fileCover: {
      color: colors.secondary.light,
      background: colors.secondary.lighter,
      textColor: colors.white,
    },
    fileType: {
      color: colors.white,
      blueColor: colors.primary.base,
      background: protectColorContrast("darker", colors.primary.base),
    },
  };
};
