import React, { FC, ReactElement, useCallback, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { SerializedStyles } from "@emotion/react";
import {
  Button,
  Checkbox,
  FormError,
  Heading,
  Input,
  InputError,
  Select,
  Text,
} from "@epignosis_llc/gnosis";
import { HideIconSVG, IconPreviewSVG } from "@epignosis_llc/gnosis/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { format } from "date-fns";

import { signUpForm } from "@components/SignUp/styles";

import { HandledError } from "@errors";
import { handleSignUpErrors } from "@errors/errors";

import { guestSignup, signin, signup, SignUpData } from "@api/app";
import { getCustomFields } from "@api/user";
import { useApplyTranslations, useEnrollmentMutation } from "@hooks";
import { useConfigurationStore, useUIStore } from "@stores";
import { dateFormatsMapping } from "@utils/helpers/date-time";
import authService from "@utils/services/AuthService";
import {
  MandatoryCustomFieldSchema,
  MaxLengthCustomFieldSchema,
  SignUpFormValidationSchema,
  yup,
} from "@utils/validation";

import localStorageKeys from "@constants/localStorageKeys";
import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";

import DateInput from "@components/FormElements/DateInput/DateInput";
import PasswordStrengthBar from "@components/FormElements/PasswordStrengthBar/PasswordStrengthBar";
import SignInOrUpText from "@components/ReusableComponents/SignInOrUpText/SignInOrUpText";
import { SignUpFormProps } from "@components/SignUp/types";

import { SelectOption } from "types/common";
import { CustomField } from "types/entities";

const SignUpForm: FC<SignUpFormProps> = ({
  setRegistrationType,
  showOnModal = false,
  isPublic = false,
  prefilledData = null,
}) => {
  const { t } = useApplyTranslations();

  const navigate = useNavigate();
  const { domainSettings, socialDomainSettings } = useConfigurationStore();
  const queryClient = useQueryClient();
  const requiresCaptcha = domainSettings?.signup.requires_captcha;
  const captcha_key = domainSettings?.captcha_public_key ?? "";
  const [signupError, setSignupError] = useState("");
  const captchaRef = useRef<ReCAPTCHA>(null);
  const { setLoginType } = useUIStore();
  const language = domainSettings?.locale;
  const [hasStrongPassword, setHasStrongPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const pendingEnrollment = localStorage.getItem(localStorageKeys.PENDING_CATALOG_ENROLLMENT);
  const courseId = pendingEnrollment ? JSON.parse(pendingEnrollment).courseId : undefined;
  const { enrollmentMutation } = useEnrollmentMutation(courseId);

  let validationSchema = SignUpFormValidationSchema;

  // Get custom fields
  const { data: customFieldsData } = useQuery(queryKeys.customFields, getCustomFields, {
    onSuccess: (res) => {
      const customFields = res?._data;

      if (customFields) {
        // Get all mandatory custom fields
        const mandatoryFields = customFields.filter((item) => item.mandatory).map((a) => a.id);

        // Create object with mandatory custom fields validations
        const mandatoryFieldsSchema = mandatoryFields.reduce((schema, field) => {
          schema[field] = MandatoryCustomFieldSchema;
          return schema;
        }, {});

        // Get all fields with max length
        const maxLengthFields = customFields.filter((item) => item.max_length);

        // Create object with max length custom fields validations
        const maxLengthFieldsSchema = maxLengthFields.reduce((schema, field) => {
          if (field.max_length) {
            schema[field.id] = MaxLengthCustomFieldSchema(field.max_length);
          }
          return schema;
        }, {});

        if (mandatoryFields || maxLengthFields) {
          // Create validation schema for custom fields
          const customFieldValidationSchema = yup.object().shape({
            custom_fields: yup
              .object()
              .shape({ ...mandatoryFieldsSchema })
              .concat(yup.object().shape({ ...maxLengthFieldsSchema })),
          });

          formReset(customFields);

          // Set validation schema
          validationSchema = validationSchema.concat(customFieldValidationSchema);
        }
      }
    },
    refetchOnWindowFocus: false,
  });

  const customFields = customFieldsData?._data;

  const storageKey = localStorage.getItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP);
  const externalEnrollment = storageKey ? JSON.parse(storageKey).enrollment === true : false;
  const externalId = storageKey ? JSON.parse(storageKey).courseId : "";
  const plainRedirectUrl = storageKey ? JSON.parse(storageKey).redirectUrl : "";
  const activeBundle = storageKey ? JSON.parse(storageKey).activeBundle : "";
  const hasPrefilledData = prefilledData !== null;
  const dateFormat = domainSettings ? dateFormatsMapping[domainSettings.date_format] : undefined;

  const defautValuesObject = {
    name: hasPrefilledData ? prefilledData.name : "",
    surname: hasPrefilledData ? prefilledData.surname : "",
    email: hasPrefilledData ? prefilledData.email : "",
    login: hasPrefilledData ? prefilledData.login : "",
    password: hasPrefilledData ? "tempStronPassword!@#123" : "",
    session_data: prefilledData?.sessionData,
  };

  const {
    control,
    handleSubmit,
    register,
    setError,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<SignUpData>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: defautValuesObject,
  });

  const onSubmitSuccess = async ({
    name,
    surname,
    email,
    login,
    password,
    custom_fields,
    session_data,
  }: SignUpData): Promise<void> => {
    if (password && !hasStrongPassword) {
      setError("password", {
        type: "manual",
        message: t("signUp.validationMessages.strongPassword"),
      });
    } else {
      // Reconstruct custom fields to object with name as key
      const dataCustomFields =
        custom_fields &&
        Object.keys(custom_fields).reduce((obj, key) => {
          const newKey = customFields?.find((field) => field.id.toString() === key)?.name ?? "";
          return { ...obj, [newKey]: custom_fields[key] };
        }, {});

      try {
        let registerResponse;
        const signUpData = {
          name,
          surname,
          email,
          login,
          password,
          captcha_token: captchaRef?.current?.getValue(),
          session_data,
          ...dataCustomFields,
        } as SignUpData;

        if (isPublic) {
          registerResponse = await guestSignup(signUpData);
        } else {
          registerResponse = await signup(signUpData);
        }

        const loginType = registerResponse?.required;

        if (loginType) {
          return setRegistrationType(loginType);
        } else if (prefilledData && prefilledData.socialServiceType !== null) {
          navigateToSocialSignInUrl(prefilledData.socialServiceType);
        } else {
          const authData = await signin({ username: login, password });
          authService.setTokens(authData);
          authService.setDefaultRole(authData.default_role);
          queryClient.invalidateQueries([queryKeys.catalogSettings]);

          setLoginType("direct");
          queryClient.invalidateQueries([queryKeys.catalogSettings]);

          // If the sign-up is performed from external catalog
          if (storageKey) {
            externalEnrollment && enrollmentMutation();

            if (plainRedirectUrl) {
              // If login or sign-up is performed from public header
              navigate(plainRedirectUrl);
            } else {
              if (externalId) {
                // Check for payment

                const url = URLS.catalog.createCourseLink({ courseId: externalId });

                externalEnrollment
                  ? navigate(url)
                  : navigate(url, {
                      state: { isPayment: true },
                    });
              } else {
                // Navigate on catalog and check for active bundle
                activeBundle
                  ? navigate(URLS.catalog.index, { state: { activeBundle } })
                  : navigate(URLS.catalog.index);
              }
            }

            localStorage.removeItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP);
          } else {
            navigate(URLS.dashboard);
          }
        }
      } catch (error) {
        const err = error as AxiosError;

        const handleError = (foundError: HandledError | null, axiosError: AxiosError): void => {
          if (foundError?.errorMsg) {
            if (foundError?.id === "forbidden.user_required_to_update_password") {
              const updatePasswordToken = axiosError?.response?.data._meta.update_password_token;
              if (updatePasswordToken) {
                localStorage.setItem(localStorageKeys.UPDATE_PASSWORD_TOKEN, updatePasswordToken);
                navigate(URLS.changePassword);
              }
            } else if (
              foundError?.id === "forbidden.plus_is_disabled" ||
              foundError?.id === "forbidden.user_not_allowed_to_access_plus"
            ) {
              const coreUrl = window.location.origin;
              window.location.replace(coreUrl);
            } else {
              setSignupError(t(foundError.errorMsg));
            }
          } else {
            const errors = err?.response?.data._errors ?? [];
            const errorId = errors[0]?.id;
            const validation_errors = errors[0]?.validation_errors;
            const specific_validation_error = validation_errors[0]?.invalid_rule_type;

            if (errorId === "validation_error") {
              if (specific_validation_error === "no_url") {
                setSignupError(t("signUp.validationMessages.noURL"));
              } else if (specific_validation_error === "registration_email_restriction") {
                setSignupError(t("signUp.validationMessages.registrationDomain"));
              } else {
                setSignupError(t("signUp.validationMessages.usernameOrEmail"));
              }
            }
          }
        };

        handleSignUpErrors(err, false, handleError);
      }
    }
  };

  const navigateToSocialSignInUrl = (social: string): void => {
    const navigateTo = socialDomainSettings?.filter((set) => {
      return social === set.type && set.url;
    })[0].url;

    navigateTo && window.location.replace(navigateTo);
  };

  // Get values for all custom fields
  const getCustomFieldsValues = useCallback(
    (customFields: CustomField[]): object => {
      return customFields?.reduce((acc, item) => {
        const value =
          item.type === "checkbox"
            ? item.checked
              ? "on"
              : "off"
            : item.type === "date"
              ? null
              : "";

        return { ...acc, [item.id]: value };
      }, {});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const formReset = useCallback(
    (customFields: CustomField[]) => {
      reset({
        ...defautValuesObject,
        custom_fields: { ...getCustomFieldsValues(customFields) },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCustomFieldsValues],
  );

  return (
    <form
      css={(theme): SerializedStyles => signUpForm(theme, { showOnModal })}
      onSubmit={handleSubmit(onSubmitSuccess)}
      autoComplete="off"
    >
      <Heading size="2xl" as="h1" className="title-container">
        {t("signUp.signUp")}
      </Heading>
      {/* Only allow sign up for guest signup */}
      {!isPublic && <SignInOrUpText isLoginSelected={false} />}

      <section className="form-content">
        {/* Show username when form opens on modal or the user login is not set */}

        <div className="form-item">
          <Input
            {...register("name")}
            id="name"
            data-testid="name"
            label={t("signUp.firstname")}
            size="lg"
            status={errors.name ? "error" : "valid"}
            required
          />
          {errors.name && <InputError data-testid="name-error">{errors.name.message}</InputError>}
        </div>

        <div className="form-item">
          <Input
            {...register("surname")}
            id="surname"
            data-testid="surname"
            label={t("signUp.lastname")}
            size="lg"
            status={errors.surname ? "error" : "valid"}
            required
          />
          {errors.surname && (
            <InputError data-testid="surname-error">{errors.surname.message}</InputError>
          )}
        </div>

        <div className="form-item">
          <Input
            {...register("email")}
            id="email"
            data-testid="email"
            label={t("signUp.email")}
            size="lg"
            status={errors.email ? "error" : "valid"}
            required
          />
          {errors.email && (
            <InputError data-testid="email-error">{errors.email.message}</InputError>
          )}
        </div>

        <div className="form-item">
          <Input
            {...register("login")}
            id="login"
            data-testid="login"
            label={t("signUp.username")}
            size="lg"
            status={errors.login ? "error" : "valid"}
            required
          />
          {errors.login && (
            <InputError data-testid="login-error">{errors.login.message}</InputError>
          )}
        </div>

        <div className="form-item" hidden={hasPrefilledData}>
          <Input
            {...register("password")}
            data-testid="password"
            id="password"
            type={showPassword ? "text" : "password"}
            label={t("signUp.password")}
            size={showOnModal ? "md" : "lg"}
            status={errors.password ? "error" : "valid"}
            iconAfter={showPassword ? HideIconSVG : IconPreviewSVG}
            onIconClick={(): void => {
              setShowPassword((v) => !v);
            }}
            required
          />
          <div className="input-wrapper">
            {errors.password && <InputError>{errors.password.message}</InputError>}
            <PasswordStrengthBar
              password={watch("password")}
              className="psw-bar"
              onStrengthChange={setHasStrongPassword}
            />

            <Text fontSize="xs" as="div" className="psw-note">
              {t("profileSettings.modal.text")}
            </Text>
          </div>
        </div>

        {Boolean(customFields?.length) && (
          <div className="custom-fields">
            {customFields?.map((item) => {
              return (
                <div key={item.id} className="form-item">
                  <Controller
                    name={`custom_fields.${item.id}`}
                    control={control}
                    render={({ field: { onChange, value } }): ReactElement => {
                      switch (item.type) {
                        case "checkbox":
                          return (
                            <Checkbox
                              id={item.id.toString()}
                              value={item.id.toString()}
                              name={item.id.toString()}
                              required={item.mandatory}
                              label={item.name}
                              defaultChecked={Boolean(item.checked)}
                              onChange={(value): void => {
                                // Set value properly for the API request
                                onChange(value.target.checked ? "on" : "off");
                              }}
                            />
                          );
                        case "date": {
                          return (
                            <>
                              <DateInput
                                className="date-picker"
                                id={item.id.toString()}
                                required={item.mandatory}
                                label={item.name}
                                value={value ? new Date(value) : null}
                                onChange={(value): void => {
                                  // Set the form date to have the same format with the API response
                                  onChange(value ? format(value, "yyyy-MM-dd") : "");
                                }}
                                status={errors?.custom_fields?.[item.id] ? "error" : "valid"}
                                dateFormat={dateFormat}
                              />
                              {errors?.custom_fields?.[item.id] && (
                                <InputError>{errors.custom_fields[item.id]?.message}</InputError>
                              )}
                            </>
                          );
                        }
                        case "dropdown": {
                          const options =
                            item.dropdown_items?.map(({ item }) => ({
                              label: item,
                              value: item,
                            })) ?? [];

                          const defaultOption = item.dropdown_items?.find(
                            (item) => item.default,
                          )?.item;

                          const isInItems = item.dropdown_items?.some(
                            (item) => item.item === value,
                          );

                          const isValueInItems = !value || !isInItems;

                          const selectedOption =
                            (defaultOption && isValueInItems) || value?.length == 0
                              ? null
                              : { label: value, value: value };

                          return (
                            <>
                              <Select
                                id={item.id.toString()}
                                required={item.mandatory}
                                label={item.name}
                                aria-label={item.name}
                                placeholder="-"
                                value={selectedOption}
                                defaultValue={selectedOption}
                                status={errors?.custom_fields?.[item.id] ? "error" : "valid"}
                                options={options}
                                onChange={(option): void => {
                                  const { value } = option as SelectOption;
                                  onChange(value);
                                }}
                              />

                              {errors?.custom_fields?.[item.id] && (
                                <InputError>{errors.custom_fields[item.id]?.message}</InputError>
                              )}
                            </>
                          );
                        }

                        default:
                          return (
                            <>
                              <Input
                                id={item.id.toString()}
                                required={item.mandatory}
                                label={item.name}
                                value={value ?? ""}
                                onChange={onChange}
                                data-lpignore="true"
                                status={errors?.custom_fields?.[item.id] ? "error" : "valid"}
                              />

                              {errors?.custom_fields?.[item.id] && (
                                <InputError>{errors.custom_fields[item.id]?.message}</InputError>
                              )}
                            </>
                          );
                      }
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}

        {requiresCaptcha && (
          <div className="form-item reCaptcha">
            <ReCAPTCHA hl={language} sitekey={captcha_key} ref={captchaRef} />
          </div>
        )}

        {Boolean(signupError) && (
          <div className="login-error-wrapper">
            <FormError>
              <p className="login-error-container">{signupError}</p>
            </FormError>
          </div>
        )}
      </section>

      <Button type="submit" block isLoading={isSubmitting} className="sign-in-btn">
        {t("signUp.createAccount")}
      </Button>
    </form>
  );
};

export default SignUpForm;
