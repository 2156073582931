import { GnosisTheme } from "@epignosis_llc/gnosis";

import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): GnosisTheme["tabs"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);
  return {
    headerBackground: colors.white,
    headerBorder: colors.secondary.base,
    linkBorder: protectColorContrast("darker", colors.primary.base),
    linkHover: protectColorContrast("darkest", colors.primary.base),
  };
};
