import React, { FC } from "react";

import { Heading, Text } from "@epignosis_llc/gnosis";

import { useApplyTranslations } from "@hooks";

import { rewardTranslations } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/constants";

import RewardProgressBar from "@views/WidgetsDashboard/components/Widgets/Leaderboard/RewardProgressBar";

import { Reward } from "types/entities/User";

type RewardItemProps = {
  header: string;
  userData: string;
  progress: number | string;
  icon: JSX.Element;
  points?: Reward | null;
  badges?: Reward | null;
  level?: Reward | null;
  type?: "points" | "badges" | "level";
};

const RewardItem: FC<RewardItemProps> = ({
  header,
  userData,
  progress,
  icon,
  points,
  badges,
  level,
  type,
}) => {
  const { t } = useApplyTranslations();

  const isProgressNumber = typeof progress === "number";
  const progressText = isProgressNumber ? progress.toFixed() : progress;
  const reward = type === "points" ? points : type === "badges" ? badges : level;

  return (
    <div className="reward-container">
      <div className="text-container">
        <div className="text-icon-container">
          <div className="avatar">{icon}</div>
          <Heading as="h3" size="md" className="header-text">
            {header}
          </Heading>
        </div>

        <div className="points-container">
          <Text fontSize="md" as="div" className="number-text" weight="700">
            {userData}
          </Text>
        </div>
      </div>
      {type && reward && (
        <div className="reward-label">
          <Text fontSize="sm">
            {t(`rewards.drawer.${rewardTranslations[type]}`, {
              count: reward.threshold,
              percentage: reward.percentage,
            })}
          </Text>
        </div>
      )}
      <div className="progress-bar-container">
        <RewardProgressBar percent={isProgressNumber ? Number(progressText) : progressText} />
      </div>
      <div className="progress-text-container"></div>
    </div>
  );
};

export default RewardItem;
