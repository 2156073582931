import React, { FC } from "react";

import { Chip, DropdownItem } from "@epignosis_llc/gnosis";

import { convertToDataTestIdName } from "@utils/helpers";

import { IconType } from "types/common";

type ActiveFiltersProps = {
  maxFilterWidth: number;
  activeFilters: DropdownItem[];
  onFilterRemove?: (filter: DropdownItem) => void;
};

const ActiveFilters: FC<ActiveFiltersProps> = ({
  maxFilterWidth,
  activeFilters,
  onFilterRemove,
}) => {
  if (!activeFilters.length) return null;

  return (
    <>
      {activeFilters.map((filter: DropdownItem) => {
        return (
          <Chip
            key={filter.value}
            className="action-item"
            icon={filter.icon as unknown as IconType}
            onClose={(): void => onFilterRemove?.(filter)}
            maxWidth={maxFilterWidth}
            data-testid={`${convertToDataTestIdName(filter.label.toString())}-filter`}
          >
            {filter.label}
          </Chip>
        );
      })}
    </>
  );
};

export default ActiveFilters;
