import { isValidUrlWithProtocol } from "@utils/helpers";
import { t } from "@utils/i18n";
import { yup } from "@utils/validation/validation";

export const IntegrationsIntercomSchema = yup.object().shape({
  intercom_secret_key: yup
    .string()
    .trim()
    .nullable()
    .max(50, () => t("errors.general.valueTooLong", { count: 50 }))
    .matches(/^\S*$/, () => t("errors.general.noSpacesAllowed")),
});

export const IntegrationsXApiSchema = yup.object().shape({
  is_active: yup.boolean(),
  lrs_is_active: yup.boolean(),
  lrs_endpoint: yup
    .string()
    .trim()
    .nullable()
    .max(1024, () => t("errors.general.valueTooLong", { count: 1024 }))
    .when(["is_active", "lrs_is_active"], {
      is: (isActive: boolean, lrsIsActive: boolean) => isActive && lrsIsActive,
      then: yup
        .string()
        .required(() => t("validationMessages.requiredField"))
        .matches(/^http\S*$/, () => t("errors.integrations.invalidXApiEndpoint")),
      otherwise: yup.string().transform(() => undefined),
    }),
  lrs_key: yup
    .string()
    .trim()
    .nullable()
    .max(80, () => t("errors.general.valueTooLong", { count: 80 }))
    .matches(/^\S*$/, () => t("errors.general.noSpacesAllowed"))
    .when(["is_active", "lrs_is_active"], {
      is: (isActive: boolean, lrsIsActive: boolean) => isActive && lrsIsActive,
      then: yup.string().required(() => t("validationMessages.requiredField")),
      otherwise: yup.string().transform(() => undefined),
    }),
  lrs_secret: yup
    .string()
    .trim()
    .nullable()
    .max(100, () => t("errors.general.valueTooLong", { count: 100 }))
    .matches(/^\S*$/, () => t("errors.general.noSpacesAllowed"))
    .when(["is_active", "lrs_is_active"], {
      is: (isActive: boolean, lrsIsActive: boolean) => isActive && lrsIsActive,
      then: yup.string().required(() => t("validationMessages.requiredField")),
      otherwise: yup.string().transform(() => undefined),
    }),
});

export const IntegrationsBambooHRSchema = yup.object().shape({
  domain_name: yup
    .string()
    .trim()
    .max(80, () => t("errors.general.valueTooLong", { count: 80 })),
});

export const IntegrationsConferencesSchema = yup.object().shape({
  conference_max_users: yup
    .number()
    .integer()
    .min(0)
    .max(100000, () => t("validationMessages.maxNumber", { number: 100000 })),
});

export const BigBlueButtonDrawerSchema = yup.object().shape({
  conference_max_users: yup
    .number()
    .integer()
    .min(0)
    .max(100000, () => t("validationMessages.maxNumber", { number: 100000 })),
  bbb_url: yup
    .string()
    .trim()
    .test({
      name: "isValidUrl",
      message: () => t("validationMessages.invalidUrl"),
      test: (value) => {
        if (value) {
          return isValidUrlWithProtocol(value);
        }
        return true;
      },
    })
    .max(250, () => t("errors.general.valueTooLong", { count: 250 }))
    .required(),
  bbb_salt: yup
    .string()
    .trim()
    .max(80, () => t("errors.general.valueTooLong", { count: 80 }))
    .required(),
});

export const IntegrationsPayPalSchema = yup.object().shape({
  enabled: yup.boolean().required(),
  email: yup
    .string()
    .trim()
    .email()
    .nullable()
    .max(50, () => t("errors.general.valueTooLong", { count: 50 }))
    .matches(/^\S*$/, () => t("errors.general.noSpacesAllowed"))
    .when("enabled", {
      is: true,
      then: yup.string().required(() => t("validationMessages.requiredField")),
      otherwise: yup.string().nullable(),
    }),
});

export const IntegrationsOpenSesameSchema = yup.object().shape({
  enabled: yup.boolean().required(),
  open_sesame_integration_id: yup.string().uuid().when("enabled", {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string(), // Not required if `enabled` is false
  }),
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const JavascriptIntegrationsSchema = (integration_name: string) =>
  yup.object().shape({
    key: yup
      .string()
      .trim()
      .max(50, () => t("errors.general.valueTooLong", { count: 50 }))
      .test(
        "is-gtm-prefix",
        () => t("validationMessages.validField", { integration_name }),
        (value) => {
          // Apply the test only for Google Tag Manager (GTM) integration
          if (integration_name === "google_tag_manager") {
            return typeof value === "string" && value.startsWith("GTM-");
          }
          return true; // Skip the test for other integrations
        },
      )
      .when("is_active", {
        is: true,
        then: yup.string().required(() => t("validationMessages.requiredField")),
        otherwise: yup.string().nullable(),
      }),
    app_id: yup
      .string()
      .trim()
      .max(50, () => t("errors.general.valueTooLong", { count: 50 }))
      .when([], {
        is: () => integration_name === "intercom",
        then: yup.string().required(() => t("validationMessages.requiredField")),
        otherwise: yup.string().nullable(),
      }),
  });

export const GoToIntegrationSchemas = yup.object().shape({
  client_id: yup
    .string()
    .trim()
    .max(50, () => t("errors.general.valueTooLong", { count: 50 }))
    .required(),
  client_secret: yup
    .string()
    .trim()
    .max(50, () => t("errors.general.valueTooLong", { count: 50 }))
    .required(),
  redirect_url: yup
    .string()
    .trim()
    .max(250, () => t("errors.general.valueTooLong", { count: 250 }))
    .required(),
});

export const LtiRegistrationFormSchema = yup.object().shape({
  url: yup
    .string()
    .trim()
    .required()
    .url(() => t("validationMessages.invalidUrl")),
});
