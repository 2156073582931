import Color from "color";

import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["achievementsStats"] => {
  return {
    scrollbarBackgroundColor: colors.secondary.base,
    iconBackground: colors.secondary.lighter,
    certificateHoverBackground: colors.white,
    dateColor: colors.black,
    iconColor: colors.black,
    buttonBackgroundColor: Color(colors.primary.lightest).alpha(0.25).string(),
    dateExpiredColor: colors.red.base,
    borderColor: colors.secondary.light,
    icon: {
      color: colors.black,
      hover: colors.primary.base,
    },
    drawer: {
      pointsBadgeBackground: Color(colors.primary.lightest).alpha(0.25).string(),
      badgeContainerBorder: colors.secondary.light,
    },
  };
};
