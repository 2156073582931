import React, { FC } from "react";

import { useTheme } from "@emotion/react";
import { Button } from "@epignosis_llc/gnosis";
import { HamburgerSVG } from "@epignosis_llc/gnosis/icons";

export type HamburgerButtonProps = {
  onClick: () => void;
  children?: never;
};

const HamburgerButton: FC<HamburgerButtonProps> = ({ onClick }) => {
  const { sidebar } = useTheme();

  return (
    <Button
      variant="ghost"
      className="hamburger-btn"
      noGutters
      onClick={onClick}
      aria-label="Toggle sidebar"
    >
      <HamburgerSVG height={32} color={sidebar.background} />
    </Button>
  );
};

export default HamburgerButton;
