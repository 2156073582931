import { useConfigurationStore } from "@stores";
import { userHasRequiredPermissions } from "@utils/permissions/index";
import authService from "@utils/services/AuthService";

export const ecommercePermissions = {
  update: "account.ecommerce",
};

const { update } = ecommercePermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => account.ecommerce
   *
   * Allowed actions:
   * GET ecommerce settings
   *
   * @returns boolean
   */

  canUpdateEcommerce: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([update], getState().userProfileData?.permissions[userRole]);
  },
};
