import { AxiosError } from "axios";

import { handledApiError, HandledError } from "@errors";
import {
  skillAssessmentCompleteErrors,
  skillAssessmentQuestionErrors,
  skillAssessmentResultErrors,
  skillAssessmentStartErrors,
  skillAssignmentErrors,
  skillMassActionsErrors,
  skillRecommendationErrors,
  skillUnassignmentErrors,
  skillUpdateErrors,
} from "@views/Skills/errors/errorMessages";

export const handleSkillRecommendationApprovalError = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, skillRecommendationErrors, hideNotification, "", handleError);
};

export const handleSkillRecommendationRejectionError = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, skillRecommendationErrors, hideNotification, "", handleError);
};

export const handleSkillsMassActionsErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, skillMassActionsErrors, false, "", handleError);
};

export const handleSkillUpdateError = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, skillUpdateErrors, hideNotification, "", handleError);
};

export const handleSkillAssessmentResultsError = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, skillAssessmentResultErrors, hideNotification, "", handleError);
};

export const handleSkillAssessmentQuestionError = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, skillAssessmentQuestionErrors, hideNotification, "", handleError);
};

export const handleSkillAssessmentStartError = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, skillAssessmentStartErrors, hideNotification, "", handleError);
};

export const handleSkillAssessmentCompleteError = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, skillAssessmentCompleteErrors, hideNotification, "", handleError);
};

export const handleAssignSkillsToUsersErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, skillAssignmentErrors, hideNotification, "", handleError);
};

export const handleUnassignSkillsToUsersErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, skillUnassignmentErrors, hideNotification, "", handleError);
};
