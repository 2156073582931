import React, { FC, StrictMode, Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { I18nextProvider } from "react-i18next";
import { Query, QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";

import { SerializedStyles, ThemeProvider } from "@emotion/react";
import { ThemeProvider as GnosisThemeProvider, Loader } from "@epignosis_llc/gnosis";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { Container, createRoot } from "react-dom/client";
import { ReactQueryDevtools } from "react-query/devtools";
import "focus-visible";
import "@channels/notifications";

import globalStyles from "@styles/global";

import { config, featureFlags, getBaseUrl } from "@config";
import { errorBoundaryHandler } from "@errors";
import Routes from "@Routes/Routes";
import { InternalServerError } from "@views/Errors";

import { useAuth, usePrevious } from "@hooks";
import { useConfigurationStore, useUIStore } from "@stores";
import { getErrorRetries } from "@utils/helpers";
import { i18n } from "@utils/i18n";

import queryKeys from "@constants/queryKeys";

const queryClient = new QueryClient();

if (config.isStaging() || config.isProd()) {
  Sentry.init({
    dsn: "https://13b17cb9c5e149389fe4d93bdec0605a@o1417853.ingest.sentry.io/6776415",
    integrations: [new Sentry.BrowserTracing()],
    environment: config.isProd() ? "production" : "staging",
    // Set tracesSampleRate to 1.0 to capture 100%
    // Of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}

const App: FC = () => {
  const { isAuthenticated } = useAuth();
  const previousIsAuthenticated = usePrevious(isAuthenticated);
  const { theme } = useUIStore((state) => state);
  const { getCollapsedMainNav } = useUIStore();
  const { isEditMode, isAddWidgetMode } = useConfigurationStore();

  const twoMinutesInterval = 1000 * 60 * 2;

  // Set the preferred query client default settings
  queryClient.setDefaultOptions({
    queries: {
      retry: (failureCount, error) => {
        const { response } = error as AxiosError;
        return isAuthenticated ? getErrorRetries(failureCount, Number(response?.status)) : false;
      },
      refetchOnWindowFocus: !isEditMode && !isAddWidgetMode, // Needed for widget dashboard to prevent refetching data while user is editing or adding widgets
      staleTime: isAuthenticated && featureFlags.fetchOnWindowFocus ? twoMinutesInterval : 0,
    },
  });

  // Reload when preload error occurs
  useEffect(() => {
    window.addEventListener("vite:preloadError", () => {
      window.location.reload();
    });
  }, []);

  // Get main nav from locale storage
  useEffect(() => {
    getCollapsedMainNav();
  }, [getCollapsedMainNav]);

  // Update the preferred query client default settings when isAuthenticated changes
  useEffect(() => {
    // Cancel queries only when isAuthenticated changes from true to false
    if (previousIsAuthenticated && !isAuthenticated) {
      queryClient.cancelQueries({
        predicate: (query: Query): boolean => query.queryKey !== queryKeys.domainSettings,
      });
    }

    queryClient.setDefaultOptions({
      queries: {
        retry: isAuthenticated ? 3 : false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <StrictMode>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <GnosisThemeProvider
            theme={theme}
            globalStyles={globalStyles as unknown as SerializedStyles}
          >
            <Router
              basename={getBaseUrl()}
              future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
            >
              <ErrorBoundary FallbackComponent={InternalServerError} onError={errorBoundaryHandler}>
                <Suspense fallback={<Loader fullScreen />}>
                  <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                    <Routes />
                  </QueryClientProvider>
                </Suspense>
              </ErrorBoundary>
            </Router>
          </GnosisThemeProvider>
        </ThemeProvider>
      </I18nextProvider>
    </StrictMode>
  );
};

const container: Container = document.getElementById("app")!;
const root = createRoot(container);

root.render(<App />);
