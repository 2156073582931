import { buildFileValidations, getFileValidations } from "@utils/helpers";

import { FileValidationsObj } from "types/entities";

export enum ValidationType {
  Document = "document",
  Audio = "audio",
  Video = "video",
  Image = "image",
  Scorm = "scorm",
  Captions = "captions",
  Sql = "sql",
  Epub = "epub",
  Csv = "csv",
}

const { Document, Audio, Video, Image, Scorm, Captions, Sql, Epub, Csv } = ValidationType;

export const DEFAULT_VALIDATION_TYPES: ValidationType[] = [
  Document,
  Audio,
  Video,
  Image,
  Scorm,
  Captions,
  Sql,
  Epub,
  Csv,
];

export const getFilesValidation = (types: ValidationType[]): FileValidationsObj => {
  const uniqueValidationTypes = [...new Set(types)];
  const documentValidation = buildFileValidations(getFileValidations("course.document"));

  return uniqueValidationTypes.reduce<FileValidationsObj>((validationObj, type) => {
    switch (type) {
      case Document:
        return { ...validationObj, ...documentValidation };
      case Audio: {
        const audioValidation = buildFileValidations(getFileValidations("course.audio"));

        return {
          ...validationObj,
          ...audioValidation,
          // Wma audio files can have 'video/x-ms-wma' extension
          "video/x-ms-wma": audioValidation["audio/x-ms-wma"],
        };
      }
      case Video: {
        const videoValidation = buildFileValidations(getFileValidations("course.video"));
        return { ...validationObj, ...videoValidation };
      }
      case Image: {
        const imageValidation = buildFileValidations(getFileValidations("course.image"));
        return { ...validationObj, ...imageValidation };
      }
      case Scorm: {
        const scormValidation = buildFileValidations(getFileValidations("course.scorm"));
        return {
          ...validationObj,
          ...scormValidation,
          "application/gzip": scormValidation["application/zip"],
        };
      }
      case Captions: {
        const captionsValidation = buildFileValidations(getFileValidations("course.captions"));
        return {
          ...validationObj,
          ...captionsValidation,
          "text/srt": captionsValidation["text/plain"],
          "text/vtt": captionsValidation["text/plain"],
        };
      }
      case Sql:
        return { ...validationObj, "application/sql": documentValidation["application/pdf"] };
      case Epub:
        return {
          ...validationObj,
          "application/epub+zip": documentValidation["application/pdf"],
        };
      case Csv:
        return { ...validationObj, "text/csv": documentValidation["application/pdf"] };
      default:
        return validationObj;
    }
  }, {} as FileValidationsObj);
};

export const getExtraValidExtensions = (types: ValidationType[]): string[] => {
  const uniqueValidationTypes = [...new Set(types)];

  return uniqueValidationTypes.reduce<string[]>((array, type) => {
    switch (type) {
      case Audio:
        array.push("wma");
        return array;
      case Captions:
        array.push("srt");
        return array;
      case Sql:
        array.push("sql");
        return array;
      default:
        return array;
    }
  }, []);
};
