import { css } from "@emotion/react";

import { mq } from "@utils/breakpoints";

export const settingCard = ({ accountAndSettings }, { placementPosition }) => css`
  display: flex;
  gap: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  flex-direction: column;

  ${mq["lg"]} {
    flex-direction: row;
    align-items: ${placementPosition};
  }

  .settings-content,
  .setting-actions {
    flex: 1;

    .textarea-setting {
      min-height: auto;
      height: 7.5rem;
    }

    .hint-container {
      margin-top: 0.5rem;
      font-style: italic;
    }

    .select-input-wrapper {
      max-width: none;
    }
  }

  .setting-actions {
    & .space-left {
      padding-left: 0.75rem;
    }
  }

  .settings {
    &-heading {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 0.25rem;
    }

    &-description {
      color: ${accountAndSettings.settingToggle.labelText};
      a {
        text-decoration: underline;
      }
    }

    &-tooltip {
      display: flex;
    }
  }
`;

export const settingInputClipboardContainer = () => css`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .textarea-setting {
    min-height: 6rem;

    &.hostName {
      min-height: 22rem;
      line-height: normal !important;
    }

    &.keyContainer {
      min-height: 10rem;
    }
  }
`;
