import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router";

import { config } from "@config";

import { getArticles } from "@components/Drawers/HelpCenter/api";
import {
  buildHelpArticlesSearchQuery,
  getPathSegments,
} from "@components/Drawers/HelpCenter/helpers";
import { useConfigurationStore } from "@stores";

import { FILTERS, getArticleSearchingField } from "@components/Drawers/HelpCenter/constants";
import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";

import KnowledgeBase from "@components/Drawers/HelpCenter/components/KnowledgeBase";
import { Article, ArticleFilters } from "@components/Drawers/HelpCenter/types";

const DASHBOARD_SECTION_ID = 13790828185628;
const SANDBOX_DASHBOARD_SECTION_ID = 17523390517404;

type HelpArticlesProps = {
  setSelectedArticle?: Dispatch<SetStateAction<Article | undefined>>;
  setHelpContent?: Dispatch<SetStateAction<string | null>>;
};
const HelpArticles: FC<HelpArticlesProps> = ({ setSelectedArticle, setHelpContent }) => {
  const location = useLocation();
  const { userProfileData } = useConfigurationStore();
  const currentLocale = userProfileData?.locale || "en-US";
  const dashboardSectionID = config.isProd() ? DASHBOARD_SECTION_ID : SANDBOX_DASHBOARD_SECTION_ID;

  const searchParams = new URLSearchParams(location.search);
  const tabValue = searchParams.get("tab");
  const currentPath = location.pathname;
  const getSearchString = tabValue ? tabValue : getPathSegments(currentPath);
  const initialSearchValue = getArticleSearchingField(getSearchString, currentLocale) || "";

  const [articlesFilters, setArticleFilters] = useState<ArticleFilters>(FILTERS);
  const [searchValue, setSearchValue] = useState<string>(initialSearchValue);

  const defaultState = {
    filters: articlesFilters,
    search: searchValue,
  };

  const [state, setState] = useState(defaultState);
  const { filters, search } = state;
  const searchQuery = buildHelpArticlesSearchQuery({ filters, search });

  const {
    data: articles = [],
    status: articlesStatus,
    error: articlesError,
  } = useQuery([queryKeys.help.articles, searchQuery], () => getArticles(searchQuery), {
    select: (articles) => articles._data,
  });

  const handleSearchChange = (searchValue: string): void => {
    if (searchValue === "") {
      const articleField = initialSearchValue;

      if (currentPath === URLS.dashboard && defaultState.filters) {
        setState((state) => ({
          ...state,
          search: "",
          filters: { section_id: dashboardSectionID },
        }));
      } else {
        setState((state) => ({
          ...state,
          search: articleField,
          filters: defaultState.filters,
        }));
      }
      setSearchValue(articleField);
    } else {
      setState((state) => ({
        ...state,
        search: searchValue,
        filters: { section_id: null },
      }));
    }
  };

  useEffect(() => {
    const articleField = getArticleSearchingField(getSearchString, currentLocale);

    if (currentPath === URLS.dashboard) {
      setArticleFilters({ section_id: dashboardSectionID });
      setState((state) => ({ ...state, filters: { section_id: dashboardSectionID } }));
    } else if (articleField) {
      setSearchValue(articleField);
      setState((state) => ({ ...state, search: articleField }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  return (
    <KnowledgeBase
      articles={articles}
      status={articlesStatus}
      error={articlesError}
      handleSearchChange={handleSearchChange}
      setHelpContent={setHelpContent}
      setSelectedArticle={setSelectedArticle}
    />
  );
};

export default HelpArticles;
