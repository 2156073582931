import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["multiSelect"] => {
  return {
    hover: colors.secondary.lighter,
    background: colors.white,
    borderHoverColor: colors.primary.base,
    inputBorderColor: colors.secondary.light,
  };
};
