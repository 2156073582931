import React, { FC, HTMLAttributes } from "react";

import { SerializedStyles } from "@emotion/react";

import { pathSeparatorStyles } from "@components/ReusableComponents/PathList/styles";

export type PathSeparatorProps = HTMLAttributes<HTMLDivElement> & {
  hideLine?: boolean;
  isEmpty?: boolean;
};

const PathSeparator: FC<PathSeparatorProps> = ({ hideLine = false, isEmpty = false, ...rest }) => (
  <div css={(theme): SerializedStyles => pathSeparatorStyles(theme, { isEmpty })} {...rest}>
    <span className="line-dot" />
    {!hideLine && <span className="line" />}
  </div>
);

export default PathSeparator;
