import React, { FC, useEffect, useState } from "react";

import { Drawer } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";

import { mainDrawerContainer } from "@layouts/DashboardLayout/components/styles";
import { GlobalStyleVariables } from "@styles";

import { useUIStore } from "@stores";
import { getMainDrawerHeader } from "@utils/helpers";
import { i18n } from "@utils/i18n";

import { HelpContent, TabKey } from "@components/Drawers/HelpCenter/constants";

import { DrawerMainMenuMobile } from "@components";
import {
  ArticleContent,
  HeaderComponent,
  HelpCenterFooter,
} from "@components/Drawers/HelpCenter/components";
import HelpCenter from "@components/Drawers/HelpCenter/HelpCenter";
import { Article } from "@components/Drawers/HelpCenter/types";

import {
  MainDrawerProps,
  RenderHeaderComponentProps,
} from "@layouts/DashboardLayout/components/types";
import { MainDrawerContent, MainDrawerContentEnum } from "types/common";

const { mainDrawerMinWidth } = GlobalStyleVariables;

const widthDimensions = {
  medium: "31.25rem",
  lg: "48rem",
  mainMenu: mainDrawerMinWidth,
  mobile: "100%",
};

const { MAIN_MENU, HELP_CENTER } = MainDrawerContentEnum;

const MainDrawer: FC<MainDrawerProps> = ({ layout = "dashboard" }) => {
  const { md } = useResponsive();
  const { mainNavState, mainDrawer, hideMainDrawer, showMainDrawer } = useUIStore((state) => state);
  const isMainNavCollapsed = mainNavState === "collapsed";
  const { show, content } = mainDrawer;

  const [helpContent, setHelpContent] = useState<string | null>(null);
  const [selectedArticle, setSelectedArticle] = useState<Article | undefined>(undefined);
  const [tabKey, setTabKey] = useState<string>(TabKey.helpArticles);

  const renderHeaderComponent = ({
    header,
    link,
    onClick,
  }: RenderHeaderComponentProps): JSX.Element => {
    return <HeaderComponent headerTitle={header} onClick={onClick} link={link} />;
  };

  const header =
    helpContent === HelpContent.article && content !== MAIN_MENU
      ? renderHeaderComponent({
          header: selectedArticle?.title,
          link: selectedArticle?.url,
          onClick: () => {
            setHelpContent(null);
          },
        })
      : getMainDrawerHeader(content);

  const isHelpCenter = content === HELP_CENTER;
  const isClosable = (!md && content !== MAIN_MENU) || isHelpCenter;
  const showFooter = isHelpCenter && helpContent !== "article";

  const shouldRenderHelpCenterFooter =
    isHelpCenter && helpContent === null && tabKey === TabKey.helpArticles;

  const onClose = (): void => {
    hideMainDrawer();
  };

  const dialogStyles =
    md && layout === "dashboard"
      ? { insetInlineStart: isMainNavCollapsed ? "4.75rem" : mainDrawerMinWidth }
      : undefined;

  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "right" : "left";

  useEffect(() => {
    if (show && content === MAIN_MENU && md) {
      hideMainDrawer();
    }
  }, [content, hideMainDrawer, show, md]);

  useEffect(() => {
    setHelpContent(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const getFinalDrawerWidth = (md: boolean, content: MainDrawerContent): string => {
    const { mainMenu, lg, medium, mobile } = widthDimensions;

    // Determine the drawer width based on the content type
    switch (content) {
      case MAIN_MENU:
        return md ? medium : mobile;
      case HELP_CENTER:
        return lg;
      default:
        return mainMenu;
    }
  };

  const finalDrawerWidth = getFinalDrawerWidth(md, content);

  return (
    <Drawer
      isOpen={show}
      width={finalDrawerWidth}
      css={mainDrawerContainer}
      placement={placement}
      onClose={onClose}
      dialogStyles={dialogStyles}
    >
      {header && <Drawer.Header closable={isClosable}>{header}</Drawer.Header>}

      <Drawer.Body>
        {content === MAIN_MENU && (
          <DrawerMainMenuMobile showMainDrawer={showMainDrawer} hideMainDrawer={hideMainDrawer} />
        )}

        {isHelpCenter && helpContent === null && (
          <HelpCenter
            setHelpContent={setHelpContent}
            setSelectedArticle={setSelectedArticle}
            setTabKey={setTabKey}
          />
        )}
        {helpContent === HelpContent.article && content !== MAIN_MENU && (
          <ArticleContent content={selectedArticle?.body} />
        )}
      </Drawer.Body>

      {showFooter && (
        <Drawer.Footer>{shouldRenderHelpCenterFooter && <HelpCenterFooter />}</Drawer.Footer>
      )}
    </Drawer>
  );
};

export default MainDrawer;
