import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";

import {
  GamificationBadgeSetsRes,
  GamificationBadgeSetUpdateRes,
  GamificationBadgesRes,
  GamificationBadgeUpdateRes,
  GamificationSettingsRes,
  LeaderboardRes,
} from "types/responses";

export const getLeaderboard = async (): Promise<LeaderboardRes> => {
  const res = await HttpClient.get(ENDPOINTS.gamification.leaderboard);

  return res.data;
};

export const getGamificationSettings = async (): Promise<GamificationSettingsRes> => {
  const res = await HttpClient.get(ENDPOINTS.gamification.settings);

  return res.data;
};

export const getBadgeSets = async (): Promise<GamificationBadgeSetsRes> => {
  const res = await HttpClient.get(ENDPOINTS.gamification.badgeSets);

  return res.data;
};

export const getBadges = async (): Promise<GamificationBadgesRes> => {
  const res = await HttpClient.get(ENDPOINTS.gamification.badges);

  return res.data;
};

export const updateActiveBadgeSet = async (
  badgeSetId: string,
): Promise<GamificationBadgeSetUpdateRes> => {
  const res = await HttpClient.put(ENDPOINTS.gamification.updateActiveBadgeSet, { id: badgeSetId });

  return res.data;
};

export const updateBadge = async (
  badgeId: string,
  badgeName: string,
  badgeImage: File | null,
): Promise<GamificationBadgeUpdateRes> => {
  const formData = new FormData();

  formData.append("name", badgeName);
  badgeImage && formData.append("image", badgeImage);

  const res = await HttpClient.post(ENDPOINTS.gamification.badge(badgeId), formData);

  return res.data;
};

export const resetBadge = async (badgeId: string): Promise<GamificationBadgeSetUpdateRes> => {
  const res = await HttpClient.put(ENDPOINTS.gamification.badge(badgeId));

  return res.data;
};
