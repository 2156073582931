import React, { FC } from "react";

import { InactiveBranchErrorSVG } from "@assets/images";
import { container } from "@views/Errors/styles";

import { useApplyTranslations } from "@hooks";

import { Page } from "@components";
import { ErrorResult } from "@components/ReusableComponents";

const InactiveBranchError: FC = () => {
  const { t } = useApplyTranslations();
  return (
    <Page pageTitle={t("general.inactiveSite")} siteNamePrefix={false}>
      <div css={container}>
        <ErrorResult errorImg={InactiveBranchErrorSVG} title={t("general.inactiveSite")} />
      </div>
    </Page>
  );
};

export default InactiveBranchError;
