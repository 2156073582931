import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { mainNavContainer } from "@components/MainNav/styles";

import { useUIStore } from "@stores";
import { UIState } from "@stores/ui";

import localStorageKeys from "@constants/localStorageKeys";

import MainMenu from "@components/MainNav/MainMenu";

import { MainDrawerContent } from "types/common";

export type MainNavData = {
  pagePath: string;
  isMainNavCollapsed: boolean;
  mainDrawer: UIState["mainDrawer"];
} | null;

const MainNav: FC = () => {
  const [navState, setNavState] = useState<MainNavData>(null);

  const location = useLocation();
  const { mainNavState, mainDrawer, toggleCollapsedMainNav, showMainDrawer, hideMainDrawer } =
    useUIStore((state) => state);
  const isMainNavCollapsed = mainNavState === "collapsed";

  const handleMainDrawer = (e: SyntheticEvent, contentType: MainDrawerContent): void => {
    e.preventDefault();
    closeMainDrawer();

    if (mainDrawer.content !== contentType) {
      showMainDrawer(contentType);
      document.body.style.setProperty("overflow", "hidden");
    }
  };

  const toggleMainNav = (): void => {
    toggleCollapsedMainNav();
    closeMainDrawer();
  };

  const closeMainDrawer = (): void => {
    if (mainDrawer.show) {
      hideMainDrawer();
      document.body.style.setProperty("overflow", "");
    }
  };

  useEffect(() => {
    const isMainNavClosed = localStorage.getItem(localStorageKeys.MAIN_NAV) === "collapsed";

    if (isMainNavClosed && !isMainNavCollapsed) {
      toggleMainNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNavState({
      pagePath: location.pathname,
      isMainNavCollapsed,
      mainDrawer,
    });
  }, [location, isMainNavCollapsed, mainDrawer]);

  return (
    <div css={mainNavContainer} className="main-nav-container">
      <MainMenu
        isCollapsed={isMainNavCollapsed}
        topMenuOnClick={closeMainDrawer}
        bottomMenuOnClick={handleMainDrawer}
        navState={navState}
      />
    </div>
  );
};

export default MainNav;
