import { css } from "@emotion/react";
import { typeScale } from "@epignosis_llc/gnosis";

import { mq } from "@utils/breakpoints";

export const searchResultsContainer = css`
  padding: 1rem;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`;

export const helpContainer = css`
  nav {
    margin-bottom: 2rem;
  }

  article {
    padding: 0;

    section {
      padding: 0 1.5rem;

      ${mq["sm"]} {
        padding: 0 2.5rem;
      }
    }
  }

  .title {
    font-size: ${typeScale.xl};
    margin-bottom: 1rem;
  }

  .search {
    margin-bottom: 2.5rem;
  }

  .list {
    margin: 1rem 0 1.5rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;

    dl,
    dt,
    dd {
      margin: 0;
      padding: 0;
    }

    dt {
      margin-bottom: 1rem;
    }

    dd {
      margin-bottom: 2.5rem;
    }
  }

  .list-action {
    display: flex;
    justify-content: center;
  }
`;

export const mainMenuContainer = css`
  .sidebar-container {
    max-width: 100%;
  }

  .inner-container {
    display: block;
  }

  .nav-handle {
    display: none;
  }
`;

export const ActionDrawerContainer = (
  { actionDrawer, icon },
  { hasSubtitle, footerPadding },
) => css`
  header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${actionDrawer.border};
    justify-content: space-between;

    .back-title-container {
      display: flex;
      align-items: ${!hasSubtitle ? "center" : "flex-start"};
      gap: 0.5rem;

      .back-button {
        padding: 0;
        min-width: auto;
        height: auto;
      }
    }

    .header-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      span {
        line-height: 0;
      }

      .tooltip-icon {
        color: ${icon.primary};
      }
    }

    .close-button {
      padding: 0;
    }
  }

  .action-drawer-body {
    height: 100%;
    overflow-y: auto;
    padding: 1.5rem 2rem;
  }

  footer {
    display: flex;
    justify-content: flex-start;
    padding: ${footerPadding ? footerPadding : "1rem 2rem"};
    border-top: 1px solid ${actionDrawer.border};

    .action-buttons {
      display: flex;
    }

    button {
      margin: 0 0.5rem;

      &.left-aligned {
        margin-left: 0;
      }
    }
  }
`;
