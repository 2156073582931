import { GnosisTheme } from "@epignosis_llc/gnosis";
import Color from "color";

import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): GnosisTheme["tag"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    backgroundColor: protectColorContrast(
      "darkest",
      Color(colors.primary.lightest).alpha(0.25).string(),
      0.25,
    ),
    color: protectColorContrast("darker", colors.primary.base),
  };
};
