import React, { FC } from "react";

import { Text } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";

import { container } from "@views/Errors/styles";

import { useApplyTranslations } from "@hooks";

import { images } from "@constants/assets";

import { Page } from "@components";

const InternalServerError: FC = () => {
  const { t } = useApplyTranslations();
  const { md } = useResponsive();
  const imageHeight = md ? 400 : 200;

  return (
    <Page pageTitle={t("general.experiencingProblem")} siteNamePrefix={false}>
      <div css={container}>
        <img src={images.errors.internalServer} height={imageHeight} alt="internal server error" />
        <div className="error-msg">
          <Text fontSize={md ? "xl" : "md"} as="div">
            {t("general.experiencingProblem")}
          </Text>
          <Text fontSize={md ? "xl" : "md"} as="div">
            {t("general.upAndRunningSoon")}
          </Text>
        </div>
      </div>
    </Page>
  );
};

export default InternalServerError;
