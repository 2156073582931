import useConfigurationStore from "@stores/configuration";
import { userHasRequiredPermissions } from "@utils/permissions/index";
import authService from "@utils/services/AuthService";

// Map with all the available permissions
export const notificationsPermissions = {
  read: "events_engine.notifications.read",
  create: "events_engine.notifications.create",
  delete: "events_engine.notifications.delete",
  update: "events_engine.notifications.update",
};

const { read, create, update, delete: notificationDelete } = notificationsPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => events_engine.notifications.read
   * notifications.read is required in order to have all the other notification related permissions
   *
   *  Allowed actions:
   *  GET notifications
   *
   * @returns boolean
   */
  canAccessNotifications: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },

  /**
   * Permission => events_engine.notifications.create
   *
   *  Allowed actions:
   *  POST notifications
   *
   * @returns boolean
   */
  canCreateNotifications: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions([create], getState().userProfileData?.permissions[userRole]);
  },

  /**
   * Permission => events_engine.notifications.update
   *
   *  Allowed actions:
   *  POST notifications
   *
   * @returns boolean
   */
  canUpdateNotifications: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions([update], getState().userProfileData?.permissions[userRole]);
  },

  /**
   * Permission => events_engine.notifications.delete
   *
   * Allowed actions:
   * DELETE events_engine.notifications.delete
   *
   * @returns boolean
   */
  canDeleteNotifications: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions(
      [notificationDelete],
      getState().userProfileData?.permissions[userRole],
    );
  },
};
