import { Column, DropdownItem } from "@epignosis_llc/gnosis";

import { t } from "@utils/i18n";

import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

// Sorting and pagination

export enum GroupUsersFilters {
  branch = "[branch_id]",
}

export const getGroupUsersColumns = (): Column[] => {
  // Table data
  return [
    {
      accessor: "formatted_name",
      cell: t("users.user"),
      classNames: ["user"],
      headerWidth: 200,
    },
    {
      accessor: "type",
      cell: t("users.userType"),
      classNames: ["type"],
      maxWidth: 200,
    },
    {
      accessor: "last_login",
      cell: t("users.lastLogin"),
      classNames: ["last_login"],
      sortOrder: "desc" as Column["sortOrder"],
    },
    {
      accessor: "courses",
      cell: t("groups.groupCoursesAdded"),
      sortOrder: "desc" as Column["sortOrder"],
      classNames: ["courses"],
    },
  ];
};

export const actionsColumn = {
  accessor: "actions",
  cell: "",
  classNames: ["hidden"],
  sortableHeader: false,
};

export const PAGINATION: IPagination = { number: 1, size: 10 };
export const DEFAULT_FILTERS: QueryFilter[] = [];

export const SORTING = { column: "formatted_name", isDescending: false };

export type UsernameColumnTitle = "login" | "surname";

export const filtersSearchQueryParams = {
  pagination: { number: 1, size: 10000 },
  sorting: ["name"],
};

type MassActionsOptionsWithPermissionsReturnType = (DropdownItem & { permission?: boolean })[];

// Render the dropdown items depending on the permissions of each one
export const groupUsersMassActionsOptionsWithPermissions = (
  allowUpdateUsers: boolean,
  allowGroupRead: boolean,
): MassActionsOptionsWithPermissionsReturnType => {
  const allowGroupUsersMassActions = allowGroupRead && allowUpdateUsers;

  return [
    {
      label: t("groups.users.massActions.addToCourses"),
      value: "add_users_to_group_courses",
      permission: allowGroupUsersMassActions,
    },
    {
      label: t("groups.users.massActions.removeFromCourses"),
      value: "remove_users_from_group_courses",
      permission: allowGroupUsersMassActions,
    },
  ];
};

export const groupUsersMassActionsWordings = {
  add_users_to_group_courses: {
    progressHeader: "groups.users.massActions.addToCourses",
    progressMessage: "groups.users.massActions.addingUsersToCourses",
    confirmationHeader: "groups.users.massActions.addToCourses",
    confirmationButton: "general.apply",
    successMessage: "groups.users.massActions.addUsersToCoursesSuccess",
    confirmationBodyTitle: "groups.users.massActions.addUsersToCourses",
  },
  remove_users_from_group_courses: {
    progressHeader: "groups.users.massActions.removeFromCourses",
    progressMessage: "groups.users.massActions.removingUsersFromCourses",
    confirmationHeader: "groups.users.massActions.removeFromCourses",
    confirmationButton: "general.apply",
    successMessage: "groups.users.massActions.removeUsersFromCoursesSuccess",
    confirmationBodyTitle: "groups.users.massActions.removeUsersFromCourses",
  },
};
