import React from "react";
import { Navigate, useParams } from "react-router-dom";

import { Loader } from "@epignosis_llc/gnosis";

import { useAuth, useIsUserEnrolledInCourse } from "@hooks";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";

import { URLS } from "@constants/urls";
import userRoles from "@constants/userRoles";

const CourseShare: React.FC = () => {
  const { courseId = "" } = useParams<{ courseId: string }>();
  const userRole = authService.getDefaultRole();
  const { domainSettings } = useConfigurationStore();
  const isExternalCatalogEnabled = domainSettings?.external_catalog;
  const { isAuthenticated } = useAuth();
  const isLearner = userRole === userRoles.LEARNER;

  const { hasCourseAccess, coursesStatus } = useIsUserEnrolledInCourse(courseId, isLearner);

  if (!isAuthenticated) {
    if (isExternalCatalogEnabled) {
      return <Navigate to={URLS.catalog.createCourseLink({ courseId })} />;
    }
    return <Navigate to={URLS.login} />;
  }

  // Show the loader while the hook is loading data
  if (coursesStatus === "loading") {
    return <Loader fullScreen />;
  }

  if (isLearner) {
    if (hasCourseAccess) {
      return <Navigate to={URLS.user.createCourseLink({ courseId })} />;
    }

    return <Navigate to={URLS.catalog.createCourseLink({ courseId })} />;
  }

  if (userRole === userRoles.ADMINISTRATOR || userRole === userRoles.INSTRUCTOR) {
    return (
      <Navigate
        to={URLS.catalog.adminCourseOverviewLink({ courseId })}
        state={{ from: window.location.pathname }}
      />
    );
  }

  // Default to showing dashboard or login page
  return <Navigate to={isAuthenticated ? URLS.dashboard : URLS.login} />;
};

export default CourseShare;
