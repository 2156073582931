import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["accountAndSettings"] => {
  return {
    boxedContainer: {
      background: colors.white,
      title: colors.secondary.darker,
      border: colors.secondary.light,
    },
    settingToggle: {
      labelText: colors.secondary.darker,
    },
    drawers: {
      description: colors.secondary.darker,
    },
    formActionsDivider: {
      background: colors.white,
      border: colors.secondary.lighter,
    },
    integrations: {
      itemHeading: colors.black,
      contentColor: colors.secondary.darker,
      figureBackground: colors.secondary.lighter,
      figureDotLine: colors.black,
      pillBackground: colors.primary.base,
      pillText: colors.white,
      listDotColor: colors.primary.base,
      listEmptyDotColor: colors.white,
      noteSuccess: colors.green.darker,
      noteError: colors.red.base,
      warningMessage: colors.primary.base,
    },
    subscription: {
      titleColor: colors.secondary.dark,
      iconColor: colors.secondary.darker,
    },
    ecommerce: {
      subscriptionNote: colors.black,
      couponRedemptionsNote: colors.secondary.base,
    },
  };
};
