// Manages tab navigation based on URL parameters and permissions using React Router and a custom useSearchQuery hook.
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useSearchQuery } from "@hooks";

import { AvailableTabsType, TabObject } from "types/entities/Common";

type TabNavigationResult = {
  selectedTabIndex: number;
  visibleTabs: TabObject[];
  updateTabParam: (selectedTabIndex: number) => void;
};

const useTabNavigation = (availableTabs: AvailableTabsType[]): TabNavigationResult => {
  const navigate = useNavigate();
  const location = useLocation();
  const { tab: selectedTabKey } = useSearchQuery();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const visibleTabs: TabObject[] = availableTabs
    .filter(({ permission }) => permission)
    .map(({ info }) => info);

  // Update tab index when the URL parameter changes
  useEffect(() => {
    if (selectedTabKey) {
      const newTabIndex = visibleTabs.findIndex((tab) => tab.key === selectedTabKey);
      setSelectedTabIndex(newTabIndex !== -1 ? newTabIndex : 0);
    }
  }, [selectedTabKey, visibleTabs]);

  // Update URL parameter when the selected tab changes
  const updateTabParam = (newTabIndex: number): void => {
    const selectedTab = visibleTabs[newTabIndex];

    if (selectedTab?.key) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("tab", selectedTab.key);
      navigate({ pathname: location.pathname, search: `?${searchParams.toString()}` });
    }
  };

  return { selectedTabIndex, visibleTabs, updateTabParam };
};

export default useTabNavigation;
