import { StatusTagColors } from "@epignosis_llc/gnosis";

import { Variant } from "@components/CustomTable/components/Cells/TextWithStatus/types";

export const mapVariantsToTagColors: Record<Variant, StatusTagColors> = {
  ready: "neutral",
  failed: "red",
  inactive: "inactive",
  info: "neutral",
  processing: "neutral",
  success: "positive",
  warning: "warning",
  negative: "negative",
};
