import React, { FC, PropsWithChildren } from "react";

import { unitPageLayout } from "@components/UnitPageLayout/styles";

import Content from "@components/UnitPageLayout/components/Content";
import ContentBody, { ContentBodyProps } from "@components/UnitPageLayout/components/ContentBody";
import ContentFooter from "@components/UnitPageLayout/components/ContentFooter";
import ContentHeader from "@components/UnitPageLayout/components/ContentHeader";
import Sidebar from "@components/UnitPageLayout/components/Sidebar";

type UnitPageLayoutProps = {
  Sidebar: FC<PropsWithChildren>;
  Content: FC<PropsWithChildren>;
  ContentHeader: FC<PropsWithChildren>;
  ContentBody: FC<ContentBodyProps>;
  ContentFooter: FC<PropsWithChildren>;
};

type UnitPageLayoutChildrenProps = {
  children?: React.ReactNode;
};

const UnitPageLayout: FC<UnitPageLayoutChildrenProps> & UnitPageLayoutProps = ({ children }) => (
  <div css={unitPageLayout}>{children}</div>
);

UnitPageLayout.Sidebar = Sidebar;
UnitPageLayout.Content = Content;
UnitPageLayout.ContentHeader = ContentHeader;
UnitPageLayout.ContentBody = ContentBody;
UnitPageLayout.ContentFooter = ContentFooter;

export default UnitPageLayout;
