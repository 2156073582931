import React, { FC, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";

import { Button, Text } from "@epignosis_llc/gnosis";
import { useToggle } from "ahooks";

import { TicketSubmissionSVG } from "@assets/images";
import { helpContainer } from "@components/Drawers/HelpCenter/styles";

import { MainMenuDrawer } from "@layouts/DashboardLayout/components";

import { getArticles } from "@components/Drawers/HelpCenter/api";
import { buildHelpArticlesSearchQuery } from "@components/Drawers/HelpCenter/helpers";
import { useApplyTranslations } from "@hooks";

import queryKeys from "@constants/queryKeys";

import { ArticleContent, SupportForm } from "@components/Drawers/HelpCenter/components";
import KnowledgeBase from "@components/Drawers/HelpCenter/components/KnowledgeBase";
import { Article } from "@components/Drawers/HelpCenter/types";
import { TabHandle } from "@components/ReusableDrawers/types";
import EmptyState from "@views/WidgetsDashboard/components/Widgets/components/EmptyState";

const DASHBOARD_SECTION_ID = 13790828185628;

type TicketDrawerProps = {
  isOpen: boolean;
  toggleDrawer: () => void;
};
const SupportTicketDrawer: FC<TicketDrawerProps> = ({ isOpen, toggleDrawer }) => {
  const { t } = useApplyTranslations();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [subjectValue, setSubjectValue] = useState("");
  const [selectedArticle, setSelectedArticle] = useState<Article | undefined>(undefined);
  const [isArticlesDrawer, { toggle: toggleIsArticlesDrawer }] = useToggle(false);
  const [isTicketSubmissionDrawer, { toggle: toggleIsTicketSubmissionDrawer }] = useToggle(false);
  const [isArticleContentDrawerOpen, setIsArticleContentDrawerOpen] = useState(false);

  const supportFormRef = useRef<TabHandle>(null);
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [fallbackArticles, setFallbackArticles] = useState<Article[]>();

  const searchQuery = buildHelpArticlesSearchQuery({ search: subjectValue });

  const {
    data: articles = [],
    status: articlesStatus,
    error: articlesError,
  } = useQuery([queryKeys.help.articles, searchQuery], () => getArticles(searchQuery), {
    select: (articles) => articles._data,
  });

  useEffect(() => {
    setIsArticleContentDrawerOpen(true);
  }, [selectedArticle]);

  useEffect(() => {
    if (articles.length === 0) {
      // Trigger fallback query if no articles are found
      const searchQueryFallback = buildHelpArticlesSearchQuery({
        search: "",
        filters: { section_id: DASHBOARD_SECTION_ID },
      });
      getArticles(searchQueryFallback).then((fallback) => {
        setFallbackArticles(fallback._data);
      });
    }
  }, [articles]);

  const displayedArticles = articles.length > 0 ? articles : fallbackArticles;

  const handleApply = (): void => {
    supportFormRef.current?.handleApply();
  };

  const onClose = (): void => {
    toggleIsTicketSubmissionDrawer();
    toggleIsArticlesDrawer();
    toggleDrawer();
  };

  return (
    <>
      <MainMenuDrawer
        id="open-a-ticket-drawer"
        isOpen={isOpen}
        headerTitle={t("helpCenter.openTicket")}
        size="lg"
        onClose={(): void => {
          toggleDrawer();
        }}
        customFooter={
          <Button disabled={isButtonDisabled} onClick={(): void => toggleIsArticlesDrawer()}>
            {t("general.next")}
          </Button>
        }
        onApply={(): void => toggleDrawer()}
        showFooter
        hasBackButton
      >
        <SupportForm
          type="ticket"
          setIsButtonDisabled={setIsButtonDisabled}
          setSubjectValue={setSubjectValue}
          ref={supportFormRef}
          onValidChange={(isValid: boolean): void => {
            setIsValid(isValid);
          }}
          onLoadingChange={(isLoading: boolean): void => {
            setIsLoading(isLoading);
          }}
          onSubmissionSuccess={(): void => {
            toggleIsTicketSubmissionDrawer();
          }}
        />
      </MainMenuDrawer>

      {isArticlesDrawer && (
        <MainMenuDrawer
          id="submit-ticket-drawer"
          isOpen={isArticlesDrawer}
          headerTitle={t("helpCenter.openTicket")}
          size="lg"
          onClose={(): void => toggleIsArticlesDrawer()}
          hasBackButton
          customFooter={
            <Button
              disabled={isButtonDisabled || !isValid}
              isLoading={isLoading}
              onClick={handleApply}
            >
              {t("helpCenter.submitTicket")}
            </Button>
          }
          showFooter
        >
          <div css={helpContainer}>
            <KnowledgeBase
              headerSubtitle={t("helpCenter.submitTicketInfo")}
              articles={displayedArticles}
              status={articlesStatus}
              error={articlesError}
              setSelectedArticle={setSelectedArticle}
            />
          </div>
        </MainMenuDrawer>
      )}

      {selectedArticle && isArticleContentDrawerOpen && (
        <MainMenuDrawer
          id="find-article-drawer"
          isOpen={!!selectedArticle}
          headerTitle={selectedArticle?.title}
          size="lg"
          onClose={(): void => {
            setSelectedArticle(undefined);
            setIsArticleContentDrawerOpen(false);
          }}
          hasBackButton
          customFooter={
            <Button
              onClick={(): void => {
                setIsArticleContentDrawerOpen(false);
                toggleIsArticlesDrawer();
                toggleDrawer();
              }}
            >
              {t("helpCenter.answersMyQuestion")}
            </Button>
          }
          showFooter
          link={selectedArticle?.url}
        >
          <ArticleContent content={selectedArticle?.body} />
        </MainMenuDrawer>
      )}

      {isTicketSubmissionDrawer && (
        <MainMenuDrawer
          id="empty-state-drawer"
          isOpen={isTicketSubmissionDrawer}
          headerTitle={t("helpCenter.openTicket")}
          size="lg"
          onClose={onClose}
          hasBackButton
          showFooter={false}
        >
          <EmptyState
            icon={<TicketSubmissionSVG />}
            title={
              <Text
                fontSize="lg"
                weight="700"
                dangerouslySetInnerHTML={{ __html: t("helpCenter.submitTicketSuccessfully") }}
              />
            }
            footer={
              <Button variant="link" onClick={onClose} noGutters>
                {t("helpCenter.backToHelp")}
              </Button>
            }
          />
        </MainMenuDrawer>
      )}
    </>
  );
};

export default SupportTicketDrawer;
