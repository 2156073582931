import { useConfigurationStore } from "@stores";
import permissionsUtils from "@utils/permissions";
import { accountPermissions } from "@utils/permissions/account";
import { automationsPermissions } from "@utils/permissions/automations";
import { branchPermissions } from "@utils/permissions/branches";
import { calendarPermissions } from "@utils/permissions/calendar";
import { catalogPermissions } from "@utils/permissions/catalog";
import { categoriesPermissions } from "@utils/permissions/categories";
import { conferencePermissions } from "@utils/permissions/conferences";
import { coursesPermissions } from "@utils/permissions/courses";
import { discussionsPermissions } from "@utils/permissions/discussions";
import { ecommercePermissions } from "@utils/permissions/ecommerce";
import { gamificationPermissions } from "@utils/permissions/gamification";
import { groupPermissions } from "@utils/permissions/groups";
import { importExportPermissions } from "@utils/permissions/importExport";
import { messagesPermissions } from "@utils/permissions/messages";
import { notificationsPermissions } from "@utils/permissions/notifications";
import { profilePermissions } from "@utils/permissions/profile";
import { reportsPermissions } from "@utils/permissions/reports";
import { skillsPermissions } from "@utils/permissions/skills";
import { usersPermissions } from "@utils/permissions/users";
import { usersTypesPermissions } from "@utils/permissions/userTypes";
import authService from "@utils/services/AuthService";

import userRoles from "@constants/userRoles";

import { UseRoutePermissionsReturnType } from "@Routes/types";

// PERMISSIONS
// Discussions permissions
const { read: discussionsRead, create: discussionsCreate } = discussionsPermissions;
// Profile permissions
const { read: profileRead } = profilePermissions;
// Reports permissions
const {
  read: reportsRead,
  courseReports,
  userReports,
  testReports,
  surveyReports,
  assignmentReports,
  iltReports,
  groupReports: userGroupReports,
  branchReports,
  timeline,
  customReports,
} = reportsPermissions;
// Ecommerce permissions
const { update: ecommerceUpdate } = ecommercePermissions;
// Course permissions
const {
  read: coursesRead,
  union_read: coursesUnionRead,
  unit_read: coursesUnitRead,
  unit_update: coursesUnitUpdate,
  files_shared_read: coursesFilesSharedRead,
} = coursesPermissions;
// Gamification permissions
const { read: gamificationRead, accountGamification } = gamificationPermissions;

// Messages permissions
const { read: messagesRead, create: messagesCreate } = messagesPermissions;
// Catalog permissions
const { read: catalogRead } = catalogPermissions;
// Calendar permissions
const { read: calendarRead } = calendarPermissions;
// Account permissions
const {
  read: accountRead,
  update: accountUpdate,
  domain: customDomain,
  subscription: courseStoreSubscription,
  subscription: subscriptionRead,
} = accountPermissions;
// Import/Export permissions
const { createImport, createExport } = importExportPermissions;
// Users permissions
const { read: usersRead, update: usersUpdate, create: usersCreate } = usersPermissions;
// Group permissions
const { read: groupRead, create: groupCreate, group_key: groupJoin } = groupPermissions;
// Categories permissions
const { read: categoriesRead } = categoriesPermissions;
// User types permissions
const { read: userTypesRead } = usersTypesPermissions;
// Branch permissions
const { read: branchRead, create: branchCreate, update: branchUpdate } = branchPermissions;
// Automations permissions
const { read: automationsRead } = automationsPermissions;
// Notifications permissions
const { read: notificationsRead } = notificationsPermissions;
// Conferences permissions
const { read: conferencesRead } = conferencePermissions;
// Skills permissions
const { read: skillsRead } = skillsPermissions;

// Reports main page permissions
const reportsMainPagePermissions =
  authService.getDefaultRole() === userRoles.ADMINISTRATOR
    ? [
        courseReports,
        userReports,
        testReports,
        surveyReports,
        assignmentReports,
        iltReports,
        userGroupReports,
        branchReports,
        timeline,
        customReports,
      ]
    : [courseReports, timeline];

const useRoutePermissions = (): UseRoutePermissionsReturnType => {
  const { domainSettings, userProfileData } = useConfigurationStore();
  // State derived permissions
  const canAccessReferral = userProfileData?.policies?.can_access_referral ?? false;
  const { features_allowed_in_plan } = domainSettings ?? {};

  const canAccessCustomHomePageBuilder = features_allowed_in_plan?.cms;
  const canAccessCustomReports = features_allowed_in_plan?.custom_reports;

  const { canAccessSkills: hasSkillsReadPermission } = permissionsUtils.skillsPermissions;

  // Permissions
  const permissions = {
    accountGamification,
    courseReports,
    customReports,
    userReports,
    testReports,
    surveyReports,
    assignmentReports,
    iltReports,
    userGroupReports,
    branchReports,
    discussionsRead,
    discussionsCreate,
    profileRead,
    reportsRead,
    ecommerceUpdate,
    coursesRead,
    coursesUnionRead,
    coursesUnitRead,
    coursesUnitUpdate,
    coursesFilesSharedRead,
    gamificationRead,
    messagesRead,
    messagesCreate,
    catalogRead,
    calendarRead,
    accountRead,
    accountUpdate,
    customDomain,
    courseStoreSubscription,
    subscriptionRead,
    createImport,
    createExport,
    usersRead,
    usersUpdate,
    usersCreate,
    groupRead,
    groupCreate,
    groupJoin,
    categoriesRead,
    userTypesRead,
    branchRead,
    branchCreate,
    branchUpdate,
    automationsRead,
    notificationsRead,
    conferencesRead,
    reportsMainPagePermissions,
    canAccessReferral,
    canAccessCustomHomePageBuilder,
    canAccessCustomReports,
    skillsRead,
    hasSkillsReadPermission,
  };

  return { permissions };
};

export default useRoutePermissions;
