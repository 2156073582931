import { t } from "@utils/i18n";
import { yup } from "@utils/validation/validation";

const tag = yup.object({
  name: yup
    .string()
    .trim()
    .max(50, () => t("errors.tags.nameTooLong", { count: 50 })),
});

// Each file has max 10 tags and each tag has max 50 chars
export const FilesSchemas = yup.object().shape({
  name: yup
    .string()
    .trim()
    .max(80, () => t("errors.files.fileNameTooLong"))
    .required(),
  tags: yup
    .array()
    .of(tag)
    .max(10, () => t("errors.files.maxNumberOfTags")),
});
