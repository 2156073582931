import { css } from "@emotion/react";

import { mq } from "@utils/breakpoints";

export const profileMenuStyles = ({ colors, button }) => css`
  position: relative;

  .profile-menu-button {
    position: relative;
    display: flex;
    max-width: 14.375rem;
    height: 3.5rem;
    padding: 0.5rem;
    border: 0.0625rem solid ${colors.secondary.lighter};
    border-radius: 0.3125rem;
    cursor: pointer;
    transition: background 0.3s ease-in-out;

    .arrow-down {
      display: flex;
      margin-left: auto;
      align-items: center;
    }

    .user-name {
      display: inline-block;
      max-width: 8.125rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // The user details on the button (not the details inside the sub-menu) should only be shown when:
    // 1. The user has more than two roles
    // 2. The screen size is medium or larger

    // Mobile version
    .user-details {
      display: none;
    }

    // Desktop version
    ${mq["md"]} {
      .user-details {
        display: flex;
      }
    }
  }

  // Active state of the sub-menu container and the profile menu button
  &.is-active {
    .sub-menu-container {
      display: block;
    }

    .profile-menu-button {
      color: ${button.primary.ghost.hoverColor};
      background: ${button.primary.ghost.background};
      border-radius: 0.3125rem;

      svg {
        fill: ${button.primary.ghost.hoverColor};
      }
    }
  }
`;
