import { t } from "@utils/i18n";

import { URLS } from "@constants/urls";

import { ContactSupportEnum } from "@components/Drawers/HelpCenter/types";
import { FeedbackSectionMapping } from "@components/ReusableModals/FeedbackModal/types";

import { MainDrawerContentEnum, SelectOption } from "types/common";

export const getFeedbackCategoriesOptions = (): SelectOption[] => {
  return [
    {
      label: t("feedbackMechanism.feedbackType.feature"),
      value: "feature",
    },
    {
      label: t("feedbackMechanism.feedbackType.aesthetic"),
      value: "aesthetic",
    },
    {
      label: t("feedbackMechanism.feedbackType.functionality"),
      value: "functionality",
    },
    {
      label: t("general.other"),
      value: "other",
    },
  ];
};

export const urlsToSections = (): FeedbackSectionMapping => ({
  [URLS.dashboard]: { translated: t("mainMenu.home"), raw: "home" },
  [URLS.users.users]: { translated: t("mainMenu.users"), raw: "users" },
  [URLS.courses.courses]: { translated: t("mainMenu.courses"), raw: "courses" },
  [URLS.groups.groups]: { translated: t("mainMenu.groups"), raw: "groups" },
  [URLS.branches.branches]: { translated: t("mainMenu.branches"), raw: "branches" },
  [URLS.automations.automations]: { translated: t("general.automations"), raw: "automations" },
  [URLS.notifications.notifications]: {
    translated: t("general.notifications"),
    raw: "notifications",
  },
  [URLS.reports.reports]: { translated: t("general.reports"), raw: "reports" },
  [URLS.skills.skills]: { translated: t("systemOptions.skills"), raw: "skills" },
  [URLS.accountAndSettings.accountAndSettings]: {
    translated: t("general.settings"),
    raw: "settings",
  },
});

export const supportTicketProcessSteps = [
  MainDrawerContentEnum.HELP_CENTER,
  ContactSupportEnum.CONTACT_SUPPORT,
  ContactSupportEnum.SUBMIT_TICKET,
];
