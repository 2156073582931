import React, { FC, ReactElement } from "react";
import { useMutation, useQueryClient } from "react-query";

import { Button, Modal, Text } from "@epignosis_llc/gnosis";
import {
  AccessSVG,
  AutomationsIconSVG,
  BrainSVG,
  BranchIconSVG,
  CatalogSVG,
  CustomReportsSVG,
} from "@epignosis_llc/gnosis/icons";

import { MicrochipAISVG, PassportSVG, TextBoxSVG } from "@assets/images";
import {
  reverseTrialModalCustomStyles,
  trialContainerStyle,
} from "@components/ReverseTrial/ReverseTrial.styles";

import { dismissStartedModal, enableReverseTrial } from "@api/reverseTrial";
import { clearTrialModalKeys } from "@components/ReverseTrial/helpers";
import { useApplyTranslations } from "@hooks";
import { generalNotification } from "@utils/helpers";

import { images } from "@constants/assets";
import queryKeys from "@constants/queryKeys";

type ReverseTrialModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
  automaticallyStarted?: boolean;
};

type ColumnItem = {
  label: string;
  icon: ReactElement;
};

export const StartReverseTrialModal: FC<ReverseTrialModalProps> = ({
  isOpen,
  toggleModal,
  automaticallyStarted = false,
}) => {
  const { t } = useApplyTranslations();

  const ICON_CLASS_NAME = "icon-color";
  const ICON_HEIGHT = 32;

  const getFirstColumnItems = (): ColumnItem[] => [
    {
      label: t("skills.skills"),
      icon: <BrainSVG height={ICON_HEIGHT} key="ai-course" className={ICON_CLASS_NAME} />,
    },
    {
      label: t("reverseTrial.aiCourseGenerator"),
      icon: <MicrochipAISVG height={ICON_HEIGHT} key="ai-course" className={ICON_CLASS_NAME} />,
    },
    {
      label: t("reverseTrial.talentLibraryCourses"),
      icon: <CatalogSVG height={ICON_HEIGHT} key="tl-library" className={ICON_CLASS_NAME} />,
    },
    {
      label: t("reverseTrial.branches", { number: 5 }),
      icon: <BranchIconSVG height={ICON_HEIGHT} key="branches" className={ICON_CLASS_NAME} />,
    },
    {
      label: t("reverseTrial.prioritySupport"),
      icon: <TextBoxSVG height={ICON_HEIGHT} key="priority-support" className={ICON_CLASS_NAME} />,
    },
  ];

  const getSecondColumnItems = (): ColumnItem[] => [
    {
      label: t("general.automations"),
      icon: (
        <AutomationsIconSVG height={ICON_HEIGHT} key="automations" className={ICON_CLASS_NAME} />
      ),
    },
    {
      label: t("systemOptions.customReports"),
      icon: (
        <CustomReportsSVG height={ICON_HEIGHT} key="custom-reports" className={ICON_CLASS_NAME} />
      ),
    },
    {
      label: t("accountAndSettings.portalSettings.customDomain.customDomain"),
      icon: <PassportSVG height={ICON_HEIGHT} key="custom-domain" className={ICON_CLASS_NAME} />,
    },
    {
      label: t("reverseTrial.sso"),
      icon: <AccessSVG height={ICON_HEIGHT} key="sso" className={ICON_CLASS_NAME} />,
    },
  ];

  const renderColumn = (items: ColumnItem[]): JSX.Element[] =>
    items.map((item, index) => (
      <div key={index} className="modal-features">
        {item.icon}
        <Text className="feature-text" fontSize="md" data-testid="feature-text">
          {item.label}
        </Text>
      </div>
    ));

  const queryClient = useQueryClient();

  const invalidateQueries = (): void => {
    queryClient.invalidateQueries([queryKeys.reverseTrial.enable]);
    queryClient.invalidateQueries([queryKeys.accountAndSettings.subscription.plans]);
    queryClient.invalidateQueries([queryKeys.accountAndSettings.subscription.currentPlan]);
    queryClient.invalidateQueries([queryKeys.domainSettings]);
    queryClient.invalidateQueries([queryKeys.userProfile]);
  };

  const { mutate: startTrialMutation, isLoading: enableReverseTrialLoading } = useMutation(
    () => enableReverseTrial(),
    {
      onSuccess: () => {
        invalidateQueries();
        clearTrialModalKeys();
        generalNotification("success", <p>{t("reverseTrial.activatedSuccessfully")}</p>);
        toggleModal();
      },
      onError: () => {
        toggleModal();
        generalNotification("error", <p>{t("reverseTrial.activationFailed")}</p>);
      },
    },
  );

  const { mutate: dismissTrialStartedModalMutation } = useMutation(
    [queryKeys.reverseTrial.dismissStartedModal],
    () => dismissStartedModal(),
    {
      onSuccess: () => {
        invalidateQueries();
      },
      onError: () => {
        generalNotification("error", <p>{t("errors.general.genericIssue")}</p>);
      },
    },
  );

  const handleStartTrial = (): void => {
    startTrialMutation();
  };

  const handleClose = (): void => {
    if (automaticallyStarted) {
      dismissTrialStartedModalMutation();
    }
    toggleModal();
  };

  const modalConfig = {
    testId: automaticallyStarted ? "trial-started-modal" : "reverse-trial-modal",
    title: t(
      automaticallyStarted
        ? "reverseTrial.trialStartedModalTitle"
        : "reverseTrial.startOfTrialHeader",
    ),
    description: t(
      automaticallyStarted ? "reverseTrial.trialStartedModalBody" : "reverseTrial.startOfTrialBody",
    ),
    imageAlt: t(
      automaticallyStarted ? "reverseTrial.trialStartedImgAlt" : "reverseTrial.startOfTrialImgAlt",
    ),
    closeOnOutsideClick: !automaticallyStarted,
    titleFontSize: automaticallyStarted ? "lg" : "xl",
    footer: automaticallyStarted ? (
      <Button
        data-testid="trial-started-modal-ok-button"
        variant="solid"
        onClick={handleClose}
        aria-label={t("general.ok")}
      >
        {t("general.ok")}
      </Button>
    ) : (
      <Button
        data-testid="reverse-trial-modal-yes-button"
        variant="solid"
        onClick={handleStartTrial}
        isLoading={enableReverseTrialLoading}
        aria-label={t("reverseTrial.start14DayTrial")}
      >
        {t("reverseTrial.start14DayTrial")}
      </Button>
    ),
  };

  return (
    <Modal
      data-testid={modalConfig.testId}
      style={reverseTrialModalCustomStyles}
      isOpen={isOpen}
      onClose={handleClose}
      closeOnOutsideClick={modalConfig.closeOnOutsideClick}
    >
      <Modal.Header>
        <Text fontSize={modalConfig.titleFontSize} weight="700">
          {modalConfig.title}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <div css={trialContainerStyle}>
          <img src={images.reverseTrial.dashboardImg} alt={modalConfig.imageAlt} />
          <Text fontSize="md" className="description">
            {modalConfig.description}
          </Text>
          <div className="modal-rows" data-testid="reverse-modal-rows">
            <div className="modal-columns">{renderColumn(getFirstColumnItems())}</div>
            <div className="modal-columns">{renderColumn(getSecondColumnItems())}</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>{modalConfig.footer}</Modal.Footer>
    </Modal>
  );
};
