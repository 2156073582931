import ReactFroalaEditor from "froala-editor";

import { CustomButton, CustomIcon } from "@components/FormElements/Editor/customCommands/types";

export const registerUploadImageButton = (callback: (editor: ReactFroalaEditor) => void): void => {
  ReactFroalaEditor.RegisterCommand(CustomButton.UploadImageButton, {
    title: "Upload image",
    type: "button",
    icon: CustomIcon.UploadImage,
    undo: true,
    focus: true,
    refreshAfterCallback: true,
    callback: function () {
      callback(this);
    },
  });
};

export const registerInsertSmartTagButton = (
  callback: (editor: ReactFroalaEditor) => void,
): void => {
  ReactFroalaEditor.RegisterCommand(CustomButton.InsertSmartTagButton, {
    title: "Smart tags",
    type: "button",
    icon: CustomIcon.InsertSmartTag,
    undo: true,
    focus: true,
    refreshAfterCallback: true,
    callback: function () {
      callback(this);
    },
  });
};

export const registerAIFeaturesButton = (callback: (editor: ReactFroalaEditor) => void): void => {
  ReactFroalaEditor.RegisterCommand(CustomButton.AIFeatures, {
    title: "AI Features",
    type: "button",
    icon: CustomIcon.Sparkles,
    toggle: true,
    callback: function () {
      callback(this);
    },
  });
};
