import React from "react";

import { Column, DropdownItem, TableProps } from "@epignosis_llc/gnosis";
import {
  ContentIcon,
  DragAndDropSVG,
  FillTheGapSVG,
  FreetextSVG,
  GeneralContentSVG,
  LearningActivitiesSVG,
  LikertSaleSVG,
  LtiToolSVG,
  MoreIconSVG,
  MultipleChoiceSVG,
  NonPreviewableSVG,
  OrderingSVG,
  RadioSelectedSVG,
  RandomizedSVG,
  TalentCraftSVG,
  UnitAssignmentSVG,
  UnitAudioSVG,
  UnitCompleteSVG,
  UnitFailedSVG,
  UnitFlashSVG,
  UnitIframeSVG,
  UnitILTSVG,
  UnitPendingSVG,
  UnitPPTSVG,
  UnitScormSVG,
  UnitSurveySVG,
  UnitTestSVG,
  UnitVideoSVG,
  UnitWebContentSVG,
} from "@epignosis_llc/gnosis/icons";
import { isBefore } from "date-fns";
import { toDate } from "date-fns-tz";
import { groupBy, pipe, prop } from "ramda";

import { useConfigurationStore } from "@stores";
import { isCourseLevelRestricted } from "@utils/helpers/course";
import { NON_FLOAT_CURRENCIES } from "@utils/helpers/currency";
import { formatUtcDate, getDistanceFromNowInSeconds } from "@utils/helpers/date-time";
import { t } from "@utils/i18n";

import { LTI } from "@constants/nonTranslatable";

import { Option } from "@components/FormElements/AutocompleteInput/AutocompleteInput";
import { MainNavData } from "@components/MainNav/MainNav";
import { RTLIcon } from "@components/ReusableComponents";

import { MainDrawerContent } from "types/common";
import {
  Availability,
  Course,
  CourseCategory,
  Leaderboard,
  LeaderboardUser,
  LimitedTo,
  MyUnit,
  QuestionType,
  Recipient,
  SSOSettings,
  TestUnit,
  Timezone,
  UserProfile,
} from "types/entities";
import { Language, SSO } from "types/entities/Domain";
import { UnitMenu, UnitProgress } from "types/entities/Unit";
import { PaginationRes } from "types/responses";

type Sorting = TableProps["sorting"];

type IntegrationTypesMapping = Record<SSO, string> & {
  none: string;
};

const { getState } = useConfigurationStore;

export const isMainMenuItemActive = (navState: MainNavData, linkPath: string): boolean => {
  const mainDrawerIsClosed = !navState?.mainDrawer.show;
  const isHighlighted =
    mainDrawerIsClosed && navState?.pagePath && linkPath && navState?.pagePath.includes(linkPath);

  return Boolean(isHighlighted);
};

export const isMainMenuItemActiveForDrawer = (
  navState: MainNavData,
  drawerLink: MainDrawerContent,
): boolean => {
  const mainDrawerIsOpen = navState?.mainDrawer.show;
  const isHighlighted = mainDrawerIsOpen && navState?.mainDrawer.content === drawerLink;

  return Boolean(isHighlighted);
};

export const getUnitIconByType = (
  type: MyUnit["type"] | UnitMenu | TestUnit["type"],
): JSX.Element => {
  const iconSize = 32;

  switch (type) {
    case "test":
      return <UnitTestSVG height={iconSize} />;
    case "audio":
      return <UnitAudioSVG height={iconSize} />;
    case "video-youtube":
    case "video-vimeo":
    case "video-uploaded":
      return <RTLIcon icon={UnitVideoSVG} height={iconSize} />;
    case "assignment":
      return <UnitAssignmentSVG height={iconSize} />;
    case "flash":
      return <UnitFlashSVG height={iconSize} />;
    case "iframe":
      return <UnitIframeSVG height={iconSize} />;
    case "ilt":
      return <UnitILTSVG height={iconSize} />;
    case "scorm":
      return <UnitScormSVG height={iconSize} />;
    case "survey":
      return <UnitSurveySVG height={iconSize} />;
    case "webpage":
      return <UnitWebContentSVG height={iconSize} />;
    case "unit":
      return <GeneralContentSVG height={iconSize} />;
    case "document-uploaded":
    case "document-slideshare":
      return <UnitPPTSVG height={iconSize} />;
    case "craft":
      return <TalentCraftSVG height={iconSize} />;
    case "content":
      return <ContentIcon height={iconSize} />;
    case "learning":
      return <LearningActivitiesSVG height={iconSize} />;
    case "more":
      return <MoreIconSVG height={iconSize} />;
    case "lti":
    case "externalContent":
      return <LtiToolSVG height={iconSize} />;
    default:
      return <RTLIcon icon={UnitVideoSVG} height={iconSize} />;
  }
};

export const getQuestionIconByType = (type: QuestionType, iconSize = 32): JSX.Element => {
  const { DragAndDrop, Freetext, MultipleChoice, Ordering, FillTheGap, LikertScale, Randomized } =
    QuestionType;

  switch (type) {
    case DragAndDrop:
      return <DragAndDropSVG height={iconSize} />;
    case Freetext:
      return <FreetextSVG height={iconSize} />;
    case MultipleChoice:
      return <MultipleChoiceSVG height={iconSize} />;
    case Ordering:
      return <OrderingSVG height={iconSize} />;
    case FillTheGap:
      return <FillTheGapSVG height={iconSize} />;
    case LikertScale:
      return <LikertSaleSVG height={iconSize} />;
    case Randomized:
      return <RandomizedSVG height={iconSize} />;
    default:
      return <></>;
  }
};

export const getIconByCompletion = (
  status: UnitProgress["status"],
  height = 32,
  defaultIcon = <UnitPendingSVG />,
  type = "default",
): JSX.Element => {
  switch (status) {
    case "completed":
      return <UnitCompleteSVG height={height} />;
    case "failed":
      return <UnitFailedSVG height={height} />;
    case "pending":
      if (type === "question") return <RadioSelectedSVG height={height} />;
      return defaultIcon;
    default:
      return defaultIcon;
  }
};

export const truncate = (str: string, length: number): string => {
  return str.length > length ? str.slice(0, length - 1) + "..." : str;
};

export const readableBytes = (bytes: number): string => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  return `${Number((bytes / Math.pow(1024, i)).toFixed(2)) * 1} ${sizes[i]}`;
};

type ByteUnit = "B" | "KB" | "MB" | "GB" | "TB" | "PB" | "EB" | "ZB" | "YB";

export const convertBytesToSizeUnit = (bytes: number, byteUnit: ByteUnit): number => {
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const index = sizes.indexOf(byteUnit);

  return Number((bytes / Math.pow(1024, index)).toFixed(2));
};

export const convertBase64ToBlob = (base64Image: string): Blob => {
  // Split into two parts
  const [, base64Content] = base64Image.split(";base64,");

  // Decode Base64 string
  const decodedData = window.atob(base64Content);

  // Create Uint8Array from decoded data
  const uInt8Array = new Uint8Array(Array.from(decodedData, (char) => char.charCodeAt(0)));

  // Get image type
  const imageType = base64Image.split(":")[1].split(";")[0];

  // Return BLOB image after conversion
  return new Blob([uInt8Array], { type: imageType });
};

export const reorder = <T,>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const paginationInfoText = (pagination: PaginationRes): string => {
  if (!pagination) {
    return "";
  }

  if (pagination.total_results === 0) {
    return "";
  }

  // Only one page
  if (pagination?.total_results <= pagination?.page_size) {
    return t("general.showing", { results: pagination.total_results });

    // More than one page
  } else {
    const pageStart = pagination.page_size * (pagination.page - 1) + 1;
    const pageEnd =
      pagination.page === pagination.total_pages // Is last page
        ? pagination.total_results
        : pageStart + pagination.page_size - 1;

    return t("general.showingPageResults", {
      pageStart: pageStart,
      pageEnd: pageEnd,
      totalResults: pagination.total_results,
    });
  }
};

// Map autocomplete options to recipients
export const mapAutocompleteOptionsToRecipients = (
  options: Option[] | Option,
): Recipient[] | Recipient => {
  if (Array.isArray(options)) {
    return options.map((option) => ({
      id: Number(option.value),
      name: option.label,
      type: option.type as Recipient["type"],
    }));
  } else {
    return {
      id: Number(options.value),
      name: options.label,
      type: options.type as Recipient["type"],
    };
  }
};

// Map autocomplete options to limited to object
export const mapAutocompleteOptionsToLimitedTo = (
  options: Option[] | Option,
): LimitedTo[] | LimitedTo => {
  if (Array.isArray(options)) {
    return options.map((option) => ({
      id: Number(option.value),
      name: option.label,
      type: option.group as LimitedTo["type"],
    }));
  } else {
    return {
      id: Number(options.value),
      name: options.label,
      type: options.group as LimitedTo["type"],
    };
  }
};

// Map timezone options to autocomplete
export const mapTimezonesToAutocompleteOptions = (timezones: Timezone[]): Option[] => {
  return timezones.map((item) => ({
    label: `(GMT ${item.gmt_offset}) ${item.label}`,
    value: item.zone_name,
  }));
};

//  Autocomplete option to timezone
export const autocompleteOptionToTimezone = (option: Option): string => option.value;

// Create string with all the recipients name grouped by type
export const messagesRecipientsText = (recipients: Recipient[]): string => {
  const translationsMapping = {
    user: t(`messages.recipient.users`, { count: 2 }),
    course: t(`messages.recipient.courses`, { count: 2 }),
    group: t(`messages.recipient.groups`, { count: 2 }),
    branch: t(`messages.recipient.branches`, { count: 2 }),
    instructor: t(`messages.recipient.instructors`, { count: 2 }),
    custom_report: t(`messages.recipient.customReports`, { count: 2 }),
  };

  // Group recipienct by type
  const groupByType = pipe<Recipient[][], Record<string, Recipient[]>>(groupBy(prop("type")));
  const groupedRecipients = groupByType(recipients);

  // Create recipients text for table
  const recipientsText = Object.keys(groupedRecipients).reduce((text, groupKey): string => {
    const mappedGroup = {
      [groupKey]: groupedRecipients[groupKey].map((recipient) => recipient.name),
    };
    const names = mappedGroup[groupKey];

    const prefix = text.length > 0 ? ", " : "";
    text += `${prefix}${translationsMapping[groupKey]}: ${names.join(", ")}`;
    return text;
  }, "");

  return recipientsText;
};

export const getFileNameAndExtension = (
  fileName: string,
): {
  name: string;
  extension: string;
} => {
  const extensionSplitCharIndex = fileName.lastIndexOf(".");
  return {
    name: fileName.substring(0, extensionSplitCharIndex),
    extension: fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length),
  };
};

export const mapTableToSelectSorting = (sorting: Sorting): string => {
  const { column, isDescending } = sorting || {};
  return column ? `${isDescending ? "-" : ""}${column}` : "";
};

export const mapSelectToTableSorting = (sorting: string): Sorting => {
  const firstChar = sorting.charAt(0);

  return {
    column: firstChar === "-" ? sorting.substring(1, sorting.length) : sorting,
    isDescending: firstChar === "-",
  };
};

export const sortLeaders = (data: Leaderboard): LeaderboardUser[] => {
  if (data) {
    return data.leaders.sort((a, b) => b.score - a.score);
  }

  return [];
};

export type UserNameFormatProps = Pick<UserProfile, "name" | "surname" | "login"> & {
  userFormat?: string;
};

// Allow the function below to accept any type of array as long as it has id and name
type IdentifiableWithName = {
  id: string | number;
  name: string | null;
};

export const getFormattedOptionByName = <T extends IdentifiableWithName>(
  id: string,
  array: T[],
): string => {
  return array.find((item) => item.id.toString() === id)?.name ?? "";
};

export const getFormattedLocale = (locale: string, languages: Language[]): string => {
  return languages.find((language) => language.locale === locale)?.name ?? "";
};

export const getFormattedTimezoneOffset = (zone_name: string, timezones: Timezone[]): string => {
  const selectedTimezone = timezones.find((timezone) => timezone.zone_name === zone_name);
  return `(GMT ${selectedTimezone?.gmt_offset}) ${selectedTimezone?.label}`;
};

export const userFormatsMapping = {
  f_lastname: "general.userFormats.fLastName",
  firstname_l: "general.userFormats.firstNameL",
  firstname_lastname: "general.userFormats.firstNameLastName",
  lastname_f: "general.userFormats.lastNameF",
  lastname_firstname: "general.userFormats.lastNamefirstName",
  username: "general.userFormats.username",
};

export const signUpOptionsMapping = {
  direct: "general.signUpOptions.direct",
  manual: "general.signUpOptions.manual",
};

export const integrationTypesOptionsMapping = (): IntegrationTypesMapping => ({
  saml: "SAML2.0",
  ldap: "LDAP",
  oidc: "OpenID Connect",
  none: t("general.none"),
});

export const SSOLoginTypeOptionsMapping = {
  classic: "accountAndSettings.usersSettings.loginPageAndIdP",
  idp: "accountAndSettings.usersSettings.idPLoginPage",
};

export const verificationOptionsMapping = {
  none: "general.verificationOptions.none",
  captcha: "general.verificationOptions.captcha",
  captcha_email: "general.verificationOptions.captcha_email",
  captcha_admin: "general.verificationOptions.captcha_admin",
  email: "general.verificationOptions.email",
  admin: "general.verificationOptions.admin",
};

export const getFormattedUserName = ({
  name,
  surname,
  login,
  userFormat = "",
}: UserNameFormatProps): string => {
  const { domainSettings } = getState();
  const userNameFormat = userFormat ? userFormat : domainSettings?.user_format;

  switch (userNameFormat) {
    case "f_lastname":
      return name && surname && `${name.charAt(0)}. ${surname}`;
    case "firstname_l":
      return name && surname && `${name} ${surname.charAt(0)}.`;
    case "lastname_f":
      return name && surname && `${surname} ${name.charAt(0)}.`;
    case "lastname_firstname":
      return `${surname} ${name}`;
    case "username":
      return login;
    default:
      return `${name} ${surname}`;
  }
};

export const unitHasDelayedAvailability = (availability: Availability | null): boolean => {
  // (not enrolled OR enrolled) course without delayed availability
  if (!availability || !availability.delay) return false;

  // Enrolled course with delayed availability
  if (availability.available_on) {
    return isBefore(new Date(), toDate(availability.available_on));
  }

  // Not enrolled course with delayed availability
  return Boolean(availability?.delay);
};

export const availabilityDelayInDaysHoursMinutes = (totalSeconds: number): string => {
  const days = Math.floor(totalSeconds / (3600 * 24));
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  const daysDisplay = days > 0 ? t("general.daysShort", { days: days }) : "";
  const hoursDisplay = hours > 0 ? t("general.hoursShort", { hours: hours }) : "";
  const minutesDisplay = minutes > 0 ? t("general.minutesShort", { minutes: minutes }) : "";

  if (totalSeconds < 60) {
    return t("general.secondsShort", { seconds: totalSeconds });
  }

  return `${daysDisplay} ${hoursDisplay} ${minutesDisplay}`;
};

export const unitAvailableInTime = (date: string): string => {
  const seconds = getDistanceFromNowInSeconds(toDate(date));
  return availabilityDelayInDaysHoursMinutes(seconds).toLowerCase();
};

export const flatten = (categoryTree: CourseCategory[]): CourseCategory[] => {
  return categoryTree.reduce((acc: CourseCategory[], r: CourseCategory) => {
    acc.push(r);
    if (r.children) {
      acc = acc.concat(flatten(r.children));
    }

    return acc;
  }, []);
};

// Returns the displayed category name
export const getCategoryText = (
  category: CourseCategory,
  categoryTree: CourseCategory[],
): string => {
  if (category?.parent_id) {
    const flatArray = flatten(categoryTree);
    const parentName = flatArray.find(({ id }) => id === category?.parent_id)?.name;
    const parentCategory = parentName ? `${parentName} / ` : "";
    return `${parentCategory}${category.name}`;
  }
  return category?.name || "";
};

// Returns the category name and its parents in an array
export const findCategoryParents = (
  category: CourseCategory,
  categories: CourseCategory[],
  parents: string[] = [category.name],
): string[] => {
  const parentCategory = categories.find(({ id }) => id === category.parent_id);

  if (parentCategory) {
    parents.push(parentCategory.name);
    return findCategoryParents(parentCategory, categories, parents);
  }

  return parents;
};

/**
 * @function getCategoryInfo is a helper function that combines the two above in order to use them inside
 * courses related arrays to get nested categories when needed
 * @returns @param categoryTreeText @param shouldDisplayTooltip @param categoryTootltipText inside an object
 * */
type CategoriesPath = {
  categoryTreeText: string;
  shouldDisplayTooltip: boolean;
  categoryTooltipText: string;
};

export const getCategoryInfo = (
  category: CourseCategory,
  categoryTree: CourseCategory[],
  displayedCategories = 2,
): CategoriesPath => {
  const categoryTreeText = getCategoryText(category, categoryTree);
  const allCategoryParent = findCategoryParents(category, flatten(categoryTree));
  const shouldDisplayTooltip = allCategoryParent.length > displayedCategories;
  const categoryTooltipText = allCategoryParent?.reverse().join(" / ");

  return {
    categoryTreeText,
    shouldDisplayTooltip,
    categoryTooltipText,
  };
};

export const getCurrencyPrice = (
  amount: number,
  currency: string,
  locale: string,
  minimumFractionDigits?: number,
): string => {
  const fractionNumber = minimumFractionDigits ?? 2;
  const isFloatCurrency = !NON_FLOAT_CURRENCIES.includes(currency);

  return amount.toLocaleString(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: !isFloatCurrency ? 0 : fractionNumber,
  });
};

export const getButtonAvailabilityText = (course: Course): string => {
  switch (course.availability?.reason) {
    case "not-started":
      return t("restrictions.startsOn", {
        startsOn: course.starts_at && formatUtcDate(course.starts_at),
      });
    case "expired":
      return t("general.expired");
    case "pending-prerequisites":
      return t("general.restricted");
    case "unlocks-on-higher-level":
      return t("restrictions.unlockedOnLevel", { level: course.level });
    case "cancelled":
      return t("general.cancelled");
    default:
      return "";
  }
};

export const getCourseRestriction = (course: Course): string => {
  const courseLevel = course.level;
  const today = Date.parse(new Date().toISOString());
  const isLevelRestricted = isCourseLevelRestricted(courseLevel);
  const { starts_at, expires_at, retain_access_after_completion, level } = course;

  // Check if course has restrictions
  if (isLevelRestricted) {
    return t("restrictions.unlockedOnLevel", { level: level });
  } else if (starts_at && Date.parse(starts_at) > today) {
    return t("restrictions.startsOn", { startsOn: formatUtcDate(starts_at) });
  } else if (expires_at) {
    if (Date.parse(expires_at) < today && retain_access_after_completion)
      return t("general.expired");
    if (Date.parse(expires_at) > today || Date.parse(expires_at) === today)
      return t("restrictions.expires", {
        expiresAt: expires_at && formatUtcDate(expires_at),
      });
    return "";
  } else return "";
};

export const isGravatarUrl = (url: string): boolean => url.includes("secure.gravatar.com/avatar");

export const renderNonPreviewable = (): JSX.Element => (
  <div className="no-thumbnail-cover" aria-label={t("a11y.general.noThumbnail")}>
    <NonPreviewableSVG height={40} />
  </div>
);

export const isChromeBrowser = (): boolean => {
  return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
};

type DefineSortOrderType = {
  str: string;
  hasMinus: boolean;
};

export const defineSortOrder = (str: string): DefineSortOrderType => {
  if (str.charAt(0) === "-") {
    return { str: str.slice(1), hasMinus: true };
  }
  return { str, hasMinus: false };
};

export const getUnitTitleByType = (type: MyUnit["type"]): string => {
  switch (type) {
    case "test":
      return t("general.test");
    case "audio":
      return t("general.audio");
    case "video-youtube":
    case "video-vimeo":
    case "video-uploaded":
      return t("general.video");
    case "assignment":
      return t("general.assignment");
    case "iframe":
      return t("general.iframe");
    case "ilt":
      return "ILT"; // Not translated
    case "scorm":
      return "SCORM"; // Not translated
    case "survey":
      return t("general.survey");
    case "webpage":
      return t("general.webContent");
    case "unit":
      return t("myCourses.content");
    case "document-uploaded":
    case "document-slideshare":
      return t("general.document");
    case "craft":
      return t("general.talentCraft");
    case "lti":
      return LTI; // Not translated
    default:
      return "";
  }
};

export const customToFixed = (num: number, digits = 2): string => {
  if (Number.isInteger(num)) {
    return num.toString();
  }

  return num.toFixed(digits);
};

export const updateUrlsToNull = (obj: SSOSettings): void => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (value && typeof value === "object") {
      // Recursively call the function for nested objects
      updateUrlsToNull(obj[key]);
    } else if (key.endsWith("_url") && obj[key] === "") {
      // Check if the property ends with "_url" and is an empty string
      obj[key] = null;
    }
  });
};

export const getSortingDropdownOptions = (columns: Column[]): DropdownItem[] => {
  const dropdownOptions: DropdownItem[] = columns.map((column) => {
    const { cell, accessor, sortOrder } = column ?? {};
    return {
      label: cell,
      value: accessor,
      id: sortOrder ? "desc" : "asc",
    } as DropdownItem;
  });

  return dropdownOptions;
};
