import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Text, Tooltip } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";

import {
  daysRemainingContainer,
  svgWrapper,
} from "@components/ReverseTrial/DaysRemaining/DaysRemaining.styles";

import { getURL } from "@api/app";
import { useApplyTranslations } from "@hooks";

import { URLS } from "@constants/urls";

import CircularProgress from "@components/ReverseTrial/DaysRemaining/components/CircularProgress/CircularProgress";

type DaysRemainingProps = {
  remainingDays: number;
  maxDays: number;
};

export const DaysRemaining: FC<DaysRemainingProps> = ({ remainingDays, maxDays }) => {
  const { t } = useApplyTranslations();
  const { lg } = useResponsive();
  const navigate = useNavigate();
  const baseURL = getURL();
  const subscriptionUrl = `${baseURL}${URLS.subscription.subscription}`;

  const translationMessage = t("reverseTrial.upgradeNowWithRemainingDays", {
    count: remainingDays,
    url: subscriptionUrl,
  });

  const handleOnClick = (): void => {
    navigate(URLS.subscription.subscription);
  };

  return (
    <Tooltip
      content={<Text fontSize="xs" dangerouslySetInnerHTML={{ __html: translationMessage }} />}
      placement="bottom"
    >
      <>
        {lg ? (
          <Button
            css={daysRemainingContainer}
            variant="solid"
            test-id="days-remaining-button"
            color="primaryLight"
            className="start-trial-button"
            onClick={handleOnClick}
            aria-label={t("general.upgradeNow")}
          >
            <div className="button-children">
              <CircularProgress currentValue={remainingDays} maxValue={maxDays} />
              <Text test-id="upgrade-now-text" fontSize="sm" weight="700" className="button-text">
                {t("general.upgradeNow")}
              </Text>
            </div>
          </Button>
        ) : (
          <div onClick={handleOnClick} role="button" css={svgWrapper}>
            <CircularProgress currentValue={remainingDays} maxValue={maxDays} />
          </div>
        )}
      </>
    </Tooltip>
  );
};
