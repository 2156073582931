import { Column, DropdownItem } from "@epignosis_llc/gnosis";

import { t } from "@utils/i18n";

import { MassActionEnum } from "@components/ReusableComponents/MassActions/types";

const ASSIGN_ACTION_MAX_WIDTH = 40;
export const getAssignSkillsToUsersColumns = (): Column[] => {
  return [
    {
      accessor: "user",
      cell: t("general.users"),
      classNames: ["formatted_name"],
    },
    {
      accessor: "actions",
      maxWidth: ASSIGN_ACTION_MAX_WIDTH,
      cell: "",
      classNames: ["hidden", "actions"],
    },
  ];
};

export const getAssignSkillsMassActionsOptions = (): DropdownItem[] => {
  return [
    {
      label: t("general.assign"),
      value: MassActionEnum.assign_skills,
    },
  ];
};

export const assignSkillsToUsersWordings = {
  assign_skills: {
    progressHeader: "skills.modal.assignSkillsHeader",
    progressMessage: "skills.modal.assignSkillsProgress",
    confirmationHeader: "skills.modal.assignSkillsHeader",
    confirmationButton: "general.assign",
    successMessage: "skills.modal.assignSkillsSuccess",
    confirmationBodyTitle: "skills.modal.assignSkillsWarning",
    confirmationBodyText: "skills.modal.assignSkillOccurences",
  },
};
