import { useLocation } from "react-router-dom";

import { useConfigurationStore } from "@stores";

import { CustomHomepageType } from "@views/CustomHomePage/types";

type useCmsType = {
  currentPath: string;
  isCustomHomePagePublished: boolean | undefined;
  pageExists: boolean | undefined;
  customPages: CustomHomepageType[] | undefined;
};

const useCms = ({ isPublic = false }: { isPublic?: boolean }): useCmsType => {
  const location = useLocation();
  const { domainSettings } = useConfigurationStore();
  const { cms } = domainSettings ?? {};

  const isCustomHomePagePublished = cms?.published;

  // Get all custom pages that are published and should be displayed before login.
  const customPages =
    cms &&
    cms.pages.filter((page) => {
      if (!page.active) return false;

      return isPublic
        ? page.show_before_login
        : (page.show_before_login && page.show_after_login) || page.show_after_login;
    });

  const pageKeys = customPages?.map((item) => item.url);
  const currentPath = location.pathname.substring(1); // Remove the leading '/'
  const pageExists = pageKeys?.includes(currentPath);

  return { currentPath, isCustomHomePagePublished, pageExists, customPages };
};

export default useCms;
