import { AxiosError } from "axios";

import { genericErrors } from "@errors/errorMessages";
import { handledApiError, HandledError } from "@errors/errors";

export const conferenceErrors: HandledError[] = [
  ...genericErrors,
  {
    status: 400,
    id: "bad_request.invalid_bigbluebutton_configuration",
    errorMsg: "errors.conference.invalidBigBlueButtonConfiguration",
  },
  {
    status: 400,
    id: "bad_request.past_date",
    errorMsg: "errors.conference.conferenceNotFound",
  },
  {
    status: 404,
    id: "not_found.conference_not_found",
    errorMsg: "errors.conference.conferenceNotFound",
  },
  {
    status: 404,
    id: " not_found.user_not_found",
    errorMsg: "errors.conference.userNotFound",
  },
  {
    status: 409,
    id: "conflict.conference_has_not_started",
    errorMsg: "errors.conference.notStarted",
  },
  {
    status: 409,
    id: "conflict.conference_server_communication_failed",
    errorMsg: "errors.conference.communicationFailed",
  },
  {
    status: 409,
    id: "conflict.conference_instructor_not_connected",
    errorMsg: "errors.conference.instructorNotConnected",
  },
  {
    status: 409,
    id: "conflict.invalid_message_recipient.recipients_not_found",
    errorMsg: "errors.general.recipientsNotFound",
  },
  {
    status: 409,
    id: "conflict.user_is_moderator_in_conference",
    errorMsg: "errors.conference.userIsModerator",
  },
  {
    status: 409,
    id: "conflict.user_is_member_of_this_conference",
    errorMsg: "errors.conference.userIsMember",
  },
  {
    status: 409,
    id: "conflict.user_is_not_member_of_this_conference",
    errorMsg: "errors.conference.userIsNotMember",
  },
  {
    status: 409,
    id: "conflict.conference_reached_capacity",
    errorMsg: "errors.conference.conferenceReachedCapacity",
  },
  {
    status: 409,
    id: "conflict.conference_has_ended",
    errorMsg: "errors.conference.hasEnded",
  },
];

export const handleConferenceErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, conferenceErrors, false, "", handleError);
};
