/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { useEffect } from "react";

import { useAuth } from "@hooks/index";
import useZendeskConfigData from "@hooks/useZendeskConfigData";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";

import { userRoles } from "@constants/index";

import { TicketField } from "@components/Drawers/HelpCenter/types";

import { ZendeskTokenRes } from "types/responses";

// Extend window interface
declare const window: {
  zE: any;
  zESettings: any;
} & Window;

const ZENDESK_SCRIPT_ID = "ze-snippet";
const ZENDESK_SCRIPT_URL = "https://static.zdassets.com/ekr/snippet.js?key=";

const canUseDOM = (): boolean => {
  return (
    typeof window !== "undefined" &&
    typeof window.document !== "undefined" &&
    typeof window.document.createElement !== "undefined"
  );
};

export const ZendeskAPI = (...args: any[]): void => {
  if (canUseDOM() && window.zE) {
    window.zE.apply(null, args);
  } else {
    // Console.warn("Zendesk is not initialized yet");
  }
};

type ZendeskScriptInfo = {
  zendeskScriptElement: HTMLElement | null;
  zendeskUniqueId: string | undefined;
};

export const getZendeskScriptInfo = (): ZendeskScriptInfo => {
  const zendeskScriptElement = document.getElementById(ZENDESK_SCRIPT_ID);
  const zendeskUniqueId = zendeskScriptElement?.dataset.uniqueId;
  return { zendeskScriptElement, zendeskUniqueId };
};

export const logoutZendesk = (): void => {
  ZendeskAPI("messenger", "logoutUser");
  closeAndHideZendeskWidget();

  const { zendeskScriptElement, zendeskUniqueId } = getZendeskScriptInfo();

  if (zendeskScriptElement && zendeskUniqueId === "help-script") {
    zendeskScriptElement.remove();
  }
};

export const closeAndHideZendeskWidget = (): void => {
  ZendeskAPI("messenger", "close");
  ZendeskAPI("messenger", "hide");
};

export const openAndShowZendeskWidget = (): void => {
  ZendeskAPI("messenger", "open");
  ZendeskAPI("messenger", "show");
};

export const openAndUpdateZendeskWidget = (zendeskFields: TicketField[]): void => {
  const zendeskFieldsValues = Object.keys(zendeskFields)
    .map((key) => ({
      id: zendeskFields[key].id,
      value: zendeskFields[key].value,
    }))
    .filter((field: TicketField) => field.value !== null);

  ZendeskAPI("messenger:set", "conversationFields", zendeskFieldsValues, openAndShowZendeskWidget);
};

type ZendeskServiceProps = {
  zendeskKey: string;
  shouldInitializeZendesk?: boolean;
  shouldInitializeMessengerZendesk?: boolean;
  defer?: boolean;
  onLoaded?: () => void;
  [key: string]: any; // For other possible settings
};

const useZendeskService = ({
  zendeskKey,
  shouldInitializeZendesk,
  shouldInitializeMessengerZendesk,
  defer,
  onLoaded,
}: ZendeskServiceProps): void => {
  const { isAuthenticated } = useAuth();
  const { domainSettings } = useConfigurationStore();
  const { chat_support: liveChatInPlan } = domainSettings?.features_allowed_in_plan ?? {};
  const { zendeskConfigDataMutation } = useZendeskConfigData();

  const userRole = authService.getDefaultRole();
  const isAdmin = userRole === userRoles.ADMINISTRATOR;

  useEffect(() => {
    const onHelpScriptLoaded = (): void => {
      if (isAuthenticated && liveChatInPlan) {
        zendeskConfigDataMutation("chat", {
          onSuccess: (data: ZendeskTokenRes) => {
            const token = data._data.jwt;

            // Log in the user to Zendesk using the retrieved token
            ZendeskAPI("messenger", "loginUser", (callback: (token: string | null) => void) => {
              callback(token);
            });
          },
        });
      } else {
        ZendeskAPI("messenger", "logoutUser");
      }

      if (typeof onLoaded === "function") {
        onLoaded();
      }
    };

    const insertHelpScript = (zendeskKey: string, defer?: boolean): void => {
      const { zendeskScriptElement } = getZendeskScriptInfo();

      if (!zendeskScriptElement && isAdmin) {
        const script = document.createElement("script");

        script.id = ZENDESK_SCRIPT_ID;
        script.src = `${ZENDESK_SCRIPT_URL}${zendeskKey}`;
        script.defer = !!defer;
        script.async = !defer;

        script.dataset.uniqueId = "help-script";

        script.addEventListener("load", () => {
          closeAndHideZendeskWidget();
          onHelpScriptLoaded();
        });
        document.body.appendChild(script);
      }
    };

    const insertMessengerScript = (zendeskKey: string): void => {
      const zendeskScriptElement = document.getElementById(ZENDESK_SCRIPT_ID);

      if (!zendeskScriptElement && !isAdmin) {
        const script = document.createElement("script");

        script.id = ZENDESK_SCRIPT_ID;
        script.src = `${ZENDESK_SCRIPT_URL}${zendeskKey}`;
        script.async = true;

        script.dataset.uniqueId = "messenger-script";

        script.addEventListener("load", () => {
          if (typeof onLoaded === "function") {
            onLoaded();
          }
        });
        document.body.appendChild(script);
      }
    };

    if (!canUseDOM()) return;

    if (shouldInitializeZendesk) {
      insertHelpScript(zendeskKey, defer);
    } else if (shouldInitializeMessengerZendesk) {
      insertMessengerScript(zendeskKey);
    }

    return () => {
      const { zendeskScriptElement, zendeskUniqueId } = getZendeskScriptInfo();

      if (zendeskScriptElement && zendeskUniqueId === "help-script") {
        zendeskScriptElement.removeEventListener("load", onHelpScriptLoaded);
      }
    };
  }, [
    zendeskKey,
    defer,
    onLoaded,
    shouldInitializeZendesk,
    shouldInitializeMessengerZendesk,
    isAuthenticated,
    liveChatInPlan,
    zendeskConfigDataMutation,
    isAdmin,
  ]);
};

export default useZendeskService;
