import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { SerializedStyles } from "@emotion/react";
import { Button, Drawer, Heading } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";

import { gamificationDrawerContainer } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/styles";

import { useGamification } from "@layouts/DashboardLayout/components/TopBarMenu/hooks/useGamification";

import { useApplyTranslations } from "@hooks";
import { useConfigurationStore } from "@stores";
import {
  filterLeaderboardData,
  shouldShowFooterButton,
} from "@views/WidgetsDashboard/components/Widgets/Leaderboard/helpers";

import { URLS } from "@constants/urls";
import { footerButtonTranslations } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/constants";

import GamificationInfoDrawer from "@views/WidgetsDashboard/components/Widgets/Leaderboard/GamificationInfoDrawer";
import LeaderboardTable from "@views/WidgetsDashboard/components/Widgets/Leaderboard/LeaderboardTable";
import OverviewStatisticsPanel from "@views/WidgetsDashboard/components/Widgets/Leaderboard/OverviewStatistics";
import { GamificationDrawerProps } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/types";

const GamificationDrawer: FC<GamificationDrawerProps> = ({ isDrawerOpen, onClose }) => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();
  const { data, permissions, leaderboardTabs, hasGamificationError, gamificationStatus, settings } =
    useGamification();
  const { lg } = useResponsive();
  const { gamificationSettings } = useConfigurationStore((state) => state);

  const [selectedTab, setSelectedTab] = useState(0);
  const [isInfoDrawerOpen, setIsInfoDrawerOpen] = useState(false);

  const currentTab = leaderboardTabs[selectedTab]?.title;
  const rewardsTab = { title: "rewards", data: data.rewards };

  const showFooterButton = shouldShowFooterButton(currentTab, gamificationSettings);

  const overviewData = filterLeaderboardData(data.leaderboard, permissions);

  const onInfoDrawerClose = (): void => {
    setIsInfoDrawerOpen(false);
  };

  const handleBadgeGalleryClick = (): void => {
    if (currentTab === "badges") {
      onClose();
      navigate(URLS.user.badges);
    } else setIsInfoDrawerOpen(true);
  };

  return (
    <Drawer
      isOpen={isDrawerOpen}
      onClose={onClose}
      placement="right"
      width={lg ? "48rem" : "24rem"}
      css={(theme): SerializedStyles => gamificationDrawerContainer(theme)}
      id="gamification-drawer"
    >
      <Drawer.Header>
        <Heading size={"md"}>{t("general.gamification")}</Heading>
      </Drawer.Header>
      <Drawer.Body>
        <OverviewStatisticsPanel overviewData={overviewData} />
        <LeaderboardTable
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          gamificationError={hasGamificationError}
          gamificationStatus={gamificationStatus}
          leaderboardTabs={leaderboardTabs}
          rewardsTab={rewardsTab}
          globalDiscount={settings.globalDiscount}
        />
        <GamificationInfoDrawer
          isOpen={isInfoDrawerOpen}
          onClose={onInfoDrawerClose}
          currentTab={currentTab}
        />
      </Drawer.Body>
      {showFooterButton && (
        <Drawer.Footer>
          <div className="footer-container">
            <Button
              color="primary"
              className="action-btn"
              variant="outline"
              onClick={handleBadgeGalleryClick}
            >
              {t(footerButtonTranslations[currentTab])}
            </Button>
          </div>
        </Drawer.Footer>
      )}
    </Drawer>
  );
};

export default GamificationDrawer;
