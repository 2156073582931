import Color from "color";

import { ThemePlusTypes } from "@styles/types";
import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["dateInput"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    borderColor: colors.secondary.lighter,
    textColor: colors.black,
    contentActiveColor: protectColorContrast("black", colors.primary.base),
    contentInactiveColor: colors.secondary.base,
    activeBackgroundColor: protectColorContrast(
      "darker",
      Color(colors.primary.lightest).alpha(0.25).string(),
      0.25,
    ),
    iconColor: colors.secondary.light,
    iconColorHover: colors.secondary.light,
    headerBackgroundColor: colors.white,
    boxShadowColor: Color(colors.black).alpha(0.1).string(),
  };
};
