import React, { FC, Fragment } from "react";
import ContentLoader from "react-content-loader";

import { i18n } from "@utils/i18n";

export type SkeletonTableProps = {
  rows?: number;
  rowHeight?: number;
};

const tableLength = 1060;

const SkeletonTable: FC<SkeletonTableProps> = ({ rows = 5, rowHeight = 60 }) => {
  const isRTL = i18n.dir() === "rtl";
  const contentVerticalPosition = (contentHeight: number, index: number): number =>
    (rows > 1 ? contentHeight + rowHeight * index : contentHeight) + 80;

  return (
    <ContentLoader viewBox={`0 0 ${tableLength} ${rowHeight * rows + 80}`} rtl={isRTL}>
      {/* Header row */}
      <rect x="0" y="0" width={tableLength} height="30" />
      <rect x="0" y="50" width={tableLength} height="30" />

      {/* Header titles */}
      <rect x="0" y="30" width="20" height="20" />
      <rect x="140" y="30" width="120" height="20" />
      <rect x="380" y="30" width="320" height="20" />
      <rect x="820" y="30" width="120" height="20" />
      <rect x="1040" y="30" width="20" height="20" />

      {/* Left border */}
      <rect x="0" y="0" rx="5" ry="5" width="1" height="100%" />

      {/* Right border */}
      <rect x={tableLength - 1} y="0" rx="5" ry="5" width="1" height="100%" />

      {new Array(rows).fill(" ").map((_, index) => {
        return (
          <Fragment key={index}>
            <rect
              x="20"
              y={`${contentVerticalPosition(20, index)}`}
              rx="5"
              ry="5"
              width="200"
              height="20"
            />
            <rect
              x="260"
              y={`${contentVerticalPosition(20, index)}`}
              rx="5"
              ry="5"
              width="400"
              height="20"
            />
            <rect
              x="700"
              y={`${contentVerticalPosition(20, index)}`}
              rx="5"
              ry="5"
              width="200"
              height="20"
            />
            <rect
              x="960"
              y={`${contentVerticalPosition(20, index)}`}
              rx="50%"
              ry="50%"
              width="20"
              height="20"
            />
            <rect
              x="1000"
              y={`${contentVerticalPosition(20, index)}`}
              rx="50%"
              ry="50%"
              width="20"
              height="20"
            />
            <rect
              y={`${contentVerticalPosition(59, index)}`}
              x="0"
              ry="5"
              width={tableLength}
              height="1"
            />
          </Fragment>
        );
      })}
    </ContentLoader>
  );
};

export default SkeletonTable;
