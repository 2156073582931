import { PlanName, PlanState } from "@views/Subscription/types";

const BILLING_PREFIX = "billingAndSubscription";
export const OPTIONS_PREFIX = `${BILLING_PREFIX}.optionsPage`;
export const CANCELLATION_SURVEY_PREFIX = `${BILLING_PREFIX}.cancellationSurvey`;
export const PLANS_PREFIX = `${BILLING_PREFIX}.plansPage`;
export const FEATURES_PREFIX = `${PLANS_PREFIX}.features`;
export const SUBSCRIPTION_PREFIX = `${BILLING_PREFIX}.subscriptionPage`;
export const TOOLTIPS_PREFIX = `${PLANS_PREFIX}.tooltips`;

export const CUSTOM_PLAN_CREDITS = 1000000;

export const ATHENS_DST_START_TIME = 5;
export const ATHENS_DST_END_TIME = 10;
export const ATHENS_NON_DST_START_TIME = 6;
export const ATHENS_NON_DST_END_TIME = 11;
export const ATLANTA_DST_START_TIME = 2;
export const ATLANTA_DST_END_TIME = 10;
export const ATLANTA_NON_DST_START_TIME = 3;
export const ATLANTA_NON_DST_END_TIME = 11;

export enum CancellationModalView {
  LANDING = "landing",
  SURVEY = "survey",
  FREE_PLAN = "downgrade",
  SUPPORT = "support",
}

export const timezones = {
  athens: "Europe/Athens",
  atlanta: "America/New_York",
};

export const USER_VALUES_PER_PLAN: Record<Exclude<PlanName, "enterprise">, number[]> = {
  core: [40, 70, 100, 150, 200, 300, 400, 500, 600, 800, 1000],
  grow: [70, 100, 150, 200, 300, 400, 500, 600, 800, 1000],
  pro: [100, 150, 200, 300, 400, 500, 600, 800, 1000],
};

export const PLAN_NAMES = ["core", "grow", "pro"];

export const PREVIOUS_PLAN_TRANSITION_MAPPING: Record<string, string> = {
  core: "Free",
  grow: "Core",
  pro: "Grow",
  enterprise: "Pro",
};

export const FREE_PLAN_NAME = "Free";
export const FREE_PLAN_KEY = "free";

export const REVERSE_TRIAL_PLAN_KEY = "reverse-trial";

export const defaultState: Record<PlanName, PlanState> = {
  core: {
    userNumber: USER_VALUES_PER_PLAN.core[0],
    interval: 12,
    hasFlex: false,
    hasLibrary: false,
  },
  grow: {
    userNumber: USER_VALUES_PER_PLAN.grow[0],
    interval: 12,
    hasFlex: false,
    hasLibrary: false,
  },
  pro: {
    userNumber: USER_VALUES_PER_PLAN.pro[0],
    interval: 12,
    hasFlex: false,
    hasLibrary: false,
  },
  enterprise: { userNumber: 100, interval: 12, hasFlex: false, hasLibrary: false },
};

export const withSoonTag = ["linkedin_learning"];
export const hiddenFeatures = ["talentlibrary"];

export const defaultSelectLabelRanges = {
  [`core-${defaultState.core.userNumber}`]: `1-${defaultState.core.userNumber}`,
  [`grow-${defaultState.grow.userNumber}`]: `1-${defaultState.grow.userNumber}`,
  [`pro-${defaultState.pro.userNumber}`]: `1-${defaultState.pro.userNumber}`,
};

export const EXTRA_FEATURES: { [key: string]: string[] } = {
  free: ["unlimited_email_support"],
  core: ["unlimited_courses", "unlimited_email_support", "public_api_key"],
  grow: ["unlimited_courses", "unlimited_email_support", "public_api_key"],
  pro: ["unlimited_courses", "unlimited_email_support", "public_api_key"],
  enterprise: ["unlimited_courses", "unlimited_email_support", "public_api_key"],
};

export const EXCLUDED_FROM_REGULAR_MONTHLY_PLANS: string[] = [
  "consultation_call",
  "onboarding",
  "customer_success_manager",
  "onboarding_advanced",
  "onboarding_discovery",
  "onboarding_starter",
  "onboarding_premium",
  "customer_success",
  "priority_customer_success",
];

export const EXTRA_FEATURES_ON_PLAN_CARD: { [key: string]: string[] } = {
  free: ["unlimited_email_support"],
  core: ["unlimited_courses", "public_api_key"],
  grow: [],
  pro: [],
  enterprise_placeholder: [
    "unlimited_branches",
    "talentcraft_ai_replenishing",
    "account_manager",
    "phone_support",
    "onboarding_premium",
  ],
  enterprise: [],
};

export const EXTRA_FEATURES_ALL_FEATURES_DRAWER: { [key: string]: string[] } = {
  core: ["unlimited_courses", "unlimited_email_support"],
  grow: [],
  pro: [],
  enterprise: ["unlimited_branches", "talentcraft_ai_replenishing", "phone_support"],
};

export const LABELS_ORDER: { [key: string]: string[] } = {
  reverseTrial: [],
  free: ["unlimited_email_support"],
  core: [
    "talentcraft_ai_once_off",
    "unlimited_courses",
    "custom_homepage",
    "public_api_key",
    "sso",
    "custom_domain",
  ],
  grow: ["talentcraft_ai_replenishing", "unlimited_courses", "custom_reports"],
  pro: [
    "talentcraft_ai_replenishing",
    "unlimited_courses",
    "white_label",
    "automated_actions",
    "live_chat",
    "priority_email_support",
  ],
  enterprise: [
    "unlimited_branches",
    "talentcraft_ai_replenishing",
    "unlimited_courses",
    "phone_support",
  ],
};

export const FLEX_INFO =
  "https://help.talentlms.com/hc/en-us/articles/9652402239516-How-TalentLMS-subscription-plans-work";
export const LIBRARY_INFO = "https://www.talentlms.com/library/";
export const BRANCHES_INFO =
  "https://help.talentlms.com/hc/en-us/articles/360014656833-How-to-work-with-branches";

export const TALENT_LIBRARY_TEXT = "TalentLibrary"; // Not translatable
export const TALENT_CRAFT_AI_TEXT = "TalentCraft AI"; // Not translatable
export const TALENT_CRAFT_AI_TEXT_PREMIUM = "TalentCraft AI premium"; // Not translatable
export const FLEX_TEXT = "Flex"; // Not translatable
export const SKILLS_TEXT = "Skills"; // Not translatable
export const SKILLS_INFO = "https://www.talentlms.com/skills";
