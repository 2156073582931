import React, { FC } from "react";

import { SerializedStyles, useTheme } from "@emotion/react";
import { m } from "framer-motion";

import { progressBarContainer } from "@components/ReusableComponents/AnimatedProgressBar/styles";

type AnimatedProgressBarProps = {
  width: number;
  initial?: boolean;
  borderRadius?: string;
  progressBarBgColor?: string;
  shouldAnimate?: boolean;
  onProgressComplete?: () => void;
};

const AnimatedProgressBar: FC<AnimatedProgressBarProps> = ({
  width,
  progressBarBgColor,
  borderRadius = "60px",
  initial = false,
  shouldAnimate = false,
  onProgressComplete,
}) => {
  const { animatedProgressBar } = useTheme();
  const defaultProgressBarColor = animatedProgressBar.background;

  return (
    <div
      css={(theme): SerializedStyles => progressBarContainer(theme, borderRadius, shouldAnimate)}
    >
      <div className="bar-wrapper">
        <m.div
          className="bar"
          initial={initial}
          animate={{
            background: shouldAnimate ? undefined : (progressBarBgColor ?? defaultProgressBarColor),
            width: `${width}%`,
            borderRadius: borderRadius,
            transition: {
              width: {
                duration: 1,
                ease: "easeInOut",
                // OnComplete callback for width transition
                onComplete: (): void => {
                  width === 100 && onProgressComplete && onProgressComplete();
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default AnimatedProgressBar;
