import React, { FC } from "react";

import { HighlithedText } from "types/common";

export type HighlightedTextProps = {
  text: HighlithedText | string;
};

const HighlightedText: FC<HighlightedTextProps> = ({ text }) => {
  if (typeof text === "string") {
    return <span>{text}</span>;
  }

  return (
    <>
      <span>{text.firstPart}</span>
      <span className="highlighted">{text.highlithedPart}</span>
      <span>{text.lastPart}</span>
    </>
  );
};

export default HighlightedText;
