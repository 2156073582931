import React, { FC } from "react";

import { Text } from "@epignosis_llc/gnosis";

import { TypographyLevels } from "@styles/utils";

import { useApplyTranslations } from "@hooks";
import { availabilityDelayInDaysHoursMinutes } from "@utils/helpers";

type AvailabilityTextProps = {
  delay: number;
  fontSize?: TypographyLevels;
  weight?: "400" | "700";
  as?: "div" | "span";
};

const AvailabilityText: FC<AvailabilityTextProps> = ({
  delay,
  fontSize = "2xs",
  weight = "700",
  as = "div",
}) => {
  const { t } = useApplyTranslations();

  return (
    <Text fontSize={fontSize} as={as} className="availability-text">
      {t("general.availableIn")}
      <Text fontSize={fontSize} weight={weight}>
        {availabilityDelayInDaysHoursMinutes(delay)}
      </Text>
    </Text>
  );
};

export default AvailabilityText;
