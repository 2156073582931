import { TabKey } from "@views/CourseEdit/components/CourseOptions/types";

import { ResponsiveValues } from "types/common";

export const TAB_WRAPPER_TEMPLATE_COLUMNS: ResponsiveValues = [1, 1, 1, 2];

export const courseOptionsIds = {
  categoryOptions: "category-options",
  certificateOptions: "certificate-options",
  codeOptions: "code-options",
  courseCompletionRules: "course-completion-rules",
  customFieldsOptions: "custom-fields-options",
  introVideoOptions: "intro-video-options",
  learningPathRules: "learning-path-rules",
  levelOptions: "level-options",
  lockedOptions: "locked-options",
  priceOptions: "price-options",
  timeOptions: "time-options",
};

export const MAX_ALLOWED_FILES = 1;

export const REACT_PLAYER_CONFIG = {
  file: {
    attributes: {
      controlsList: "nodownload noplaybackrate",
      disablePictureInPicture: true,
      crossOrigin: "true",
    },
  },
};

export const TAB_FIELDS: Record<TabKey, string[]> = {
  [TabKey.Info]: [
    "code",
    "category",
    "price",
    "intro_video_url",
    "is_active",
    "custom_fields",
    "is_locked",
  ],
  [TabKey.Availability]: ["is_hidden_from_catalog", "capacity", "shared"],
  [TabKey.Limits]: [
    "level",
    "time_limit",
    "retain_access_after_completion",
    "starts_at",
    "expires_at",
    "learning_paths_obj",
  ],
  [TabKey.Completion]: [
    "certificate",
    "traversal_rule",
    "completion_rule",
    "completion_percentage",
    "completion_unit_ids",
    "score_rule",
    "score_units",
  ],
};
