import { logDebugger } from "@utils/services/LogDeviceMetricsService/config";
import { EncryptedReturnType } from "@utils/services/LogDeviceMetricsService/types";

// Converts a base64 encoded key to a JSON Web Key
export async function keyBase64ToJSONWebKey(keyBase64: string): Promise<CryptoKey> {
  const key = Uint8Array.from(atob(keyBase64), (c) => c.charCodeAt(0));
  const cryptoKey = await window.crypto.subtle.importKey("raw", key, "AES-CBC", true, [
    "encrypt",
    "decrypt",
  ]);

  return cryptoKey;
}

// Function to encrypt data with AES-CBC
export async function encryptData(
  metricsPayload: Record<string, unknown>,
  keyBase64: string,
): Promise<EncryptedReturnType> {
  const jsonPayload = JSON.stringify(metricsPayload);
  const encoder = new TextEncoder();
  const plainText = encoder.encode(jsonPayload);
  const iv = crypto.getRandomValues(new Uint8Array(16));
  const cryptoKey = await keyBase64ToJSONWebKey(keyBase64);
  const cipherText = await window.crypto.subtle.encrypt(
    {
      name: "AES-CBC",
      length: 128,
      iv,
    },
    cryptoKey,
    plainText,
  );

  return {
    iv,
    payload: {
      i: arrayBufferToBase64(iv),
      v: arrayBufferToBase64(cipherText),
    },
  };
}

// Function to decrypt data with AES-CBC
export async function decryptData(
  keyBase64: string,
  encryptedData: ArrayBuffer,
  iv: Uint8Array,
): Promise<string> {
  const cryptoKey = await keyBase64ToJSONWebKey(keyBase64);

  // Decrypt data using AES-CBC
  const decryptedData = await crypto.subtle.decrypt(
    {
      name: "AES-CBC",
      iv: iv,
    },
    cryptoKey,
    encryptedData,
  );

  const decoder = new TextDecoder();
  return decoder.decode(decryptedData);
}

// Function to convert ArrayBuffer to Base64
export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  const uint8Array = new Uint8Array(buffer);
  let binaryString = "";
  uint8Array.forEach((byte) => {
    binaryString += String.fromCharCode(byte);
  });

  return btoa(binaryString);
}

// Function to convert Base64 to ArrayBuffer
export function base64ToArrayBuffer(base64: string): ArrayBuffer {
  const binaryString = atob(base64);
  const length = binaryString.length;
  const uint8Array = new Uint8Array(length);

  for (let i = 0; i < length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  return uint8Array.buffer;
}

export function debugLog(message: string, color = "blue"): void {
  if (!logDebugger) return;

  const style = `
  color: ${color};
  font-weight: bold;
  background-color: #f0f0f0;
  padding: 2px 4px;
  border-radius: 4px;
`;

  // eslint-disable-next-line no-console
  console.log(`%c${message}`, style);
}
