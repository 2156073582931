import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["profile"] => {
  return {
    emailColor: colors.secondary.base,
    menuItem: {
      color: colors.black,
      hoverColor: colors.primary.base,
    },
    successColor: colors.green.base,
  };
};
