import React, { FC, MouseEvent } from "react";

import { SerializedStyles } from "@emotion/react";
import { Button } from "@epignosis_llc/gnosis";

import { ActionGhostButtonStyles } from "@components/ReusableComponents/ActionSecondaryButton/styles";

type ActionSecondaryButtonProps = {
  disabled?: boolean;
  isPressed?: boolean;
  className?: string;
  children?: React.ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

const ActionSecondaryButton: FC<ActionSecondaryButtonProps> = ({
  disabled = false,
  isPressed = false,
  className,
  onClick,
  children,
  ...rest
}) => {
  return (
    <Button
      css={(theme): SerializedStyles => ActionGhostButtonStyles(theme, { isPressed })}
      color="secondary"
      variant="outline"
      noGutters
      disabled={disabled}
      className={className}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ActionSecondaryButton;
