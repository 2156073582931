import { ThemePlusTypes } from "@styles/types";
import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["icon"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    black: colors.black,
    danger: colors.red.base,
    primary: protectColorContrast("darker", colors.primary.base),
    secondary: colors.secondary.base,
    success: colors.green.base,
    white: colors.white,
  };
};
