import { RichText, Tag } from "types/entities/Common";
import { CourseProgress } from "types/entities/Courses";
import { UnitProgress } from "types/entities/Unit";

/** *** Question types *****/

export type Question =
  | MultipleChoiceQuestion
  | FreeTextQuestion
  | OrderingQuestion
  | FillTheGapQuestion
  | MatchPairsQuestion
  | LikertScaleQuestion
  | RandomizedQuestion;

export type QuestionUsages = { usage_count: number };

// Multiple choice types
export type MultipleChoiceQuestion = QuestionCommon & {
  type: QuestionType.MultipleChoice;
  answers: MultipleChoiceAnswers;
  user_answers: MultipleChoiceAnswers;
  children: null;
  correct_answers?: MultipleChoiceAnswers;
};

// Freetext types
export type FreeTextQuestion = QuestionCommon & {
  type: QuestionType.Freetext;
  answers: FreeTextAnswers;
  user_answers: FreeTextUserAnswers;
  children: null;
  correct_answers?: MultipleChoiceAnswers;
  point_rules?: PointRule[];
  accumulated_points?: number;
};

// Ordering types
export type OrderingQuestion = QuestionCommon & {
  type: QuestionType.Ordering;
  answers: OrderingAnswers;
  user_answers: OrderingAnswers;
  children: null;
  correct_answers?: OrderingAnswers;
};

// Fill the gap types
export type FillTheGapQuestion = QuestionCommon & {
  type: QuestionType.FillTheGap;
  answers: FillTheGapAnswers;
  user_answers: FillTheGapUserAnswers;
  children: null;
  correct_answers?: FillTheGapUserAnswers;
};

// Drag and drop types
export type MatchPairsQuestion = QuestionCommon & {
  type: QuestionType.DragAndDrop;
  answers: MatchPairsAnswers;
  user_answers: MatchPairsAnswers;
  children: null;
  correct_answers?: MatchPairsAnswers;
};

export type RandomizedQuestion = QuestionCommon & {
  type: QuestionType.Randomized;
  answers: {
    possible: null;
    pairs: null;
    gaps: null;
  };
  user_answers: null;
  children: null;
  pool: QuestionPool;
};

// Likert scale types
export type LikertScaleQuestion = QuestionCommon & {
  type: QuestionType.LikertScale;
  answers: null;
  has_custom_answers: boolean;
  children: LikertScaleChildren[];
  user_answers: null;
  answer_set: AnswerSet;
};

export type LikertScaleChildren = {
  answers: {
    gaps: null;
    pairs: null;
    possible: string[];
  };
  children: null;
  feedback: null;
  id: number;
  multiple_answers: false;
  text: RichText;
  type: QuestionType.MultipleChoice;
  user_answers: {
    gaps: null;
    pairs: null;
    possible: string[];
  } | null;
};

/** *** Question results types *****/

export type ResultsQuestion =
  | MultipleChoiceQuestionAnswered
  | FreeTextQuestionAnswered
  | OrderingQuestionAnswered
  | FillTheGapQuestionAnswered
  | MatchPairsQuestionAnswered
  | LikertScaleQuestionAnswered;

type QuestionCommonAnswered = {
  answered: boolean;
  correct: boolean;
  points_percentage: number | null;
  weight_percentage: number | null;
};

type MultipleChoiceAnswers = {
  possible: string[];
  pairs: null;
  gaps: null;
};

// Multiple choice types
export type MultipleChoiceQuestionAnswered = QuestionCommonAnswered &
  MultipleChoiceQuestion & {
    correct_answers: MultipleChoiceAnswers | null;
    user_answers: MultipleChoiceAnswers | null;
  };

// Freetext types
export type FreeTextQuestionAnswered = QuestionCommonAnswered &
  FreeTextQuestion & {
    correct_answers: FreeTextAnswers | null;
    user_answers: FreeTextUserAnswers | null;
  };

// Ordering types
export type OrderingQuestionAnswered = QuestionCommonAnswered &
  Omit<OrderingQuestion, "user_answers" | "correct_answers"> & {
    correct_answers: OrderingAnswers | null;
    user_answers: OrderingAnswers | null;
  };

// Fill the gap types
export type FillTheGapQuestionAnswered = QuestionCommonAnswered &
  Omit<FillTheGapQuestion, "user_answers" | "correct_answers"> & {
    correct_answers: FillTheGapUserAnswers | null;
    user_answers: FillTheGapUserAnswers | null;
  };

// Drag and drop types
export type MatchPairsQuestionAnswered = QuestionCommonAnswered &
  Omit<MatchPairsQuestion, "user_answers" | "correct_answers"> & {
    correct_answers: MatchPairsAnswers | null;
    user_answers: MatchPairsAnswers | null;
  };

// Likert scale types
export type LikertScaleQuestionAnswered = Omit<LikertScaleQuestion, "children"> & {
  answered: null;
  correct: null;
  points: null;
  weight_percentage: number | null;
  accumulated_points: null;
  correct_answers: null;
  children: {
    answers: {
      gaps: null;
      pairs: null;
      possible: string[];
    };
    children: null;
    feedback: null;
    id: number;
    multiple_answers: false;
    text: RichText;
    type: QuestionType.MultipleChoice;
    user_answers: {
      gaps: null;
      pairs: null;
      possible: string[];
    } | null;
  }[];
};

/** *** Question common types *****/

type QuestionCommon = {
  id: number;
  type: QuestionType;
  text: RichText; // Nullable in schema
  feedback: RichText | null;
  multiple_answers: boolean | null;
  tags?: Tag[];
  weight: number;
  belongs_to_unit?: boolean;
  course_name?: string;
  policies?: {
    can_delete: boolean;
    can_edit: boolean;
  };
  belongs_to_question?: boolean;
  pool?: QuestionPool;
};

export enum QuestionType {
  DragAndDrop = "drag_and_drop",
  Freetext = "freetext",
  MultipleChoice = "multiple_choice",
  Ordering = "ordering",
  FillTheGap = "fill_the_gap",
  LikertScale = "likert_scale",
  Randomized = "randomized",
}

export enum ImportQuestionType {
  Gift = "gift",
  Aiken = "aiken",
}

export enum ImportQuestion {
  Import = "import",
}

export enum AIQuestion {
  Default = "ai_question",
}

export type ImportExampleType = {
  title: string;
  description: string;
  example: string;
};

export type ImportQuestionPreview = {
  text: string;
  type: QuestionType;
  answers: string[];
  correct_answers: string[];
  pairs: string[][];
  is_valid: boolean;
};

export enum ExistingQuestion {
  Existing = "existing",
}

export enum AnswerSet {
  Agreement = "Agreement",
  Satisfaction = "Satisfaction",
  Quality = "Quality",
  Likelihood = "Likelihood",
  Frequency = "Frequency",
  Custom = "Custom",
}

type FreeTextAnswers = {
  possible: null;
  pairs: null;
  gaps: null;
};

type FreeTextUserAnswers = {
  possible: string[];
  pairs: null;
  gaps: null;
};

export type OrderingAnswers = {
  possible: string[];
  pairs: null;
  gaps: null;
};

export type FillTheGapAnswers = {
  possible: null;
  pairs: null;
  gaps: Record<string, string[]>;
};

export type FillTheGapUserAnswers = {
  possible: null;
  pairs: null;
  gaps: Record<string, string>;
};

export type MatchPairsAnswers = {
  possible: null;
  pairs: {
    left: string[];
    right: string[];
  };
  gaps: null;
};

/** *** Question generic types *****/

export type QuestionSubmit = {
  question_ids: string[];
  next_question_id: number;
  feedback: string;
  correct: boolean;
  unit_progress: UnitProgress;
  course_progress: CourseProgress;
  completion_reason: string | null;
};

export type QuestionData<T> = {
  previous_id: number | null;
  next_id: number | null;
  question: T;
};

export type QuestionAnswers =
  | string[]
  | string[][]
  | {
      [key: string]: string;
    }
  | LikertScaleAnswer[];

export type LikertScaleAnswer = {
  id: number;
  user_answer: string;
};

export enum PointRuleOption {
  Contains = "contains",
  DoesNotContain = "does_not_contain",
}

export type PointRule = {
  type: PointRuleOption;
  text: string; // MaxLength: 255
  points: number; // Min: -5, max: 5
};

export type QuestionPool = {
  appearances: number;
  question_ids: number[];
};
