import React, { FC } from "react";

import { Loader, Text } from "@epignosis_llc/gnosis";
import classNames from "classnames";

import { LoaderContainer } from "@components/Autologin/styles";

import { useApplyTranslations } from "@hooks";

type AutologinLoaderProps = {
  isFullScreen?: boolean;
};

const LoaderClassNames = (isFullScreen: boolean): string =>
  classNames({
    loader: true,
    "is-fullscreen": isFullScreen,
  });

const AutologinLoader: FC<AutologinLoaderProps> = ({ isFullScreen = false }) => {
  const { t } = useApplyTranslations();

  return (
    <div css={LoaderContainer}>
      <div className={LoaderClassNames(isFullScreen)}>
        <Loader />
        <Text fontSize="lg">{t("general.gettingEverythingReady")}</Text>
      </div>
    </div>
  );
};

export default AutologinLoader;
