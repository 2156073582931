import React, { FC } from "react";

import { UpdateExpirationDateSVG } from "@epignosis_llc/gnosis/icons";

import { useApplyTranslations } from "@hooks";

import ActionButton from "@components/CustomTable/components/TableActions/ActionButton";
import { GenericActionProps } from "@components/CustomTable/components/TableActions/types";

const UpdateExpirationDateAction: FC<GenericActionProps> = ({
  tooltipContent,
  entity,
  icon: Icon = UpdateExpirationDateSVG,
  iconHeight = 32,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.updateExpirationDate")}
      onClick={onClick}
      data-testid="update-expiration-date-action"
      aria-label={t("general.actions.updateExpirationDateEntity", { entity })}
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default UpdateExpirationDateAction;
