import React, { FC } from "react";

import { Button, Text, Tooltip } from "@epignosis_llc/gnosis";

import { startReverseTrialButtonStyles } from "@layouts/DashboardLayout/components/TopBarMenu/components/StartReverseTrialButton/StartReverseTrialButton.styles";

import { useApplyTranslations } from "@hooks";

const StartReverseTrialButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useApplyTranslations();

  return (
    <Tooltip content={<Text fontSize="sm">{t("reverseTrial.activatePremiumTrialTooltip")}</Text>}>
      <Button
        data-testid="start-reverse-trial-button"
        css={startReverseTrialButtonStyles}
        variant="solid"
        color="primary"
        className="start-trial-button"
        onClick={onClick}
        aria-label={t("reverseTrial.activatePremiumTrial")}
      >
        <Text as="p" fontSize="sm" className="button-text">
          {t("reverseTrial.activatePremiumTrial")}
        </Text>
      </Button>
    </Tooltip>
  );
};

export default StartReverseTrialButton;
