import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";

import { CertificateTemplatesPreviewRes, CertificateTemplatesRes } from "types/responses";

export const getCertificateTemplates = async (): Promise<CertificateTemplatesRes> => {
  const res = await HttpClient.get(ENDPOINTS.certificates.templates);

  return res.data;
};

export const getCertificateTemplatePreview = async (
  templateId: string,
): Promise<CertificateTemplatesPreviewRes> => {
  const res = await HttpClient.get(ENDPOINTS.certificates.templatePreview(templateId));

  return res.data;
};
