import React from "react";

import { sanitizeHTML } from "@utils/helpers/security";

type ElementWithDangerousHTML = {
  props: {
    dangerouslySetInnerHTML?: { __html: string };
  };
};

const isElementWithDangerousHTML = (
  element: JSX.Element,
): element is JSX.Element & ElementWithDangerousHTML => {
  return typeof element.props === "object" && element.props?.dangerouslySetInnerHTML !== undefined;
};

export const sanitizeData = (
  data: JSX.Element | string | undefined,
): JSX.Element | string | null => {
  if (typeof data === "undefined") return null; // Return null instead of void

  // If the input is a JSX.Element, check if it has dangerouslySetInnerHTML
  if (React.isValidElement(data) && isElementWithDangerousHTML(data)) {
    const sanitizedHTML = sanitizeHTML(data.props.dangerouslySetInnerHTML.__html);

    // Return a new element immutably with sanitized HTML
    return React.cloneElement(data, {
      dangerouslySetInnerHTML: { __html: sanitizedHTML },
    });
  }

  // If it's not JSX.Element with dangerouslySetInnerHTML, return it as is
  return data;
};
