import React, { FC } from "react";

import { Heading } from "@epignosis_llc/gnosis";

import { badgeCategoryContainer } from "@components/UserStats/styles";

import { useApplyTranslations } from "@hooks";

import { BadgeFigure } from "@components/ReusableComponents";

import { Badge } from "types/entities";

type BadgeCategoryProps = {
  title: string;
  badges: Badge[];
};

const BadgeCategory: FC<BadgeCategoryProps> = ({ title, badges }) => {
  const { t } = useApplyTranslations();
  const hasBagdesCategory = badges.some((badge) => badge.type === title);

  return (
    <>
      {hasBagdesCategory && (
        <span css={badgeCategoryContainer}>
          <Heading as="h2" size="md" className="header-text">
            {t(`badges.${title}`)}
          </Heading>
          <div className="badges-container">
            {badges
              .filter((badge) => badge.type === title)
              .map((badge) => (
                <span key={badge.id}>
                  <BadgeFigure
                    type={badge.type}
                    image={badge.image}
                    awarded={badge.awarded}
                    name={badge.name}
                    criteria={badge.criteria}
                    showTooltip
                  />
                </span>
              ))}
          </div>
        </span>
      )}
    </>
  );
};

export default BadgeCategory;
