import React, { FC } from "react";
import { Link, useParams } from "react-router-dom";

import { Button } from "@epignosis_llc/gnosis";
import { LoginSVG } from "@epignosis_llc/gnosis/icons";
import { useResponsive } from "ahooks";

import { BrandLogoSVG } from "@assets/images";
import { externalHeaderContainer } from "@layouts/ExternalLayout/components/styles";

import { useApplyTranslations } from "@hooks";
import { useConfigurationStore, useUIStore } from "@stores";

import localStorageKeys from "@constants/localStorageKeys";
import { URLS } from "@constants/urls";

import { SkipLink } from "@components";

const getRedirectUrl = (location: string, courseId: string | undefined): string => {
  switch (location) {
    case "/external-catalog":
      return URLS.catalog.index;

    default:
      return courseId ? URLS.catalog.createCourseLink({ courseId: courseId }) : "";
  }
};

const ExternalHeader: FC = () => {
  const { t } = useApplyTranslations();
  const { sm } = useResponsive();
  const { setShowSignInModal, setShowSignUpModal } = useUIStore();
  const { domainSettings, ssoDomainSettings } = useConfigurationStore();
  const { courseId } = useParams();
  const logo = domainSettings?.logo?.default;
  const isSignUpEnabled = Boolean(domainSettings?.signup.enabled);

  const { sso } = domainSettings ?? {};
  const { disable_direct_login: isDirectLoginDisabled } = sso ?? {};
  const { login_url = "" } = ssoDomainSettings ?? {};
  const isSSOEnabled = Boolean(domainSettings?.sso.integration_type);

  const handleButtonClick = (type: "signIn" | "signUp"): void => {
    const redirectUrl = getRedirectUrl(window.location.pathname, courseId);

    if (isDirectLoginDisabled && isSSOEnabled) {
      window.location.replace(login_url);
    } else {
      type === "signIn" ? setShowSignInModal(true) : setShowSignUpModal(true);
    }

    localStorage.setItem(
      localStorageKeys.EXTERNAL_SIGNIN_SIGNUP,
      JSON.stringify({
        redirectUrl: redirectUrl,
      }),
    );
  };

  return (
    <header css={externalHeaderContainer} role="banner">
      <SkipLink focusId="courses-wrapper" />
      <Link
        to={URLS.root}
        className="logo"
        aria-label={t("accountAndSettings.portalSettings.logo")}
      >
        {logo ? (
          <img src={logo} alt={t("accountAndSettings.portalSettings.logo")} />
        ) : (
          <BrandLogoSVG height={sm ? 28 : 20} />
        )}
      </Link>

      <div className="action-buttons">
        {isSignUpEnabled && (
          <Button variant="link" noGutters onClick={(): void => handleButtonClick("signUp")}>
            {t("signUp.signUp")}
          </Button>
        )}

        <Button
          iconAfter={LoginSVG}
          variant="link"
          className="login-btn"
          onClick={(): void => handleButtonClick("signIn")}
        >
          {t("signIn.login")}
        </Button>
      </div>
    </header>
  );
};

export default ExternalHeader;
