import React, { memo, MouseEvent, useState } from "react";
import { useMutation } from "react-query";

import { Select, Text } from "@epignosis_llc/gnosis";
import { AxiosError } from "axios";

import { roleCellContainer } from "@components/CustomTable/components/Cells/DropdownRoleCell/styles";

import { handleUserCoursesErrors } from "@errors/errors";

import { editCourseUser } from "@api/courses";
import { useApplyTranslations } from "@hooks";
import { capitalize, getRoleInCourse } from "@utils/helpers";

import queryKeys from "@constants/queryKeys";

import { SelectOption } from "types/common";

type RoleCellType = {
  roleInCourse: string;
  availableRoles: string[];
  userId: string;
  courseId: string;
  isTable?: boolean;
  canChangeUserRolePolicy?: boolean;
  menuPortalTarget?: HTMLElement | null;
  invalidateQueries?: () => void;
};

const RoleCell = ({
  roleInCourse,
  availableRoles,
  userId,
  courseId,
  isTable = false,
  canChangeUserRolePolicy = false,
  menuPortalTarget = document.body,
  invalidateQueries,
}: RoleCellType): JSX.Element => {
  const { t } = useApplyTranslations();
  const [selectedValue, setSelectedValue] = useState(roleInCourse);
  const roleOptions: SelectOption[] = availableRoles.map((role) => {
    return {
      label: capitalize(role),
      value: role,
    };
  });
  const canChangeRole = roleOptions.length > 1 && canChangeUserRolePolicy;

  const { mutate: patchCourseUser } = useMutation(
    [queryKeys.courses.patchUser],
    (course_role: string) => editCourseUser(courseId, userId, course_role),
    {
      onSuccess: async (_, course_role) => {
        setSelectedValue(course_role);
        invalidateQueries && invalidateQueries();
      },
      onError: (error: AxiosError) => {
        handleUserCoursesErrors(error);
      },
    },
  );

  if (!canChangeRole) {
    return (
      <Text className="user-role-text" fontSize="sm">
        {getRoleInCourse(roleInCourse)}
      </Text>
    );
  }

  return (
    <div
      css={roleCellContainer(isTable)}
      onClick={(event: MouseEvent): void => event.stopPropagation()}
    >
      <Select
        aria-label={t("general.role")}
        className="user-role-select"
        name={`user-${userId}-role`}
        id={`user-${userId}-role`}
        menuPortalTarget={menuPortalTarget}
        menuPlacement="auto"
        value={roleOptions.find((roleOption) => roleOption.value === selectedValue)}
        onChange={(option): void => {
          const { value } = option as SelectOption;
          patchCourseUser(value);
        }}
        options={roleOptions}
      />
    </div>
  );
};

export default memo(RoleCell);
