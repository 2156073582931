import { css } from "@emotion/react";

import { mq } from "@utils/breakpoints";

export const reverseTrialModalCustomStyles = css`
  background-color: rgba(0, 0, 0, 0.9);
`;

export const trialContainerStyle = ({ reverseTrial }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding-bottom: 1.5rem;

  max-height: 60vh;
  overflow: auto;

  ${mq["sm"]} {
    max-height: 65vh;
  }

  ${mq["md"]} {
    max-height: 70vh;
  }

  .modal-icon {
    display: flex;
    justify-content: center;
  }

  .description {
    align-self: start;
  }

  .modal-rows {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: start;
  }

  .modal-columns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
    margin-inline-start: 0.3rem;
  }

  .modal-features {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    .feature-text {
      line-height: 1.1rem;
    }

    .icon-color {
      color: ${reverseTrial.trialModal.tickColor};
      flex-shrink: 0;
    }
  }
`;
