import userRoles from "@constants/userRoles";

import { UserRole } from "types/entities";
import { OAuthRes } from "types/responses";

const defaultAuthData = {
  access_token: null,
  expires_in: null,
  refresh_token: null,
  default_role: null,
  token_type: null,
};

class AuthService {
  private data: {
    access_token: string | null;
    expires_in: number | null;
    refresh_token: string | null;
    default_role: UserRole | null;
    token_type: string | null;
  };

  constructor() {
    this.data = { ...defaultAuthData };
  }

  setTokens(newData: OAuthRes): void {
    const { access_token, refresh_token } = { ...newData };
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("refresh_token", refresh_token);
  }

  setDefaultRole(role: string): void {
    localStorage.setItem("default_role", role);
  }

  removeTokens(): void {
    this.data = { ...defaultAuthData };
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }

  removeRole(): void {
    localStorage.removeItem("default_role");
  }

  getAccessToken(): string | null {
    return localStorage.getItem("access_token") ?? this.data.access_token;
  }

  getRefreshToken(): string | null {
    return localStorage.getItem("refresh_token") ?? this.data.refresh_token;
  }

  getDefaultRole(): string {
    const defaultRole = localStorage.getItem("default_role") ?? ("learner" as UserRole);

    return defaultRole;
  }

  isAdministrator(): boolean {
    return this.getDefaultRole() === userRoles.ADMINISTRATOR;
  }

  isInstructor(): boolean {
    return this.getDefaultRole() === userRoles.INSTRUCTOR;
  }

  isLearner(): boolean {
    return this.getDefaultRole() === userRoles.LEARNER;
  }
}

const authService = new AuthService();

export default authService;
