import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["unitsToC"] => {
  return {
    backgroundColor: colors.secondary.lighter,
    borderColor: colors.secondary.lighter,
    header: {
      activeColor: colors.primary.darker,
      backgroundColor: colors.white,
      borderColor: colors.secondary.base,
    },
    top: {
      backgroundColor: colors.primary.darker,
    },
    listItem: {
      activeColor: colors.primary.darker,
      disabledColor: colors.secondary.darker,
      hoverColor: colors.white,
      borderColor: colors.secondary.lighter,
    },
  };
};
