import { UseFormReturn } from "react-hook-form";

import { StringSchema } from "yup";

import { EditCourseData } from "@views/CourseEdit/api";

import { CourseRulesFormData } from "@views/CourseEdit/types";
import { Course, CustomField, Section } from "types/entities";

export enum TabKey {
  Info = "infoTab",
  Availability = "availabilityTab",
  Limits = "limitsTab",
  Completion = "completionTab",
}

export type StringFieldSchema = {
  [key: string]: StringSchema;
};

export type CourseCustomFieldWithValue = CustomField & {
  value: string;
};

export type TabProps = {
  course: Course;
  form: UseFormReturn<EditCourseData>;
  sections?: Section[];
  customFieldsWithValues?: CourseCustomFieldWithValue[];
  videoPreviewFile?: File | null;
  handleFileChanged?: (file: File | null) => void;
  setShouldDeleteVideo?: (shouldDelete: boolean) => void;
};

export type RulesTabProps = TabProps & {
  rulesForm: UseFormReturn<CourseRulesFormData>;
};

export enum VideoType {
  Youtube = "youtube",
  File = "file",
}
