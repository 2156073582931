import React, { FC, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

import { SerializedStyles } from "@emotion/react";
import { useResponsive } from "ahooks";

import { profileSubMenuStyles } from "@layouts/DashboardLayout/components/TopBarMenu/components/ProfileMenu/components/ProfileSubMenu/ProfileSubMenu.styles";

import { BranchesMobile } from "@layouts/DashboardLayout/components/TopBarMenu/components/BranchesMobile";
import {
  useMenuPermissions,
  useReturnToLegacy,
} from "@layouts/DashboardLayout/components/TopBarMenu/components/ProfileMenu/components/ProfileSubMenu/hooks";
import { UpgradeNowMenuButton } from "@layouts/DashboardLayout/components/TopBarMenu/components/ProfileMenu/components/ReverseTrialUpgradeButtons";
import { UserDetails } from "@layouts/DashboardLayout/components/TopBarMenu/components/ProfileMenu/components/UserDetails";
import { UserPages } from "@layouts/DashboardLayout/components/TopBarMenu/components/ProfileMenu/components/UserPages";
import { UserRoleSwitch } from "@layouts/DashboardLayout/components/TopBarMenu/components/ProfileMenu/components/UserRoleSwitch";
import { StartReverseTrialButton } from "@layouts/DashboardLayout/components/TopBarMenu/components/StartReverseTrialButton";
import useWatchDemo from "@layouts/DashboardLayout/components/TopBarMenu/components/WatchDemoButton/useWatchDemo";
import { useBranches } from "@layouts/DashboardLayout/components/TopBarMenu/hooks/useBranches";

import { getDomainSettings } from "@api/app";
import { useLogout } from "@hooks";
import { useCanSeeUpgradeButton } from "@hooks/useCanSeeUpgradeButton";
import { useConfigurationStore } from "@stores";
import permissions from "@utils/permissions";
import authService from "@utils/services/AuthService";
import { LogDeviceMetricsService } from "@utils/services/LogDeviceMetricsService";

import queryKeys from "@constants/queryKeys";
import userRoles from "@constants/userRoles";
import {
  branches,
  impersonation,
  logout,
  referral,
  returnToLegacy,
  watchDemo,
} from "@layouts/DashboardLayout/components/TopBarMenu/components/ProfileMenu/constants";

import UpgradeMenuItem from "@components/ReverseTrial/UpgradeMenuItem/UpgradeMenuItem";

import { ReverseTrialStats } from "types/entities/ReverseTrial";

type SubMenuProps = {
  setIsActive: (isActive: boolean) => void;
  isImpersonated: boolean | undefined;
  toggleTrialModal: () => void;
  reverseTrialStats?: ReverseTrialStats;
};

const ProfileSubMenu: FC<SubMenuProps> = ({
  isImpersonated,
  setIsActive,
  toggleTrialModal,
  reverseTrialStats,
}) => {
  const [showBranches, setShowBranches] = useState(false);
  const { handleReturnToLegacy } = useReturnToLegacy(); // HandleReturnToLegacy is used both for leaving beta and impersonation.
  const { handleLogOut } = useLogout();
  const currentUserRole = authService.getDefaultRole();
  const { userProfileData, domainSettings, setDomainSettings } = useConfigurationStore();

  const { legacy_ui_enabled: isLegacyUiEnabled } = domainSettings ?? {};
  const canAccessReferral = userProfileData?.policies.can_access_referral ?? false;
  const canWatchDemo = userProfileData?.policies.can_watch_demo ?? false;
  const canEnableReverseTrial = userProfileData?.policies.can_enable_reverse_trial ?? false;
  const isReverseTrialEnabled = domainSettings?.is_in_trial ?? false;
  const { canAccessSubscription } = permissions.accountPermissions;
  const userRole = authService.getDefaultRole();
  const isAdmin = userRole === userRoles.ADMINISTRATOR;
  const canSeeTrialUpgradeNowItem =
    isReverseTrialEnabled && reverseTrialStats && canAccessSubscription() && isAdmin;

  const { md, lg } = useResponsive();

  const canSeeUpgradeMenuButton = useCanSeeUpgradeButton() && !lg;

  const { handleWatchDemoClick } = useWatchDemo();

  const { days_left: trialDaysLeft } = reverseTrialStats ?? {};

  useQuery(queryKeys.domainSettings, getDomainSettings, {
    onSuccess: (res) => {
      setDomainSettings(res._data);
    },
  });

  const { messagesAndDiscussionsItems, userPagesItems, roleOptions } = useMenuPermissions({
    currentUserRole,
  });
  const { branchesOptions } = useBranches();
  const shouldRenderBranches = branchesOptions && branchesOptions.length > 0;

  // QueryClient and current user role
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!md) document.body.style.setProperty("overflow", "hidden");

    return () => {
      if (!md) document.body.style.setProperty("overflow", "");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRoleSelect = (selectedValue: string | React.FormEvent<HTMLFieldSetElement>): void => {
    if (selectedValue !== currentUserRole) {
      LogDeviceMetricsService.logDeviceMetrics(["device"]); // Log device metrics
      authService.setDefaultRole(selectedValue.toString() ?? userRoles.LEARNER);
      queryClient.invalidateQueries();
      setIsActive(false);
    }
  };

  return (
    <div
      css={(theme): SerializedStyles => profileSubMenuStyles(theme, { isMobile: !md })}
      className="sub-menu-container"
    >
      <div className="sub-menu-content">
        {/* User details */}
        {!md && (
          <>
            <UserDetails
              isInnerUserInfo={true}
              showRole={false}
              handleCloseMenu={(): void => setIsActive(false)}
            />
            <div className="divider" />
          </>
        )}
        {/* UpgradeNowMenuButton */}
        {canSeeUpgradeMenuButton && (
          <>
            <UpgradeNowMenuButton onActionComplete={(): void => setIsActive(false)} />
            <div className="divider" />
          </>
        )}
        {/* Start Reverse Trial */}
        {!lg && canEnableReverseTrial && (
          <>
            <div className="start-reverse-trial-button-wrapper">
              <StartReverseTrialButton onClick={toggleTrialModal} />
            </div>
            <div className="divider" />
          </>
        )}
        {/* Upgrade from reverse trial */}
        {!lg && canSeeTrialUpgradeNowItem && (
          <>
            <UpgradeMenuItem
              trialDaysLeft={trialDaysLeft ?? 0}
              onActionComplete={(): void => setIsActive(false)}
            />
            <div className="divider" />
          </>
        )}
        {/* Impersonation - back to admin  */}
        {!md && isImpersonated && (
          <>
            <UserPages pageItems={impersonation} handleCallback={handleReturnToLegacy} />
            <div className="divider" />
          </>
        )}

        {/* Role switching  */}
        <UserRoleSwitch
          roleOptions={roleOptions}
          currentUserRole={currentUserRole}
          onRoleSelect={handleRoleSelect}
          isImpersonated={isImpersonated}
        />

        {/* Switch Branch  */}
        {!md && shouldRenderBranches && (
          <>
            <UserPages
              hasSubMenu={true}
              pageItems={branches}
              handleCallback={(): void => setShowBranches(true)}
            />
            {showBranches && (
              <BranchesMobile isOpen={showBranches} onClose={(): void => setShowBranches(false)} />
            )}
            <div className="divider" />
          </>
        )}

        {/* Messages & Discussions (MOBILE ONLY) */}
        {!md && messagesAndDiscussionsItems && (
          <>
            <UserPages
              pageItems={messagesAndDiscussionsItems}
              handleCloseMenu={(): void => setIsActive(false)}
            />
            <div className="divider" />
          </>
        )}

        {/* Menu Pages */}
        {userPagesItems.length > 0 && (
          <>
            <UserPages
              pageItems={userPagesItems}
              handleCloseMenu={(): void => setIsActive(false)}
            />
            <div className="divider" />
          </>
        )}

        {/* Return to legacy UI */}
        {isLegacyUiEnabled && !isImpersonated && (
          <>
            <UserPages pageItems={returnToLegacy} handleCallback={handleReturnToLegacy} />
            <div className="divider" />
          </>
        )}
        {/* Referral */}
        {canAccessReferral && (
          <>
            <UserPages pageItems={referral} handleCloseMenu={(): void => setIsActive(false)} />
            <div className="divider" />
          </>
        )}

        {canWatchDemo && (
          <>
            <UserPages pageItems={watchDemo} handleCallback={handleWatchDemoClick} />
            <div className="divider" />
          </>
        )}

        {/* Logout button */}
        <UserPages pageItems={logout} handleCallback={handleLogOut} />
      </div>
    </div>
  );
};

export default ProfileSubMenu;
