import { useConfigurationStore } from "@stores";
import { userHasRequiredPermissions } from "@utils/permissions/index";
import authService from "@utils/services/AuthService";

// Map with all the available permissions
export const filesPermissions = {
  read: "file.read",
  create: "file.create",
  update: "file.update",
  delete: "file.delete",
};

const { read, update, delete: deleteFile, create } = filesPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => users.read
   *
   *  Allowed actions:
   *  GET courses.users
   *
   * @returns boolean
   */
  canAccessFiles: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => users.update
   *
   *  Allowed actions:
   *  PATCH courses.user.edit
   *
   * @returns boolean
   */
  canUpdateFiles: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([update], getState().userProfileData?.permissions[userRole]);
  },

  /**
   * Permission => users.delete
   *
   *  Allowed actions:
   *
   *
   * @returns boolean
   */
  canDeleteFiles: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [deleteFile],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => user.create
   * user.create is required in order to have all the other user related permissions
   *
   *  Allowed actions:
   *  POST user.create
   *
   * @returns boolean
   */
  canCreateFile: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([create], getState().userProfileData?.permissions[userRole]);
  },
};
