import React from "react";
import { toast, ToastOptions } from "react-toastify";

import PubSub from "pubsub-js";

import { AIGenerationNotification } from "@components/AI/AIGenerationNotification/AIGenerationNotification";

export type AIGenerationNotificationOptions = ToastOptions & {
  id: string;
  message: string;
  duration?: number;
  action: "new" | "cancel" | "close";
};

export const AI_GENERATION_NOTIFICATIONS = "AI_GENERATION_NOTIFICATIONS";

const notificationSubscriber = (
  _channel: string,
  options: AIGenerationNotificationOptions,
): void => {
  const { action, message, id, ...rest } = options;

  if (action === "cancel" || action === "close") {
    return toast.dismiss(id);
  }

  toast.loading(
    <>
      <AIGenerationNotification
        key={id}
        message={message}
        onStop={(): void => {
          PubSub.publish(AI_GENERATION_NOTIFICATIONS, {
            id: id,
            action: "cancel",
          });
          toast.dismiss(id);
        }}
        onClose={(): void => {
          toast.dismiss(id);
        }}
      />
    </>,
    {
      style: {
        background: "transparent",
        padding: 0,
        boxShadow: "none",
      },
      toastId: id,
      hideProgressBar: true,
      autoClose: false,
      position: "bottom-left",
      ...rest,
    },
  );
};

export const aiGenerationNotificationChannel = PubSub.subscribe(
  AI_GENERATION_NOTIFICATIONS,
  notificationSubscriber,
);
