import React, { FC } from "react";

import { divider } from "@components/ReusableComponents/Divider/styles";

export type DividerProps = {
  className?: string;
  text?: string;
};

const Divider: FC<DividerProps> = ({ text, className }) => {
  return (
    <span className={className} css={divider}>
      {text ? (
        <div className="container">
          <span className="line" />
          <div className="text">{text}</div>
          <span className="line" />
        </div>
      ) : (
        <div className="line" />
      )}
    </span>
  );
};

export default Divider;
