import React, { FC, ReactElement, useCallback } from "react";

import { SerializedStyles } from "@emotion/react";
import { Button, Dropdown, DropdownItem, Text } from "@epignosis_llc/gnosis";
import { BranchesDropdownSVG } from "@epignosis_llc/gnosis/icons";

import { branchesMenuStyles } from "@layouts/DashboardLayout/components/TopBarMenu/components/BranchesMenu/BranchesMenu.styles";

import { useBranches } from "@layouts/DashboardLayout/components/TopBarMenu/hooks/useBranches";

import { useApplyTranslations } from "@hooks";

const BranchesMenu: FC = () => {
  const {
    shouldShowMainBranch,
    shouldShowSearch,
    branchesOptions,
    switchBranchMutation,
    currentBranchId,
  } = useBranches();
  const { t } = useApplyTranslations();
  // Derived state
  const shouldRenderBranches = branchesOptions && branchesOptions.length > 0;

  const handleBranchClick = (branchId: string): void => {
    if (branchId !== currentBranchId?.toString()) {
      if (switchBranchMutation) {
        switchBranchMutation(branchId);
      }
    }
  };

  const DropdownHeader = useCallback((): ReactElement => {
    return (
      <div className="branches-dropdown-header">
        {shouldShowMainBranch && (
          <div className="main-branch-switch">
            <Button
              variant="link"
              onClick={(): void => switchBranchMutation && switchBranchMutation("main")}
            >
              {t("topBar.goToMainDomain")}
            </Button>
          </div>
        )}

        <Text as="div" className="switch-label" fontSize={"sm"} weight="700">
          {t("topBar.switchBranch")}
        </Text>
      </div>
    );
  }, [shouldShowMainBranch, switchBranchMutation, t]);

  if (!shouldRenderBranches) return null;

  return (
    <Dropdown
      css={(theme): SerializedStyles => branchesMenuStyles(theme, { shouldShowSearch })}
      placement="bottom-end"
      className="branches-dropdown"
      hover={true}
      prependContent={<DropdownHeader />}
      isSearchable={shouldShowSearch}
      list={branchesOptions as DropdownItem[]}
      onListItemSelect={({ id }): void => {
        if (id) handleBranchClick(id);
      }}
    >
      <Button
        title={t("mainMenu.branches")}
        data-testid="topbar-branches"
        variant="ghost"
        className="action-btn"
        color="primary"
        noGutters
      >
        <BranchesDropdownSVG height={32} />
      </Button>
    </Dropdown>
  );
};

export default BranchesMenu;
