import { FC } from "react";
import { useMutation, useQueryClient } from "react-query";

import React, { Button, Modal } from "@epignosis_llc/gnosis";

import { termsModalBody, termsModalFooter } from "@views/Terms/styles";

import { putDomainTerms } from "@api/app";
import { useApplyTranslations, useCourseNavigation, useLogout } from "@hooks";
import { useConfigurationStore, useUIStore } from "@stores";

import queryKeys from "@constants/queryKeys";

const termsModalCustomStyles = {
  overlay: { zIndex: 10000, backgroundColor: "rgba(0,0,0,0.9)" },
};

const TermsModal: FC = () => {
  const { t } = useApplyTranslations();
  const { showTermsModal } = useUIStore();
  const { domainTerms } = useConfigurationStore();
  const { handleLogOut } = useLogout();
  const queryClient = useQueryClient();
  const navigateToCourse = useCourseNavigation();

  const { mutate: putDomainTermsMutation, isLoading } = useMutation(
    queryKeys.domainTerms.accept,
    putDomainTerms,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.domainTerms.terms]);
        navigateToCourse();
      },
    },
  );

  const handleAccept = (): void => {
    if (domainTerms?.terms) {
      putDomainTermsMutation();
    }
  };

  return (
    <Modal size="lg" style={termsModalCustomStyles} isOpen={showTermsModal}>
      <Modal.Header>
        <div className="terms-header">{t("general.termsOfService")}</div>
      </Modal.Header>
      <Modal.Body css={termsModalBody}>
        <div
          className="terms-body"
          dangerouslySetInnerHTML={{ __html: domainTerms?.terms?.html ?? "" }}
        />
      </Modal.Body>
      <Modal.Footer css={termsModalFooter}>
        <div className="terms-footer">
          <Button className="button" color="secondary" onClick={handleLogOut}>
            {t("general.logOut")}
          </Button>
          <Button className="button" isLoading={isLoading} onClick={handleAccept}>
            {t("general.accept")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsModal;
