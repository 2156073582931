import React, { FC, memo } from "react";

import { StatusTag } from "@epignosis_llc/gnosis";
import { AssignmentSubmissionSVG } from "@epignosis_llc/gnosis/icons";

import { listItemActionsWrapperStyles } from "@views/CourseEdit/components/Sidebar/components/TocContent/components/styles";

import { useApplyTranslations } from "@hooks";

import LinkedUnitTag from "@views/CourseEdit/components/Sidebar/components/LinkedUnitTag";

import { MyUnit } from "types/entities";

type UnitListTitleProps = {
  linkedUnits: number;
  sourceUnit?: MyUnit["source_unit"];
  showDraftTag: boolean;
  showUnpublishedTag: boolean;
  pendingSubmissions?: number;
  pendingUsers?: number;
  onPendingSubmissionsClick: () => void;
  toggleLinkedUnitsDrawer: () => void;
  onPendingUsersClick: () => void;
};

const UnitListTitleActions: FC<UnitListTitleProps> = ({
  linkedUnits,
  sourceUnit,
  showDraftTag,
  showUnpublishedTag,
  pendingSubmissions = 0,
  pendingUsers = 0,
  onPendingSubmissionsClick,
  toggleLinkedUnitsDrawer,
  onPendingUsersClick,
}) => {
  const { t } = useApplyTranslations();
  const isParentLinkedUnit = !sourceUnit && linkedUnits > 0;
  const isChildrenLinkedUnit = Boolean(sourceUnit);

  const handlePendingSubmissionsClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    onPendingSubmissionsClick();
  };

  const handlePendingSubmissionsKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
    e.stopPropagation();
    if ("key" in e && e.key === "Enter") onPendingSubmissionsClick();
  };

  const handlePendingUsersClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    onPendingUsersClick();
  };

  const handlePendingUsersKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
    e.stopPropagation();
    if ("key" in e && e.key === "Enter") onPendingUsersClick();
  };

  if (
    !isParentLinkedUnit &&
    !isChildrenLinkedUnit &&
    !showDraftTag &&
    !showUnpublishedTag &&
    !pendingUsers &&
    !pendingSubmissions
  ) {
    return null;
  }

  return (
    <div css={listItemActionsWrapperStyles}>
      <LinkedUnitTag
        linkedUnits={linkedUnits}
        sourceUnit={sourceUnit}
        toggleLinkedUnitsDrawer={toggleLinkedUnitsDrawer}
      />
      {showDraftTag && <StatusTag size="sm" color="negative" text={t("general.draft")} />}
      {showUnpublishedTag && (
        <StatusTag color="warning" size="sm" text={t("unitEdit.unpublishedChanges")} />
      )}
      {pendingSubmissions > 0 && (
        <StatusTag
          color="neutral"
          size="sm"
          text={pendingSubmissions?.toString()}
          icon={AssignmentSubmissionSVG}
          ariaLabel={t("a11y.units.assignments.pendingSubmissions", { count: pendingSubmissions })}
          tabIndex={0}
          onClick={handlePendingSubmissionsClick}
          onKeyDown={handlePendingSubmissionsKeyDown}
        />
      )}

      {pendingUsers > 0 && (
        <StatusTag
          color="neutral"
          size="sm"
          text={pendingUsers.toString()}
          icon={AssignmentSubmissionSVG}
          ariaLabel={t("a11y.units.ilts.pendingUsers", { count: pendingUsers })}
          tabIndex={0}
          onClick={handlePendingUsersClick}
          onKeyDown={handlePendingUsersKeyDown}
        />
      )}
    </div>
  );
};

export default memo(UnitListTitleActions);
