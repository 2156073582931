import account from "@utils/permissions/account";
import ai from "@utils/permissions/ai";
import automations from "@utils/permissions/automations";
import branches from "@utils/permissions/branches";
import calendar from "@utils/permissions/calendar";
import catalog from "@utils/permissions/catalog";
import categories from "@utils/permissions/categories";
import conferences from "@utils/permissions/conferences";
import courses from "@utils/permissions/courses";
import discussions from "@utils/permissions/discussions";
import ecommerce from "@utils/permissions/ecommerce";
import files from "@utils/permissions/files";
import gamification from "@utils/permissions/gamification";
import groups from "@utils/permissions/groups";
import helpCenter from "@utils/permissions/helpCenter";
import importExport from "@utils/permissions/importExport";
import messages from "@utils/permissions/messages";
import notifications from "@utils/permissions/notifications";
import profile from "@utils/permissions/profile";
import reports from "@utils/permissions/reports";
import skills from "@utils/permissions/skills";
import users from "@utils/permissions/users";
import userTypes from "@utils/permissions/userTypes";

export type Permissions = {
  administrator: string[];
  instructor: string[];
  learner: string[];
};

// User has the given permissions
export const userHasRequiredPermissions = (
  permissions: string[],
  userPermissions: string[] | undefined,
  requireAll = true,
): boolean => {
  if (!userPermissions) return false;
  const hasPermission = (permission: string): boolean => userPermissions.includes(permission);

  return requireAll ? permissions.every(hasPermission) : permissions.some(hasPermission);
};

export default {
  discussionsPermissions: discussions,
  profilePermissions: profile,
  reportsPermissions: reports,
  gamificationPermissions: gamification,
  coursesPermissions: courses,
  messagesPermissions: messages,
  catalogPermissions: catalog,
  calendarPermissions: calendar,
  groupPermissions: groups,
  accountPermissions: account,
  branchPermissions: branches,
  usersPermissions: users,
  userTypesPermissions: userTypes,
  categoriesPermissions: categories,
  importExportPermissions: importExport,
  filePermissions: files,
  automationsPermissions: automations,
  notificationsPermissions: notifications,
  ecommercePermissions: ecommerce,
  helpCenterPermissions: helpCenter,
  conferencesPermissions: conferences,
  skillsPermissions: skills,
  ai: ai,
};
