import React, { FC } from "react";
import { To } from "react-router";
import { Link } from "react-router-dom";

import { SerializedStyles } from "@emotion/react";
import { Button, Drawer, Heading, Text, Tooltip } from "@epignosis_llc/gnosis";
import { ArrowLeftSVG, CloseSVG, RedirectLinkSVG } from "@epignosis_llc/gnosis/icons";
import { useResponsive } from "ahooks";

import { MainMenuDrawerContainer } from "@layouts/DashboardLayout/components/MainMenuDrawer/styles";

import { useApplyTranslations } from "@hooks";
import { useUIStore } from "@stores";

export type MainMenuDrawerProps = {
  isOpen: boolean;
  headerTitle: string;
  headerSubtitle?: string;
  showFooter?: boolean;
  hasBackButton?: boolean;
  size?: "sm" | "md" | "lg";
  id?: string;
  onClose: () => void;
  onApply?: () => void;
  actionButton?: {
    text?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
  };
  customFooter?: JSX.Element;
  link?: To;
  children?: React.ReactNode;
};

const widthDimensions = {
  sm: "16rem", // MainDrawerMinWidth
  md: "31.25rem",
  lg: "48rem",
};

const DEFAULT_SIZE = "sm";

const MainMenuDrawer: FC<MainMenuDrawerProps> = ({
  isOpen,
  onClose,
  onApply,
  headerTitle,
  headerSubtitle,
  showFooter = true,
  hasBackButton = false,
  size = DEFAULT_SIZE,
  id,
  children,
  actionButton = {
    text: "",
    isDisabled: false,
    isLoading: false,
  },
  customFooter,
  link,
}) => {
  const { t, i18n } = useApplyTranslations();
  const { md } = useResponsive();
  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "right" : "left";
  const { mainNavState, mainDrawer } = useUIStore((state) => state);
  const isMainNavCollapsed = mainNavState === "collapsed";
  const width = md ? widthDimensions[size] : "100%";
  const dialogStyles = md
    ? { insetInlineStart: isMainNavCollapsed ? "4.75rem" : widthDimensions["sm"] }
    : undefined;
  const actionButtonText = actionButton?.text ? actionButton?.text : t("general.apply");
  const { isDisabled, isLoading } = actionButton;
  const isTitleOverTooltipThreshold = headerTitle && headerTitle.length > 51;

  const { show } = mainDrawer;

  return (
    <Drawer
      id={id}
      isOpen={isOpen || show}
      onClose={onClose}
      placement={placement}
      width={width}
      css={(theme): SerializedStyles => MainMenuDrawerContainer(theme)}
      dialogStyles={dialogStyles}
    >
      {headerTitle && (
        <Drawer.Header closable={false}>
          <div className="back-title-container">
            {hasBackButton && (
              <Button
                color="secondary"
                variant="link"
                onClick={onClose}
                noGutters
                className="back-button"
              >
                <ArrowLeftSVG height={28} />
              </Button>
            )}
            <div className="header-content">
              <Heading as="h2" data-testid={`${id ? id : "drawer"}-header`}>
                <div className="header-container">
                  <Tooltip content={headerTitle} disabled={!isTitleOverTooltipThreshold}>
                    <div className="header-title">{headerTitle}</div>
                  </Tooltip>

                  {link && (
                    <Link to={link} target="_blank" className="header-link">
                      <RedirectLinkSVG height={32} className="link-icon" />
                    </Link>
                  )}
                </div>
              </Heading>
              {headerSubtitle && <Text fontSize="xs">{headerSubtitle}</Text>}
            </div>
          </div>
          <Button
            color="secondary"
            variant="link"
            onClick={onClose}
            noGutters
            className="close-button"
          >
            <CloseSVG height={24} />
          </Button>
        </Drawer.Header>
      )}
      <Drawer.Body>
        <section className="action-drawer-body">{children}</section>
      </Drawer.Body>
      {showFooter && (
        <Drawer.Footer>
          {customFooter ? (
            customFooter
          ) : (
            <div className="action-drawer-footer">
              <Button
                onClick={onApply ? onApply : onClose}
                disabled={isDisabled}
                isLoading={isLoading}
                className="button button-left"
                data-testid="drawer-left-action"
              >
                {actionButtonText}
              </Button>
              <Button
                color="secondary"
                className="button button-right"
                data-testid="drawer-right-action"
                onClick={onClose}
              >
                {t("general.cancel")}
              </Button>
            </div>
          )}
        </Drawer.Footer>
      )}
    </Drawer>
  );
};

export default MainMenuDrawer;
