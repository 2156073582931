import React, { FC } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { Modal, Text } from "@epignosis_llc/gnosis";
import { AxiosError } from "axios";
import PubSub from "pubsub-js";

import { HandledError } from "@errors";
import { handleUploadFilesErrors } from "@errors/errors";

import { postCourseFile } from "@api/courses";
import { ValidationType } from "@components/ReusableComponents/UploadArea/helpers";
import { generalNotification } from "@utils/helpers";
import { t } from "@utils/i18n";

import {
  COURSE_IMAGE_UPLOAD,
  COURSE_IMAGE_UPLOAD_CLOSE,
} from "@components/ReusableComponents/EditableContent/constants";
import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";

import { FileUploadArea } from "@components/ReusableComponents";

import { FileRes } from "types/responses";

type CourseFileUploadModalProps = {
  isOpen: boolean;
  courseId: string;
  onClose: () => void;
};

const CourseFileUploadModal: FC<CourseFileUploadModalProps> = ({ isOpen, courseId, onClose }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleFileUpload = async (file: FileRes): Promise<void> => {
    PubSub.publish(COURSE_IMAGE_UPLOAD, file._data.url);
    onClose();
  };

  const handleClose = (): void => {
    PubSub.publish(COURSE_IMAGE_UPLOAD_CLOSE);
    onClose();
  };

  const { mutate: postFileMutation, isLoading } = useMutation(
    [queryKeys.courses.postFile, courseId],
    (variables: { file: File; isLastFile: boolean }) =>
      postCourseFile({ courseId: courseId, file: variables.file }),
    {
      onSuccess: async (file) => {
        generalNotification("success", <p>{t("files.fileUploadSuccessful")}</p>);

        // Cancel course images queries to ensure that the invalidation will fetch the latest uploaded file
        queryClient.cancelQueries([queryKeys.courses.images, courseId]);
        await queryClient.invalidateQueries([queryKeys.courses.images, courseId]);

        handleFileUpload(file ?? null);
      },
      onError: (error: AxiosError) => {
        const handleError = (error: HandledError | null): void => {
          if (error?.id === "not_found.course_not_found") {
            navigate(URLS.courses.courses);
          }
        };
        handleUploadFilesErrors(error, false, handleError);
      },
    },
  );

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header>
        <Text fontSize="xl" weight="700">
          {t("courseEdit.uploadImage")}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <FileUploadArea
          isLoading={isLoading}
          postFileMutation={postFileMutation}
          maxFiles={1}
          validationTypes={[ValidationType.Image]}
        />
      </Modal.Body>
    </Modal>
  );
};

export default CourseFileUploadModal;
