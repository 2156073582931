import React, { FC, HTMLAttributes, ReactNode } from "react";

import { ContentStyles } from "@views/CourseEdit/components/CourseEditLayout/styles";

export type ContentProps = FC<
  HTMLAttributes<HTMLDivElement> & {
    children: ReactNode;
  }
>;

const Content: ContentProps = ({ children, ...rest }) => (
  <div css={ContentStyles} {...rest}>
    {children}
  </div>
);

export default Content;
