import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["branches"] => {
  return {
    info: {
      backgroundColor: colors.secondary.base,
      textColor: colors.black,
    },
  };
};
