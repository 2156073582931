import React, { FC, useEffect, useState } from "react";

import { usePrevious } from "ahooks";

import { useAuth } from "@hooks";
import { useConfigurationStore } from "@stores";

import localStorageKeys from "@constants/localStorageKeys";

import AnnouncementContent from "@components/ReusableComponents/Announcements/AnnouncementContent";

import { Announcement, ShowAnnouncements } from "types/entities";

type ShownAnnouncements = {
  [key in Announcement["type"]]: Announcement[];
};

const getExternal = (isAuthenticated: boolean, announcements: Announcement[]): Announcement[] => {
  return !isAuthenticated
    ? announcements?.filter((announcement) => announcement.type === "external")
    : [];
};

const getInternal = (
  isAuthenticated: boolean,
  announcements: Announcement[],
  show: boolean,
  type: "admin" | "internal",
): Announcement[] => {
  return isAuthenticated && show
    ? announcements?.filter((announcement) => announcement.type === type)
    : [];
};

const getAnnouncements = (
  isAuthenticated: boolean,
  announcements: Announcement[],
): ShownAnnouncements => {
  const showAnnouncementsString = localStorage.getItem(localStorageKeys.ANNOUNCEMENTS);
  const showAnnouncements: ShowAnnouncements = showAnnouncementsString
    ? JSON.parse(showAnnouncementsString)
    : { internal: true, admin: true };

  const { admin, internal } = showAnnouncements;

  return {
    external: getExternal(isAuthenticated, announcements ?? []),
    admin: getInternal(isAuthenticated, announcements ?? [], admin, "admin"),
    internal: getInternal(isAuthenticated, announcements ?? [], internal, "internal"),
  };
};

const Announcements: FC = () => {
  const { isAuthenticated } = useAuth();
  const announcements = useConfigurationStore((state) => state.announcements);
  const currentInternalAnnouncements = announcements?.find(
    (announcement) => announcement.type === "internal",
  );
  const currentAdminAnnouncements = announcements?.find(
    (announcement) => announcement.type === "admin",
  );
  const { text: currentInternalText } = currentInternalAnnouncements ?? {};
  const { text: currentAdminText } = currentAdminAnnouncements ?? {};
  const previousInternalAnnouncements = usePrevious(currentInternalAnnouncements);
  const previousAdminAnnouncements = usePrevious(currentAdminAnnouncements);
  const { text: previousInternalText } = previousInternalAnnouncements ?? {};
  const { text: previousAdminText } = previousAdminAnnouncements ?? {};

  const hasInternalAnnouncementChanged =
    previousInternalText && previousInternalText !== currentInternalText;
  const hasAdminAnnouncementChanged = previousAdminText && previousAdminText !== currentAdminText;

  // Check if internal or admin announcements are updated and user has closed them.
  useEffect(() => {
    if (hasInternalAnnouncementChanged || hasAdminAnnouncementChanged) {
      const showAnnouncementsString = localStorage.getItem(localStorageKeys.ANNOUNCEMENTS);

      const showAnnouncements: ShowAnnouncements = showAnnouncementsString
        ? JSON.parse(showAnnouncementsString)
        : { internal: true, admin: true };

      const newShowAnnouncements = {
        ...showAnnouncements,
        internal: Boolean(hasInternalAnnouncementChanged),
        admin: Boolean(hasAdminAnnouncementChanged),
      };
      localStorage.setItem(localStorageKeys.ANNOUNCEMENTS, JSON.stringify(newShowAnnouncements));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcements]);

  const [shown, setShown] = useState<ShownAnnouncements>(() =>
    getAnnouncements(isAuthenticated, announcements ?? []),
  );

  const { external, admin, internal } = shown;

  // Set announcements on admin or external close
  const handleClose = (): void => {
    setShown(getAnnouncements(isAuthenticated, announcements ?? []));
  };

  // Set announcements when announcements or isAuthenticated have changed
  useEffect(() => {
    setShown(getAnnouncements(isAuthenticated, announcements ?? []));
  }, [announcements, isAuthenticated]);

  return (
    <>
      {external.length > 0 && <AnnouncementContent announcements={external} type="external" />}
      {admin.length > 0 && (
        <AnnouncementContent announcements={admin} type="admin" onClose={handleClose} />
      )}
      {internal.length > 0 && (
        <AnnouncementContent announcements={internal} type="internal" onClose={handleClose} />
      )}
    </>
  );
};

export default Announcements;
