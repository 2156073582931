import React, { FC } from "react";

import { Button } from "@epignosis_llc/gnosis";
import { SSOIconSVG } from "@epignosis_llc/gnosis/icons";

import appendRedirectParam from "@components/SignIn/helpers";
import { useApplyTranslations, useSearchQuery } from "@hooks";
import { useConfigurationStore } from "@stores";

import localStorageKeys from "@constants/localStorageKeys";

import { SignInSearchQueryFilters } from "types/common";

const SamlOicdSignIn: FC = () => {
  const { t } = useApplyTranslations();
  const { domainSettings, ssoDomainSettings } = useConfigurationStore();
  const integrationType = domainSettings?.sso?.integration_type ?? "";
  const showSamlOidcLogIn = ["saml", "oidc"].includes(integrationType);

  const { redirect } = useSearchQuery() as SignInSearchQueryFilters;

  const handleSamlClick = (): void => {
    if (showSamlOidcLogIn) {
      const { logout_url = "" } = ssoDomainSettings ?? {};
      let { login_url = "" } = ssoDomainSettings ?? {};

      if (redirect) {
        login_url = appendRedirectParam(login_url, redirect);
      }

      if (logout_url) localStorage.setItem(localStorageKeys.SSO_LOGOUT_URL, logout_url);

      if (login_url) window.location.replace(login_url);
    }
  };

  return (
    <section className="sso-container">
      <Button
        as="button"
        color="primary"
        iconBefore={SSOIconSVG}
        className="sign-in-type"
        onClick={handleSamlClick}
        variant="outline"
      >
        {integrationType === "saml" ? t("signIn.loginWithSaml") : t("signIn.loginWithOidc")}
      </Button>
    </section>
  );
};

export default SamlOicdSignIn;
