import Color from "color";

import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["calendar"] => {
  return {
    primaryColor: Color(colors.primary.base).alpha(0.25).string(),
    eventColor: colors.primary.base,
    pastEventColor: colors.secondary.light,
    pastEventTextColor: colors.black,
    pastDayColor: colors.secondary.lighter,
    eventDrawerBorderColor: colors.secondary.lighter,
  };
};
