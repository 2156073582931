import { COLORS, themes } from "@styles";
import { getGnosisTheme } from "@styles/themes/gnosis";
import { getPlusTheme } from "@styles/themes/plus";
import { palletColorsGenerator } from "@styles/utils";

import { useUIStore } from "@stores";

import { defaultThemes } from "@constants/themes";

import { Theme } from "types/entities";

const { getState } = useUIStore;

export const setActiveTheme = (theme: Theme): void => {
  const setTheme = getState().setTheme;
  // Set a default theme
  if (theme.default) {
    setTheme(themes[theme.id] ?? themes.defaultBlue);
  } else {
    if (theme.primary_color) {
      const THEME_COLOURS = {
        ...COLORS,
        primary: theme.primary_color,
      };

      const colors = palletColorsGenerator(THEME_COLOURS);
      const customTheme = { ...getGnosisTheme(colors), ...getPlusTheme(colors) };

      setTheme(customTheme);
    }
  }
};

export const getActiveThemeName = (activeTheme: Theme): string => {
  return (
    activeTheme?.name ?? defaultThemes.find((theme) => theme.id === activeTheme.id)?.name ?? ""
  );
};

export const getDefaultThemes = (activeThemeId: string): Theme[] =>
  defaultThemes.map((theme) => ({ ...theme, active: theme.id === activeThemeId }));

export const getActiveThemePrimaryColor = (activeTheme: Theme): string | undefined => {
  const { primary_color } = activeTheme;

  // Primary color will be null whet the active theme is a default theme
  return (
    primary_color ??
    defaultThemes.find(({ id }) => id === activeTheme.id)?.primary_color ??
    undefined
  );
};
