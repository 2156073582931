import React, { FC, memo, useEffect, useLayoutEffect, useRef, useState } from "react";

import { StatusTag, Tooltip } from "@epignosis_llc/gnosis";

import { mapVariantsToTagColors } from "@components/CustomTable/components/Cells/TextWithStatus/constants";

import { textWithStatusContainer } from "@components/CustomTable/components/Cells/TextWithStatus/style";
import { TextWithStatusProps } from "@components/CustomTable/components/Cells/TextWithStatus/types";

const TextWithStatus: FC<TextWithStatusProps> = ({
  content,
  tagText,
  variant = "ready",
  children,
  tooltipContent,
  maxWidth = 350,
  isHtml = false,
}) => {
  const componentRef = useRef<HTMLSpanElement | null>(null);
  const overflowRef = useRef<HTMLElement | null>(null);
  const [isOverflowActive, setIsOverflowActive] = useState(false);
  const variantList = Array.isArray(variant) ? variant : [variant];

  useLayoutEffect(() => {
    if (componentRef.current) {
      overflowRef.current = componentRef.current.querySelector(".overflow-text");
    }
  }, []);

  useEffect(() => {
    const el = overflowRef.current;

    if (el) {
      setIsOverflowActive(el.offsetWidth < el.scrollWidth);
    }
  }, [overflowRef]);

  return (
    <div css={textWithStatusContainer}>
      <div className="column-description">
        <Tooltip
          content={tooltipContent ?? content}
          disabled={!isOverflowActive}
          maxWidth={maxWidth}
        >
          <span ref={componentRef} style={{ display: "flex" }}>
            {content}
            {isHtml && isOverflowActive && "..."}
          </span>
        </Tooltip>
        {children}
        {tagText &&
          variant !== "ready" &&
          (Array.isArray(tagText) ? (
            <div className="multiple-status-tags">
              {tagText.map((text, index) => (
                <StatusTag
                  key={index}
                  text={text}
                  color={mapVariantsToTagColors[variantList[index]]}
                />
              ))}
            </div>
          ) : (
            <div className="status-tag">
              <StatusTag text={tagText} color={mapVariantsToTagColors[variantList[0]]} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default memo(TextWithStatus);
