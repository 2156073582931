import { css } from "@emotion/react";

import { mq } from "@utils/breakpoints";

export const mainHeaderContainer = ({ mainHeader, gamification, colors }) => css`
  position: sticky;
  display: flex;
  top: 0;
  max-height: 4.5rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: ${mainHeader.background};
  border-bottom: 1px solid ${mainHeader.borderBottom};
  z-index: 2;
  transition: all 0.2s ease;

  ${mq["md"]} {
    justify-content: space-between;
  }

  .left-header-block,
  .middle-header-block,
  .right-header-block {
    display: flex;
    align-items: center;
  }

  // Contains the hamburger menu and the logo.
  .left-header-block {
    .hamburger-btn {
      margin-inline: 0.25rem;
    }

    svg {
      color: ${colors.black};
    }
  }

  // Contains the search spotlight.
  // On mobile should be placed on the right.
  .middle-header-block {
    flex-grow: 0;
    margin-left: auto;
  }
  // On desktop should be placed in the middle.
  ${mq["md"]} {
    .middle-header-block {
      margin-left: inherit;
      flex-grow: 1;
    }
  }

  .logo {
    ${mq["md"]} {
      margin-inline-end: 2rem;
    }

    ${mq["lg"]} {
      margin-inline-start: 1rem;
    }

    img {
      max-height: 38px;

      ${mq["md"]} {
        max-height: 46px;
      }

      ${mq["sm"]} {
        max-width: fit-content;
      }
    }
  }

  .main-header-content {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    #toolbar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .mobile-search-btn {
        margin-inline-end: 0.5rem;
        color: ${mainHeader.mobileSearch.color};
      }

      .gamification-btn {
        .gamification-icon {
          color: ${gamification.color};
        }

        &:hover {
          .gamification-icon {
            color: ${gamification.hover};
          }
        }
      }
    }
  }
`;

export const footerButtons = css`
  text-align: center;
`;

export const mainDrawerContainer = ({ actionDrawer }) => css`
  --mainHeaderHeight: 72px;
  top: var(--mainHeaderHeight);
  height: calc(100% - var(--mainHeaderHeight));
  display: block;

  ${mq["md"]} {
    display: none;
  }

  header {
    border-bottom: 1px solid ${actionDrawer.border};
  }

  footer {
    border-top: 1px solid ${actionDrawer.border};
    padding: 1rem 2rem;
  }

  .help-footer-buttons {
    display: flex;
    justify-content: space-between;

    svg {
      height: 1.125rem;
    }
  }
`;
