import { Column, DropdownItem, TableProps } from "@epignosis_llc/gnosis";

import { t } from "@utils/i18n";

import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

export enum CourseUsersFilters {
  group = "[group_id]",
  branch = "[branch_id]",
}

const PAGINATION: IPagination = { number: 1, size: 10 };
const SORTING: TableProps["sorting"] = { column: "formatted_name", isDescending: false };
const DEFAULT_FILTERS: QueryFilter[] = [];

export const DEFAULT_STATE = {
  pagination: PAGINATION,
  sorting: SORTING,
  filters: DEFAULT_FILTERS,
};

export const getEnrollUsersInitialColumns = (): Column[] => {
  return [
    {
      accessor: "formatted_name",
      cell: t("general.user"),
      classNames: ["formatted_name"],
      maxWidth: 250,
    },
    {
      accessor: "actions",
      cell: "",
      classNames: ["hidden"],
      sortableHeader: false,
    },
  ];
};

export const getMassActionsOptions = (): DropdownItem[] => {
  return [
    {
      label: t("general.enroll"),
      value: "enroll",
    },
  ];
};

export const enrollUsersToCourseWordings = {
  enroll: {
    progressHeader: "courses.massActions.enrollModalTitle",
    progressMessage: "courses.massActions.enrollProgress",
    confirmationHeader: "courses.massActions.enrollModalTitle",
    confirmationButton: "general.enroll",
    successMessage: "courses.massActions.enrollSuccess",
    confirmationBodyTitle: "courses.massActions.enrollWarning",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
};
