import React, { FC, HTMLAttributes, memo, useRef } from "react";

import { Heading, Tooltip } from "@epignosis_llc/gnosis";

import { tocListTitleStyles } from "@components/ToC/components/styles";

import { useIsOverflow } from "@hooks";

export type ListTitleProps = HTMLAttributes<HTMLDivElement> & {
  text: string;
};

const ListTitle: FC<ListTitleProps> = ({ text, children, ...rest }) => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const overflowActive = useIsOverflow(ref);

  return (
    <div className="list-title" css={tocListTitleStyles} {...rest}>
      <div className="title-container">
        <Tooltip content={text} disabled={!overflowActive}>
          <Heading as="h2" size="sm">
            <span className="title" ref={ref}>
              {text}
            </span>
          </Heading>
        </Tooltip>
      </div>
      {children}
    </div>
  );
};

export default memo(ListTitle);
