import React, { FC, useEffect, useLayoutEffect, useState } from "react";

import { SerializedStyles } from "@emotion/react";
import {
  ArrowChevronRightSVG,
  ArrowLeftSVG,
  ChangeToneSVG,
  ContinueSentenceSVG,
  FixGrammarSVG,
  LongTextSVG,
  MagicWandSVG,
  ShortTextSVG,
  TranslateSVG,
} from "@epignosis_llc/gnosis/icons";
import { m } from "framer-motion";

import { AIDropdownListStyles } from "@components/FormElements/Editor/styles";

import { useApplyTranslations, useClickOutside } from "@hooks";

import { AIActions } from "@components/FormElements/Editor/AI/AIActions";
import { AIEditorOptions, AIGenerateTextActionType } from "@components/FormElements/Editor/types";

export type AIDropdownPosition = { x: number; y: number };

type AIDropdownListProps = {
  aiActions: AIActions;
  aiOptions: AIEditorOptions;
  dropdownRef: React.RefObject<HTMLDivElement>;
  close: () => void;
};

type MenuItem = {
  title: string;
  actionType: AIGenerateTextActionType;
  SVGComponent?: React.FC<React.SVGProps<SVGSVGElement>>;
  callback?: () => void;
  children?: MenuItem[];
};

const AIDropdownList: FC<AIDropdownListProps> = ({ aiActions, aiOptions, dropdownRef, close }) => {
  const { t } = useApplyTranslations();

  const [currentMenuItem, setCurrentMenuItem] = useState<MenuItem | undefined>(undefined);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [position, setPosition] = useState<AIDropdownPosition>({ x: 0, y: 0 });

  const handleClickOutside = (event: MouseEvent): void => {
    let target = event.target;

    while (target && target instanceof HTMLElement) {
      if (target.hasAttribute("data-cmd") && target.getAttribute("data-cmd") === "aiFeatures") {
        return;
      }
      target = target.parentElement; // Move up the DOM tree
    }

    close();
  };
  useClickOutside(dropdownRef, handleClickOutside);

  const calculatePosition = (
    toolbarRect: DOMRect,
    componentRect: DOMRect,
  ): { x: number; y: number } => {
    const SPACING = 8; // Spacing of 8px
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let x = toolbarRect.right + SPACING; // Default: submenu to the right of toolbar with spacing
    let y = toolbarRect.top; // Align tops of toolbar and submenu

    // Check if there's enough space to display submenu on the right horizontally
    const fitsHorizontally = x + SPACING + componentRect.width <= viewportWidth;

    // Check if the submenu fits vertically when placed to the right of the toolbar
    const fitsVertically = y + SPACING + componentRect.height <= viewportHeight && y >= 0;

    if (fitsHorizontally && fitsVertically) {
      // Case 1: Place submenu to the right of the toolbar
      return { x, y };
    }

    // Case 2: If it doesn't fit vertically, move below the toolbar
    x = toolbarRect.right - componentRect.width; // Align right edges
    y = toolbarRect.bottom + SPACING; // Add spacing below the toolbar

    const fitsBelow = y + componentRect.height <= viewportHeight;

    if (fitsBelow) {
      // Align right edges when submenu is below the toolbar
      x = toolbarRect.right - componentRect.width;
      return { x, y };
    }

    // Case 3: If it doesn't fit below, move above the toolbar
    y = toolbarRect.top - componentRect.height - SPACING; // Add spacing above the toolbar

    // Align right edges when submenu is above the toolbar
    x = toolbarRect.right - componentRect.width;
    return { x, y };
  };

  const updatePosition = (): void => {
    const toolbars = Array.from(document.querySelectorAll(".fr-toolbar")).filter(
      (toolbar) => getComputedStyle(toolbar).display !== "none",
    );

    if (toolbars.length < 1) {
      return;
    }

    const toolbar = toolbars[toolbars.length - 1];
    const componentElement = dropdownRef.current;

    if (toolbar && componentElement) {
      const toolbarRect = toolbar.getBoundingClientRect();
      const componentRect = componentElement.getBoundingClientRect();

      if (toolbarRect.width === 0) {
        return;
      }

      const { x, y } = calculatePosition(toolbarRect, componentRect);
      setPosition({ x, y });
    }
  };

  useEffect(() => {
    const toolbars = document.querySelectorAll(".fr-toolbar");

    if (toolbars.length < 1) {
      return;
    }
    const toolbar = toolbars[toolbars.length - 1];

    if (!toolbar || !(toolbar instanceof HTMLElement)) {
      return;
    }

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.attributeName === "style") {
          updatePosition();
        }
      }
    });

    observer.observe(toolbar, { attributes: true });

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    updatePosition();

    const handleResizeOrScroll = (): void => {
      updatePosition();
    };

    window.addEventListener("resize", handleResizeOrScroll);
    window.addEventListener("scroll", handleResizeOrScroll);

    return () => {
      window.removeEventListener("resize", handleResizeOrScroll);
      window.removeEventListener("scroll", handleResizeOrScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuItem]);

  const dropdownItems: MenuItem[] = [
    {
      SVGComponent: MagicWandSVG,
      title: t("ai.aiToolbar.improveWriting"),
      callback: aiActions.improveWriting,
      actionType: "improve_writing",
    },
    {
      SVGComponent: ContinueSentenceSVG,
      title: t("ai.aiToolbar.continueSentence"),
      callback: aiActions.continueSentence,
      actionType: "continue_sentence",
    },
    {
      SVGComponent: FixGrammarSVG,
      title: t("ai.aiToolbar.fixSpelling"),
      callback: aiActions.fixGrammar,
      actionType: "fix_spelling_grammar",
    },
    {
      SVGComponent: LongTextSVG,
      title: t("ai.aiToolbar.makeLonger"),
      callback: aiActions.makeLonger,
      actionType: "make_longer",
    },
    {
      SVGComponent: ShortTextSVG,
      title: t("ai.aiToolbar.makeShorter"),
      callback: aiActions.makeShorter,
      actionType: "make_shorter",
    },
    {
      SVGComponent: TranslateSVG,
      title: t("ai.aiToolbar.translate"),
      actionType: "translate",
      children: aiActions.translationLocales.map((locale) => {
        return {
          title: locale.name,
          actionType: "translate",
          callback: () => aiActions.translate(locale),
        };
      }),
    },
    {
      SVGComponent: ChangeToneSVG,
      title: "Change tone",
      actionType: "change_tone",
      children: aiActions.textTones.map((tone) => {
        return {
          title: tone.name,
          actionType: "change_tone",
          callback: () => aiActions.changeTone(tone),
        };
      }),
    },
  ];

  const availableItems = (currentMenuItem?.children ?? dropdownItems).filter((item) => {
    return (
      aiActions.canDoAction(item.actionType) &&
      !(aiOptions.disabledActions ?? []).includes(item.actionType)
    );
  });

  return (
    <>
      <div
        onPointerDown={(event): void => {
          event.stopPropagation();
          event.preventDefault();
        }}
        ref={dropdownRef}
        css={(): SerializedStyles => AIDropdownListStyles(position)}
      >
        <div className="ai-dropdown">
          <ul className="ai-dropdown-list">
            {currentMenuItem && (
              <li
                className="ai-dropdown-item"
                onClick={(): void => {
                  setShouldAnimate(true);
                  setCurrentMenuItem(undefined);
                }}
              >
                <button className="aiSubmenuButton">
                  <div className="content">
                    <div className="icon">
                      <ArrowLeftSVG height={16} />
                    </div>
                  </div>
                </button>
              </li>
            )}
            {availableItems.map((item) => (
              <m.li
                initial={shouldAnimate ? { opacity: 0, x: -20 } : undefined}
                animate={shouldAnimate ? { opacity: 1, x: 0 } : undefined}
                exit={shouldAnimate ? { opacity: 0, x: -20 } : undefined}
                transition={{ duration: 0.2 }}
                key={item.title}
                className="ai-dropdown-item"
                onClick={
                  item.children
                    ? (): void => {
                        if (item.children) {
                          setShouldAnimate(true);
                          return setCurrentMenuItem(item);
                        }
                      }
                    : item.callback
                }
              >
                <button className="aiSubmenuButton">
                  <div className="content outline-content">
                    {item.SVGComponent && (
                      <div className="icon">
                        <item.SVGComponent height={32} />
                      </div>
                    )}
                    <div className="title">{item.title}</div>
                    {item.children && (
                      <div className="icon">
                        <ArrowChevronRightSVG height={24} />
                      </div>
                    )}
                  </div>
                </button>
              </m.li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default AIDropdownList;
