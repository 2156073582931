import { t } from "@utils/i18n";
import { yup } from "@utils/validation/validation";

import { USERNAME_REGEX } from "@constants/validation";

export const SignUpFormValidationSchema = yup.object().shape({
  name: yup.string().trim().max(50).required(),
  surname: yup.string().trim().max(50).required(),
  email: yup.string().trim().max(150).required().email(),
  login: yup
    .string()
    .trim()
    .matches(USERNAME_REGEX, () => t("signUp.validationMessages.invalidUsername"))
    .max(150)
    .required(),
  password: yup.string().trim().max(30).required(),
  session_data: yup.string().trim(),
});
