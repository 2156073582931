import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMutation, useQueryClient } from "react-query";

import { AxiosError } from "axios";

import { useApplyTranslations } from "@hooks";
import { useAIJobPolling } from "@hooks/useAIJobPolling";
import { useConfigurationStore } from "@stores";
import { generalNotification } from "@utils/helpers";
import { performEditorAIAction } from "@utils/helpers/ai";
import { generateCourseImage, getCourseAIJobs } from "@views/CourseEdit/api";

import { COURSE_DESCRIPTION_EDITOR_ID } from "@components/CourseOverview/constants";
import { queryKeys } from "@constants/index";
import { AIJobFilters } from "@views/CourseEdit/constants";

import { AIGenerateTextType } from "@components/FormElements/Editor/types";

import { CourseEditAIState } from "@views/CourseEdit/types";
import { Course } from "types/entities";

const CourseEditAIContext = createContext<CourseEditAIState | undefined>(undefined);

type CourseEditAIProviderProps = {
  course: Course;
} & PropsWithChildren;

/**
 * A Context Provider responsible for the state of AI Course-editing capabilities.
 */
export const CourseEditAIProvider: FC<CourseEditAIProviderProps> = ({ children, course }) => {
  const { t } = useApplyTranslations();

  const [isImagePromptOpen, setIsImagePromptOpen] = useState(false);
  const [isAIImageGenerationAvailable, setIsAIImageGenerationAvailable] = useState(false);
  const [isAIWriteAboutAvailable, setIsAIDescriptionAvailable] = useState(false);
  const [isImageGenerationStarting, setIsImageGenerationStarting] = useState(false);
  const [isImageWorking, setIsImageWorking] = useState(false);
  const [isDescriptionWorking, setIsDescriptionWorking] = useState(false);

  const queryClient = useQueryClient();
  const { userProfileData } = useConfigurationStore();
  const { can_write_about = false } = userProfileData?.policies?.ai?.editor ?? {};
  const { id, policies } = course;
  const {
    can_generate_image_with_ai = false,
    can_update_description = false,
    can_update_image = false,
  } = policies ?? {};
  const courseId = id.toString();

  const showImageGenerationErrorNotification = (error?: AxiosError): void => {
    if (error?.response?.status === 409) {
      generalNotification("warning", <p>{t("ai.errors.imageGenerationAlreadyInProgress")}</p>);
      return;
    }

    generalNotification("error", <p>{t("ai.errors.ImageGenerationFailed")}</p>);
  };

  // Poll for AI jobs.
  const { course_image: courseImageJob, startPolling } = useAIJobPolling(
    true,
    (signal) => getCourseAIJobs(courseId, signal),
    AIJobFilters,
    (error) => {
      if (isImageWorking) {
        showImageGenerationErrorNotification(error);
      }
      setIsImageWorking(false);
    },
  );

  // Update permissions.
  useEffect(() => {
    setIsAIImageGenerationAvailable(can_generate_image_with_ai && can_update_image);
  }, [can_generate_image_with_ai, can_update_image]);

  useEffect(() => {
    setIsAIDescriptionAvailable(can_write_about && can_update_description);
  }, [can_write_about, can_update_description]);

  // Update state based on AI Job statuses.
  useEffect(() => {
    if (courseImageJob?.status === "working") {
      setIsImageGenerationStarting(false);
      setIsImageWorking(true);
      return;
    }

    setIsImageWorking(false);

    if (courseImageJob?.status === "completed") {
      queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
    }
  }, [queryClient, courseId, courseImageJob, setIsImageWorking]);

  // Start image generation.
  const { mutate: generateCourseImageMutation } = useMutation(
    (aiPrompt: string) => generateCourseImage(courseId, aiPrompt),
    {
      onError: (error: AxiosError) => {
        setIsImageGenerationStarting(false);
        setIsImageWorking(false);
        showImageGenerationErrorNotification(error);
      },
      onSuccess: () => {
        setIsImageWorking(true);
        setIsImageGenerationStarting(false);
        setIsImagePromptOpen(false);
        startPolling();
      },
    },
  );

  const generateImage = useCallback(
    (prompt: string) => {
      if (isImageWorking || isImageGenerationStarting) {
        return;
      }

      setIsImageGenerationStarting(true);
      generateCourseImageMutation(prompt);
    },
    [isImageWorking, isImageGenerationStarting, generateCourseImageMutation],
  );

  const generateDescription = useCallback(
    (prompt: string): void => {
      if (isDescriptionWorking) {
        return;
      }

      const actionType: AIGenerateTextType = {
        action: "completion",
        args: {
          clearEditor: true,
        },
      };

      performEditorAIAction(COURSE_DESCRIPTION_EDITOR_ID, actionType, prompt);
    },
    [isDescriptionWorking],
  );

  const generateImageAndDescription = useCallback(
    (prompt: string) => {
      generateImage(prompt);
      generateDescription(prompt);
    },
    [generateImage, generateDescription],
  );

  const state: CourseEditAIState = useMemo(
    () => ({
      isAIImageGenerationAvailable,
      isAIDescriptionAvailable: isAIWriteAboutAvailable,
      isImagePromptOpen,
      setIsImagePromptOpen,
      isImageGenerationStarting,
      isImageWorking,
      setIsImageWorking,
      isDescriptionWorking,
      setIsDescriptionWorking,
      generateImage,
      generateImageAndDescription,
    }),
    [
      isAIImageGenerationAvailable,
      isAIWriteAboutAvailable,
      isImagePromptOpen,
      isImageGenerationStarting,
      isImageWorking,
      isDescriptionWorking,
      generateImage,
      generateImageAndDescription,
    ],
  );

  return <CourseEditAIContext.Provider value={state}>{children}</CourseEditAIContext.Provider>;
};

export const useCourseEditAI = (): CourseEditAIState => {
  const context = useContext(CourseEditAIContext);
  if (!context) {
    throw new Error("useCourseEditAI must be used within CourseEditAIProvider");
  }

  return context;
};
