import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["animatedProgressBar"] => {
  return {
    animationBackground: `repeating-linear-gradient(to right, ${colors.primary.base} 0%, ${colors.primary.lightest} 50%, ${colors.primary.base} 100%)`,
    background: `linear-gradient(270deg, ${colors.primary.base} -1.3%, ${colors.primary.lightest} 100%)`,
    barBackground: colors.secondary.lighter,
  };
};
