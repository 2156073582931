import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["certificateCard"] => {
  return {
    certificateCover: {
      background: colors.secondary.lighter,
      color: colors.secondary.light,
      textColor: colors.white,
    },
    deleteOption: {
      color: colors.red.base,
    },
  };
};
