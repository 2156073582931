import { GnosisTheme } from "@epignosis_llc/gnosis";
import Color from "color";

import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): GnosisTheme["progressBar"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);
  return {
    primary: {
      background: protectColorContrast(
        "background",
        Color(colors.primary.lightest).alpha(0.25).string(),
        0.25,
      ),
      progressBackground: protectColorContrast("darker", colors.primary.base),
      percentColor: colors.white,
      textColor: protectColorContrast("darker", colors.primary.base),
    },
    success: {
      background: colors.secondary.lighter,
      progressBackground: colors.green.base,
      percentColor: colors.white,
      textColor: colors.black,
    },
    white: {
      background: Color(colors.primary.lightest).alpha(0.25).string(),
      progressBackground: colors.white,
      percentColor: colors.black,
      textColor: colors.white,
    },
  };
};
