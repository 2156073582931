import React, { FC, ReactElement } from "react";

import { Text } from "@epignosis_llc/gnosis";
import { CloseModalSVG } from "@epignosis_llc/gnosis/icons";
import { useResponsive } from "ahooks";

import { UserDetailsStyles } from "@layouts/DashboardLayout/components/TopBarMenu/components/ProfileMenu/components/UserDetails/UserDetails.styles";

import { useApplyTranslations } from "@hooks";
import { useConfigurationStore } from "@stores";
import { getFormattedUserName } from "@utils/helpers";
import authService from "@utils/services/AuthService";

import { UserAvatar } from "@components";

type UserDetailsProps = {
  showRole?: boolean;
  isInnerUserInfo?: boolean;
  handleCloseMenu?: () => void;
};

const UserDetails: FC<UserDetailsProps> = ({
  showRole = true, // Shows the role or email. If false, it shows the email
  isInnerUserInfo = false, // If false, it shows the user's name and role(or email) in a smaller font size (also the avatar)
  handleCloseMenu,
}) => {
  const { md } = useResponsive();
  const { t } = useApplyTranslations();
  const currentRole = authService.getDefaultRole();
  const { userProfileData } = useConfigurationStore();
  const { name = "", surname = "", avatar = null, login = "", email } = userProfileData ?? {};
  const formattedUserName = getFormattedUserName({ name, surname, login });
  const userRole = t(`general.${currentRole}`);

  const renderAvatar = (size: "sm" | "md" | "lg" | "xl" | "xs"): ReactElement => (
    <div className="user-avatar">
      <UserAvatar
        avatar={avatar}
        imageSize="sm"
        avatarSize={size}
        name={name}
        alt={`${name} ${surname}`}
        bgColor="#207DD7"
      />
    </div>
  );

  return (
    <div css={UserDetailsStyles} className="user-details-container">
      {renderAvatar(isInnerUserInfo ? "md" : "sm")}
      <div data-testid="user-details" className="user-details">
        <Text
          className="user-name"
          fontSize={`${isInnerUserInfo ? "lg" : "md"}`}
          as="div"
          weight="700"
          data-testid="user-name"
          title={formattedUserName}
        >
          {formattedUserName}
        </Text>
        <Text
          className="user-info"
          fontSize="sm"
          as="div"
          weight="400"
          data-testid="user-info"
          title={showRole ? userRole : email}
        >
          {showRole ? userRole : <span className="user-email">{email}</span>}
        </Text>
      </div>

      {!md && isInnerUserInfo && (
        <span
          className="close-btn"
          onClick={(): void => {
            handleCloseMenu && handleCloseMenu();
          }}
        >
          <CloseModalSVG height="32" />
        </span>
      )}
    </div>
  );
};

export default UserDetails;
