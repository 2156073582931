import { Method } from "axios";

import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";
import authService from "@utils/services/AuthService";

import { MassActionParam } from "@components/ReusableComponents";

import { BranchesMassAction } from "types/entities";
import {
  BranchesByUsersRes,
  BranchesRes,
  BranchFilesRes,
  BranchSwitchRes,
  CountMassActionResponse,
  FileRes,
  MassActionResultResponse,
} from "types/responses";

const formContentType = {
  "Content-Type": "multipart/form-data",
};

export type EditGroupFileBodyData = Partial<{
  name: string;
  tags: string[];
  shared: boolean;
}>;

export const getBranches = async (queryStr = ""): Promise<BranchesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.branches.branches}${queryStr}`);

  return res.data;
};

export const postBranchSwitch = async (branchId: string): Promise<BranchSwitchRes> => {
  const refreshToken = authService.getRefreshToken() as string;
  const bodyFormData = new FormData();
  bodyFormData.append("grant_type", "refresh_token");
  bodyFormData.append("client_id", "TalentPlus");
  bodyFormData.append("client_secret", "secret");
  bodyFormData.append("refresh_token", refreshToken);

  const res = await HttpClient.post(ENDPOINTS.branches.switch(branchId), bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const addUserToBranch = async (branchId: string, userId: string): Promise<void> => {
  const res = await HttpClient.put(`${ENDPOINTS.branches.userAction(branchId, userId)}`);
  return res.data;
};

export const removeUserFromBranch = async (branchId: string, userId: string): Promise<void> => {
  const res = await HttpClient.delete(`${ENDPOINTS.branches.userAction(branchId, userId)}`);
  return res.data;
};

/** *** BRANCH FILES endpoints *****/

export const getBranchFiles = async (branchId: string, queryStr = ""): Promise<BranchFilesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.branches.files(branchId)}${queryStr}`);

  return res.data;
};

export const editBranchFile = async ({
  branchId,
  fileId,
  data,
}: {
  branchId: string;
  fileId: string;
  data: EditGroupFileBodyData;
}): Promise<void> => {
  await HttpClient.patch(ENDPOINTS.branches.filesEdit(branchId, fileId), data);
};

export const postBranchFile = async ({
  branchId,
  file,
}: {
  branchId: string;
  file: File;
}): Promise<FileRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  const endpoint = `${ENDPOINTS.branches.filesUpload(branchId)}`;

  const res = await HttpClient.post(endpoint, bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const postBranchFileURL = async ({
  branchId,
  fileURL,
}: {
  branchId: string;
  fileURL: string;
}): Promise<FileRes> => {
  const endpoint = `${ENDPOINTS.branches.filesUploadURL(branchId)}`;
  const body = { url: fileURL };

  const res = await HttpClient.post(endpoint, body);

  return res.data;
};

export const deleteBranchFile = async ({
  branchId,
  fileId,
}: {
  branchId: string;
  fileId: string;
}): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.branches.filesEdit(branchId, fileId));
};

/** *** Mass Actions Branches endpoints *****/
export const branchesMassActionsCount = async (
  type: BranchesMassAction,
  data: MassActionParam,
): Promise<CountMassActionResponse> => {
  const dataToSend = data?.course_id;
  const res = await HttpClient.get(ENDPOINTS.branches.affectedBranches(type, dataToSend as number));

  return res.data;
};

export const branchesMassActions = async (
  type: BranchesMassAction,
  data: MassActionParam,
): Promise<MassActionResultResponse> => {
  const dataToSend = data?.course_id as number;

  const methods: Record<string, Method> = {
    add: "post",
    remove: "delete",
  };

  const requestType = methods[type];

  const res = await HttpClient.request({
    url: ENDPOINTS.branches.massAction(dataToSend),
    method: requestType,
  });

  return res.data;
};

export const getBranchesByUsers = async (userIds: number[]): Promise<BranchesByUsersRes> => {
  const res = await HttpClient.post(ENDPOINTS.branches.branchesByUsers, { user_ids: userIds });

  return res.data;
};
