import ReactFroalaEditor from "froala-editor";

import { CustomDropdown, CustomIcon } from "@components/FormElements/Editor/customCommands/types";
import { InsertCustomOptions } from "@components/FormElements/Editor/types";

export const registerInsertImagesDropdown = (insertImagesOptions: InsertCustomOptions): void => {
  ReactFroalaEditor.RegisterCommand(CustomDropdown.InsertImageDropdown, {
    title: "Insert images dropdown",
    type: "dropdown",
    icon: CustomIcon.InsertImage,
    options: insertImagesOptions,
    undo: true,
    focus: true,
    refreshAfterCallback: true,
    callback: function (_: string, val: string) {
      this.image.insert(val, false, {}, null);
    },
  });
};
