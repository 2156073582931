import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["assignment"] => {
  return {
    drawer: {
      infoField: colors.secondary.darker,
    },
  };
};
