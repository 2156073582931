import { useConfigurationStore } from "@stores";
import { userHasRequiredPermissions } from "@utils/permissions/index";
import authService from "@utils/services/AuthService";

// Map with all the available permissions
export const helpCenterPermissions = {
  read: "help.read",
};

const { read } = helpCenterPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => help.read
   * help.read is required in order to have all the other help related permissions
   *
   * Allowed actions:
   *  GET articles
   *
   * @returns boolean
   */
  canAccessHelp: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
};
