import React, { FC, useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { Grid, Input, InputError, ToggleSwitch } from "@epignosis_llc/gnosis";
import { ClockFiveSVG, FolderOpenSVG } from "@epignosis_llc/gnosis/icons";
import { add, startOfDay } from "date-fns";

import { timeOptionsStyles } from "@views/CourseEdit/components/CourseOptions/Tabs/LimitsTab/components/styles";

import { useApplyTranslations } from "@hooks";
import { useConfigurationStore } from "@stores";
import {
  formatSelectedDate,
  getDomainDateFormat,
  getDomainTimeFormat,
  timeZonedDate,
} from "@utils/helpers";
import { EditCourseData } from "@views/CourseEdit/api";

import { courseOptionsIds } from "@views/CourseEdit/components/CourseOptions/constants";

import { DateInput, TimeInput } from "@components";
import { ActionSecondaryButton } from "@components/ReusableComponents";
import Note from "@views/CourseEdit/components/CourseOptions/components/Note";
import TabFormField from "@views/CourseEdit/components/CourseOptions/components/TabFormField";

import { Course } from "types/entities";

type TimeOptionsProps = {
  course: Course;
  form: UseFormReturn<EditCourseData>;
  hasDivider: boolean;
};

enum TimeOption {
  Duration = "duration",
  TimeLimit = "timeLimit",
}
const todayStartOfDay = startOfDay(new Date());
const startsAtMinDate = add(todayStartOfDay, { days: 1 });

const TimeOptions: FC<TimeOptionsProps> = ({ course, form, hasDivider }) => {
  const { t } = useApplyTranslations();
  const { userProfileData } = useConfigurationStore();
  const timezone = userProfileData?.timezone ?? "UTC";
  const dateFormat = getDomainDateFormat();
  const timeFormat = getDomainTimeFormat();

  const [timeOptionsType, setTimeOptionsType] = useState<TimeOption | null>(() => {
    const { starts_at, time_limit } = course;

    if (time_limit) return TimeOption.TimeLimit;

    return starts_at ? TimeOption.Duration : TimeOption.TimeLimit;
  });
  const isDurationSelected = timeOptionsType === TimeOption.Duration;
  const isTimeLimitSelected = timeOptionsType === TimeOption.TimeLimit;

  const { control, watch, setValue, trigger } = form;
  const startsAtDateWatch = watch("starts_at");
  const startsAtDate = startsAtDateWatch ? timeZonedDate(startsAtDateWatch, timezone) : null;
  const expiresAtDateWatch = watch("expires_at");
  const expiresAtDate = expiresAtDateWatch ? timeZonedDate(expiresAtDateWatch, timezone) : null;
  const startsAtMaxDate = expiresAtDate ?? undefined;
  // Expires_at minimum date is the date after starts_at at midnight (eg. if starts_at is 2021-10-10 23:30:00, expires_at minimum date is 2021-10-11 00:00:00)
  const expiresAtMinDate = startsAtDate
    ? new Date(startsAtDate.setHours(24, 0, 0, 0))
    : startsAtMinDate;

  const handleTimeOptionSelect = (type: TimeOption): void => {
    if (type === timeOptionsType) return;
    setTimeOptionsType(type);
  };

  // Trigger validation when date and time fields change
  useEffect(() => {
    trigger();
  }, [startsAtDateWatch, expiresAtDateWatch, trigger]);

  return (
    <>
      <TabFormField
        text={t("general.time")}
        icon={ClockFiveSVG}
        id={courseOptionsIds.timeOptions}
        colSpan={2}
        hasDivider={false}
      >
        <Grid templateColumns={[1, 1, 1, 2]} rowGap={1} columnGap={1} css={timeOptionsStyles}>
          <Grid.Item colSpan={1}>
            <ActionSecondaryButton
              data-testid="time-limit"
              isPressed={isTimeLimitSelected}
              className="time-type-button"
              onClick={(): void => handleTimeOptionSelect(TimeOption.TimeLimit)}
            >
              {t("general.timeLimit")}
            </ActionSecondaryButton>
          </Grid.Item>
          <Grid.Item colSpan={1}>
            <ActionSecondaryButton
              data-testid="duration"
              isPressed={isDurationSelected}
              className="time-type-button"
              onClick={(): void => handleTimeOptionSelect(TimeOption.Duration)}
            >
              {t("general.timeFrame")}
            </ActionSecondaryButton>
          </Grid.Item>

          {isDurationSelected && (
            <>
              <Grid.Item colSpan={[1, 1, 1, 2]}>
                <Note text={t("courseEdit.options.timeFrameNote")} className="time-note" />
              </Grid.Item>

              <Controller
                name="starts_at"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => {
                  const startsAt = value ? timeZonedDate(value, timezone) : null;
                  return (
                    <>
                      <Grid.Item colSpan={1}>
                        <DateInput
                          id="starts-at-date"
                          value={startsAt}
                          dateFormat={dateFormat}
                          label={t("courseEdit.startDate")}
                          minDate={startsAtMinDate}
                          maxDate={startsAtMaxDate}
                          status={error ? "error" : "valid"}
                          onChange={(selectedDate: Date | null): void =>
                            onChange(formatSelectedDate(selectedDate, timezone))
                          }
                        />
                        {error && <InputError>{error.message}</InputError>}
                      </Grid.Item>
                      <Grid.Item colSpan={1}>
                        <TimeInput
                          id="starts-at-time"
                          value={startsAt}
                          timeFormat={timeFormat}
                          label={t("courseEdit.startTime")}
                          status={error ? "error" : "valid"}
                          onChange={(selectedTime: Date | null): void =>
                            onChange(formatSelectedDate(selectedTime, timezone))
                          }
                        />
                      </Grid.Item>
                    </>
                  );
                }}
              />

              <Controller
                name="expires_at"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => {
                  const expiresAt = value ? timeZonedDate(value, timezone) : null;
                  return (
                    <>
                      <Grid.Item colSpan={1}>
                        <DateInput
                          id="expires-at-date"
                          value={expiresAt}
                          dateFormat={dateFormat}
                          label={t("courseEdit.endDate")}
                          minDate={expiresAtMinDate}
                          status={error ? "error" : "valid"}
                          onChange={(selectedDate: Date | null): void => {
                            onChange(
                              selectedDate ? formatSelectedDate(selectedDate, timezone) : null,
                            );

                            setValue("time_limit", null);
                          }}
                        />
                        {error && <InputError>{error.message}</InputError>}
                      </Grid.Item>
                      <Grid.Item colSpan={1}>
                        <TimeInput
                          id="expired-at-time"
                          value={expiresAt}
                          timeFormat={timeFormat}
                          label={t("courseEdit.endTime")}
                          status={error ? "error" : "valid"}
                          onChange={(selectedTime: Date | null): void =>
                            onChange(formatSelectedDate(selectedTime, timezone))
                          }
                        />
                      </Grid.Item>
                    </>
                  );
                }}
              />
            </>
          )}

          {isTimeLimitSelected && (
            <>
              <Grid.Item colSpan={[1, 1, 1, 2]}>
                <Note text={t("courseEdit.options.timeLimitNote")} className="time-note" />
              </Grid.Item>

              <Grid.Item colSpan={1}>
                <Controller
                  name="time_limit"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => (
                    <>
                      <Input
                        id="time-limit"
                        type="number"
                        value={value ?? ""}
                        min={1}
                        step={1}
                        label={t("courseEdit.numberOfDays")}
                        placeholder={t("courseEdit.setNumberOfDays")}
                        status={error ? "error" : "valid"}
                        onChange={(e): void => {
                          const timeLimit = e.target.value;
                          onChange(timeLimit ? timeLimit : null);
                          setValue("expires_at", null);
                        }}
                      />
                      {error && <InputError>{error.message}</InputError>}
                    </>
                  )}
                />
              </Grid.Item>
            </>
          )}
        </Grid>
      </TabFormField>

      {(isDurationSelected || isTimeLimitSelected) && (
        <TabFormField
          text={t("courseEdit.accessRetention")}
          icon={FolderOpenSVG}
          note={t("courseEdit.options.accessRetentionNote")}
          colSpan={2}
          hasDivider={hasDivider}
        >
          <Controller
            name="retain_access_after_completion"
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => {
              return (
                <ToggleSwitch
                  id="retain-access"
                  labelledById="retain-access"
                  data-testid="retain-access"
                  labelAfter={t("courseEdit.activateAccessRetention")}
                  defaultChecked={value}
                  onChange={(): void => onChange(!value)}
                />
              );
            }}
          />
        </TabFormField>
      )}
    </>
  );
};

export default TimeOptions;
