import React, { FC, memo } from "react";

import { Button, Dropdown, DropdownItem, Tooltip } from "@epignosis_llc/gnosis";
import { CloseSVG, VerticalEllipsisSVG } from "@epignosis_llc/gnosis/icons";

import { useApplyTranslations } from "@hooks";

import { ActionGhostButton } from "@components/ReusableComponents";

type UnitListActionsProps = {
  isReorderingSection: boolean;
  dropdownOptions: DropdownItem[];
  isDropdownDisabled: boolean;
  unitName: string;
  onListItemSelect: (item: DropdownItem) => void;
  onClose: () => void;
};

const UnitListActions: FC<UnitListActionsProps> = ({
  isReorderingSection,
  dropdownOptions,
  isDropdownDisabled,
  unitName,
  onClose,
  onListItemSelect,
}) => {
  const { t } = useApplyTranslations();

  return (
    <>
      {/* Close full section reordering */}
      {isReorderingSection ? (
        <Tooltip content={t("courseEdit.cancelFullSectionReorder")}>
          <Button variant="ghost" noGutters as="div" onClick={onClose}>
            <CloseSVG height={16} />
          </Button>
        </Tooltip>
      ) : (
        <>
          {dropdownOptions.length > 0 && (
            <Dropdown
              list={dropdownOptions}
              placement="bottom-end"
              fixPlacement
              onListItemSelect={onListItemSelect}
            >
              <ActionGhostButton
                data-testid="unit-dropdown"
                aria-label={t("a11y.courseEdit.unitOptions", { unitName: unitName })}
                className="unit-dropdown-options"
                disabled={isDropdownDisabled}
                tabIndex={-1}
              >
                <VerticalEllipsisSVG height={16} />
              </ActionGhostButton>
            </Dropdown>
          )}
        </>
      )}
    </>
  );
};

export default memo(UnitListActions);
