import React, { FC } from "react";

import { SerializedStyles } from "@emotion/react";

import { tocTopStyles } from "@components/ToC/components/styles";

import Trigger from "@components/ToC/Trigger";

export type topProps = {
  isOpen: boolean;
  toggleTocMenu: () => void;
  children?: React.ReactNode;
};

const Top: FC<topProps> = ({ isOpen, toggleTocMenu, children }) => (
  <div css={(theme): SerializedStyles => tocTopStyles(theme, { isOpen })}>
    <Trigger isOpen={isOpen} toggleTocMenu={toggleTocMenu} />
    {children && <div className="children-wrapper">{children}</div>}
  </div>
);

export default Top;
