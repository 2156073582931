import React, { FC, memo } from "react";

import { Text } from "@epignosis_llc/gnosis";

import { useConfigurationStore } from "@stores";
import { getCurrencyPrice } from "@utils/helpers";

import { Price } from "types/entities/Common";

type PriceCellPops = {
  amount?: Price["amount"];
  currency?: Price["currency"];
};

const PriceCell: FC<PriceCellPops> = ({ amount = 0, currency = "" }) => {
  const { domainSettings } = useConfigurationStore();
  const { locale = null } = domainSettings ?? {};
  const currencyPrice = amount > 0 && locale ? getCurrencyPrice(amount, currency, locale) : "-";

  return (
    <Text fontSize="sm" weight="400" as="div">
      {currencyPrice}
    </Text>
  );
};

export default memo(PriceCell);
