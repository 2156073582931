import { css } from "@emotion/react";

import { mq } from "@utils/breakpoints";

export const externalLayoutContainer = css`
  position: relative;
  display: flex;

  .main-content-container {
    width: 100%;
  }
`;

export const externalLayoutContent = css`
  position: relative;

  .main-content-wrapper {
    position: relative;
    max-width: 1920px;
    padding: 1rem 1rem 3rem;
    z-index: 1;

    ${mq["lg"]} {
      padding: 2rem 2rem 3rem;
    }
  }

  &.no-padding {
    .main-content-wrapper {
      padding: 0;
    }
  }
`;
