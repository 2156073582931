import React, { FC, ReactNode } from "react";

import { MainStyles } from "@views/CourseEdit/components/CourseEditLayout/styles";

export type MainProps = FC<{
  children: ReactNode;
}>;

const Main: MainProps = ({ children }) => <div css={MainStyles}>{children}</div>;

export default Main;
