import React, { FC } from "react";

import { Button } from "@epignosis_llc/gnosis";
import { CloseSVG } from "@epignosis_llc/gnosis/icons";

import { mobileSearch } from "@components/MainSearch/styles";

import MainSearch from "@components/MainSearch/MainSearch";

import { Spotlight } from "types/entities/Spotlight";

export type MobileSearchProps = {
  isLoading: boolean;
  searchResults: Spotlight | null;
  onSearch: (inputValue: string) => void;
  onClose: () => void;
};

const MobileSearch: FC<MobileSearchProps> = ({ isLoading, searchResults, onSearch, onClose }) => {
  return (
    <div css={mobileSearch} data-testid="search-container">
      <header className="searchContainer">
        <MainSearch
          searchResults={searchResults}
          isLoading={isLoading}
          onSearch={onSearch}
          onClose={onClose}
        />

        <Button
          variant="ghost"
          aria-label="close search"
          noGutters
          className="close-btn"
          onClick={onClose}
        >
          <CloseSVG height={24} />
        </Button>
      </header>
    </div>
  );
};

export default MobileSearch;
