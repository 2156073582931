import { Column, DropdownItem, TableProps } from "@epignosis_llc/gnosis";

import { t } from "@utils/i18n";

import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

export const getUserCoursesColumns = (): Column[] => [
  {
    accessor: "name",
    cell: t("branches.courses.course"),
    classNames: ["name"],
    headerWidth: 250,
  },
  {
    accessor: "code",
    cell: t("branches.courses.code"),
    classNames: ["name"],
    sortOrder: "desc" as Column["sortOrder"],
    headerWidth: 80,
    maxWidth: 80,
  },
  {
    accessor: "role_in_course",
    cell: t("general.role"),
    classNames: ["role"],
    sortOrder: "desc" as Column["sortOrder"],
    headerWidth: 200,
    maxWidth: 200,
  },
  {
    accessor: "enrolled_date",
    cell: t("general.enrolledDate"),
    classNames: ["enrolledDate"],
    sortOrder: "desc" as Column["sortOrder"],
    headerWidth: 150,
  },
  {
    accessor: "completion_date",
    cell: t("general.completionDate"),
    classNames: ["completionDate"],
    sortOrder: "desc" as Column["sortOrder"],
  },
];

export const actionsColumn = {
  accessor: "actions",
  cell: "",
  classNames: ["hidden"],
  sortableHeader: false,
};

export const getAvailableCoursesColumns = (): Column[] => [
  {
    accessor: "name",
    cell: t("branches.courses.course"),
    classNames: ["name"],
    maxWidth: 250,
  },
  {
    accessor: "actions",
    cell: "",
    classNames: ["hidden"],
    sortableHeader: false,
  },
];

export enum CoursesFilters {
  status = "[status]",
}

export const SORTING: TableProps["sorting"] = { column: "name", isDescending: false };
export const PAGINATION: IPagination = { number: 1, size: 10 };
export const DEFAULT_FILTERS: QueryFilter[] = [{ key: "[status]", value: "active" }];

export const getMassActionsOptions = (): DropdownItem[] => [
  {
    label: t("general.unenroll"),
    value: "unenroll",
  },
  {
    label: t("users.courses.resetProgress"),
    value: "reset",
  },
];

export const userCoursesWordings = {
  unenroll: {
    progressHeader: "users.courses.massActions.unenrollModalTitle",
    progressMessage: "users.courses.massActions.unenrollProgress",
    confirmationHeader: "users.courses.massActions.unenrollModalTitle",
    confirmationButton: "general.unenroll",
    successMessage: "users.courses.massActions.unenrollSuccess",
    confirmationBodyTitle: "users.courses.massActions.unenrollWarning",
    confirmationBodyText: "users.courses.massActions.coursesAffectedByMassAction",
  },
  reset: {
    progressHeader: "courses.massActions.resetModalTitle",
    progressMessage: "users.courses.massActions.resetProgress",
    confirmationHeader: "courses.massActions.resetModalTitle",
    confirmationBodyTitle: "courses.massActions.resetWarning",
    confirmationBodyText: "users.courses.massActions.coursesAffectedByMassAction",
    successMessage: "users.courses.massActions.resetSuccess",
  },
};
