import { config, getBaseUrl } from "@config";

import { t } from "@utils/i18n";

import userRoles from "@constants/userRoles";

const instructorRoles = [userRoles.INSTRUCTOR, userRoles.TRAINER];

export const convertToDataTestIdName = (input: string | undefined): string => {
  if (!input) return "";

  const sanitizedString = input
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, "");

  // Remove trailing hyphens without using regular expressions
  const lastHyphenIndex = sanitizedString.lastIndexOf("-");
  const finalString =
    lastHyphenIndex === sanitizedString.length - 1
      ? sanitizedString.slice(0, lastHyphenIndex)
      : sanitizedString;

  return finalString;
};

export const getRoleInCourse = (role_in_course: string): string => {
  if (instructorRoles.includes(role_in_course)) return t("general.instructor");
  else return t("general.learner");
};

/*
 Create portal's full link

 @params url:string (should use only URLS from src/constants/urls.ts)
 @return location origin + url provided
*/
export const getPortalLink = (url: string): string => {
  const originUrl = window.location.origin;
  const baseUrl = config.NODE_ENV != "dev" ? getBaseUrl() : "";

  return `${originUrl}${baseUrl}${url}`;
};

// Generates a unix timestamp
export const generateTimestamp = (): number => {
  return Math.floor(Date.now() / 1000);
};

export const toCamelCase = (str: string): string => {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};

export const toSnakeCase = (str: string): string =>
  str.replace(/(?<!^)[A-Z]/g, "_$&").toLowerCase();
