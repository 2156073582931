import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";

import { Recipient } from "types/entities";
import {
  FileRes,
  MessageInboxRes,
  MessageRes,
  MessageSentRes,
  RecipientsRes,
  TableCSVExportRes,
  UnreadMessagesRes,
} from "types/responses";

const formContentType = {
  "Content-Type": "multipart/form-data",
};

export type createMessagePostData = {
  subject: string;
  content: string;
  attachment_hash?: string;
  recipients: (Omit<Recipient, "name"> & { name?: string })[];
};

export type replyMessageData = {
  content: string;
  attachment_hash?: string;
  recipients: Recipient[];
};

export type forwardMessageData = {
  content: string;
  attachment_hash?: string;
  recipients: Recipient[];
};

export const getMessageById = async (messageId: number): Promise<MessageRes> => {
  const res = await HttpClient.get(ENDPOINTS.messages.singleMessage(messageId));

  return res.data;
};

export const getInboxMessages = async (queryStr = ""): Promise<MessageInboxRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.messages.inbox}${queryStr}`);

  return res.data;
};

export const getSentMessages = async (queryStr = ""): Promise<MessageSentRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.messages.sent}${queryStr}`);

  return res.data;
};

export const getUnreadMessages = async (): Promise<UnreadMessagesRes> => {
  const res = await HttpClient.get(ENDPOINTS.messages.unreadMessages);

  return res.data;
};

export const createMessage = async (newMessage: createMessagePostData): Promise<MessageRes> => {
  const res = await HttpClient.post(ENDPOINTS.messages.createMessage, newMessage);
  return res.data;
};

export const postMessageReply = async (
  messageId: number,
  message: replyMessageData,
): Promise<MessageRes> => {
  const res = await HttpClient.post(ENDPOINTS.messages.reply(messageId), message);
  return res.data;
};

export const postMessageForward = async (
  messageId: number,
  message: forwardMessageData,
): Promise<MessageRes> => {
  const res = await HttpClient.post(ENDPOINTS.messages.forward(messageId), message);
  return res.data;
};

export const deleteMessage = async (messageId: number): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.messages.delete(messageId));
};

export const getRecipients = async (queryStr = ""): Promise<RecipientsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.messages.recipients}${queryStr}`);

  return res.data;
};

export const putReadMessage = async (messageId: number): Promise<void> => {
  await HttpClient.put(ENDPOINTS.messages.read(messageId));
};

export const putUnreadMessage = async (messageId: number): Promise<void> => {
  await HttpClient.put(ENDPOINTS.messages.unread(messageId));
};

export const postAttachmentFile = async (file: File): Promise<FileRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  const res = await HttpClient.post(ENDPOINTS.messages.attachment, bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const incomingMessagesExport = async (queryStr = ""): Promise<TableCSVExportRes> => {
  const res = await HttpClient.post(`${ENDPOINTS.tableExports.incomingMessagesExport}${queryStr}`);
  return res.data;
};

export const outgoingMessagesExport = async (queryStr = ""): Promise<TableCSVExportRes> => {
  const res = await HttpClient.post(`${ENDPOINTS.tableExports.outgoingMessagesExport}${queryStr}`);
  return res.data;
};
