import { DropdownItem } from "@epignosis_llc/gnosis";

import { t } from "@utils/i18n";
import permissions from "@utils/permissions";

import { CourseUnit } from "@views/Reports/CourseReports/types";

export const getCanAccessUnit = (unitType: CourseUnit["unit"]["type"]): boolean => {
  const {
    canAccessTestReports,
    canAccessSurveyReports,
    canAccessIltReports,
    canAccessAssignmentReports,
  } = permissions.reportsPermissions;

  switch (unitType) {
    case "assignment":
      return canAccessAssignmentReports();
    case "ilt":
      return canAccessIltReports();
    case "test":
    case "scorm":
      return canAccessTestReports();
    case "survey":
      return canAccessSurveyReports();
    default:
      return false;
  }
};

type massActionOptions = {
  canMassUnenrollUsers?: boolean;
  canMassResetProgress?: boolean;
  canMassSyncProgress?: boolean;
  canCreateMessages: () => boolean;
};

export const resolveMassActionOptions = ({
  canMassUnenrollUsers,
  canMassResetProgress,
  canMassSyncProgress,
  canCreateMessages,
}: massActionOptions): DropdownItem[] => {
  const options = [];

  if (canMassUnenrollUsers) {
    options.push({
      label: t("general.unenroll"),
      value: "unenroll",
    });
  }

  if (canMassSyncProgress) {
    options.push({
      label: t("general.synchronize"),
      value: "sync",
    });

    options.push({
      label: t("users.courses.changeExpirationDate"),
      value: "set_expiration_date",
    });

    options.push({
      label: t("general.complete"),
      value: "complete",
    });
  }

  if (canMassResetProgress) {
    options.push({
      label: t("general.reset"),
      value: "reset",
    });
  }

  if (canCreateMessages()) {
    options.push({
      label: t("general.message"),
      value: "send_message",
    });
  }

  return options;
};
