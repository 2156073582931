import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["users"] => {
  return {
    info: {
      backgroundColor: colors.secondary.base,
      text: colors.white,
    },
  };
};
