import React, { FC, useState } from "react";

import { MainMenuDrawer } from "@layouts/DashboardLayout/components";

import { useApplyTranslations } from "@hooks";

import { TabKey } from "@components/Drawers/HelpCenter/constants";

import { ArticleContent, HelpCenterFooter } from "@components/Drawers/HelpCenter/components";
import HelpCenter from "@components/Drawers/HelpCenter/HelpCenter";
import { Article } from "@components/Drawers/HelpCenter/types";

type HelpCenterDrawerProps = {
  isOpen: boolean;
  toggleDrawer: () => void;
  hideMainDrawer: () => void;
};
const HelpCenterDrawer: FC<HelpCenterDrawerProps> = ({ isOpen, toggleDrawer, hideMainDrawer }) => {
  const { t } = useApplyTranslations();
  const [selectedArticle, setSelectedArticle] = useState<Article | undefined>(undefined);
  const [tabKey, setTabKey] = useState<string>(TabKey.helpArticles);

  const hasCustomFooter = tabKey === TabKey.helpArticles;

  return (
    <>
      <MainMenuDrawer
        id="help-menu-drawer"
        isOpen={isOpen}
        headerTitle={t("general.help")}
        size="lg"
        onClose={(): void => {
          toggleDrawer();
          hideMainDrawer();
        }}
        customFooter={<HelpCenterFooter toggleDrawer={toggleDrawer} />}
        onApply={(): void => toggleDrawer()}
        showFooter={hasCustomFooter}
      >
        <HelpCenter fullscreen setSelectedArticle={setSelectedArticle} setTabKey={setTabKey} />
      </MainMenuDrawer>

      {selectedArticle && (
        <MainMenuDrawer
          id="help-article-drawer"
          isOpen={!!selectedArticle}
          headerTitle={selectedArticle?.title}
          size="lg"
          onClose={(): void => setSelectedArticle(undefined)}
          hasBackButton
          showFooter={false}
          link={selectedArticle?.url}
        >
          <ArticleContent content={selectedArticle?.body} />
        </MainMenuDrawer>
      )}
    </>
  );
};

export default HelpCenterDrawer;
