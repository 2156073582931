import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["payments"] => {
  return {
    creditsValid: colors.green.base,
    creditsInvalid: colors.red.base,
    code: colors.secondary.dark,
    bundleBackground: colors.secondary.lighter,
    priceColor: colors.black,
    prevPriceColor: colors.secondary.darkest,
    invoiceModal: {
      headerBackground: colors.secondary.lighter,
      borderColor: colors.secondary.light,
    },
    activeSubscriptionBackground: colors.secondary.lighter,
    tagBackground: colors.primary.base,
    tagColor: colors.white,
    couponValid: colors.green.base,
    subscriptionButtonHover: colors.primary.light,
    subscriptionButtonBackground: colors.white,
    sectionDivider: colors.secondary.lighter,
  };
};
