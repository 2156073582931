import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

import { EditorViewStyles } from "@components/ReusableComponents/EditableContent/styles";

import { getCourseFiles } from "@api/courses";
import {
  builtContentForPreview,
  builtContentForSave,
  getMinimalToolbarButtons,
  getToolbarButtons,
} from "@components/ReusableComponents/EditableContent/helpers";
import { useApplyTranslations } from "@hooks";
import { buildPaginatedSearchQuery } from "@utils/helpers";
import { getUnitSmartTags } from "@views/CourseEdit/api";

import {
  COURSE_FILES_DEFAULT_STATE,
  COURSE_IMAGE_UPLOAD,
  COURSE_IMAGE_UPLOAD_CLOSE,
  SMART_TAG_CLOSE,
  SMART_TAG_INSERT,
} from "@components/ReusableComponents/EditableContent/constants";
import queryKeys from "@constants/queryKeys";

import { Editor } from "@components";
import { useAIEditorOptions } from "@components/FormElements/Editor";
import CourseFileUploadModal from "@components/ReusableComponents/EditableContent/CourseFileUploadModal";
import SmartTagsModal from "@components/ReusableComponents/EditableContent/SmartTagsModal";

type EditableContentProps = {
  id: string;
  courseId: string;
  canViewCourseFiles: boolean;
  canUpdateContent: boolean;
  initialContent: string;
  viewOnlyContent?: string; // Used to show version history content
  placeholder?: string;
  canEdit: boolean;
  quickInsertEnabled?: boolean;
  showMinimalToolbarButtons?: boolean;
  isAIOptionsEnabled?: boolean;
  forceEnableWriteAbout?: boolean;
  onChange?: (newContent: string) => void;
  onContentSave?: (newContent: string) => void;
  onAIStatusChanged?: (isWorking: boolean) => void;
};

const EditableContent: FC<EditableContentProps> = ({
  id,
  courseId,
  canViewCourseFiles,
  canUpdateContent,
  initialContent,
  viewOnlyContent,
  placeholder,
  canEdit,
  quickInsertEnabled = true,
  showMinimalToolbarButtons = false,
  isAIOptionsEnabled = false,
  forceEnableWriteAbout = false,
  onChange,
  onContentSave,
  onAIStatusChanged,
}) => {
  const { t } = useApplyTranslations();
  const aiEditorOptions = useAIEditorOptions(isAIOptionsEnabled, forceEnableWriteAbout);

  const searchQuery = buildPaginatedSearchQuery(COURSE_FILES_DEFAULT_STATE);

  const { data: courseImagesRes } = useQuery(
    [queryKeys.courses.images, courseId],
    () => getCourseFiles(courseId, searchQuery),
    { enabled: canViewCourseFiles },
  );

  const { data: smartTags = [] } = useQuery([queryKeys.courses.unitSmartTags], getUnitSmartTags, {
    select: (smartTagsRes) => smartTagsRes._data ?? [],
    enabled: canUpdateContent,
  });

  const courseImages = useMemo(() => courseImagesRes?._data ?? [], [courseImagesRes?._data]);

  const [isCourseFileUploadModalOpen, setCourseFileUploadModalOpen] = useState(false);
  const [isSmartTagsModalOpen, setSmartTagsModalOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState<string>(() =>
    builtContentForPreview(initialContent, courseImages),
  );
  const [saveContent, setSaveContent] = useState<string>(initialContent);
  const [isCodeViewActive, setIsCodeViewActive] = useState(false);

  const insertImagesOptions = useMemo(
    () => courseImages.reduce((obj, { url, name }) => ({ ...obj, [url]: name }), {}),
    [courseImages],
  );

  const smartTagsSelectOptions = useMemo(
    () => smartTags.map(({ value, label }) => ({ value, label })),
    [smartTags],
  );
  const hasSmartTags = smartTagsSelectOptions.length > 0;

  const toolbarButtons = useMemo(() => {
    return showMinimalToolbarButtons
      ? getMinimalToolbarButtons(aiEditorOptions)
      : getToolbarButtons(aiEditorOptions);
  }, [aiEditorOptions, showMinimalToolbarButtons]);

  const editorPlaceholder = placeholder ?? t("unitEdit.addContent");
  const hasViewOnlyContent = typeof viewOnlyContent === "string";
  const showEditorView = !canEdit || hasViewOnlyContent;

  const handleContentSave = useCallback(() => {
    onContentSave?.(saveContent);
  }, [onContentSave, saveContent]);

  const handleCodeViewChange = useCallback(
    (content: string) => {
      const newPreviewContent = builtContentForPreview(content, courseImages);
      setPreviewContent(newPreviewContent);
      setSaveContent(content);
      onChange?.(content);
    },
    [courseImages, onChange],
  );

  const handleTextViewChange = useCallback(
    (content: string) => {
      setPreviewContent(content);
      const newSaveContent = builtContentForSave(content, courseImages);
      setSaveContent(newSaveContent);
      onChange?.(newSaveContent);
    },
    [onChange, courseImages],
  );

  const handleImageUploadClick = useCallback((): void => {
    setCourseFileUploadModalOpen(true);
  }, []);

  const handleInsertSmartTagClick = useCallback((): void => {
    setSmartTagsModalOpen(true);
  }, []);

  // Set preview content on image change
  useEffect(() => {
    if (courseImages.length > 0) {
      setPreviewContent(builtContentForPreview(initialContent, courseImages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseImages]);

  useEffect(() => {
    if (initialContent !== saveContent) {
      setSaveContent(initialContent);
      setPreviewContent(builtContentForPreview(initialContent, courseImages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialContent]);

  // Do not render empty content when cannot edit
  if (!canEdit && !previewContent) return null;

  return (
    <section className="editable-content">
      {showEditorView ? (
        <div css={EditorViewStyles}>
          <FroalaEditorView
            model={
              hasViewOnlyContent
                ? builtContentForPreview(viewOnlyContent, courseImages)
                : previewContent
            }
          />
        </div>
      ) : (
        <>
          <Editor
            id={id}
            model={isCodeViewActive ? saveContent : previewContent}
            toolbarButtons={toolbarButtons}
            placeholderText={editorPlaceholder}
            toolbarInline
            minHeight={0}
            insertImagesOptions={insertImagesOptions}
            uploadImageSubscriber={COURSE_IMAGE_UPLOAD}
            uploadImageCloseSubscriber={COURSE_IMAGE_UPLOAD_CLOSE}
            smartTagInsertSubscriber={SMART_TAG_INSERT}
            smartTagCloseSubscriber={SMART_TAG_CLOSE}
            quickInsertEnabled={quickInsertEnabled}
            aiEditorOptions={aiEditorOptions}
            onChange={isCodeViewActive ? handleCodeViewChange : handleTextViewChange}
            onBlur={handleContentSave}
            onCodeViewToggle={setIsCodeViewActive}
            onUploadImageButtonClick={handleImageUploadClick}
            onInsertSmartTagButtonClick={handleInsertSmartTagClick}
            onAIStatusChanged={onAIStatusChanged}
          />

          <CourseFileUploadModal
            isOpen={isCourseFileUploadModalOpen}
            courseId={courseId}
            onClose={(): void => setCourseFileUploadModalOpen(false)}
          />

          {hasSmartTags && isSmartTagsModalOpen && (
            <SmartTagsModal
              isOpen={isSmartTagsModalOpen}
              options={smartTagsSelectOptions}
              onClose={(): void => setSmartTagsModalOpen(false)}
            />
          )}
        </>
      )}
    </section>
  );
};

export default React.memo(EditableContent);
