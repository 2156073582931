import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";

import { TableCSVExportRes } from "types/responses";

export const getTask = async (taskId: string): Promise<TableCSVExportRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.tableExports.task(taskId)}`);
  return res.data;
};

export const deleteTask = async (taskId: string): Promise<TableCSVExportRes> => {
  const res = await HttpClient.delete(`${ENDPOINTS.tableExports.task(taskId)}`);
  return res.data;
};
