import defaultBlack from "@styles/themes/defaultBlack";
import defaultBlue from "@styles/themes/defaultBlue";
import defaultGray from "@styles/themes/defaultGray";
import defaultGreen from "@styles/themes/defaultGreen";
import defaultOrange from "@styles/themes/defaultOrange";
import defaultRed from "@styles/themes/defaultRed";
import defaultTurquoise from "@styles/themes/defaultTurquoise";

export { GlobalStyleVariables } from "@styles/global";

export const themes = {
  defaultBlack,
  defaultBlue,
  defaultGray,
  defaultGreen,
  defaultOrange,
  defaultRed,
  defaultTurquoise,
};

export { COLORS } from "@styles/constants";
