import React, { FC } from "react";

import { CheckRegularSVG } from "@epignosis_llc/gnosis/icons";

import { useApplyTranslations } from "@hooks";

import ActionButton from "@components/CustomTable/components/TableActions/ActionButton";
import { GenericActionProps } from "@components/CustomTable/components/TableActions/types";

const ApproveAction: FC<GenericActionProps> = ({
  tooltipContent,
  entity,
  icon: Icon = CheckRegularSVG,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.approve")}
      onClick={onClick}
      data-testid="approve-action"
      aria-label={t("general.actions.approveEntity", { entity })}
    >
      <Icon height={20} />
    </ActionButton>
  );
};

export default ApproveAction;
