import React, { FC } from "react";

import { SerializedStyles } from "@emotion/react";
import { Button, Input } from "@epignosis_llc/gnosis";
import {
  ArrowChevronRightSVG,
  ArrowLeftChevronSVG,
  ScreenExpandSVG,
  ZoomInSVG,
  ZoomOutSVG,
} from "@epignosis_llc/gnosis/icons";

import { ToolbarStyles } from "@components/PdfViewer/components/styles";

import { useApplyTranslations } from "@hooks";
import { capitalize } from "@utils/helpers";

type ToolbarProps = {
  pageNumber: number;
  numPages: number;
  onZoomIn: () => void;
  onZoomOut: () => void;
  goToPreviousPage: () => void;
  goToNextPage: () => void;
  onPageChange: (page: number) => void;
  onFullScreen?: () => void;
};

const Toolbar: FC<ToolbarProps> = ({
  pageNumber,
  numPages,
  onZoomIn: zoomIn,
  onZoomOut,
  goToPreviousPage,
  goToNextPage,
  onPageChange,
  onFullScreen,
}) => {
  const { t, i18n } = useApplyTranslations();
  const isRtl = i18n.dir() === "rtl";
  const PreviousBtnIcon = isRtl ? ArrowChevronRightSVG : ArrowLeftChevronSVG;
  const NextBtnIcon = isRtl ? ArrowLeftChevronSVG : ArrowChevronRightSVG;
  const isPreviousBtnDisabled = pageNumber <= 1;
  const isNextBtnDisabled = pageNumber >= numPages;

  return (
    <div css={(theme): SerializedStyles => ToolbarStyles(theme, isRtl)} className="page-controls">
      <Button
        className="action-btn"
        variant="link"
        noGutters
        aria-label={t("pdfViewer.zoomIn")}
        onClick={zoomIn}
      >
        <ZoomInSVG width={16} />
      </Button>

      <Button
        className="action-btn"
        variant="link"
        noGutters
        aria-label={t("pdfViewer.zoomOut")}
        onClick={onZoomOut}
      >
        <ZoomOutSVG width={16} />
      </Button>

      <Button
        className="action-btn"
        variant="link"
        noGutters
        disabled={isPreviousBtnDisabled}
        aria-label={capitalize(t("pagination.previousPage"))}
        onClick={goToPreviousPage}
      >
        <PreviousBtnIcon height={32} className="previous-page-btn" />
      </Button>

      <div className="current-page-container">
        <div className="input-wrapper">
          <Input
            id="current-page"
            type="number"
            min="1"
            max={numPages}
            value={pageNumber}
            aria-label={t("a11y.pdfViewer.currentPage")}
            onChange={(e): void => onPageChange(Number(e.target.value))}
          />
        </div>
        <div className="separator">/</div>
        <div className="total-pages">{numPages}</div>
      </div>

      <Button
        className="action-btn"
        variant="link"
        noGutters
        disabled={isNextBtnDisabled}
        aria-label={capitalize(t("pagination.nextPage"))}
        onClick={goToNextPage}
      >
        <NextBtnIcon height={32} className="next-page-btn" />
      </Button>

      <Button
        className="action-btn"
        variant="link"
        noGutters
        aria-label={t("pdfViewer.fullScreen")}
        onClick={onFullScreen}
      >
        <ScreenExpandSVG width={32} />
      </Button>
    </div>
  );
};

export default Toolbar;
