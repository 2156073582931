import React, { FC } from "react";

import { pathItemStyles } from "@components/ReusableComponents/PathList/styles";

import PathContent, { PathContentProps } from "@components/ReusableComponents/PathList/PathContent";
import PathSeparator, {
  PathSeparatorProps,
} from "@components/ReusableComponents/PathList/PathSeparator";

export type PathItemCompoundProps = {
  Separator: FC<PathSeparatorProps>;
  Content: PathContentProps;
};

type PathItemProps = {
  children?: React.ReactNode;
};

const PathItem: FC<PathItemProps> & PathItemCompoundProps = ({ children }) => {
  return <li css={pathItemStyles}>{children}</li>;
};

PathItem.Separator = PathSeparator;
PathItem.Content = PathContent;

export default PathItem;
