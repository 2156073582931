import { ThemePlusTypes } from "@styles/types";
import { isShadeOfWhite, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["themeCard"] => {
  const isLightTheme = isShadeOfWhite(colors.primary.base);

  return {
    color: isLightTheme ? colors.black : colors.white,
  };
};
