// Here we should import all the individual theme objects.
import accordion from "@styles/themes/plus/accordion";
import accountAndSettings from "@styles/themes/plus/accountsAndSettings";
import achievementsStats from "@styles/themes/plus/achievementsStats";
import actionDrawer from "@styles/themes/plus/actionDrawer";
import activeButton from "@styles/themes/plus/activeButton";
import alertBox from "@styles/themes/plus/alertBox";
import animatedProgressBar from "@styles/themes/plus/animatedProgressBar";
import announcements from "@styles/themes/plus/announcements";
import announcementsDrawer from "@styles/themes/plus/announcementsDrawer";
import arrowButton from "@styles/themes/plus/arrowButton";
import assignment from "@styles/themes/plus/assignment";
import autocompleteInput from "@styles/themes/plus/autocompleteInput";
import automationsDrawer from "@styles/themes/plus/automationsDrawer";
import availabilityTag from "@styles/themes/plus/availabilityTag";
import avatar from "@styles/themes/plus/avatar";
import borderedSection from "@styles/themes/plus/borderedSection";
import branches from "@styles/themes/plus/branches";
import brandedForms from "@styles/themes/plus/brandedForms";
import brandingLabel from "@styles/themes/plus/brandingLabel";
import calendar from "@styles/themes/plus/calendar";
import carousel from "@styles/themes/plus/carousel";
import catalog from "@styles/themes/plus/catalog";
import categories from "@styles/themes/plus/categories";
import categoryCard from "@styles/themes/plus/categoryCard";
import certificateCard from "@styles/themes/plus/certificateCard";
import certificateTemplateCard from "@styles/themes/plus/certificateTemplateCard";
import chip from "@styles/themes/plus/chip";
import colorPicker from "@styles/themes/plus/colorPicker";
import colorPalette from "@styles/themes/plus/colors";
import copyToClipboard from "@styles/themes/plus/copyToClipboard";
import courseCard from "@styles/themes/plus/courseCard";
import courseEdit from "@styles/themes/plus/courseEdit";
import courseOverview from "@styles/themes/plus/courseOverview";
import courseReport from "@styles/themes/plus/courseReport";
import courseStore from "@styles/themes/plus/courseStore";
import courseToc from "@styles/themes/plus/courseToc";
import courseUnits from "@styles/themes/plus/courseUnits";
import customBadge from "@styles/themes/plus/customBadge";
import customCourseFields from "@styles/themes/plus/customCourseFields";
import customHomePage from "@styles/themes/plus/customHomePage";
import customLink from "@styles/themes/plus/customLink";
import customReport from "@styles/themes/plus/customReport";
import customUserFields from "@styles/themes/plus/customUserFields";
import dateInput from "@styles/themes/plus/dateInput";
import dialogBox from "@styles/themes/plus/dialogBox";
import discussions from "@styles/themes/plus/discussions";
import dropdown from "@styles/themes/plus/dropdown";
import editableText from "@styles/themes/plus/editableText";
import editor from "@styles/themes/plus/editor";
import featuredCard from "@styles/themes/plus/featuredCard";
import feedbackBox from "@styles/themes/plus/feedbackBox";
import fileCard from "@styles/themes/plus/fileCard";
import fileInput from "@styles/themes/plus/fileInput";
import fileList from "@styles/themes/plus/fileList";
import formfields from "@styles/themes/plus/formfields";
import ftpConfigDrawer from "@styles/themes/plus/ftpConfigDrawer";
import gamification from "@styles/themes/plus/gamification";
import gamificationDrawer from "@styles/themes/plus/gamificationDrawer";
import gamificationNotification from "@styles/themes/plus/gamificationNotification";
import general from "@styles/themes/plus/general";
import groups from "@styles/themes/plus/groups";
import icon from "@styles/themes/plus/icon";
import iltContainer from "@styles/themes/plus/iltContainer";
import imageEditor from "@styles/themes/plus/imageEditor";
import imageSelector from "@styles/themes/plus/imageSelector";
import importResults from "@styles/themes/plus/importResults";
import infoGrid from "@styles/themes/plus/infoGrid";
import infoPanel from "@styles/themes/plus/infoPanel";
import innerPage from "@styles/themes/plus/innerPage";
import leaderBoard from "@styles/themes/plus/leaderBoard";
import mainHeader from "@styles/themes/plus/mainHeader";
import massActionProgressBar from "@styles/themes/plus/massActionProgressBar";
import messages from "@styles/themes/plus/messages";
import microstats from "@styles/themes/plus/microstats";
import modal from "@styles/themes/plus/modal";
import multiSelect from "@styles/themes/plus/multiSelect";
import myCourses from "@styles/themes/plus/myCourses";
import notificationsWidget from "@styles/themes/plus/notificationsWidget";
import onboardingIntro from "@styles/themes/plus/onboardingIntro";
import passwordStrengthBar from "@styles/themes/plus/passwordStrengthBar";
import payments from "@styles/themes/plus/payments";
import pdfPreview from "@styles/themes/plus/pdfPreview";
import pressedRectangle from "@styles/themes/plus/pressedRectangle";
import price from "@styles/themes/plus/price";
import profile from "@styles/themes/plus/profile";
import progressDrawer from "@styles/themes/plus/progressDrawer";
import quickActionsWidget from "@styles/themes/plus/quickActionsWidget";
import rangeInput from "@styles/themes/plus/rangeInput";
import rating from "@styles/themes/plus/rating";
import recording from "@styles/themes/plus/recording";
import referral from "@styles/themes/plus/referral";
import reminderWidget from "@styles/themes/plus/reminderWidget";
import reorderColumns from "@styles/themes/plus/reorderColumns";
import reports from "@styles/themes/plus/reports";
import reqardsTabWidget from "@styles/themes/plus/reqardsTabWidget";
import resultsPage from "@styles/themes/plus/resultsPage";
import reverseTrial from "@styles/themes/plus/reverseTrial";
import scheduledReportsDrawer from "@styles/themes/plus/scheduledReportsDrawer";
import screenSizeAlert from "@styles/themes/plus/screenSizeAlert";
import scrollbar from "@styles/themes/plus/scrollbar";
import selectCard from "@styles/themes/plus/selectCard";
import sidebarExtended from "@styles/themes/plus/sidebar";
import signInPage from "@styles/themes/plus/signInPage";
import skipLink from "@styles/themes/plus/skipLink";
import stats from "@styles/themes/plus/stats";
import stripedProgressBar from "@styles/themes/plus/stripedProgressBar";
import subscription from "@styles/themes/plus/subscription";
import terms from "@styles/themes/plus/terms";
import textCard from "@styles/themes/plus/textCard";
import textWithIcon from "@styles/themes/plus/textWithIcon";
import themeCard from "@styles/themes/plus/themeCard";
import timeLimitBar from "@styles/themes/plus/timeLimitBar";
import timeline from "@styles/themes/plus/timeline";
import toggleButtonView from "@styles/themes/plus/toggleButtonView";
import trainingMatrix from "@styles/themes/plus/trainingMatrix";
import unitFooter from "@styles/themes/plus/unitFooter";
import units from "@styles/themes/plus/units";
import unitsToC from "@styles/themes/plus/unitsToC";
import unitTopNav from "@styles/themes/plus/unitTopNav";
import uploadArea from "@styles/themes/plus/uploadArea";
import upsell from "@styles/themes/plus/upsell";
import userReport from "@styles/themes/plus/userReport";
import users from "@styles/themes/plus/users";
import usersSettings from "@styles/themes/plus/usersSettings";
import widget from "@styles/themes/plus/widget";
import widgets from "@styles/themes/plus/widgets";
import widgetsDashboard from "@styles/themes/plus/widgetsDashboard";
import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export const getPlusTheme = (colors: PalletThemeColors): ThemePlusTypes => {
  return {
    accordion: accordion(colors),
    accountAndSettings: accountAndSettings(colors),
    achievementsStats: achievementsStats(colors),
    actionDrawer: actionDrawer(colors),
    activeButton: activeButton(colors),
    alertBox: alertBox(colors),
    announcements: announcements(colors),
    announcementsDrawer: announcementsDrawer(colors),
    animatedProgressBar: animatedProgressBar(colors),
    arrowButton: arrowButton(colors),
    assignment: assignment(colors),
    autocompleteInput: autocompleteInput(colors),
    automationsDrawer: automationsDrawer(colors),
    availabilityTag: availabilityTag(colors),
    avatar: avatar(colors),
    borderedSection: borderedSection(colors),
    branches: branches(colors),
    brandedForms: brandedForms(colors),
    brandingLabel: brandingLabel(colors),
    calendar: calendar(colors),
    carousel: carousel(colors),
    catalog: catalog(colors),
    colorPicker: colorPicker(colors),
    colors: colorPalette(colors),
    categories: categories(colors),
    categoryCard: categoryCard(colors),
    certificateCard: certificateCard(colors),
    certificateTemplateCard: certificateTemplateCard(colors),
    chip: chip(colors),
    copyToClipboard: copyToClipboard(colors),
    courseEdit: courseEdit(colors),
    courseCard: courseCard(colors),
    courseStore: courseStore(colors),
    courseOverview: courseOverview(colors),
    courseReport: courseReport(colors),
    courseToc: courseToc(colors),
    courseUnits: courseUnits(colors),
    customHomePage: customHomePage(colors),
    customLink: customLink(colors),
    customReport: customReport(colors),
    customBadge: customBadge(colors),
    customCourseFields: customCourseFields(colors),
    customUserFields: customUserFields(colors),
    dateInput: dateInput(colors),
    dropdown: dropdown(colors),
    discussions: discussions(colors),
    dialogBox: dialogBox(colors),
    editableText: editableText(colors),
    editor: editor(colors),
    formFields: formfields(colors),
    featuredCard: featuredCard(colors),
    feedbackBox: feedbackBox(colors),
    ftpConfigDrawer: ftpConfigDrawer(colors),
    fileCard: fileCard(colors),
    fileInput: fileInput(colors),
    fileList: fileList(colors),
    gamification: gamification(colors),
    gamificationDrawer: gamificationDrawer(colors),
    gamificationNotification: gamificationNotification(colors),
    general: general(colors),
    groups: groups(colors),
    icon: icon(colors),
    infoGrid: infoGrid(colors),
    infoPanel: infoPanel(colors),
    importResults: importResults(colors),
    imageEditor: imageEditor(colors),
    innerPage: innerPage(colors),
    imageSelector: imageSelector(colors),
    iltContainer: iltContainer(colors),
    leaderboard: leaderBoard(colors),
    mainHeader: mainHeader(colors),
    massActionProgressBar: massActionProgressBar(colors),
    messages: messages(colors),
    microstats: microstats(colors),
    modal: modal(colors),
    multiSelect: multiSelect(colors),
    myCourses: myCourses(colors),
    notificationsWidget: notificationsWidget(colors),
    onboardingIntro: onboardingIntro(colors),
    passwordStrengthBar: passwordStrengthBar(colors),
    payments: payments(colors),
    pressedRectangle: pressedRectangle(colors),
    profile: profile(colors),
    pdfPreview: pdfPreview(colors),
    price: price(colors),
    progressDrawer: progressDrawer(colors),
    quickActionsWidget: quickActionsWidget(colors),
    rangeInput: rangeInput(colors),
    rating: rating(colors),
    recording: recording(colors),
    reminderWidget: reminderWidget(colors),
    referral: referral(colors),
    reorderColumns: reorderColumns(colors),
    resultsPage: resultsPage(colors),
    rewardsTabWidget: reqardsTabWidget(colors),
    reports: reports(colors),
    selectCard: selectCard(colors),
    scheduledReportsDrawer: scheduledReportsDrawer(colors),
    scrollbar: scrollbar(colors),
    signInPage: signInPage(colors),
    sidebarExtended: sidebarExtended(colors),
    stats: stats(colors),
    stripedProgressBar: stripedProgressBar(colors),
    screenSizeAlert: screenSizeAlert(colors),
    subscription: subscription(colors),
    terms: terms(colors),
    textCard: textCard(colors),
    textWithIcon: textWithIcon(colors),
    themeCard: themeCard(colors),
    timeLimitBar: timeLimitBar(colors),
    timeline: timeline(colors),
    toggleButtonsView: toggleButtonView(colors),
    trainingMatrix: trainingMatrix(colors),
    unitTopNav: unitTopNav(colors),
    unitFooter: unitFooter(colors),
    units: units(colors),
    unitsToC: unitsToC(colors),
    uploadArea: uploadArea(colors),
    upsell: upsell(colors),
    users: users(colors),
    userReport: userReport(colors),
    usersSettings: usersSettings(colors),
    widgetsDashboard: widgetsDashboard(colors),
    widget: widget(colors),
    widgets: widgets(colors),
    reverseTrial: reverseTrial(colors),
    skipLink: skipLink(colors),
    skills: {
      dropdownAction: {
        delete: colors.red.base,
      },
      progressTag: {
        complete: colors.green.base,
        incomplete: colors.secondary.base,
      },
      progress: {
        full: colors.green.base,
        half: colors.orange.base,
        empty: colors.red.base,
      },
      progressLight: {
        full: colors.green.light,
        half: colors.orange.light,
        low: colors.red.light,
        empty: colors.secondary.light,
      },
      tag: {
        inactive: {
          backgroundColor: colors.secondary.base,
          textColor: colors.white,
        },
      },
      questions: {
        disabledColor: colors.secondary.base,
      },
      separator: colors.secondary.light,
    },
  };
};
