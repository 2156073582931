import React, { FC, memo } from "react";

import { SerializedStyles } from "@emotion/react";
import { Text, Tooltip } from "@epignosis_llc/gnosis";
import { DateExpiredSVG, DateNeutralSVG, DateNotExpiredSVG } from "@epignosis_llc/gnosis/icons";
import { isPast } from "date-fns";

import { expirationDateWrapper } from "@views/Course/CourseUsers/styles";

import { useApplyTranslations } from "@hooks";
import { formatUtcDate } from "@utils/helpers";

import { EmptyCell } from "@components";

export type DateCellProps = {
  expirationDate: string | null;
  hasIcon?: boolean;
  completionStatus?: string;
  completionDate?: string | null;
};

type IconContainerProps = {
  hasExpired: boolean;
  inProgress: boolean;
  expirationDate: string;
};

const IconContainer: FC<IconContainerProps> = ({ hasExpired, inProgress, expirationDate }) => {
  const { t } = useApplyTranslations();
  const Icon = hasExpired ? DateExpiredSVG : inProgress ? DateNeutralSVG : DateNotExpiredSVG;
  const tooltipContent = hasExpired
    ? t("courses.courseExpired")
    : inProgress
      ? t("courses.courseNotExpiredAndInProgress", {
          date: expirationDate ? formatUtcDate(expirationDate) : "",
        })
      : t("courses.courseCompletedBeforeExpiration");

  return (
    <div className="expiration-date-icon">
      <Tooltip content={tooltipContent} as="div">
        <Icon height={24} />
      </Tooltip>
    </div>
  );
};

function calculateHasExpired(
  expirationDate: string,
  completionDate: string | null | undefined,
): boolean {
  if (!completionDate) return isPast(new Date(expirationDate));

  return expirationDate < completionDate;
}

const DateCell: FC<DateCellProps> = ({
  expirationDate,
  hasIcon,
  completionStatus,
  completionDate,
}) => {
  const hasExpired = expirationDate ? calculateHasExpired(expirationDate, completionDate) : false;

  const inProgress =
    !hasExpired && (completionStatus === "incomplete" || completionStatus === "not_attempted");

  return (
    <div
      css={(theme): SerializedStyles => expirationDateWrapper(theme, { hasExpired, inProgress })}
    >
      {!expirationDate ? (
        <EmptyCell />
      ) : (
        <>
          {hasIcon && (
            <IconContainer
              hasExpired={hasExpired}
              inProgress={inProgress}
              expirationDate={expirationDate}
            />
          )}
          <Text fontSize="sm" weight="400" className="date-button-text">
            {formatUtcDate(expirationDate)}
          </Text>
        </>
      )}
    </div>
  );
};

export default memo(DateCell);
