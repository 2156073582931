import React, { FC } from "react";

import { Heading, Text } from "@epignosis_llc/gnosis";
import { PendingIconSVG } from "@epignosis_llc/gnosis/icons";

import { signUpFormContainer } from "@views/PendingActivation/styles";

import { useApplyTranslations } from "@hooks";

type SignUpFormProps = {
  activation_type: string;
};

const PendingActivation: FC<SignUpFormProps> = ({ activation_type }) => {
  const { t } = useApplyTranslations();
  const isAdminSignUp = activation_type === "admin";

  return (
    <div css={signUpFormContainer}>
      <Heading size="2xl">{t("pendingVerification.registration")}</Heading>
      <div className="form-content">
        <div className="icon">
          <PendingIconSVG />
        </div>
        <Text fontSize="md"> {t("pendingVerification.accountCreation")}</Text>
        <Text fontSize="md">
          {isAdminSignUp
            ? t("pendingVerification.accountActivation")
            : t("pendingVerification.emailActivation")}
        </Text>
      </div>
    </div>
  );
};

export default PendingActivation;
