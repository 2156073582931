import React, { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { Drawer, Loader } from "@epignosis_llc/gnosis";
import { domAnimation, LazyMotion } from "framer-motion";

import { main } from "@layouts/CourseOverviewLayout/styles";

import { ExternalHeader } from "@layouts";

import { useIsExternalCatalog, useIsPublicCourse, useScreenSizeAlert } from "@hooks";

import userRoles from "@constants/userRoles";

import { ScreenSizeAlert } from "@components/ReusableComponents";

const notLearnerRoles = [userRoles.ADMINISTRATOR, userRoles.INSTRUCTOR];
const CourseOverviewLayout: FC = () => {
  const isExternalCatalogView = useIsExternalCatalog();
  const isPublicCourse = useIsPublicCourse();

  const shouldShowScreenSizeAlert = useScreenSizeAlert(notLearnerRoles);

  return (
    <div css={main}>
      <div className="main-content-container">
        {(isExternalCatalogView || isPublicCourse) && <ExternalHeader />}
        <LazyMotion features={domAnimation} strict>
          <Drawer.Root />
          <Suspense fallback={<Loader fullScreen />}>
            <main className="main-content-wrapper">
              {shouldShowScreenSizeAlert ? <ScreenSizeAlert /> : <Outlet />}
            </main>
          </Suspense>
        </LazyMotion>
      </div>
    </div>
  );
};

export default CourseOverviewLayout;
