import { GnosisTheme } from "@epignosis_llc/gnosis";
import Color from "color";

import { getContrastColor, getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): GnosisTheme["button"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    disabled: {
      background: colors.secondary.lighter,
      color: colors.secondary.base,
      borderColor: colors.secondary.base,
    },
    primary: {
      default: {
        background: protectColorContrast("darker", colors.primary.base),
        borderColor: protectColorContrast("darker", colors.primary.base),
        color: getContrastColor(protectColorContrast("darker", colors.primary.base)),
      },
      hover: {
        background: protectColorContrast("darkest", colors.primary.light),
        borderColor: protectColorContrast("darkest", colors.primary.light),
        color: getContrastColor(protectColorContrast("darkest", colors.primary.light)),
      },
      active: {
        background: protectColorContrast("darker", colors.primary.base),
        borderColor: protectColorContrast("darker", colors.primary.base),
        color: getContrastColor(protectColorContrast("darker", colors.primary.base)),
      },
      ghost: {
        background: protectColorContrast(
          "darker",
          Color(colors.primary.lightest).alpha(0.25).string(),
          0.25,
        ),
        hoverColor: protectColorContrast("darkest", colors.primary.base),
        color: protectColorContrast("darker", colors.primary.base),
      },
      outline: {
        color: protectColorContrast("darker", colors.primary.base),
        borderColor: protectColorContrast("darker", colors.primary.base),
      },
      link: {
        color: protectColorContrast("darker", colors.primary.base),
        hoverColor: protectColorContrast("darkest", colors.primary.light),
      },
    },
    secondary: {
      default: {
        background: colors.secondary.lighter,
        borderColor: colors.secondary.lighter,
        color: colors.black,
      },
      hover: {
        background: colors.secondary.base,
        borderColor: colors.secondary.base,
        color: colors.black,
      },
      active: {
        background: colors.secondary.lighter,
        borderColor: colors.secondary.lighter,
        color: colors.black,
      },
      ghost: {
        color: colors.black,
        background: colors.secondary.light,
        hoverColor: colors.black,
      },
      outline: {
        color: colors.secondary.darker,
        borderColor: colors.secondary.dark,
      },
      link: {
        color: colors.black,
        hoverColor: colors.secondary.base,
      },
    },
    danger: {
      default: {
        background: colors.red.base,
        borderColor: colors.red.base,
        color: colors.white,
      },
      hover: {
        background: colors.red.light,
        borderColor: colors.red.light,
        color: colors.white,
      },
      active: {
        background: colors.red.base,
        borderColor: colors.red.base,
        color: colors.white,
      },
      ghost: {
        color: colors.red.base,
        background: colors.red.light,
        hoverColor: colors.white,
      },
      outline: {
        color: colors.red.base,
        borderColor: colors.red.base,
      },
      link: {
        color: colors.red.base,
        hoverColor: colors.red.lightest,
      },
    },
    success: {
      default: {
        background: colors.green.base,
        borderColor: colors.green.base,
        color: colors.white,
      },
      hover: {
        background: colors.green.light,
        borderColor: colors.green.light,
        color: colors.white,
      },
      active: {
        background: colors.green.base,
        borderColor: colors.green.base,
        color: colors.white,
      },
      ghost: {
        color: colors.green.base,
        background: colors.green.light,
        hoverColor: colors.white,
      },
      outline: {
        color: colors.green.base,
        borderColor: colors.green.base,
      },
      link: {
        color: colors.green.base,
        hoverColor: colors.green.lightest,
      },
    },
    // This button will be used only on parent elements with "primary.darker" color as background
    // Need to use this color to determine the contrast
    primaryLight: {
      default: {
        background: Color(colors.primary.lightest).alpha(0.25).string(),
        borderColor: "transparent",
        color: getContrastColor(Color(colors.primary.darker).string()),
      },
      hover: {
        background: Color(colors.primary.lightest).alpha(0.5).string(),
        borderColor: "transparent",
        color: getContrastColor(Color(colors.primary.darker).string()),
      },
      active: {
        background: Color(colors.primary.lightest).alpha(0.5).string(),
        borderColor: Color(colors.primary.lightest).alpha(0.5).string(),
        color: getContrastColor(Color(colors.primary.darker).string()),
      },
      ghost: {
        color: getContrastColor(Color(colors.primary.darker).string()),
        background: Color(colors.primary.lightest).alpha(0.25).string(),
        hoverColor: getContrastColor(Color(colors.primary.darker).string()),
      },
      outline: {
        color: colors.white,
        borderColor: Color(colors.primary.lightest).alpha(0.25).string(),
      },
      link: {
        color: colors.white,
        hoverColor: Color(colors.primary.lightest).alpha(0.5).string(),
      },
    },
    primaryDarker: {
      default: {
        background: colors.primary.darker,
        borderColor: colors.primary.darker,
        color: getContrastColor(colors.primary.darker),
      },
      hover: {
        background: Color(colors.primary.darker).alpha(0.9).string(),
        borderColor: Color(colors.primary.darker).alpha(0.9).string(),
        color: getContrastColor(Color(colors.primary.darker).alpha(0.9).string()),
      },
      active: {
        background: colors.primary.darker,
        borderColor: colors.primary.darker,
        color: getContrastColor(colors.primary.darker),
      },
      ghost: {
        color: getContrastColor(Color(colors.primary.darker).alpha(0.15).string()),
        background: Color(colors.primary.darker).alpha(0.15).string(),
        hoverColor: colors.primary.darker,
      },
      outline: {
        color: colors.primary.darker,
        borderColor: colors.primary.darker,
      },
      link: {
        color: colors.primary.darker,
        hoverColor: colors.primary.base,
      },
    },
    white: {
      default: {
        background: colors.white,
        borderColor: colors.white,
        color: colors.primary.darker,
      },
      hover: {
        background: colors.white,
        borderColor: colors.white,
        color: colors.primary.base,
      },
      active: {
        background: colors.white,
        borderColor: colors.white,
        color: colors.primary.base,
      },
      ghost: {
        color: colors.primary.darker,
        background: colors.white,
        hoverColor: colors.primary.base,
      },
      outline: {
        color: colors.primary.darker,
        borderColor: colors.white,
      },
      link: {
        color: colors.primary.darker,
        hoverColor: colors.primary.base,
      },
    },
    orange: {
      default: {
        background: colors.orange.base,
        borderColor: colors.orange.base,
        color: colors.black,
      },
      hover: {
        background: colors.orange.light,
        borderColor: colors.orange.light,
        color: colors.black,
      },
      active: {
        background: colors.orange.base,
        borderColor: colors.orange.base,
        color: colors.black,
      },
      ghost: {
        color: colors.orange.base,
        background: colors.orange.light,
        hoverColor: colors.black,
      },
      outline: {
        color: colors.black,
        borderColor: colors.orange.light,
      },
      link: {
        color: colors.orange.base,
        hoverColor: colors.primary.base,
      },
    },
  };
};
