import { ThemePlusTypes } from "@styles/types";
import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["carousel"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    dot: {
      activeBackground: protectColorContrast("darker", colors.primary.light),
      inactiveBackground: colors.secondary.light,
    },
  };
};
