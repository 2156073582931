import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";

import {
  createFeedbackTicketPostData,
  FeedbackTicketRes,
} from "@components/ReusableModals/FeedbackModal/types";

export const createFeedbackTicket = async (
  ticket: createFeedbackTicketPostData,
): Promise<FeedbackTicketRes> => {
  const res = await HttpClient.post(ENDPOINTS.help.feedback, ticket);

  return res.data;
};
