import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["recording"] => {
  return {
    audio: {
      backgroundColor: colors.secondary.lighter,
      color: colors.secondary.light,
    },
  };
};
