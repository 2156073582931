import { t } from "@utils/i18n";
import { yup } from "@utils/validation/validation";

export const TicketFormValidationSchema = yup.object().shape({
  subject: yup.string().when("$type", {
    is: (type: "ticket" | "accountManager" | "support") => type !== "support",
    then: yup
      .string()
      .trim()
      .required()
      .max(255, () => t("validationMessages.maxCharacters", { number: 255 })),
    otherwise: yup.string().notRequired(),
  }),
  body: yup
    .string()
    .trim()
    .required()
    .max(10000, () => t("validationMessages.maxCharacters", { number: 10000 })),
});
