import React, { FC, Fragment, memo } from "react";

import { Button } from "@epignosis_llc/gnosis";
import { EllipsisSVG } from "@epignosis_llc/gnosis/icons";

import { actionIconClassNames, actionsClassNames } from "@components/CustomTable/helpers";
import { useApplyTranslations } from "@hooks";

import { tableActionsContainerStyles } from "../styles";

export type ActionsContainerProps = {
  isHovered: boolean;
  actions: React.ReactNode[];
};

const ACTIONS_NUM = 1;

const ActionsContainer: FC<ActionsContainerProps> = ({ actions, isHovered }) => {
  const { t } = useApplyTranslations();
  const filteredActions = actions.filter((action) => action);
  const shouldShowDots = filteredActions.length > ACTIONS_NUM;
  const areActionsVisible = shouldShowDots ? isHovered : true;

  if (!filteredActions?.length) {
    return null;
  }

  return (
    <div css={tableActionsContainerStyles}>
      <div className="table-actions-container">
        <div className={actionsClassNames(areActionsVisible)}>
          {filteredActions?.map((action, index) => (
            <Fragment key={`table-action-${index}`}> {action} </Fragment>
          ))}
        </div>
        {shouldShowDots && (
          <Button
            tabIndex={-1}
            className={actionIconClassNames(isHovered)}
            variant="ghost"
            noGutters
            aria-label={t("general.actionPlural")}
          >
            <EllipsisSVG height={16} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default memo(ActionsContainer);
