import { GnosisTheme } from "@epignosis_llc/gnosis";
import Color from "color";

import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): GnosisTheme["pagination"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    color: colors.primary.base,
    background: protectColorContrast("darker", colors.primary.lightest),
    boxShadowColor: colors.secondary.light,
    hoverBackground: protectColorContrast(
      "darkest",
      Color(colors.primary.lightest).alpha(0.25).string(),
      0.25,
    ),
    dropdownBackground: colors.secondary.lighter,
    emptyState: colors.white,
    textColor: colors.black,
    iconColor: colors.black,
    white: colors.white,
    scrollBar: colors.secondary.light,
  };
};
