import { ThemePlusTypes } from "@styles/types";
import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["general"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    primary: protectColorContrast("darker", colors.primary.base),
  };
};
