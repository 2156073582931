import React, { FC } from "react";
import ContentLoader from "react-content-loader";

import { i18n } from "@utils/i18n";

export type SkeletonListItemProps = {
  thumbnailShape?: "circle" | "rectangle";
};

const SkeletonListItem: FC<SkeletonListItemProps> = ({ thumbnailShape = "circle" }) => {
  const isRTL = i18n.dir() === "rtl";

  return (
    <ContentLoader
      speed={2}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ height: 70, minWidth: "100%", width: "100%" }}
      rtl={isRTL}
    >
      {thumbnailShape === "circle" ? (
        <circle cx="27" cy="27" r="27" />
      ) : (
        <rect x="10" y="10" rx="5" ry="5" width="50" height="50" />
      )}
      <rect x="78" y="8" rx="5" ry="5" width="100%" height="15" />
      <rect x="78" y="31" rx="5" ry="5" width="100%" height="15" />
    </ContentLoader>
  );
};

export default SkeletonListItem;
