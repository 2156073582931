import React, { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { NotFound } from "@views/Errors";

import { useCms } from "@hooks";
import { useConfigurationStore } from "@stores";

import { URLS } from "@constants/urls";

const CmsPublicRoute: FC = () => {
  const location = useLocation();
  const { pageExists } = useCms({ isPublic: true });
  const { domainSettings } = useConfigurationStore();
  const { sso } = domainSettings ?? {};
  const { disable_direct_login: isDirectLoginDisabled } = sso ?? {};

  // If you are in root or the page exists, then it is a valid custom home page link.
  const isValidCustomHomePageLink = location.pathname === URLS.root || pageExists;

  // If the user must login from IDP, then don't show the custom home page.
  if (isDirectLoginDisabled) return <Navigate to={URLS.login} />;

  // Not valid CMS link
  if (!isValidCustomHomePageLink) return <NotFound />;

  // Render CMS page
  return <Outlet />;
};

export default CmsPublicRoute;
