import React from "react";

import { SerializedStyles, useTheme } from "@emotion/react";
import { Button, Loader, Text } from "@epignosis_llc/gnosis";
import { CloseSVG } from "@epignosis_llc/gnosis/icons";

import { notificationStyles } from "@components/AI/AIGenerationNotification/styles";

import { useApplyTranslations } from "@hooks";

import { VerticalDivider } from "@components/ReusableComponents";

interface AIGenerationNotificationProps {
  message?: string;
  onStop: () => void;
  onClose: () => void;
}

export const AIGenerationNotification: React.FC<AIGenerationNotificationProps> = ({
  message,
  onStop,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useApplyTranslations();

  return (
    <div css={(): SerializedStyles => notificationStyles(theme)}>
      <Loader type={"clip"} className={"loader"} size={"md"} />
      {message && (
        <Text dangerouslySetInnerHTML={{ __html: message }} className={"text"} fontSize={"sm"} />
      )}
      <Button className={"stopButton"} variant={"outline"} onClick={onStop} aria-label="Stop">
        {t("ai.notifications.stop")}
      </Button>
      <VerticalDivider height={24} />
      <Button
        color={"primaryDarker"}
        className={"closeButton"}
        onClick={onClose}
        noGutters
        variant="link"
      >
        <CloseSVG height={16} width={16} />
      </Button>
    </div>
  );
};
