import { Column, TableProps } from "@epignosis_llc/gnosis";

import { t } from "@utils/i18n";

import { ConfirmationButtonColor } from "@components/ReusableModals/ConfirmationModal";

import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

const PAGINATION: IPagination = { number: 1, size: 10 };
export const SORTING: TableProps["sorting"] = { column: "name", isDescending: false };
export const DEFAULT_FILTERS: QueryFilter[] = [];

export const DEFAULT_STATE = {
  pagination: PAGINATION,
  sorting: SORTING,
  filters: DEFAULT_FILTERS,
};

export const NAME_HEADER_WIDTH = 200;
export const CATEGORY_HEADER_WIDTH = 150;
export const CODE_HEADER_WIDTH = 150;

export const getCourseColumns = (): Column[] => {
  return [
    {
      accessor: "name",
      cell: t("myCourses.instructor.course"),
      classNames: ["name"],
      headerWidth: NAME_HEADER_WIDTH,
    },
    {
      accessor: "code",
      cell: t("myCourses.instructor.code"),
      classNames: ["code"],
      maxWidth: CODE_HEADER_WIDTH,
      sortOrder: "desc",
    },
    {
      accessor: "category",
      cell: t("myCourses.instructor.category"),
      classNames: ["category"],
      headerWidth: CATEGORY_HEADER_WIDTH,
      maxWidth: CATEGORY_HEADER_WIDTH,
      sortOrder: "desc",
    },
    {
      accessor: "price",
      cell: t("myCourses.instructor.price"),
      classNames: ["price"],
      sortOrder: "desc",
    },
    {
      accessor: "last_updated_on",
      cell: t("myCourses.instructor.lastUpdatedOn"),
      classNames: ["last-updated-on"],
      sortOrder: "desc",
    },
  ];
};

export const actionsColumn = {
  accessor: "actions",
  cell: "",
  classNames: ["hidden"],
  sortableHeader: false,
};

export const coursesMassActionWordings = {
  activate: {
    progressHeader: "general.activate",
    progressMessage: "courses.massActions.activateProgress",
    confirmationBodyTitle: "courses.massActions.activateWarningTitle",
    confirmationBodyText: "courses.massActions.affectedCourses",
    confirmationHeader: "general.activate",
    confirmationButton: "general.activate",
    successMessage: "courses.massActions.activateSuccess",
    limitReachedMessage: "courses.massActions.limitReachedMessage",
  },
  deactivate: {
    progressHeader: "general.deactivate",
    progressMessage: "courses.massActions.deactivateProgress",
    confirmationHeader: "general.deactivate",
    confirmationButton: "general.deactivate",
    successMessage: "courses.massActions.deactivateSuccess",
    confirmationBodyTitle: "courses.massActions.deactivateWarning",
    confirmationBodyText: "courses.massActions.affectedCourses",
    prerequisiteWarningMessage: "courses.massActions.prerequisiteWarning",
  },
  delete: {
    progressHeader: "general.delete",
    progressMessage: "courses.massActions.deleteProgress",
    confirmationHeader: "general.delete",
    confirmationButton: "general.delete",
    confirmationButtonColor: "danger" as ConfirmationButtonColor,
    successMessage: "courses.massActions.deleteSuccess",
    confirmationBodyTitle: "courses.massActions.deleteWarning",
    confirmationBodyText: "courses.massActions.affectedCourses",
    limitReachedMessage: "courses.massActions",
  },
  add_to_category: {
    progressHeader: "courses.massActions.addCourseToCategory",
    progressMessage: "courses.massActions.changeCategoryProgress",
    confirmationHeader: "courses.massActions.addCourseToCategory",
    confirmationButton: "general.apply",
    successMessage: "courses.massActions.changeCategorySuccess",
    confirmationBodyText: "courses.massActions.affectedCourses",
    limitReachedMessage: "courses.massActions",
  },
  add_to_groups: {
    progressHeader: "courses.massActions.addCourseToGroup",
    progressMessage: "courses.massActions.changeGroupProgress",
    confirmationHeader: "courses.massActions.addCourseToGroup",
    confirmationBodyText: "courses.massActions.affectedCourses",
    confirmationButton: "general.apply",
    successMessage: "courses.massActions.addGroupsSuccess",
    confirmationBodyTitle: "courses.massActions.changeWarning",
    limitReachedMessage: "courses.massActions",
  },
  remove_from_groups: {
    progressHeader: "courses.massActions.removeCourseFromGroup",
    progressMessage: "courses.massActions.removeGroupsProgress",
    confirmationHeader: "courses.massActions.removeCourseFromGroup",
    confirmationButton: "general.apply",
    successMessage: "courses.massActions.removeGroupsSuccess",
    confirmationBodyText: "courses.massActions.affectedCourses",
    limitReachedMessage: "courses.massActions",
  },
  add_to_branches: {
    progressHeader: "courses.massActions.addCourseToBranch",
    progressMessage: "courses.massActions.changeBranchProgress",
    confirmationHeader: "courses.massActions.addCourseToBranch",
    confirmationButton: "general.apply",
    successMessage: "courses.massActions.addBranchesSuccess",
    confirmationBodyText: "courses.massActions.affectedCourses",
    limitReachedMessage: "courses.massActions",
  },
  remove_from_branches: {
    progressHeader: "courses.massActions.removeCourseFromBranch",
    progressMessage: "courses.massActions.removeBranchesProgress",
    confirmationHeader: "courses.massActions.removeCourseFromBranch",
    confirmationButton: "general.apply",
    successMessage: "courses.massActions.removeBranchesSuccess",
    confirmationBodyText: "courses.massActions.affectedCourses",
    limitReachedMessage: "courses.massActions",
  },
  show_in_catalog: {
    progressHeader: "general.showOnCatalog",
    progressMessage: "courses.massActions.showOnCatalogProgress",
    confirmationHeader: "general.showOnCatalog",
    confirmationButton: "general.apply",
    successMessage: "courses.massActions.showOnCatalogSuccess",
    confirmationBodyText: "courses.massActions.affectedCourses",
    confirmationBodyTitle: "courses.massActions.showOnCatalogWarning",
  },
  hide_from_catalog: {
    progressHeader: "general.hideFromCatalog",
    progressMessage: "courses.massActions.hideFromCatalogProgress",
    confirmationHeader: "general.hideFromCatalog",
    confirmationButton: "general.apply",
    successMessage: "courses.massActions.hideFromCatalogSuccess",
    confirmationBodyText: "courses.massActions.affectedCourses",
    confirmationBodyTitle: "courses.massActions.hideFromCatalogWarning",
  },
  assign_certification: {
    progressHeader: "courses.massActions.assignCertificate",
    progressMessage: "courses.massActions.assignCertificateProgress",
    confirmationHeader: "courses.massActions.assignCertificate",
    confirmationButton: "general.apply",
    successMessage: "courses.massActions.assignCertificateSuccess",
    confirmationBodyText: "courses.massActions.affectedCourses",
  },
};
