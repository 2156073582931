import { css } from "@emotion/react";

import { mq } from "@utils/breakpoints";

export const leaderBoardContainer = ({ isGamificationDrawer }) => css`
  height: calc(100% - 11rem);
  overflow-y: hidden;

  .loading-container {
    height: 100%;
  }

  .header-container {
    padding: 1.5rem 2rem;
  }

  .content-container {
    width: 100%;
    height: 100%;
    position: relative;

    #content {
      ${isGamificationDrawer && "overflow-y: scroll"};
    }

    .leaderboard-tabs {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      #content {
        padding: 0 2rem;

        ol {
          padding-top: 1rem;
        }
      }

      article {
        height: 100%;

        footer {
          display: flex;
          justify-content: center;
          margin-top: 0.5rem;

          ${mq["sm"]} {
            justify-content: flex-end;
            margin-inline-end: 1.5rem;
            margin-top: 0.25rem;
          }
        }
      }
    }
  }

  nav {
    padding: 0 2rem;
  }

  .skeleton-wrapper {
    padding: 2rem;
  }
`;

export const gamificationDrawerContainer = ({ actionDrawer }) => css`
  header {
    border-bottom: 1px solid ${actionDrawer.border};
  }

  footer {
    border-top: 1px solid ${actionDrawer.border};
    padding: 1rem 2rem;

    .footer-container {
      display: flex;
      justify-content: flex-end;
    }
  }

  #drawer-dialog > div:first-of-type {
    overflow-y: clip;
  }
`;

export const gamificationLeaderboardContainer = ({ achievementsStats }) => css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0.5rem;
  border: 1px solid ${achievementsStats.borderColor};
  border-radius: 0.3125rem;

  .stat-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    margin: 1rem 0.5rem;

    .stat-value {
      align-self: center;
      font-weight: 700;
    }
  }

  ${mq["lg"]} {
    margin: 1.5rem 2.5rem;

    .stat-container {
      margin: 1.75rem 3rem 1rem 3rem;
    }
  }
`;

export const pointsContainer = ({ achievementsStats }) => css`
  list-style: none;
  padding: 0;
  margin: 0;

  ul {
    padding-inline-start: 1rem;

    .list-item {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      .points-badge {
        background-color: ${achievementsStats.drawer.pointsBadgeBackground};
        border-radius: 0.75rem;
        padding: 0.25rem 0.75rem;
        margin-right: 0.5rem;
        text-align: center;
      }
    }
  }
`;

export const userBadgesDrawerContainer = ({ achievementsStats, actionDrawer }) => css`
  .user-item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: 0 0.5rem;
    border-bottom: 1px solid ${achievementsStats.drawer.badgeContainerBorder};
    padding-bottom: 1rem;
  }

  &:not(:last-child) {
    margin-block: 0 0.5rem;
  }

  .empty-state-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    svg {
      margin-top: 3rem;
    }
  }

  .container {
    display: flex;
    align-items: center;
    flex: 5;
    padding: 0.5rem 1rem 0.5rem 0.5rem;

    .tooltip {
      max-width: 10rem;
      word-break: break-word;
    }
    .info-value-container {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      .info {
        margin-inline-start: 1rem;
        flex: 4;

        div {
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 15.625rem;
        }

        p {
          margin-bottom: 0;
        }

        span {
          text-align: start;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
        }
      }
    }
  }

  .badges-container {
    margin-top: 2rem;

    .header-text {
      border-bottom: 1px solid ${actionDrawer.border};
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
    .badges-container {
      margin-bottom: 1.5rem;
      gap: 0.75rem;
    }
  }

  .stats {
    margin-inline-end: 1rem;
    align-items: center;
    display: flex;
    gap: 1rem;
  }

  &.user-background {
    background: #e1eefb;
    border-radius: 0.625rem;
  }

  .image-container {
    display: flex;
    align-items: center;

    .ranking-number {
      width: 1.5rem;
      height: 1.5rem;
      text-align: center;
      margin-right: 0.5rem;
      margin: 0 0.5rem 0.5rem 0;
    }

    .avatar-container {
      span,
      img {
        height: 3.125rem;
        width: 3.125rem;
        margin-inline: 1rem 0;
        margin-block: 0.375rem;
      }
    }
  }
`;

export const rewardsTabContainer = ({ rewardsTabWidget, achievementsStats }) => css`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  .my-reward-wrapper {
    overflow-y: auto;
    padding: 0 1.5rem 0.5rem 2rem;

    .reward-container {
      padding: 1rem;
      margin-bottom: 1rem;
      border: 1px solid ${achievementsStats.borderColor};
      border-radius: 0.3125rem;

      .text-container {
        display: flex;
        font-size: 0.75rem;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .avatar {
          margin-right: 0.5rem;
          height: 1rem;

          span {
            background-color: ${rewardsTabWidget.iconBackgroundColor};
          }
        }

        #percentage-icon {
          margin: 0 0 0 -0.5rem;
          height: 2rem;
        }

        .text-icon-container {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }

      .progress-text-container {
        display: block;
      }

      ${mq["md"]} {
        .progress-bar-container {
          display: block;
          width: 100%;
        }

        .progress-text-container {
          display: none;
        }
      }

      .reward-label {
        margin: 0.5rem 0;
        width: 100%;
        font-style: italic;
      }

      .progress-bar {
        margin-bottom: 1rem;
        height: 12.5rem;

        ${mq["sm"]} {
          height: 100%;
          min-height: 16.25rem;
        }
      }
    }
  }

  .global-discount {
    max-width: 15.625rem;
    text-align: center;
    margin: 0 2rem 1rem;
    color: ${rewardsTabWidget.drawer.globalDiscountColor};
    align-self: flex-start;
  }
`;

export const rewardProgressBarContainer = ({ courseCard }, { percent, isProgressNumber }) => css`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background: ${courseCard.cardFooter.background};
  height: 2rem;
  border-radius: 0.3125rem;
  width: 100%;
  justify-content: ${isProgressNumber ? "flex-start" : "center"};

  .percentage-bar {
    width: ${percent}%;
    background: ${courseCard.progressBar.percentageColor};
    height: 0.625rem;
    border-radius: 0 0.375rem 0.375rem 0;
  }

  .completion-container {
    display: flex;
    align-items: center;
  }

  .label {
    margin-inline-end: 0.5rem;
  }
`;
