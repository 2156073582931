// Packages or third-party libraries
import React, { FC, HTMLAttributes, useRef } from "react";

import { SerializedStyles } from "@emotion/react";
import { Tooltip } from "@epignosis_llc/gnosis";
import classNames from "classnames";

// Styles
import { listItemStyles } from "@components/ToC/components/styles";

// Hooks
import { useIsOverflow } from "@hooks";

export type ListItemProps = HTMLAttributes<HTMLElement> & {
  text: string | JSX.Element;
  icon?: JSX.Element;
  active?: boolean;
  disabled?: boolean;
  actions?: JSX.Element;
  readonly?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
};

const contentClassNames = (active: boolean): string => classNames({ "is-active": active });

const ListItem: FC<ListItemProps> = ({
  text,
  icon,
  active = false,
  disabled = false,
  actions,
  readonly = false,
  onClick,
  children,
  tabIndex = 0,
  ...rest
}) => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const overflowActive = useIsOverflow(ref);

  const handleIconClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
  };

  const handleChildrenClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
  };

  const handleClick = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ): void => {
    e.stopPropagation();
    !disabled && onClick?.(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
    e.stopPropagation();
    if ("key" in e && e.key === "Enter") {
      !disabled && handleClick?.(e);
    }
  };

  return (
    <>
      <dt className="screen-reader-only">{text}</dt>
      <dd
        css={(theme): SerializedStyles => listItemStyles(theme, { disabled, readonly })}
        className={contentClassNames(active)}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        {...rest}
      >
        <div className="list-item-wrapper">
          <span className="list-item-content">
            {icon ? (
              <div className="list-item-icon" onClick={handleIconClick}>
                {icon}
              </div>
            ) : null}
            <div className="list-item">
              <Tooltip content={text} disabled={!overflowActive}>
                <span tabIndex={tabIndex} className="list-item-text" ref={ref}>
                  {text}
                </span>
              </Tooltip>
              {actions}
            </div>
          </span>

          <div className="list-item-children" onClick={handleChildrenClick}>
            {children}
          </div>
        </div>
      </dd>
    </>
  );
};

export default ListItem;
