import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { AxiosError } from "axios";

import { getAutologinURL, getUserProfile } from "@api/app";
import { getGamificationSettings } from "@api/gamification";
import { useConfigurationStore, useUIStore } from "@stores";
import { deleteCookie, generalNotification, setCookie } from "@utils/helpers";
import authService from "@utils/services/AuthService";

import queryKeys from "@constants/queryKeys";

import { UserRes } from "types/responses";

const { getState } = useConfigurationStore;
const { setGamificationSettings } = getState();

export const useGetUserProfile = <T = UserRes>(
  options: UseQueryOptions<UserRes, unknown, T>,
): UseQueryResult<T, unknown> =>
  useQuery<UserRes, unknown, T>(queryKeys.userProfile, getUserProfile, options);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useGetGamificationSettings(): any {
  return useMutation(queryKeys.gamificationSettings, getGamificationSettings, {
    onSuccess: (res) => setGamificationSettings(res._data),
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useGetAutologinURL = (): any => {
  const queryClient = useQueryClient();
  const setShowRedirectLoading = useUIStore((state) => state.setShowRedirectLoading);

  return useMutation([queryKeys.autologin], () => getAutologinURL(), {
    onSuccess: (res) => {
      // Clear tokens before redirect user to talent app
      queryClient.clear();
      authService.removeTokens();
      authService.removeRole();

      // Set preferable platform to be TalentLMS
      deleteCookie("preferable_platform");
      setCookie("preferable_platform", "normal", "/");

      window.location.replace(res.url);
    },
    onError: (error: AxiosError) => {
      setShowRedirectLoading(false);

      if (error.response?.status === 403) {
        generalNotification("error", error.response?.data._errors[0].title);
      }
    },
  });
};
