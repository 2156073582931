import React, { FC, Suspense, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Drawer, Loader } from "@epignosis_llc/gnosis";
import classNames from "classnames";
import { domAnimation, domMax, LazyMotion } from "framer-motion";

import { content, main } from "@layouts/DashboardLayout/styles";

import { GamificationDrawer, MainDrawer, MainHeader } from "@layouts/DashboardLayout/components";

import { resolveCurrentSection } from "@components/ReusableModals/FeedbackModal/helpers";
import { useScreenSizeAlert } from "@hooks";
import { useConfigurationStore, useUIStore } from "@stores";
import { isValidPathname } from "@utils/helpers/url";

import { rootURLS, URLS } from "@constants/urls";
import userRoles from "@constants/userRoles";

import { MainNav } from "@components";
import { Announcements, ScreenSizeAlert } from "@components/ReusableComponents";
import FeedbackModal from "@components/ReusableModals/FeedbackModal/FeedbackModal";

type DashboardLayoutProps = {
  noMainContentPadding?: boolean;
};

const notLearnerRoles = [userRoles.ADMINISTRATOR, userRoles.INSTRUCTOR];
const DashboardLayout: FC<DashboardLayoutProps> = ({ noMainContentPadding = false }) => {
  const contentContainerClassNames = classNames("main-inner-container", {
    "no-padding": noMainContentPadding,
  });
  const dashboardClassNames = (isDashboard?: boolean): string =>
    classNames("main-page-container", {
      "widgets-dashboard": isDashboard,
    });

  const location = useLocation();
  const pathname = location.pathname;

  const isDashboard = pathname.includes(URLS.dashboard);
  const shouldShowScreenSizeAlert = useScreenSizeAlert(notLearnerRoles);

  const { setActiveTab } = useUIStore();
  const findPath = rootURLS.find((url) => pathname.includes(url));
  const isValid = isValidPathname(pathname, rootURLS);

  const { userProfileData } = useConfigurationStore();
  const showFeedbackModal = userProfileData?.show_feedback_modal ?? false;
  const skippedFeedback = userProfileData?.feedback_skipped ?? false;
  const visibleFeedbackPages = userProfileData?.visible_feedback_pages ?? [];
  const currentSection = resolveCurrentSection(false);
  const isFeedbackVisible =
    showFeedbackModal && visibleFeedbackPages.includes(currentSection) && !skippedFeedback;

  useEffect(() => {
    isValid ? setActiveTab(pathname) : findPath ? setActiveTab(findPath) : "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div css={main}>
      <MainHeader />
      <div className={dashboardClassNames(isDashboard)}>
        <LazyMotion features={domMax} strict>
          <MainNav />
        </LazyMotion>
        <LazyMotion features={domAnimation} strict>
          <Drawer.Root />
          <MainDrawer />
          <GamificationDrawer />
          {isFeedbackVisible && <FeedbackModal />}

          <Suspense fallback={<Loader fullScreen />}>
            <div
              role="main"
              id="scroll-container"
              css={content}
              className={contentContainerClassNames}
            >
              <Announcements />
              {shouldShowScreenSizeAlert ? <ScreenSizeAlert /> : <Outlet />}
            </div>
          </Suspense>
        </LazyMotion>
      </div>
    </div>
  );
};

export default DashboardLayout;
