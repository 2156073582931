import React, { FC, MouseEvent } from "react";
import { useMutation, useQueryClient } from "react-query";

import { Button } from "@epignosis_llc/gnosis";
import { RotateLeftSVG } from "@epignosis_llc/gnosis/icons";

import { restartOnboardingTour } from "@api/onboardingTour";
import { useApplyTranslations } from "@hooks";
import { useConfigurationStore, useUIStore, useUserPreferencesStore } from "@stores";

import localStorageKeys from "@constants/localStorageKeys";
import queryKeys from "@constants/queryKeys";

type HelpCenterFooterProps = {
  toggleDrawer?: () => void;
};
const HelpCenterFooter: FC<HelpCenterFooterProps> = ({ toggleDrawer }) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const { hideMainDrawer } = useUIStore((state) => state);
  const useUserStore = useUserPreferencesStore();
  const { resetSettings } = useUserStore() ?? {};
  const { domainSettings, userProfileData } = useConfigurationStore();
  const isAccountOwner = userProfileData?.is_owner;
  const domainSettingsLocale = domainSettings?.locale;
  const localStorageLocale = localStorage.getItem(localStorageKeys.LANGUAGE_LOCALE);

  const goToKnowledgeBase = (e: MouseEvent): void => {
    e.preventDefault();

    const baseUrl = "https://help.talentlms.com/hc/";
    const knowledgeBasePath = "categories/9466737069468-TalentLMS-Knowledge-Base";

    const urlLocale =
      domainSettingsLocale === "es-ES" || localStorageLocale === "es-ES" ? "es" : "en-us";

    const fullUrl = `${baseUrl}${urlLocale}/${knowledgeBasePath}`;

    // Reset userPreference for table states
    resetSettings();
    hideMainDrawer();
    toggleDrawer && toggleDrawer();

    // Open the help center in a new tab
    window.open(fullUrl, "_blank");
  };

  const { mutate: restartTour, isLoading: isRestartTourLoading } = useMutation(
    [queryKeys.onboarding.restartTour],
    () => restartOnboardingTour(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.userProfile]);
        hideMainDrawer();
      },
    },
  );

  const handleRestartTour = (): void => {
    restartTour();
  };

  return (
    <div className="help-footer-buttons">
      <Button onClick={goToKnowledgeBase}>{t("helpCenter.goToKB")}</Button>
      {isAccountOwner && (
        <Button
          variant="outline"
          iconBefore={RotateLeftSVG}
          onClick={handleRestartTour}
          isLoading={isRestartTourLoading}
        >
          {t("onboarding.restartTour")}
        </Button>
      )}
    </div>
  );
};

export default HelpCenterFooter;
