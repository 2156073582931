import Color from "color";

import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["copyToClipboard"] => {
  return {
    hoverColor: colors.primary.base,
    wrapperHoverColor: Color(colors.primary.lightest).alpha(0.25).string(),
  };
};
