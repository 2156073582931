import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";

import { AnnouncementsRes } from "types/responses";

export const getAnnouncements = async (): Promise<AnnouncementsRes> => {
  const res = await HttpClient.get(ENDPOINTS.announcements.announcements);

  return res.data;
};

export const putAnnouncementsClose = async (): Promise<void> => {
  await HttpClient.put(ENDPOINTS.announcements.close);
};
