/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

import { config } from "@config";

const INRGORED_ERROR_CODES = [400, 401, 403, 404, 409];

export const logAxiosError = (error: AxiosError): void => {
  // Log error to sentry
  if (config.isStaging() || config.isProd()) {
    const status = error?.response?.status;

    if (status && !INRGORED_ERROR_CODES.includes(status)) {
      Sentry.captureException(error);
    }

    // Log error in console
  } else {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log("Error", error.message);
    }
  }
};
