import yup from "@utils/validation/yup-extended";

export const UsersSettingsSchema = yup.object().shape({
  terms_of_service: yup.lazy((value) => {
    switch (typeof value) {
      case "string":
        return yup.string().max(35000); // Schema for string
      default:
        return yup.mixed(); // Here you can decide what is the default
    }
  }),
});
