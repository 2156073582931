// Gnosis theme components
import { GnosisTheme } from "@epignosis_llc/gnosis";

import alert from "@styles/themes/gnosis/alert";
import badge from "@styles/themes/gnosis/badge";
import breadcrumbs from "@styles/themes/gnosis/breadcrumbs";
import button from "@styles/themes/gnosis/button";
import card from "@styles/themes/gnosis/card";
import chip from "@styles/themes/gnosis/chip";
import drawer from "@styles/themes/gnosis/drawer";
import dropdown from "@styles/themes/gnosis/dropdown";
import formElements from "@styles/themes/gnosis/formElements";
import loader from "@styles/themes/gnosis/loader";
import modal from "@styles/themes/gnosis/modal";
import pagination from "@styles/themes/gnosis/pagination";
import progressBar from "@styles/themes/gnosis/progressBar";
import result from "@styles/themes/gnosis/result";
import scrollbar from "@styles/themes/gnosis/scrollbar";
import sidebar from "@styles/themes/gnosis/sidebar";
import statusTag from "@styles/themes/gnosis/statusTag";
import table from "@styles/themes/gnosis/table";
import tabs from "@styles/themes/gnosis/tabs";
import tag from "@styles/themes/gnosis/tag";
import tooltip from "@styles/themes/gnosis/tooltip";
import { ColorPaletteTypes } from "@styles/types";

// Initialize the gnosis theme object
export const getGnosisTheme = (
  colors: ColorPaletteTypes,
): Omit<GnosisTheme, "body" | "link" | "typeScaleSizes"> => ({
  alert: alert(colors),
  badge: badge(colors),
  button: button(colors),
  breadcrumbs: breadcrumbs(colors),
  card: card(colors),
  chip: chip(colors),
  drawer: drawer(colors),
  dropdown: dropdown(colors),
  formElements: formElements(colors),
  loader: loader(colors),
  modal: modal(colors),
  progressBar: progressBar(colors),
  result: result(colors),
  sidebar: sidebar(colors),
  tabs: tabs(colors),
  table: table(colors),
  tag: tag(colors),
  tooltip: tooltip(colors),
  pagination: pagination(colors),
  scrollbar: scrollbar(colors),
  statusTag: statusTag(colors),
});
