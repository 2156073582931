import { Column, TableProps } from "@epignosis_llc/gnosis";

import { t } from "@utils/i18n";

import { QueryFilter, SelectOption } from "types/common";
import { Pagination as IPagination } from "types/responses";

export const DEFAULT_FILTERS: QueryFilter[] = [];
export const SORTING: TableProps["sorting"] = { column: "name", isDescending: false };
export const PAGINATION: IPagination = { number: 1, size: 10 };

export const getNotificationsOverviewColumns = (): Column[] => {
  return [
    {
      accessor: "name",
      cell: t("general.name"),
      classNames: ["name"],
      headerWidth: 400,
      sortOrder: "desc" as Column["sortOrder"],
    },
    {
      accessor: "event_name",
      cell: t("general.event"),
      classNames: ["event"],
      headerWidth: 200,
      maxWidth: 200,
    },
    {
      accessor: "recipient_type",
      cell: t("general.recipient"),
      classNames: ["recipient"],
    },
  ];
};

export const actionsColumn = {
  accessor: "actions",
  cell: "",
  classNames: ["hidden"],
  sortableHeader: false,
};

export enum RecipientCondition {
  RelatedUser = "related_user",
  AccountOwner = "account_owner",
  SuperAdmins = "super_admins",
  BranchAdmins = "branch_admins",
  CourseLearners = "course_learners",
  CourseInstructors = "course_instructors",
  SpecificRecipients = "specific_recipients",
}

export enum SchedulerConditionEvent {
  UserCreate = "_scheduler_condition_user_create",
  UserSelfRegister = "_scheduler_condition_user_self_register",
  UserAfterSelfRegister = "_scheduler_condition_user_after_self_register",
  UserAfterSignupNoPurchase = "_scheduler_condition_user_after_signup_and_no_purchase",
  UserAfterCreation = "_scheduler_condition_user_after_creation",
  UserAfterCreationNoSignIn = "_scheduler_condition_user_after_creation_and_no_sign_in",
  UserAfterSignupNoSignIn = "_scheduler_condition_user_after_signup_and_no_sign_in",
  UserNotLogin = "_scheduler_condition_user_not_login",
  UserSinceSignInNoCompletion = "_scheduler_condition_user_since_signin_and_no_completion",
  UserDeactivation = "_scheduler_condition_user_deactivation",
  CourseAssignment = "_scheduler_condition_course_assignment",
  CourseSelfAssignment = "_scheduler_condition_course_self_assignment",
  CourseAfterUser = "_scheduler_condition_course_after_add_user",
  CourseBeforeStart = "_scheduler_condition_course_before_start",
  CourseCompletion = "_scheduler_condition_course_completion",
  CourseAfterCompletion = "_scheduler_condition_course_after_completion",
  CourseFailure = "_scheduler_condition_course_failure",
  CourseExpired = "_scheduler_condition_course_expired",
  CourseBeforeExpired = "_scheduler_condition_course_before_expired",
  CertificationAwarded = "_scheduler_condition_certification_awarded",
  CertificationExpired = "_scheduler_condition_certification_expired",
  CertificationBeforeExpired = "_scheduler_condition_certification_before_expired",
  GroupAssignment = "_scheduler_condition_group_assignment",
  BranchAssignment = "_scheduler_condition_branch_assignment",
  AssignmentSubmission = "_scheduler_condition_assignment_submission",
  AssignmentGrading = "_scheduler_condition_assignment_grading",
  IltSessionCreate = "_scheduler_condition_ilt_session_create",
  IltSessionRegistration = "_scheduler_condition_ilt_session_registration",
  IltSessionBeforeStart = "_scheduler_condition_ilt_session_before_start",
  IltGrading = "_scheduler_condition_ilt_grading",
  UserPayment = "_scheduler_condition_user_payment",
  LevelReached = "_scheduler_condition_level_reached",
  CourseRequestSubmission = "_scheduler_condition_course_request_submission",
  CourseRequestApproval = "_scheduler_condition_course_request_approval",
  CourseRequestDecline = "_scheduler_condition_course_request_decline",
}

export const eventOptions = (): SelectOption[] => [
  {
    value: SchedulerConditionEvent.UserCreate,
    label: t("eventsEngine.notifications.addEventOptions.userCreate"),
  },

  {
    value: SchedulerConditionEvent.UserSelfRegister,
    label: t("eventsEngine.notifications.addEventOptions.userSelfRegister"),
  },
  {
    value: SchedulerConditionEvent.UserAfterSelfRegister,
    label: t("eventsEngine.notifications.addEventOptions.userAfterSelfRegister"),
  },
  {
    value: SchedulerConditionEvent.UserAfterSignupNoPurchase,
    label: t("eventsEngine.notifications.addEventOptions.userAfterSignupNoPurchase"),
  },
  {
    value: SchedulerConditionEvent.UserAfterCreation,
    label: t("eventsEngine.notifications.addEventOptions.userAfterCreation"),
  },

  {
    value: SchedulerConditionEvent.UserAfterCreationNoSignIn,
    label: t("eventsEngine.notifications.addEventOptions.userAfterCreationNoSignIn"),
  },
  {
    value: SchedulerConditionEvent.UserAfterSignupNoSignIn,
    label: t("eventsEngine.notifications.addEventOptions.userAfterSignupNoSignIn"),
  },
  {
    value: SchedulerConditionEvent.UserNotLogin,
    label: t("eventsEngine.notifications.addEventOptions.userNotLogin"),
  },
  {
    value: SchedulerConditionEvent.UserSinceSignInNoCompletion,
    label: t("eventsEngine.notifications.addEventOptions.userSinceSignInNoCompletion"),
  },
  {
    value: SchedulerConditionEvent.UserDeactivation,
    label: t("eventsEngine.notifications.addEventOptions.userDeactivation"),
  },
  {
    value: SchedulerConditionEvent.CourseAssignment,
    label: t("eventsEngine.notifications.addEventOptions.courseAssignment"),
  },
  {
    value: SchedulerConditionEvent.CourseSelfAssignment,
    label: t("eventsEngine.notifications.addEventOptions.courseSelfAssignment"),
  },
  {
    value: SchedulerConditionEvent.CourseAfterUser,
    label: t("eventsEngine.notifications.addEventOptions.courseAfterUser"),
  },
  {
    value: SchedulerConditionEvent.CourseBeforeStart,
    label: t("eventsEngine.notifications.addEventOptions.courseBeforeStart"),
  },
  {
    value: SchedulerConditionEvent.CourseCompletion,
    label: t("eventsEngine.notifications.addEventOptions.courseCompletion"),
  },
  {
    value: SchedulerConditionEvent.CourseAfterCompletion,
    label: t("eventsEngine.notifications.addEventOptions.courseAfterCompletion"),
  },
  {
    value: SchedulerConditionEvent.CourseFailure,
    label: t("eventsEngine.notifications.addEventOptions.courseFailure"),
  },
  {
    value: SchedulerConditionEvent.CourseExpired,
    label: t("eventsEngine.notifications.addEventOptions.courseExpired"),
  },
  {
    value: SchedulerConditionEvent.CourseBeforeExpired,
    label: t("eventsEngine.notifications.addEventOptions.courseBeforeExpired"),
  },
  {
    value: SchedulerConditionEvent.CertificationAwarded,
    label: t("eventsEngine.notifications.addEventOptions.certificationAwarded"),
  },
  {
    value: SchedulerConditionEvent.CertificationExpired,
    label: t("eventsEngine.notifications.addEventOptions.certificationExpired"),
  },
  {
    value: SchedulerConditionEvent.CertificationBeforeExpired,
    label: t("eventsEngine.notifications.addEventOptions.certificationBeforeExpired"),
  },
  {
    value: SchedulerConditionEvent.GroupAssignment,
    label: t("eventsEngine.notifications.addEventOptions.groupAssignment"),
  },
  {
    value: SchedulerConditionEvent.BranchAssignment,
    label: t("eventsEngine.notifications.addEventOptions.branchAssignment"),
  },
  {
    value: SchedulerConditionEvent.AssignmentSubmission,
    label: t("eventsEngine.notifications.addEventOptions.assignmentSubmission"),
  },
  {
    value: SchedulerConditionEvent.AssignmentGrading,
    label: t("eventsEngine.notifications.addEventOptions.assignmentGrading"),
  },
  {
    value: SchedulerConditionEvent.IltSessionCreate,
    label: t("eventsEngine.notifications.addEventOptions.iltSessionCreate"),
  },
  {
    value: SchedulerConditionEvent.IltSessionRegistration,
    label: t("eventsEngine.notifications.addEventOptions.iltSessionRegistration"),
  },
  {
    value: SchedulerConditionEvent.IltSessionBeforeStart,
    label: t("eventsEngine.notifications.addEventOptions.iltSessionBeforeStart"),
  },
  {
    value: SchedulerConditionEvent.IltGrading,
    label: t("eventsEngine.notifications.addEventOptions.iltGrading"),
  },
  {
    value: SchedulerConditionEvent.UserPayment,
    label: t("eventsEngine.notifications.addEventOptions.userPayment"),
  },
  {
    value: SchedulerConditionEvent.LevelReached,
    label: t("eventsEngine.notifications.addEventOptions.levelReached"),
  },
  {
    value: SchedulerConditionEvent.CourseRequestSubmission,
    label: t("eventsEngine.notifications.addEventOptions.courseRequestSubmission"),
  },
  {
    value: SchedulerConditionEvent.CourseRequestApproval,
    label: t("eventsEngine.notifications.addEventOptions.courseRequestApproval"),
  },
  {
    value: SchedulerConditionEvent.CourseRequestDecline,
    label: t("eventsEngine.notifications.addEventOptions.courseRequestDecline"),
  },
];

export const filterByCourseOptions = [
  SchedulerConditionEvent.CourseAssignment,
  SchedulerConditionEvent.CourseSelfAssignment,
  SchedulerConditionEvent.CourseAfterUser,
  SchedulerConditionEvent.CourseBeforeStart,
  SchedulerConditionEvent.CourseCompletion,
  SchedulerConditionEvent.CourseAfterCompletion,
  SchedulerConditionEvent.CourseFailure,
  SchedulerConditionEvent.CourseExpired,
  SchedulerConditionEvent.CourseBeforeExpired,
  SchedulerConditionEvent.CertificationAwarded,
  SchedulerConditionEvent.CertificationExpired,
  SchedulerConditionEvent.CertificationBeforeExpired,
  SchedulerConditionEvent.AssignmentSubmission,
  SchedulerConditionEvent.AssignmentGrading,
  SchedulerConditionEvent.IltGrading,
  SchedulerConditionEvent.IltSessionCreate,
  SchedulerConditionEvent.IltSessionRegistration,
  SchedulerConditionEvent.IltSessionBeforeStart,
];

export const filterByBranchOptions = [
  SchedulerConditionEvent.UserCreate,
  SchedulerConditionEvent.UserSelfRegister,
  SchedulerConditionEvent.UserAfterSelfRegister,
  SchedulerConditionEvent.UserAfterSignupNoPurchase,
  SchedulerConditionEvent.UserAfterCreation,
  SchedulerConditionEvent.UserAfterCreationNoSignIn,
  SchedulerConditionEvent.UserAfterSignupNoSignIn,
  SchedulerConditionEvent.UserNotLogin,
  SchedulerConditionEvent.UserDeactivation,
  SchedulerConditionEvent.BranchAssignment,
];

export const filterByGroupsOptions = [SchedulerConditionEvent.GroupAssignment];

export const recurringOptionsData = (): SelectOption[] =>
  [
    { label: t("general.dayCapital_other"), value: "days" },
    { label: t("general.week_other"), value: "weeks" },
  ] as SelectOption[];

export const requiredHoursEvents = [
  SchedulerConditionEvent.UserAfterSelfRegister,
  SchedulerConditionEvent.UserAfterSignupNoPurchase,
  SchedulerConditionEvent.UserAfterCreation,
  SchedulerConditionEvent.UserAfterCreationNoSignIn,
  SchedulerConditionEvent.UserAfterSignupNoSignIn,
  SchedulerConditionEvent.CourseAfterCompletion,
  SchedulerConditionEvent.UserDeactivation,
  SchedulerConditionEvent.CourseBeforeStart,
  SchedulerConditionEvent.CourseBeforeExpired,
  SchedulerConditionEvent.CertificationBeforeExpired,
  SchedulerConditionEvent.IltSessionBeforeStart,
  SchedulerConditionEvent.UserNotLogin,
  SchedulerConditionEvent.UserSinceSignInNoCompletion,
  SchedulerConditionEvent.CourseAfterUser,
];
