import { css } from "@emotion/react";

import { mq } from "@utils/breakpoints";

export const certificateOptionStyles = css`
  .preview-btn-grid-item {
    position: relative;

    .preview-btn {
      position: absolute;
      bottom: 0;
    }
  }
`;

export const templatePreviewStyles = ({ courseEdit: { certificatePreview } }, { minHeight }) => css`
  background: ${certificatePreview.backgroundColor};
  border: 1px solid ${certificatePreview.borderColor};
  border-radius: 5px;
  padding: 0.75rem;
  position: relative;

  ${mq["lg"]} {
    padding: 0.75rem 4rem;
  }

  .pdf-container {
    width: 100%;
    height: 100%;
    min-height: ${minHeight ? `${minHeight}px` : "260px"};

    ${mq["lg"]} {
      min-height: ${minHeight ? `${minHeight}px` : "350px"};
    }
  }

  button {
    position: absolute;
    top: 0.75rem;
    inset-inline-end: 0.75rem;
  }
`;

export const UnitWeightsStyles = ({ courseEdit: { weights } }) => css`
  border: 1px solid ${weights.borderColor};
  border-radius: 5px;
  padding: 0.75rem 0;

  .btns-container {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 0.5rem;
    margin: 0 0.75rem;
  }

  .score-unit-container {
    .weight-percentage {
      background-color: ${weights.percentageBackgroundColor};
      color: ${weights.percentageColor};
      padding: 0.25rem 0.5rem;
    }

    .line {
      width: auto;
      margin: 0.25rem 0.75rem 0.75rem;
    }
  }

  .overall {
    margin: 0 0.75rem;
  }
`;
