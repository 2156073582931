import React, { FC, MouseEvent, ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Trans } from "react-i18next";

import { Button, Checkbox, InputError, Select, Text, Textarea } from "@epignosis_llc/gnosis";

import { feedbackModalStyles } from "@components/ReusableModals/FeedbackModal/FeedbackModal.styles";

import { resolveCurrentSection } from "@components/ReusableModals/FeedbackModal/helpers";
import { useApplyTranslations } from "@hooks";
import { useConfigurationStore } from "@stores";
import permissions from "@utils/permissions";

import { getFeedbackCategoriesOptions } from "@components/ReusableModals/FeedbackModal/constants";
import userRoles from "@constants/userRoles";

import { createFeedbackTicketPostData } from "@components/ReusableModals/FeedbackModal/types";

import { SelectOption } from "types/common";

type FeedbackFormProps = {
  isLoading: boolean;
  isValid: boolean;
  onSubmit: (data: createFeedbackTicketPostData) => void;
  handleOnClick: (e: MouseEvent<HTMLButtonElement>) => Promise<void>;
};

const FeedbackForm: FC<FeedbackFormProps> = ({ isLoading, isValid, onSubmit, handleOnClick }) => {
  const { t, i18n } = useApplyTranslations();
  const dir = i18n.dir();

  const { canAccessHelp } = permissions.helpCenterPermissions;
  const { userProfileData, domainSettings } = useConfigurationStore();
  const { available_roles: availRoles } = userProfileData ?? {};
  const { main_portal } = domainSettings ?? {};
  const shouldShowReportLink =
    availRoles?.map((role) => role.toString()).includes(userRoles.ADMINISTRATOR) &&
    main_portal &&
    canAccessHelp();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useFormContext<createFeedbackTicketPostData>();

  const feedbackSelectOptions = getFeedbackCategoriesOptions();
  const feedbackTypeLabel = t("feedbackMechanism.selectFeedbackType", {
    page: resolveCurrentSection(),
  });

  return (
    <form
      id="feedback-form"
      className="feedback-form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      css={feedbackModalStyles}
    >
      <div className="feedback-content">
        <Controller
          name="feedback_category"
          control={control}
          render={({ field: { onChange, value } }): ReactElement => {
            const defaultValue = value
              ? feedbackSelectOptions.find((option) => option.value === value)
              : null;
            return (
              <Select
                id="feedback-type-select"
                aria-label={feedbackTypeLabel}
                data-testid="feedback-type"
                label={feedbackTypeLabel}
                options={getFeedbackCategoriesOptions()}
                maxWidth="30rem"
                onChange={(option): void => {
                  const { value } = option as SelectOption;
                  onChange(value);
                }}
                defaultValue={defaultValue}
                isRtl={dir === "rtl"}
                isClearable={false}
                status={errors.feedback_category ? "error" : "valid"}
                required
              />
            );
          }}
        />
        {errors.feedback_category?.message && (
          <InputError>{errors?.feedback_category?.message}</InputError>
        )}

        <div className="feedback-item">
          <Textarea
            {...register("body")}
            className="feedback-textarea"
            id="feedback-message"
            label={t("feedbackMechanism.writeFeedback")}
            placeholder={t("feedbackMechanism.writeFeedbackPlaceholder")}
            required
          />
          {errors.body?.message && <InputError>{errors?.body?.message}</InputError>}

          {shouldShowReportLink && (
            <Text fontSize="sm" className="feedback-message">
              <Trans i18nKey="general.reportBug">
                <Button
                  as="button"
                  id="report-bug-button"
                  data-testid="report-bug-button"
                  aria-label={t("general.reportBug")}
                  variant="link"
                  className="report-link"
                  onClick={handleOnClick}
                  noGutters
                >
                  {t("general.reportBug")}
                </Button>
              </Trans>
            </Text>
          )}

          <Controller
            name="skip_feedback"
            control={control}
            render={({ field: { onChange, value } }): ReactElement => (
              <Checkbox
                id="feedback-dismiss"
                containerAttrs={{ className: "feedback-dismiss-checkbox" }}
                aria-label={t("general.dontShowAgain")}
                name={t("general.dontShowAgain")}
                label={t("general.dontShowAgain")}
                value="feedback-dismiss"
                checked={value}
                onChange={(e): void => onChange(e.target.checked)}
              />
            )}
          />
        </div>
      </div>

      <div className="feedback-footer">
        <Button
          type="submit"
          isLoading={isLoading}
          disabled={!isValid}
          id="submit-feedback-button"
          data-testid="submit-feedback-button"
          aria-label={t("general.submit")}
        >
          {t("general.submit")}
        </Button>
      </div>
    </form>
  );
};

export default FeedbackForm;
