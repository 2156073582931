import React, { FC, HTMLAttributes } from "react";
import { components, MenuProps } from "react-select";

import { Option } from "@components/MainSearch/types";

export type CustomDivProps = HTMLAttributes<HTMLDivElement> & {
  "data-testid"?: string;
};

const CustomMenuContainer: FC<MenuProps<Option>> = ({ children, ...props }) => {
  return (
    <components.Menu
      {...props}
      innerProps={{ ...props.innerProps, "data-testid": "spotlight-menu" } as CustomDivProps}
    >
      {children}
    </components.Menu>
  );
};

export default CustomMenuContainer;
