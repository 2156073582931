import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Text } from "@epignosis_llc/gnosis";

import { upgradeNowButtonStyles } from "@layouts/DashboardLayout/components/TopBarMenu/components/ProfileMenu/components/ReverseTrialUpgradeButtons/UpgradeNowButton/UpgradeNowButton.styles";

import { useApplyTranslations } from "@hooks";

import { URLS } from "@constants/urls";

const UpgradeNowButton: FC = () => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();

  const onClick = (): void => {
    navigate(URLS.subscription.subscription);
  };

  return (
    <Button
      data-testid="upgrade-now-button"
      css={upgradeNowButtonStyles}
      variant="solid"
      color="primaryLight"
      className="upgrade-now-button"
      onClick={onClick}
      aria-label={t("general.upgradeNow")}
    >
      <Text as="p" fontSize="sm" weight="700" className="button-text">
        {t("general.upgradeNow")}
      </Text>
    </Button>
  );
};

export default UpgradeNowButton;
