import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["resultsPage"] => {
  return {
    userResults: {
      color: colors.primary.base,
      hoverColor: colors.primary.lightest,
      svgColor: colors.black,
    },
  };
};
