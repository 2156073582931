import { fetchEventSource } from "@microsoft/fetch-event-source";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import lodash from "lodash";
import { v4 as uuidv4 } from "uuid";

import { requestConfigInterceptor } from "@api/HttpClient";
import { getCurrentDomainWithSchemeAndPort, joinDomainWithPath } from "@utils/helpers/url";

export const isValidArrayIndex = (arr: unknown[], index: number): boolean => {
  return Array.isArray(arr) && index >= 0 && index < arr.length && Number.isInteger(index);
};

export type UnpublishedChangesObject = {
  has_unpublished_changes: boolean;
  state: "draft" | "published";
};

type HostMessageType = "state_update" | "ai_credits_consumed" | "ai_credits_inadequate";

type MessageForHost = {
  message: HostMessageType;
};

type StateUpdateForHost = {
  unit: UnpublishedChangesObject;
  is_working: boolean;
} & MessageForHost;

export class MessageToHost {
  private static readonly MIN_INTERVAL_BETWEEN_UPDATES_MS = 1000;

  static aiCreditsConsumed(): void {
    this.postMessage({ message: "ai_credits_consumed" });
  }

  static aiCreditsExhausted(): void {
    this.postMessage({ message: "ai_credits_inadequate" });
  }

  private static lastHostStateUpdate?: StateUpdateForHost;

  static stateUpdate(unpublishedChanges: UnpublishedChangesObject, isWorking?: boolean): void {
    const message: StateUpdateForHost = {
      message: "state_update",
      unit: {
        state: unpublishedChanges.state,
        has_unpublished_changes: unpublishedChanges.has_unpublished_changes,
      },
      is_working: isWorking ?? this.lastHostStateUpdate?.is_working ?? false,
    };

    if (lodash.isEqual(this.lastHostStateUpdate, message)) {
      return;
    }

    this.lastHostStateUpdate = message;
    this.postMessage(message);
  }

  private static lastSentTime = 0;
  private static lastSentMessageType?: HostMessageType = undefined;

  private static postMessage(message: MessageForHost): void {
    const now = Date.now();
    if (
      (message.message != "state_update" || this.lastSentMessageType == message.message) &&
      now - this.lastSentTime < this.MIN_INTERVAL_BETWEEN_UPDATES_MS
    ) {
      return;
    }

    window.parent.postMessage(JSON.stringify(message), "*");

    this.lastSentTime = now;
    this.lastSentMessageType = message.message;
  }
}

export default class JobsHandler {
  private readonly jobs: string[] = [];

  constructor(private onJobsUpdate: (isWorking: boolean) => void) {}

  isWorking = (): boolean => {
    return this.jobs.length > 0;
  };

  getNewJob = (): string => {
    const uuid = uuidv4();
    this.jobs.push(uuid);
    this.onJobsUpdate(this.isWorking());
    return uuid;
  };

  removeJob = (id: string): void => {
    const index = this.jobs.findIndex((item) => item === id);
    if (isValidArrayIndex(this.jobs, index)) {
      this.jobs.splice(index, 1);
      this.onJobsUpdate(this.isWorking());
    }
  };

  async post(
    client: AxiosInstance,
    endpoint: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    const uuid = this.getNewJob();
    return await client.post(endpoint, data, config).finally(() => {
      this.removeJob(uuid);
    });
  }

  async put(
    client: AxiosInstance,
    endpoint: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    const uuid = this.getNewJob();
    return await client.put(endpoint, data, config).finally(() => {
      this.removeJob(uuid);
    });
  }

  async fetchSSE<T>(
    urlOrPath: string,
    body: unknown,
    update: (result: T | Error | true) => void,
    abortController?: AbortController,
  ): Promise<void> {
    const currentDomain = getCurrentDomainWithSchemeAndPort();
    const url =
      urlOrPath.startsWith("/") && currentDomain !== undefined
        ? joinDomainWithPath(currentDomain, urlOrPath)
        : urlOrPath;

    const uuid = this.getNewJob();
    let hasReceivedFirstMessage = false;
    const axiosAuthConfig: AxiosRequestConfig = await requestConfigInterceptor({ headers: {} });

    return await fetchEventSource(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...axiosAuthConfig.headers,
      },
      credentials: "include",
      body: JSON.stringify(body),
      openWhenHidden: true,
      signal: abortController?.signal,
      onopen: (response: Response) => {
        if (response.status === 402) {
          update(new Error("402"));
        }

        return Promise.resolve();
      },
      onmessage: (message) => {
        if (!hasReceivedFirstMessage) {
          MessageToHost.aiCreditsConsumed();
          hasReceivedFirstMessage = true;
        }

        update(JSON.parse(message.data));
      },
      onerror: (error) => {
        if (axios.isAxiosError(error) && error.response?.status === 402) {
          update(new Error("402"));
          return;
        }

        update(error);
        throw error;
      },
      onclose: () => {
        update(true);
      },
    })
      .then()
      .finally(() => this.removeJob(uuid));
  }
}
