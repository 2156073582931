import { GnosisTheme } from "@epignosis_llc/gnosis";
import Color from "color";

import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): GnosisTheme["chip"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);
  return {
    backgroundColor: protectColorContrast(
      "darker",
      Color(colors.primary.lightest).alpha(0.25).string(),
      0.25,
    ),
    color: colors.black,
  };
};
