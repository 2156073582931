import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["textCard"] => {
  return {
    primary: {
      background: colors.primary.base,
      color: colors.white,
      border: colors.primary.base,
    },
    secondary: {
      background: colors.secondary.lighter,
      color: colors.black,
      border: colors.secondary.light,
    },
  };
};
