import { useTranslation } from "react-i18next";

import { i18n } from "i18next";

import { applyOverrides } from "@utils/i18n";

type TranslationsVariables = {
  [key: string]: string | number | undefined | null;
};

type ApplyTranslationsReturnType = {
  t: (key: string, translationsVariables?: TranslationsVariables) => string;
  i18n: i18n;
};

const useApplyTranslations = (): ApplyTranslationsReturnType => {
  const { t: translate, i18n } = useTranslation();

  const t = (key: string, translationsVariables?: TranslationsVariables): string => {
    const currentTranslation = translate(key, translationsVariables);
    return applyOverrides(currentTranslation);
  };

  return { t, i18n };
};

export default useApplyTranslations;
