import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["upsell"] => {
  return {
    description: colors.black,
    note: colors.secondary.base,
    link: colors.primary.base,
    linkHover: colors.primary.lighter,
  };
};
