import { Column, TableProps } from "@epignosis_llc/gnosis";

import { t } from "@utils/i18n";

import { QueryFilter, SelectOption } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

export enum ReportType {
  Users = "users",
  LearningProgress = "learning_progress",
}

export interface Rule {
  value: string;
  label: string;
  config?: RuleConfiguration;
}

export type ExtraData =
  | "branches"
  | "groups"
  | "courses"
  | "categories"
  | "period"
  | "userTypes"
  | "fieldValue"
  | "points"
  | "level"
  | "anyString"
  | "email";

export interface OutputField {
  label: string;
  value: string;
  default?: boolean;
}

export type RulesType =
  | {
      usersFilters: Rule[];
    }
  | {
      coursesFilters: Rule[];
    };

export interface CustomReportFormData {
  name: string;
  type: ReportType;
  rules: RulesType;
  output: OutputField[];
}

export const getDropdownOptions = (): SelectOption[] => {
  return [
    {
      value: ReportType.Users,
      label: t("reports.customReports.specificUsers"),
    },
    {
      value: ReportType.LearningProgress,
      label: t("reports.customReports.learningProgress"),
    },
  ];
};

export function getUserReportOutputColumns(): {
  label: string;
  value: string;
  default: boolean;
}[] {
  return [
    { label: t("users.user"), value: Columns.User, default: true },
    { label: t("signUp.email"), value: Columns.Email, default: true },
    { label: t("signIn.username"), value: Columns.Username, default: false },
    { label: t("users.userType"), value: Columns.UserType, default: true },
    {
      label: t("reports.customReports.registrationDate"),
      value: Columns.Registration,
      default: true,
    },
    { label: t("reports.customReports.lastLogin"), value: Columns.LastLogin, default: true },
    { label: t("general.points"), value: Columns.Points, default: false },
    {
      label: t("widgetsDashboard.overview.assignedCourses"),
      value: Columns.AssignedCourses,
      default: true,
    },
    {
      label: t("dashboard.leaderboardTabs.completedCourses"),
      value: Columns.CompletedCourses,
      default: true,
    },
    { label: t("microstats.completion_rate"), value: Columns.CompletionRate, default: false },
    { label: t("general.groups_other"), value: Columns.Groups, default: false },
    { label: t("general.branches_other"), value: Columns.Branches, default: false },
    { label: t("general.badges"), value: Columns.Badges, default: false },
    { label: t("general.level"), value: Columns.Level, default: false },
    { label: t("reports.customReports.userId"), value: Columns.UserId, default: false },
    {
      label: t("reports.customReports.excludedFromEmails"),
      value: Columns.ExcludedFromEmails,
      default: false,
    },
    { label: t("general.totalTrainingTime"), value: Columns.TrainingTime, default: false },
  ];
}

export function getLearningProgressReportOutputColumns(): {
  label: string;
  value: string;
  default: boolean;
}[] {
  return [
    { label: t("users.user"), value: Columns.User, default: true },
    { label: t("signUp.email"), value: Columns.Email, default: true },
    { label: t("branches.courses.course"), value: Columns.Course, default: true },
    {
      label: t("reports.customReports.enrolledOn"),
      value: Columns.CourseEnrolledOn,
      default: true,
    },
    { label: t("general.status"), value: Columns.CourseStatus, default: true },
    { label: t("reports.customReports.timeSpent"), value: Columns.CourseTime, default: true },
    {
      label: t("reports.customReports.timeSpentInSeconds"),
      value: Columns.CourseTimeSeconds,
      default: false,
    },
    {
      label: t("reports.customReports.completionDate"),
      value: Columns.CourseCompletionDate,
      default: false,
    },
    {
      label: t("reports.customReports.averageScore"),
      value: Columns.CourseAverageScore,
      default: false,
    },
    {
      label: t("general.category"),
      value: Columns.CourseCategory,
      default: false,
    },
    {
      label: t("reports.customReports.courseExpiration"),
      value: Columns.CourseExpirationDate,
      default: false,
    },
    { label: t("certificates.certificate"), value: Columns.Certification, default: false },
    {
      label: t("reports.customReports.certificateId"),
      value: Columns.CertificationId,
      default: false,
    },
    {
      label: t("reports.customReports.certificateDate"),
      value: Columns.CertificationDate,
      default: false,
    },
    {
      label: t("reports.customReports.certificateExpiration"),
      value: Columns.CertificationExpiration,
      default: false,
    },
    {
      label: t("reports.customReports.remainingTime"),
      value: Columns.RemainingTime,
      default: false,
    },
    { label: t("signIn.username"), value: Columns.Username, default: false },
    { label: t("reports.customReports.lastLogin"), value: Columns.LastLogin, default: false },
    { label: t("general.points"), value: Columns.Points, default: false },
    { label: t("general.groups_other"), value: Columns.Groups, default: false },
    { label: t("general.branches_other"), value: Columns.Branches, default: false },
    { label: t("general.level"), value: Columns.Level, default: false },
    { label: t("reports.customReports.userId"), value: Columns.UserId, default: false },
    { label: t("reports.customReports.courseId"), value: Columns.CourseId, default: false },
    {
      label: t("reports.customReports.excludedFromEmails"),
      value: Columns.ExcludedFromEmails,
      default: false,
    },
  ];
}

export interface RuleConfiguration {
  label: string;
  component?: ComponentType;
  extraData?: ExtraData;
  hasNextXDaysWording?: boolean;
}

export type ComponentType = "TextInput" | "Select" | "DateRange" | "FieldValue";

export function getUsersRuleConfigurations(): Record<string, RuleConfiguration> {
  return {
    belongs_to_branch: {
      label: t("reports.customReports.belongToBranch"),
      component: "Select",
      extraData: "branches",
    },
    not_belongs_to_branch: {
      label: t("reports.customReports.doNotBelongToBranch"),
      component: "Select",
      extraData: "branches",
    },
    belongs_to_group: {
      label: t("reports.customReports.belongToGroup"),
      component: "Select",
      extraData: "groups",
    },
    not_belongs_to_group: {
      label: t("reports.customReports.doNotBelongToGroup"),
      component: "Select",
      extraData: "groups",
    },
    has_course: {
      label: t("reports.customReports.haveTheCourse"),
      component: "Select",
      extraData: "courses",
    },
    has_not_course: {
      label: t("reports.customReports.doNotHaveTheCourse"),
      component: "Select",
      extraData: "courses",
    },
    assigned_course_within_period: {
      label: t("reports.customReports.haveBeenEnrolledToCourse"),
      component: "DateRange",
      extraData: "period",
    },
    completed_course: {
      label: t("reports.customReports.haveCompletedTheCourse"),
      component: "Select",
      extraData: "courses",
    },
    not_completed_course: {
      label: t("reports.customReports.haveNotCompletedTheCourse"),
      component: "Select",
      extraData: "courses",
    },
    completed_course_within_period: {
      label: t("reports.customReports.haveCompletedTheCourseWithin"),
      component: "DateRange",
      extraData: "period",
    },
    not_completed_course_within_period: {
      label: t("reports.customReports.haveNotCompletedTheCourseWithin"),
      component: "DateRange",
      extraData: "period",
    },
    started_uncompleted_course: {
      label: t("reports.customReports.haveStartedButNotCompletedCourse"),
      component: "Select",
      extraData: "courses",
    },
    course_expired: {
      label: t("reports.customReports.courseExpired"),
      component: "Select",
      extraData: "courses",
    },
    uncompleted_expiring_course_within_period: {
      label: t("reports.customReports.haveNotCompletedCourseExpiring"),
      component: "DateRange",
      extraData: "period",
      hasNextXDaysWording: true,
    },
    has_course_certification: {
      label: t("reports.customReports.haveCertificateForCourse"),
      component: "Select",
      extraData: "courses",
    },
    has_expired_course_certification: {
      label: t("reports.customReports.haveExpiredCertificateForCourse"),
      component: "Select",
      extraData: "courses",
    },
    has_expiring_certification_within_period: {
      label: t("reports.customReports.haveExpiringCertificate"),
      component: "DateRange",
      extraData: "period",
      hasNextXDaysWording: true,
    },
    earned_certification_within_period: {
      label: t("reports.customReports.earnedCertificateWithin"),
      component: "DateRange",
      extraData: "period",
    },
    logged_in_within_period: {
      label: t("reports.customReports.loggedInWithin"),
      component: "DateRange",
      extraData: "period",
    },
    not_logged_within_period: {
      label: t("reports.customReports.haveNotLoggedInWithin"),
      component: "DateRange",
      extraData: "period",
    },
    signed_up_within_period: {
      label: t("reports.customReports.signedUpWithin"),
      component: "DateRange",
      extraData: "period",
    },
    has_user_type: {
      label: t("reports.customReports.hasUserType"),
      component: "Select",
      extraData: "userTypes",
    },
    has_specific_custom_field_value: {
      label: t("reports.customReports.hasSpecificCustomField"),
      component: "FieldValue",
      extraData: "fieldValue",
    },
    is_active: {
      label: t("reports.customReports.isActive"),
    },
    is_inactive: {
      label: t("reports.customReports.isInactive"),
    },
    has_points: {
      label: t("reports.customReports.hasPoints"),
      component: "TextInput",
      extraData: "points",
    },
    reached_level_or_higher: {
      label: t("reports.customReports.reachedLevelOrHigher"),
      component: "TextInput",
      extraData: "level",
    },
    not_accepted_tos: {
      label: t("reports.customReports.notAcceptedTos"),
    },
    accepted_tos: {
      label: t("reports.customReports.acceptedTos"),
    },
    is_excluded_from_emails: {
      label: t("reports.customReports.areExcludedFromEmails"),
    },
    email_contains: {
      label: t("reports.customReports.emailContains"),
      component: "TextInput",
      extraData: "email",
    },
  };
}

export function getLearningProgressRuleConfigurations(): Record<string, RuleConfiguration> {
  return {
    learning_progress_system_decide: {
      label: t("reports.customReports.learningProgressSystemDecide"),
    },
    learning_progress_belongs_to_branches: {
      label: t("reports.customReports.learningProgressBelongsToBranches"),
      component: "Select",
      extraData: "branches",
    },
    learning_progress_belongs_to_categories: {
      label: t("reports.customReports.learningProgressBelongsToCategories"),
      component: "Select",
      extraData: "categories",
    },
    learning_progress_belongs_to_groups: {
      label: t("reports.customReports.learningProgressBelongsToGroups"),
      component: "Select",
      extraData: "groups",
    },
    learning_progress_belongs_to_list: {
      label: t("reports.customReports.learningProgressBelongsToList"),
      component: "Select",
      extraData: "courses",
    },
  };
}

const PAGINATION: IPagination = { number: 1, size: 100 };
const CUSTOM_PAGINATION: IPagination = { number: 1, size: 10 };
const DEFAULT_FILTERS: QueryFilter[] = [{ key: "[status]", value: "active" }];
const SORTING: TableProps["sorting"] = { column: "name", isDescending: false };
const getDefaultEditFilters = (value: string): QueryFilter[] => {
  return [
    { key: "[status]", value: "active" },
    { key: "[id][in]", value },
  ];
};

export function getDefaultState(
  paginationSize: 10 | 100,
  isEdit?: boolean,
  editValue?: string,
): {
  pagination: IPagination;
  sorting: TableProps["sorting"] | undefined;
  filters: QueryFilter[];
  url: string;
} {
  return {
    pagination: paginationSize === 100 ? PAGINATION : CUSTOM_PAGINATION,
    sorting: SORTING,
    filters: isEdit && editValue ? getDefaultEditFilters(editValue) : DEFAULT_FILTERS,
    url: ``,
  };
}

export enum Columns {
  User = "user",
  Email = "email",
  Username = "username",
  UserType = "user_type",
  Registration = "registration",
  LastLogin = "last_login",
  Points = "points",
  AssignedCourses = "assigned_courses",
  CompletedCourses = "completed_courses",
  CompletionRate = "completion_rate",
  Groups = "groups",
  Branches = "branches",
  Course = "course",
  CourseCategory = "course_category",
  CourseStatus = "course_status",
  CourseTime = "course_time",
  CourseTimeSeconds = "course_seconds",
  CourseEnrolledOn = "enrolled_on",
  CourseCompletionDate = "completion_date",
  CourseAverageScore = "average_score",
  CourseExpirationDate = "course_expiration_date",
  Certification = "course_certificate",
  CertificationId = "certificate_unique_id",
  CertificationDate = "certificate_date",
  CertificationExpiration = "certificate_expiration",
  RemainingTime = "remaining_time",
  TrainingTime = "training_time",
  Badges = "badges",
  Level = "level",
  UserId = "user_id",
  CourseId = "course_id",
  ExcludedFromEmails = "excluded_from_emails",
}

type CustomColumnFieldName = `custom_user_field_${number}` | `custom_course_field_${number}`;

type ColumnFields =
  | "user"
  | "email"
  | "username"
  | "user_type"
  | "registration"
  | "last_login"
  | "points"
  | "assigned_courses"
  | "completed_courses"
  | "completion_rate"
  | "groups"
  | "branches"
  | "course"
  | "course_status"
  | "course_time"
  | "course_seconds"
  | "enrolled_on"
  | "completion_date"
  | "average_score"
  | "course_expiration_date"
  | "course_certificate"
  | "certificate_unique_id"
  | "certificate_date"
  | "certificate_expiration"
  | "course_category"
  | "remaining_time"
  | "training_time"
  | "badges"
  | "level"
  | "user_id"
  | "course_id"
  | "excluded_from_emails";

export type ColumnFieldsType = ColumnFields | CustomColumnFieldName;

export const getCustomReportColumns = (columns: ColumnFieldsType[]): Column[] => {
  return [
    {
      accessor: Columns.User,
      cell: t("users.user"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.User),
    },
    {
      accessor: Columns.Email,
      cell: t("signUp.email"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.Email),
    },
    {
      accessor: Columns.UserType,
      cell: t("users.userType"),
      headerWidth: 250,
      hidden: !columns.includes(Columns.UserType),
    },
    {
      accessor: Columns.Registration,
      cell: t("reports.customReports.registrationDate"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.Registration),
    },
    {
      accessor: Columns.LastLogin,
      cell: t("reports.customReports.lastLogin"),
      headerWidth: 250,
      hidden: !columns.includes(Columns.LastLogin),
    },
    {
      accessor: Columns.AssignedCourses,
      cell: t("widgetsDashboard.overview.assignedCourses"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.AssignedCourses),
    },
    {
      accessor: Columns.CompletedCourses,
      cell: t("dashboard.leaderboardTabs.completedCourses"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.CompletedCourses),
    },
    {
      accessor: Columns.Username,
      cell: t("signIn.username"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.Username),
    },
    {
      accessor: Columns.Points,
      cell: t("general.points"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.Points),
    },
    {
      accessor: Columns.CompletionRate,
      cell: t("microstats.completion_rate"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.CompletionRate),
    },
    {
      accessor: Columns.Groups,
      cell: t("general.groups_other"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.Groups),
    },
    {
      accessor: Columns.Branches,
      cell: t("general.branches_other"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.Branches),
    },
    {
      accessor: Columns.Badges,
      cell: t("general.badges"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.Badges),
    },
    {
      accessor: Columns.Level,
      cell: t("general.level"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.Level),
    },
    {
      accessor: Columns.UserId,
      cell: t("reports.customReports.userId"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.UserId),
    },
    {
      accessor: Columns.Course,
      cell: t("branches.courses.course"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.Course),
    },
    {
      accessor: Columns.CourseEnrolledOn,
      cell: t("reports.customReports.enrolledOn"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.CourseEnrolledOn),
    },
    {
      accessor: Columns.CourseStatus,
      cell: t("general.status"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.CourseStatus),
    },
    {
      accessor: Columns.CourseTime,
      cell: t("reports.customReports.timeSpent"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.CourseTime),
    },
    {
      accessor: Columns.CourseTimeSeconds,
      cell: t("reports.customReports.timeSpentInSeconds"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.CourseTimeSeconds),
    },
    {
      accessor: Columns.CourseCompletionDate,
      cell: t("reports.customReports.completionDate"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.CourseCompletionDate),
    },
    {
      accessor: Columns.CourseAverageScore,
      cell: t("reports.customReports.averageScore"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.CourseAverageScore),
    },
    {
      accessor: Columns.Certification,
      cell: t("certificates.certificate"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.Certification),
    },
    {
      accessor: Columns.CertificationId,
      cell: t("reports.customReports.certificateId"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.CertificationId),
    },
    {
      accessor: Columns.CourseExpirationDate,
      cell: t("reports.customReports.courseExpiration"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.CourseExpirationDate),
    },
    {
      accessor: Columns.CertificationDate,
      cell: t("reports.customReports.certificateDate"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.CertificationDate),
    },
    {
      accessor: Columns.CertificationExpiration,
      cell: t("reports.customReports.certificateExpiration"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.CertificationExpiration),
    },
    {
      accessor: Columns.CourseId,
      cell: t("reports.customReports.courseId"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.CourseId),
    },
    {
      accessor: Columns.RemainingTime,
      cell: t("reports.customReports.remainingTime"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.RemainingTime),
    },
    {
      accessor: Columns.TrainingTime,
      cell: t("general.totalTrainingTime"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.TrainingTime),
    },
    {
      accessor: Columns.CourseCategory,
      cell: t("general.category"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.CourseCategory),
    },
    {
      accessor: Columns.ExcludedFromEmails,
      cell: t("reports.customReports.excludedFromEmails"),
      headerWidth: 150,
      hidden: !columns.includes(Columns.ExcludedFromEmails),
    },
  ];
};

export const customReportActions = [
  {
    accessor: "actions",
    cell: "",
    classNames: ["hidden"],
    sortableHeader: false,
  },
];

export const customReportMassActionsWordings = {
  activate: {
    progressHeader: "general.activate",
    progressMessage: "users.massActions.activatingUsers",
    confirmationBodyTitle: "users.massActions.activateUsers",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
    confirmationHeader: "general.activate",
    confirmationButton: "general.activate",
    successMessage: "users.massActions.activateUsersSuccess",
  },
  deactivate: {
    progressHeader: "general.deactivate",
    progressMessage: "users.massActions.deactivatingUsers",
    confirmationHeader: "general.deactivate",
    confirmationButton: "general.deactivate",
    successMessage: "users.massActions.deactivateUsersSuccess",
    confirmationBodyTitle: "users.massActions.deactivateUsers",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  add_to_branch: {
    progressHeader: "branches.courses.addToBranch",
    progressMessage: "users.massActions.addingUsersToBranches",
    confirmationHeader: "branches.courses.addToBranch",
    confirmationButton: "general.apply",
    successMessage: "users.massActions.addToBranchesSuccess",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  remove_from_branch: {
    progressHeader: "users.massActions.removeUsersFromBranches",
    progressMessage: "users.massActions.removingUsersFromBranches",
    confirmationHeader: "users.massActions.removeUsersFromBranches",
    confirmationButton: "general.apply",
    successMessage: "users.massActions.removeFromBranchesSuccess",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  add_to_group: {
    progressHeader: "courses.addToGroup",
    progressMessage: "users.massActions.addingUsersToGroups",
    confirmationHeader: "courses.addToGroup",
    confirmationButton: "general.apply",
    successMessage: "users.massActions.addToGroupsSuccess",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  remove_from_group: {
    progressHeader: "users.massActions.removeUsersFromGroups",
    progressMessage: "users.massActions.removingUsersFromGroups",
    confirmationHeader: "users.massActions.removeUsersFromGroups",
    confirmationButton: "general.apply",
    successMessage: "users.massActions.removeFromGroupsSuccess",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  add_to_course: {
    progressHeader: "courses.massActions.enrollModalTitle",
    progressMessage: "courses.massActions.enrollProgress",
    confirmationHeader: "courses.massActions.enrollModalTitle",
    confirmationButton: "general.enroll",
    successMessage: "courses.massActions.enrollSuccess",
    confirmationBodyTitle: "courses.massActions.enrollWarning",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  remove_from_course: {
    progressHeader: "courses.massActions.unenrollModalTitle",
    progressMessage: "courses.massActions.unenrollProgress",
    confirmationHeader: "courses.massActions.unenrollModalTitle",
    confirmationButton: "general.unenroll",
    successMessage: "courses.massActions.unenrollSuccess",
    confirmationBodyTitle: "courses.massActions.unenrollWarning",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  custom_reports_reset_progress: {
    progressHeader: "reports.customReports.resetProgressOnCourse",
    progressMessage: "reports.customReports.resettingProgressOnCourse",
    confirmationHeader: "reports.customReports.resetProgressOnCourse",
    confirmationButton: "general.apply",
    successMessage: "reports.customReports.resetProgressOnCourseSuccess",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
  custom_reports_delete_users: {
    progressHeader: "general.delete",
    progressMessage: "reports.customReports.deletingUsers",
    confirmationHeader: "general.delete",
    confirmationButton: "general.delete",
    successMessage: "reports.customReports.deleteUserSuccess",
    confirmationBodyTitle: "reports.customReports.confirmUserDelete",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
  },
};
