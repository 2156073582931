import React, { FC, memo, useLayoutEffect, useRef } from "react";

import { Text, Tooltip } from "@epignosis_llc/gnosis";

import { nestedCategoryContainer } from "@components/CustomTable/components/Cells/NestedCategoryText/styles";

type NestedCategoryProps = {
  showNested: boolean;
  text: string;
  tooltipText?: string;
  headerWidth?: number;
};

const NestedCategoryText: FC<NestedCategoryProps> = ({
  showNested,
  text,
  tooltipText,
  headerWidth = 150,
}) => {
  const componentRef = useRef<HTMLSpanElement | null>(null);
  const overflowRef = useRef<HTMLElement | null>(null);

  useLayoutEffect(() => {
    if (componentRef.current) {
      overflowRef.current = componentRef.current.querySelector(".overflow-category-text");
    }
  }, []);

  if (!showNested) {
    return (
      <Text fontSize="sm" as="div" className="has-overflow">
        {text || "-"}
      </Text>
    );
  }

  return (
    <div css={nestedCategoryContainer}>
      <div className="column-category">
        <Tooltip
          content={
            <Text fontSize="sm" as="div" style={{ maxWidth: headerWidth }}>
              {tooltipText}
            </Text>
          }
        >
          <span ref={componentRef}>
            {" "}
            <Text
              fontSize="sm"
              as="div"
              className="overflow-category-text"
              style={{ maxWidth: headerWidth }}
            >
              {text}
            </Text>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default memo(NestedCategoryText);
