import { css } from "@emotion/react";

import { mq } from "@utils/breakpoints";

export const userStatsItem = ({ leaderboard }, { isGamificationDrawer, hasFooter }) => css`
  padding-inline-start: 0;
  ${!isGamificationDrawer && "height: 15.625rem"};
  overflow-y: auto;
  margin: 0;
  /* add padding same as the fixed user height */
  padding-top: 1rem;

  .list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: 0 0.5rem;
    margin-bottom: ${isGamificationDrawer ? "0.5rem" : "0"};

    &:last-child {
      margin-block: 0;
    }

    .container {
      display: flex;
      align-items: center;
      flex: 5;
      position: relative;

      .tooltip {
        max-width: 10rem;
        word-break: break-word;
      }

      .info-value-container {
        display: flex;
        width: 100%;
        flex-direction: column;

        ${mq["sm"]} {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .info {
          margin-inline-start: 1rem;
          flex: 4;

          div {
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 15.625rem;
          }

          p {
            margin-bottom: 0;
          }

          span {
            text-align: start;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
          }
        }

        .stats {
          margin: 0.5rem 1rem;
        }
      }
    }

    .stats {
      margin-inline-end: 1rem;
      align-items: center;
      display: flex;
      gap: 1rem;
    }

    &.user-background {
      background: ${leaderboard.selectedUserBackground};
      border-radius: 10px;
    }

    &.fixed-user {
      position: fixed;
      inset-inline-end: 1rem;
      bottom: 0;
      z-index: 1;
      margin-bottom: 0;
      bottom: ${hasFooter ? "72px" : "32px"};
      width: calc(100% - 2.25rem);

      ${mq["sm"]} {
        inset-inline-end: 1.5rem;
        /* hardcoded value to work in rtl the fixed position */
        width: 498px;
      }

      ${mq["md"]} {
        bottom: ${hasFooter ? "104px" : "64px"};
      }
    }
  }

  .image-container {
    display: flex;
    align-items: center;
    gap: ${isGamificationDrawer ? "0.5rem" : "0"};

    .ranking-number {
      margin-inline-start: 1rem;
      width: 1.5rem;
      height: ${"1.5rem"};
      text-align: center;
    }

    .avatar-container {
      margin-inline: 1rem 0;
      margin-block: 0.375rem;

      .tooltip-container {
        text-align: center;
      }

      span,
      img {
        height: 50px;
        width: 50px;
      }
    }
  }
`;

export const userItemContainerModal = () => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: 0 0.5rem;

  &:not(:last-child) {
    margin-block: 0 0.5rem;
  }

  .container {
    display: flex;
    align-items: center;
    flex: 5;
    position: relative;

    .tooltip {
      max-width: 10rem;
      word-break: break-word;
    }
    .info-value-container {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      .info {
        margin-inline-start: 1rem;
        flex: 4;

        div {
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 250px;
        }

        p {
          margin-bottom: 0;
        }

        span {
          text-align: start;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
        }
      }
    }
  }

  .stats {
    margin-inline-end: 1rem;
    align-items: center;
    display: flex;
    gap: 1rem;
  }

  &.user-background {
    background: #e1eefb;
    border-radius: 10px;
  }

  .image-container {
    display: flex;
    align-items: center;

    .ranking-number {
      width: 24px;
      height: 24px;
      text-align: center;
    }

    .avatar-container {
      span,
      img {
        height: 50px;
        width: 50px;
        margin-inline: 1rem 0;
        margin-block: 0.375rem;
      }
    }
  }
`;

export const badgeCategoryContainer = () => css`
  .badges-container {
    display: flex;
    gap: 0.5rem;
    margin: 0.5rem 0 1rem 0;

    figure {
      width: 50px;
      height: 50px;
    }
  }
`;

export const pointsContainer = () => css`
  .points-item {
    div {
      display: flex;
    }

    display: flex;
    align-items: center;
    gap: 8px;
  }
`;
