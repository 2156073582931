import Color from "color";

import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["infoGrid"] => {
  return {
    info: {
      backgroundColor: Color(colors.primary.lightest).alpha(0.25).string(),
      textColor: colors.black,
      borderColor: colors.primary.base,
    },
    failed: {
      backgroundColor: Color(colors.red.lightest).alpha(0.25).string(),
      textColor: colors.white,
      borderColor: colors.red.base,
    },
  };
};
