import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["gamificationDrawer"] => {
  return {
    color: colors.primary.base,
    background: colors.secondary.lighter,
    closeBtn: colors.secondary.base,
  };
};
