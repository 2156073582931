import { GnosisTheme } from "@epignosis_llc/gnosis";

import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): GnosisTheme["breadcrumbs"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    link: protectColorContrast("darkest", colors.primary.base),
    black: colors.black,
    separator: colors.secondary.darker,
  };
};
