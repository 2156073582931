import Color from "color";

import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["dialogBox"] => {
  return {
    background: colors.secondary.lighter,
    overlayBackground: Color(colors.black).alpha(0.5).string(),
    boxShadow: Color(colors.black).alpha(0.16).string(),
  };
};
