import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

import { SerializedStyles } from "@emotion/react";
import classNames from "classnames";

import { EditableTextStyles } from "@components/ReusableComponents/EditableText/styles";

type EditableTextProps = {
  text: string | null;
  placeholder: string;
  variant?: "heading" | "text";
  canEdit?: boolean;
  autoFocus?: boolean;
  fontSize?: string;
  onBlur?: (newContent: string | null) => void;
  onInput?: (newContent: string | null) => void;
};

const EditableText: ForwardRefRenderFunction<HTMLDivElement | null, EditableTextProps> = (
  {
    text,
    placeholder,
    variant = "heading",
    canEdit = true,
    autoFocus = false,
    fontSize = "4xl",
    onBlur,
    onInput,
  },
  ref,
) => {
  const editableDivRef = useRef<HTMLDivElement>(null);
  const editableClassNames = classNames("editable-text", { [variant]: true });

  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
    ref,
    () => editableDivRef.current,
  );

  const handleOnInput = (e: React.ChangeEvent<HTMLDivElement>): void => {
    if (editableDivRef.current) {
      const newContent = e.target.textContent;
      onInput?.(newContent);
    }
  };

  const handleOnBlur = (): void => {
    if (editableDivRef.current) {
      onBlur?.(editableDivRef.current.textContent);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    // On enter trigger blur callback
    if (["Enter", "NumpadEnter"].includes(e.code)) {
      e.preventDefault();
      onBlur?.(editableDivRef?.current?.textContent ?? null);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>): void => {
    // Prevent the default paste behavior
    e.preventDefault();

    // Get the plain text from the clipboard
    const plainText = e.clipboardData.getData("text/plain");

    // Get the current selection and caret position
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0) {
      // Get the current range and insert plain text at the caret position
      const range = selection.getRangeAt(0);
      range.deleteContents(); // Remove any selected content
      const textNode = document.createTextNode(plainText);
      range.insertNode(textNode);

      // Move the caret after the inserted text
      range.setStartAfter(textNode);
      range.collapse(true);

      // Clear the current selection and apply the new range
      selection.removeAllRanges();
      selection.addRange(range);
    }

    // Manually trigger an `input` event, due to preventing the paste default behavior
    e.currentTarget.dispatchEvent(new Event("input", { bubbles: true }));
  };

  useEffect(() => {
    if (editableDivRef.current && text !== editableDivRef.current.textContent) {
      editableDivRef.current.textContent = text;
    }
  }, [text, canEdit]);

  useEffect(() => {
    if (editableDivRef.current && autoFocus) {
      editableDivRef.current.focus();
      const textNode = editableDivRef.current.firstChild as Text | null;
      const textLength = textNode?.textContent?.length ?? 0;
      const selection = window.getSelection();

      if (textNode && selection) {
        selection.collapse(textNode, textLength);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={editableClassNames}
      css={(theme): SerializedStyles => EditableTextStyles(theme, { fontSize })}
      onClick={(): void => editableDivRef?.current?.focus()}
    >
      {canEdit ? (
        <div
          className="editable-container"
          contentEditable
          suppressContentEditableWarning
          data-text={placeholder}
          ref={editableDivRef}
          onInput={handleOnInput}
          onBlur={handleOnBlur}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          data-testid="editable-text"
          spellCheck={false}
        />
      ) : (
        <div className="editable-container not-editing" data-testid="editable-text">
          {text || <div className="placeholder">{placeholder}</div>}
        </div>
      )}
    </div>
  );
};

export default forwardRef(EditableText);
