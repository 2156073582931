import React, { FC, useCallback, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { Button, DropdownItem, Row, TableHandlers, TableProps } from "@epignosis_llc/gnosis";

import { getBranches } from "@api/branch";
import { getGroups } from "@api/group";
import {
  courseApprovalRequestsMassActions,
  courseApprovalRequestsMassActionsCount,
} from "@api/massActions/courses";
import { useApplyTranslations, useExportToCSV, usePaginatedStateReducer } from "@hooks";
import { PaginatedStateActions } from "@hooks/usePaginatedStateReducer";
import { useConfigurationStore } from "@stores";
import {
  applyQueryFilter,
  buildPaginatedSearchQuery,
  getFilterDropdownOptions,
  handlePaginatedTableState,
  mapTableToSelectSorting,
} from "@utils/helpers";
import authService from "@utils/services/AuthService";
import {
  exportEnrollmentRequests,
  getEnrollmentRequests,
  postProcessEnrollmentRequest,
} from "@views/Course/CourseUsers/api";

import queryKeys from "@constants/queryKeys";
import {
  getEnrollmentRequestsColumns,
  getMassActionsOptionsForCourseApproval,
} from "@views/Course/CourseUsers/constants";

import { ActionDrawer, CustomTableActions, ExportToCsvModal } from "@components";
import { buildEmptyStateProps, emptyState } from "@components/CustomTable";
import { MassActionsProps } from "@components/CustomTable/types";
import { MassActionType } from "@components/ReusableComponents";
import EnrollmentRequestsTable from "@views/Course/CourseUsers/components/EnrollmentRequestsTable";

import { EnrollmentRequest, RequestResponse } from "@views/Course/CourseUsers/types";
import { DrawerSize, QueryFilter } from "types/common";
import { UserRoles } from "types/entities/User";
import {
  CountMassActionResponse,
  Pagination as IPagination,
  MassActionResultResponse,
} from "types/responses/index";

export type EnrollmentRequestsDrawerProps = {
  isOpen: boolean;
  canMassEnrollUsers: boolean;
  onClose: () => void;
  hasBackButton?: boolean;
  openDrawerFullscreen?: boolean;
  size?: DrawerSize;
};

type RequestDataType = {
  requestId: string;
  action: RequestResponse;
};

const EnrollmentRequestsDrawer: FC<EnrollmentRequestsDrawerProps> = ({
  isOpen,
  onClose,
  canMassEnrollUsers,
  hasBackButton = false,
  openDrawerFullscreen = false,
  size = "md",
}) => {
  const { t } = useApplyTranslations();
  const { courseId } = useParams() as { courseId: string };
  const queryClient = useQueryClient();
  const { domainSettings } = useConfigurationStore();
  const { main_portal: isMainPortal } = domainSettings ?? {};
  const currentRole = authService.getDefaultRole();
  const isAdmin = currentRole === UserRoles.ADMINISTRATOR;
  const canAdminAccessBranches = isAdmin && Boolean(isMainPortal);

  const SORTING = { column: "formatted_name", isDescending: false };
  const PAGINATION: IPagination = { number: 1, size: 10 };
  const DEFAULT_FILTERS: QueryFilter[] = [];

  const filtersSearchQuery = buildPaginatedSearchQuery({
    pagination: { number: 1, size: 10000 },
    sorting: ["name"],
  });

  const defaultValues = {
    sorting: SORTING,
    filters: DEFAULT_FILTERS,
    pagination: PAGINATION,
  };

  const defaultState = handlePaginatedTableState(defaultValues);

  // Mass actions
  const massActionsOptions = getMassActionsOptionsForCourseApproval();
  const tableRef = React.useRef<TableHandlers>(null);
  const [requestIds, setRequestIds] = useState<number[]>([]);

  const [enrollmentRequestsState, enrollmentRequestsDispatch] =
    usePaginatedStateReducer(defaultState);
  const { pagination, sorting: tableSorting, filters } = enrollmentRequestsState;
  const sorting = [mapTableToSelectSorting(tableSorting)];
  const searchQuery = buildPaginatedSearchQuery({ pagination, sorting, filters });

  const [resetInput, setResetInput] = useState(false);

  const handleRestoreDefault = (): void => {
    enrollmentRequestsDispatch({
      type: PaginatedStateActions.filters,
      payload: { filters: DEFAULT_FILTERS },
    });
    handleSortingChanged(SORTING);
    setResetInput(true);
  };

  const {
    data: enrollmentRequests,
    status,
    error,
  } = useQuery(
    [queryKeys.courses.enrollmentRequests, searchQuery],
    () => getEnrollmentRequests(courseId, searchQuery),
    {
      select: (res) => ({
        data: res._data,
        pagination: res._meta?.pagination,
      }),
    },
  );

  const { data: groups = [] } = useQuery(
    [queryKeys.allGroups],
    () => getGroups(filtersSearchQuery),
    {
      select: (groups) => groups._data,
      enabled: isAdmin,
    },
  );

  const { data: branches = [] } = useQuery(
    [queryKeys.branches.branches],
    () => getBranches(filtersSearchQuery),
    {
      select: (branches) => branches._data,
      enabled: canAdminAccessBranches,
    },
  );

  const { mutate: processRequestMutation } = useMutation(
    [queryKeys.courseSubscription],
    (data: RequestDataType) => postProcessEnrollmentRequest(courseId, data.requestId, data.action),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.courses.enrollmentRequests);
        queryClient.invalidateQueries([queryKeys.courses.users, courseId]);
      },
    },
  );

  // Export to CSV
  const {
    isExportModalOpen,
    exportProgress,
    handleExportModalClose,
    deleteTaskMutationLoading,
    handleExportToCSV,
  } = useExportToCSV({
    queryKey: queryKeys.tableExports.enrollmentRequests,
    handleExport: exportEnrollmentRequests,
    exportId: courseId,
    searchQuery,
  });

  const handlePaginationPageSizeChange = (size: number): void => {
    enrollmentRequestsDispatch({
      type: PaginatedStateActions.paginationPageSize,
      payload: { size },
    });
  };

  const handlePaginationPageChange = (number: number): void => {
    enrollmentRequestsDispatch({ type: PaginatedStateActions.paginationPage, payload: { number } });
  };

  const handleSortingChanged = (sorting: TableProps["sorting"]): void => {
    enrollmentRequestsDispatch({
      type: PaginatedStateActions.sorting,
      payload: { sorting },
    });
  };

  const handleSearchChange = (searchValue: string): void => {
    setResetInput(false);
    const filter = { key: "[keyword][like]", value: searchValue };
    const newFilters = applyQueryFilter({ filters: [...filters], filter });

    enrollmentRequestsDispatch({
      type: PaginatedStateActions.filters,
      payload: { filters: newFilters },
    });
  };

  const handleFiltersChange = ({ category, value }: DropdownItem): void => {
    const filter = { key: category as string, value: value as string };
    const newFilters = applyQueryFilter({ filters: [...filters], filter });
    enrollmentRequestsDispatch({
      type: PaginatedStateActions.filters,
      payload: { filters: newFilters },
    });
  };

  const handleFilterRemove = ({ category, value }: DropdownItem): void => {
    const newFilters = [...filters].filter(
      (item) => !(category === item.key && value === item.value),
    );

    enrollmentRequestsDispatch({
      type: PaginatedStateActions.filters,
      payload: { filters: newFilters },
    });
  };

  const emptyStateProps = buildEmptyStateProps({
    filters,
    tableType: "enrollmentRequests",
    opensDrawer: false,
    isDrawer: false,
  });

  const tableProps = {
    columns: getEnrollmentRequestsColumns(),
    rows: enrollmentRequests?.data as EnrollmentRequest[],
    emptyState: emptyState(emptyStateProps),
    selectable: true,
    autohide: true,
  };

  const tableStatus = {
    status,
    error,
  };

  const handleProcessRequest = (requestId: string, action: RequestResponse): void => {
    const requestData = {
      requestId,
      action,
    };

    processRequestMutation(requestData);
  };

  const handleRowSelect = (rows: Row[]): void => {
    const selectedRows = rows.map((row) => Number(row.id));
    setRequestIds(selectedRows);
  };

  const countRequest = useCallback(
    (type: MassActionType): Promise<CountMassActionResponse> => {
      return courseApprovalRequestsMassActionsCount(type, courseId, requestIds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(requestIds)],
  );

  const massActionRequest = useCallback(
    (type: MassActionType): Promise<MassActionResultResponse> => {
      return courseApprovalRequestsMassActions(type, courseId, requestIds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(requestIds)],
  );

  const cleanState = (): void => {
    setRequestIds([]);
    tableRef.current?.resetSelected();
  };

  const handleInvalidateQueryMassActions = (): void => {
    queryClient.invalidateQueries(queryKeys.courses.enrollmentRequests);
    queryClient.invalidateQueries([queryKeys.courses.users, courseId]);
  };

  const massActionsProps = {
    allowMassActions: canMassEnrollUsers,
    massActionsOptions: massActionsOptions,
    originTableName: "enrollmentRequests",
    countRequest,
    massActionRequest,
    cleanState,
    handleInvalidateQueryMassActions,
    selectedRows: requestIds.length,
  } as MassActionsProps;

  return (
    <>
      <ActionDrawer
        isOpen={isOpen}
        headerTitle={t("general.enrollmentRequests", { count: 2 })}
        onClose={onClose}
        size={size}
        hasBackButton={hasBackButton}
        fullscreen={openDrawerFullscreen}
        customFooter={
          <Button onClick={onClose} color="secondary">
            {t("general.cancel")}
          </Button>
        }
      >
        <CustomTableActions
          filterDropdownOptions={getFilterDropdownOptions({ groups, branches })}
          selectedFilters={filters}
          onResetAllFilters={handleRestoreDefault}
          searchValue={defaultState.searchValue}
          tableHasData={Boolean(enrollmentRequests?.data.length)}
          resetInput={resetInput}
          onSearchChange={handleSearchChange}
          onFilterSelect={handleFiltersChange}
          onFilterRemove={handleFilterRemove}
          massActionsProps={massActionsProps}
        />

        <EnrollmentRequestsTable
          paginationRes={enrollmentRequests?.pagination}
          tableProps={tableProps}
          data={enrollmentRequests?.data ?? []}
          tableStatus={tableStatus}
          handleProcessRequest={handleProcessRequest}
          handleRestoreDefault={handleRestoreDefault}
          onSortingChanged={handleSortingChanged}
          onPageChange={handlePaginationPageChange}
          onPageSizeChange={handlePaginationPageSizeChange}
          handleRowSelect={handleRowSelect}
          tableRef={tableRef}
          handleExportToCSV={handleExportToCSV}
        />

        {isExportModalOpen && (
          <ExportToCsvModal
            isOpen={isExportModalOpen}
            progress={exportProgress ?? 0}
            isBeingCancelled={deleteTaskMutationLoading}
            onClose={(): void => handleExportModalClose()}
          />
        )}
      </ActionDrawer>
    </>
  );
};

export default EnrollmentRequestsDrawer;
