import React, { Dispatch, FC, SetStateAction, useEffect } from "react";

import { Tabs } from "@epignosis_llc/gnosis";

import { helpContainer } from "@components/Drawers/HelpCenter/styles";

import { useApplyTranslations, useTabNavigation } from "@hooks";
import { useConfigurationStore } from "@stores";

import { TabKey } from "@components/Drawers/HelpCenter/constants";
import userRoles from "@constants/userRoles";

import { ContactSupport, HelpArticles } from "@components/Drawers/HelpCenter/components";
import { Article } from "@components/Drawers/HelpCenter/types";

import { AvailableTabsType } from "types/entities/Common";

type HelpCenterProps = {
  setHelpContent?: Dispatch<SetStateAction<string | null>>;
  setSelectedArticle?: Dispatch<SetStateAction<Article | undefined>>;
  setTabKey?: Dispatch<SetStateAction<string>>;
  fullscreen?: boolean;
};

const HelpCenter: FC<HelpCenterProps> = ({
  setSelectedArticle,
  setHelpContent,
  setTabKey,
  fullscreen,
}) => {
  const { t } = useApplyTranslations();
  const { userProfileData, domainSettings } = useConfigurationStore();
  const { available_roles: availRoles } = userProfileData ?? {};
  const { main_portal } = domainSettings ?? {};
  const shouldShowContactSupport =
    availRoles?.map((role) => role.toString()).includes(userRoles.ADMINISTRATOR) &&
    fullscreen &&
    main_portal;

  const availableTabs: AvailableTabsType[] = [
    {
      info: {
        id: TabKey.helpArticles,
        title: t("general.knowledgeBase"),
        content: (
          <HelpArticles setSelectedArticle={setSelectedArticle} setHelpContent={setHelpContent} />
        ),
      },
      permission: true,
    },
    {
      info: {
        id: TabKey.contactSupport,
        title: t("general.contactSupport"),
        content: <ContactSupport />,
      },
      permission: shouldShowContactSupport ? shouldShowContactSupport : false,
    },
  ];

  const { selectedTabIndex, visibleTabs, updateTabParam } = useTabNavigation(availableTabs);

  useEffect(() => {
    const mainSidebar = window.document.querySelector(".main-nav-container");
    mainSidebar?.setAttribute("style", "z-index: 1001");

    return (): void => {
      mainSidebar?.setAttribute("style", "z-index: 1000");
    };
  }, []);

  const handleTabChange = (newTabIndex: number): void => {
    const newTabKey = visibleTabs[newTabIndex].id;
    setTabKey && setTabKey(String(newTabKey));
    updateTabParam(newTabIndex);
  };

  return (
    <div css={helpContainer}>
      <Tabs tabs={visibleTabs} selectedTab={selectedTabIndex} onChangeTab={handleTabChange} />
    </div>
  );
};

export default HelpCenter;
