import React from "react";
import { components, DropdownIndicatorProps } from "react-select";

import { MagnifierSVG } from "@epignosis_llc/gnosis/icons";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomDropdownIndicator = (props: DropdownIndicatorProps<any, true>): JSX.Element => {
  return (
    <components.DropdownIndicator {...props}>
      <MagnifierSVG height={24} />
    </components.DropdownIndicator>
  );
};

export default CustomDropdownIndicator;
