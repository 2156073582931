import { useEffect, useMemo, useState } from "react";
import { QueryStatus, useMutation, useQuery, useQueryClient } from "react-query";

import { AxiosError } from "axios";

import { handleBranchSwitchErrors } from "@errors";

import { postBranchSwitch } from "@api/branch";
import { getMyBranches } from "@api/user";
import { getFormattedBranches } from "@layouts/DashboardLayout/components/TopBarMenu/utils";
import { useConfigurationStore } from "@stores";
import { parseAccessToken } from "@utils/helpers";
import { buildApiBranchesSearchQuery } from "@utils/helpers/url";
import authService from "@utils/services/AuthService";

import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";
import { defaultState } from "@layouts/DashboardLayout/components/TopBarMenu/constants";

import { Branch } from "types/entities";

type BranchOptions = {
  id: string;
  label: string;
  value: string;
  icon: JSX.Element | undefined;
};

type UseFetchBranchesProps = {
  data: Branch[] | undefined;
  status: QueryStatus;
  error: Error | null;
};

type UseBranchesProps = Pick<UseFetchBranchesProps, "data" | "status" | "error"> & {
  branchesOptions: BranchOptions[];
  switchBranchMutation: (branchId: string) => void;
  currentBranchId: number | undefined;
  shouldShowMainBranch: boolean | undefined;
  shouldShowSearch: boolean | undefined;
  branchesStatus: QueryStatus;
  branchesError: Error | null;
};
export const useBranches = (search?: string): UseBranchesProps => {
  const [branchesOptionsList, setBranchesOptionsList] = useState<BranchOptions[]>([]);
  const { userProfileData, domainSettings } = useConfigurationStore();
  const queryClient = useQueryClient();
  const searchQuery = buildApiBranchesSearchQuery(defaultState);
  const token = useMemo(() => authService.getAccessToken(), []);
  const decodeToken = token ? parseAccessToken(token) : null;
  const currentBranchId = decodeToken?.sub?.branch_id ?? 0;
  const role = authService.getDefaultRole();
  const shouldShowMainBranch = !domainSettings?.main_portal && userProfileData?.main_portal_access;

  // Fetch branches
  const {
    data: branches,
    status: branchesStatus,
    error: branchesError,
  }: UseFetchBranchesProps = useQuery(
    [queryKeys.myBranchesProfileMenu, searchQuery],
    () => getMyBranches(searchQuery),
    {
      select: (branches: { _data: Branch[] }) => branches._data,
    },
  );

  useEffect(() => {
    if (search && branches) {
      const results = branches.filter((branch) => {
        return branch.name.toLowerCase().includes(search.toLowerCase());
      });
      setBranchesOptionsList(getFormattedBranches(results, currentBranchId));
    } else {
      setBranchesOptionsList(getFormattedBranches(branches ?? [], currentBranchId));
    }
  }, [branches, currentBranchId, search]);

  // Switch branch mutation - redirect to branch on success
  const { mutate: switchBranchMutation } = useMutation(
    [queryKeys.branchSwitch],
    (branchId: string) => postBranchSwitch(branchId),
    {
      onSuccess: (res) => {
        const { domain, token } = res._data;
        queryClient.clear();
        authService.removeTokens();
        window.location.replace(
          `https://${domain}/plus${URLS.autologin}?token=${token}&role=${role}`,
        );
      },
      onError: (error: AxiosError) => {
        handleBranchSwitchErrors(error);
      },
    },
  );

  return {
    data: branches,
    status: branchesStatus,
    error: branchesError,
    branchesOptions: branchesOptionsList,
    switchBranchMutation,
    currentBranchId,
    shouldShowMainBranch,
    shouldShowSearch: branches && branches.length > 10,
    branchesStatus,
    branchesError,
  };
};
