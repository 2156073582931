import React, { FC } from "react";
import ReactPlayer from "react-player";

import { Loader } from "@epignosis_llc/gnosis";

import { useApplyTranslations } from "@hooks";

import { PdfViewer } from "@components";

type FilePreviewProps = {
  url: string;
  mime_type: string;
  fileName: string;
  hasMaxHeight?: boolean;
  isLoading?: boolean;
};

const FilePreview: FC<FilePreviewProps> = ({
  mime_type,
  url,
  fileName,
  hasMaxHeight = false,
  isLoading = false,
}) => {
  const { t } = useApplyTranslations();

  if (isLoading) {
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  }

  switch (mime_type) {
    case "video/mp4":
      return <ReactPlayer controls url={url} width="100%" height="100%" />;
    case "audio/mpeg":
      return <ReactPlayer controls url={url} height="3.5rem" width="100%" />;
    case "application/pdf":
    case "application/msword":
    case "application/vnd.ms-excel":
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/powerpoint": // This mime type is returned by the API for ptt files
      return (
        <div className="pdf-container">
          <PdfViewer fileUrl={url} hasMaxHeight={hasMaxHeight} />
        </div>
      );
    case "image/gif":
    case "image/png":
    case "image/jpeg":
      return (
        <div className="img-container">
          <img src={url} alt={fileName} />
        </div>
      );
    default:
      return <p>{t("myFiles.noPreviewAvailable")}</p>;
  }
};

export default FilePreview;
