import { ThemePlusTypes } from "@styles/types";
import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["catalog"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);
  return {
    activeButton: {
      background: protectColorContrast("darkest", colors.primary.light),
      borderColor: protectColorContrast("darker", colors.primary.light),
      color: colors.white,
    },
    color: colors.black,
  };
};
