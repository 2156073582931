import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["gamificationNotification"] => {
  return {
    success: {
      background: colors.white,
      color: colors.black,
      borderColor: colors.green.base,
    },
    error: {
      background: colors.white,
      color: colors.black,
      borderColor: colors.red.base,
    },
  };
};
