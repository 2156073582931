import { MutableRefObject, useEffect, useRef, useState } from "react";

import ReactFroalaEditor from "froala-editor";
import { t } from "i18next";
import PubSub from "pubsub-js";
import { v4 as uuidv4 } from "uuid";

import {
  AI_GENERATION_NOTIFICATIONS,
  AIGenerationNotificationOptions,
} from "@channels/aiGenerationNotifications";

import { generateTextSSE } from "@components/FormElements/Editor/api";
import {
  editorSelectionContainsInvalidElements,
  FroalaInserter,
} from "@components/FormElements/Editor/helpers";
import { useConfigurationStore } from "@stores";
import { generalNotification } from "@utils/helpers";
import { sanitizeHTML } from "@utils/helpers/security";

import {
  AIEditorOptions,
  AIGenerateTextActionType,
  AIGenerateTextType,
  AllAIGenerateTextActions,
} from "@components/FormElements/Editor/types";

export type AITextActionMessage = {
  type: AIGenerateTextType;
  editorId: string;
  prompt?: string;
};

type AITranslationLocale = {
  code: string;
  englishName: string;
  name: string;
};

export type AITextTone = {
  type: "professional" | "casual" | "straightforward" | "confident" | "friendly";
  name: string;
};

/**
 * Omitting all fields means that this AI feature works for all possible prompts (even empty ones).
 */
type AIGenerateTextTypeConstraints = {
  isPromptValid: (words: string[]) => boolean;
};

const typeConstraints: Record<AIGenerateTextActionType, AIGenerateTextTypeConstraints> = {
  completion: {
    isPromptValid: (words: string[]) => {
      const l = words.length;
      return (l > 2 || (l > 1 && words[1].length > 2)) && l < 300;
    },
  },
  fix_spelling_grammar: {
    isPromptValid: (words: string[]) => {
      return words.length > 0 && words[0].length > 0;
    },
  },
  improve_writing: {
    isPromptValid: (words: string[]) => {
      const l = words.length;
      return l > 1 && l < 900;
    },
  },
  make_longer: {
    isPromptValid: (words: string[]) => {
      const l = words.length;
      return l > 1 && l < 500;
    },
  },
  make_shorter: {
    isPromptValid: (words: string[]) => {
      const l = words.length;
      return l > 1 && l < 900;
    },
  },
  continue_sentence: {
    isPromptValid: (words: string[]) => {
      const l = words.length;
      return (l > 2 || (l > 1 && words[1].length > 2)) && l < 300;
    },
  },
  change_tone: {
    isPromptValid: (words: string[]) => {
      const l = words.length;
      return l > 1 && l < 900;
    },
  },
  translate: {
    isPromptValid: (words: string[]) => {
      return words.length > 0 && words[0].length > 0;
    },
  },
};

export const splitToWords = (string: string, max?: number): string[] => {
  return string.split(/[\s\p{P}]+/u, max);
};

export const isAIPromptValid = (
  prompt: string,
  type: AIGenerateTextType,
  words?: string[], // Provided as an optimization, in case function is called multiple times for the same prompt.
): boolean => {
  const constraints = typeConstraints[type.action];
  const actualWords = words ?? splitToWords(prompt.trim());
  return constraints.isPromptValid(actualWords);
};

export interface AIActions {
  translationLocales: AITranslationLocale[];
  textTones: AITextTone[];

  cancel: () => void;

  insert: (prompt: string, editor: ReactFroalaEditor) => void;
  improveWriting: () => void;
  continueSentence: () => void;
  fixGrammar: () => void;
  makeLonger: () => void;
  makeShorter: () => void;
  translate: (locale: AITranslationLocale) => void;
  changeTone: (tone: AITextTone) => void;

  canDoAction: (action: AIGenerateTextActionType) => boolean;
  isSelectionInvalid: () => boolean;
}

function showNotification(prompt: string, type: AIGenerateTextType, id: string): void {
  let literal = "";
  switch (type.action) {
    case "change_tone":
      literal = "changingTone";
      break;
    case "translate":
      literal = "translating";
      break;
    case "completion":
      literal = "generating";
      break;
    case "improve_writing":
      literal = "improvingWriting";
      break;
    case "fix_spelling_grammar":
      literal = "fixingSpelling";
      break;
    case "continue_sentence":
      literal = "continuingSentence";
      break;
    case "make_shorter":
      literal = "makingShorter";
      break;
    case "make_longer":
      literal = "makingLonger";
      break;
  }
  const message = t(`ai.notifications.${literal}`, { prompt: sanitizeHTML(prompt) });

  PubSub.publish(AI_GENERATION_NOTIFICATIONS, {
    id: id,
    message: message,
    action: "new",
  });
}

function closeNotification(id: string): void {
  PubSub.publish(AI_GENERATION_NOTIFICATIONS, {
    id: id,
    action: "close",
  });
}

export const AI_EDITOR_ACTIONS = "AI_EDITOR_ACTIONS";

export function useAIActions(
  editorId: string,
  editorInstance?: MutableRefObject<ReactFroalaEditor | null>,
  onAIStatusChanged?: (isWorking: boolean) => void,
): AIActions {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [currentId, setCurrentId] = useState<string | undefined>(undefined);

  const locales: AITranslationLocale[] = [
    { code: "en", englishName: "English", name: t("ai.aiToolbar.languages.English") },
    { code: "zh", englishName: "Chinese", name: t("ai.aiToolbar.languages.Chinese") },
    { code: "es", englishName: "Spanish", name: t("ai.aiToolbar.languages.Spanish") },
    { code: "ko", englishName: "Korean", name: t("ai.aiToolbar.languages.Korean") },
    { code: "ja", englishName: "Japanese", name: t("ai.aiToolbar.languages.Japanese") },
    { code: "ru", englishName: "Russian", name: t("ai.aiToolbar.languages.Russian") },
    { code: "fr", englishName: "French", name: t("ai.aiToolbar.languages.French") },
    { code: "po", englishName: "Portuguese", name: t("ai.aiToolbar.languages.Portuguese") },
    { code: "de", englishName: "German", name: t("ai.aiToolbar.languages.German") },
    { code: "it", englishName: "Italian", name: t("ai.aiToolbar.languages.Italian") },
    { code: "dum", englishName: "Dutch", name: t("ai.aiToolbar.languages.Dutch") },
    { code: "el", englishName: "Greek", name: t("ai.aiToolbar.languages.Greek") },
    { code: "id", englishName: "Indonesian", name: t("ai.aiToolbar.languages.Indonesian") },
    { code: "fil", englishName: "Filipino", name: t("ai.aiToolbar.languages.Filipino") },
    { code: "vi", englishName: "Vietnamese", name: t("ai.aiToolbar.languages.Vietnamese") },
  ];

  const textTones: AITextTone[] = [
    { type: "professional", name: t("ai.aiToolbar.textTone.professional") },
    { type: "casual", name: t("ai.aiToolbar.textTone.casual") },
    { type: "straightforward", name: t("ai.aiToolbar.textTone.straightforward") },
    { type: "confident", name: t("ai.aiToolbar.textTone.confident") },
    { type: "friendly", name: t("ai.aiToolbar.textTone.friendly") },
  ];

  const getEditor = (): ReactFroalaEditor | undefined => {
    return editorInstance?.current ?? undefined;
  };

  const getSelection = (): string | undefined => {
    const editor = getEditor();
    if (!editor) {
      return undefined;
    }

    return editor.selection.text();
  };

  const resetEditor = (editor = getEditor()): void => {
    onAIStatusChanged?.(false);
    setCurrentId((currentId) => {
      if (currentId) {
        closeNotification(currentId);
      }

      return undefined;
    });
    editor?.edit?.on();
    editor?.undo?.saveStep();
  };

  const cancel = (editor = getEditor()): void => {
    abortControllerRef.current?.abort();
    resetEditor(editor);
  };

  const insert = async (
    prompt: string,
    type: AIGenerateTextType,
    editor = getEditor(),
  ): Promise<void> => {
    if (!editor) {
      return;
    }

    editor.toolbar.hide();
    editor.edit.off();
    onAIStatusChanged?.(true);

    if (type.action === "completion") {
      editor.selection.restore();
    }

    const id = uuidv4();
    setCurrentId(id);
    abortControllerRef.current = new AbortController();
    showNotification(prompt, type, id);

    if (type.args?.clearEditor === true) {
      editor.html.set("");
    }

    let inserter: FroalaInserter | undefined = undefined;
    let isFirstInsert = true;

    await generateTextSSE(
      prompt,
      type,
      (result: string | boolean | Error) => {
        if (typeof result === "boolean" || result instanceof Error) {
          editor.html.set(editor.html.get());
          resetEditor(editor);
          return;
        }
        editor.events.focus();
        const cleanResult = result.replace(/\n/g, "<br />");

        if (isFirstInsert) {
          editor.html.insert(cleanResult, true);
          isFirstInsert = false;
        } else {
          if (!inserter) {
            inserter = new FroalaInserter(editor);
          }

          inserter?.insertPlainText(cleanResult);
        }
      },
      () => {
        generalNotification("error", t("ai.errors.textGenerationFailed"));
        cancel(editor);
      },
      abortControllerRef.current ?? undefined,
    );
  };

  const transformSelection = async (type: AIGenerateTextType): Promise<void> => {
    const selection = getSelection();
    if (!selection) {
      return;
    }

    return await insert(selection, type);
  };

  // Handle PubSub messages
  useEffect(() => {
    const subscription = async (_: string, message: AITextActionMessage): Promise<void> => {
      if (message.editorId !== editorId) {
        return;
      }

      if (message.type.action === "completion" && message.prompt) {
        await insert(message.prompt, message.type);
        return;
      }

      await transformSelection(message.type);
      return;
    };

    PubSub.subscribe(AI_EDITOR_ACTIONS, subscription);

    return () => {
      PubSub.unsubscribe(subscription);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorId, editorInstance]);

  // Listen to "STOP" button presses from AI generation notifications
  useEffect(() => {
    const subscription = (_: string, options: AIGenerationNotificationOptions): void => {
      if (options.action !== "cancel") {
        return;
      }

      if (options.id === currentId) {
        cancel();
      }
    };

    PubSub.subscribe(AI_GENERATION_NOTIFICATIONS, subscription);
    return () => {
      PubSub.unsubscribe(subscription);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId, editorInstance]);

  return {
    translationLocales: locales,
    textTones: textTones,
    cancel: cancel,
    insert: async (prompt: string, editor: ReactFroalaEditor): Promise<void> => {
      return await insert(prompt, { action: "completion" }, editor);
    },
    improveWriting: () => transformSelection({ action: "improve_writing" }),
    continueSentence: () => transformSelection({ action: "continue_sentence" }),
    fixGrammar: () => transformSelection({ action: "fix_spelling_grammar" }),
    makeLonger: () => transformSelection({ action: "make_longer" }),
    makeShorter: () => transformSelection({ action: "make_shorter" }),
    translate: (locale) =>
      transformSelection({
        action: "translate",
        args: {
          language: {
            name: locale.englishName,
            isoCode: locale.code,
          },
        },
      }),
    changeTone: (tone) =>
      transformSelection({
        action: "change_tone",
        args: {
          tone: tone.type,
        },
      }),
    canDoAction: (action): boolean => {
      if (action === "completion") {
        return true;
      }

      const prompt = getSelection();
      if (!prompt) {
        return false;
      }

      return isAIPromptValid(prompt, { action });
    },
    isSelectionInvalid: (): boolean => {
      return editorSelectionContainsInvalidElements(getEditor());
    },
  };
}

export function useAIEditorOptions(enabled = true, forceEnableWriteAbout = false): AIEditorOptions {
  const { userProfileData } = useConfigurationStore();
  const policies = userProfileData?.policies?.ai?.editor;
  const {
    can_write_about = false,
    can_change_tone = false,
    can_continue_sentence = false,
    can_fix_spelling_and_grammar = false,
    can_improve_writing = false,
    can_make_longer = false,
    can_make_shorter = false,
    can_translate = false,
  } = policies ?? {};

  const disabledActions = !policies
    ? Array.from(AllAIGenerateTextActions)
    : AllAIGenerateTextActions.filter((option) => {
        switch (option) {
          case "completion":
            return !can_write_about;
          case "change_tone":
            return !can_change_tone;
          case "continue_sentence":
            return !can_continue_sentence;
          case "fix_spelling_grammar":
            return !can_fix_spelling_and_grammar;
          case "improve_writing":
            return !can_improve_writing;
          case "make_longer":
            return !can_make_longer;
          case "make_shorter":
            return !can_make_shorter;
          case "translate":
            return !can_translate;
          default:
            return false; // Disables unknown (not mapped to a policy) actions
        }
      });

  return {
    aiEnabled: enabled,
    disabledActions,
    forceEnableWriteAbout,
  };
}
