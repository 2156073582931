import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["usersSettings"] => {
  return {
    SSOSettings: {
      fontColor: colors.secondary.darker,
      dividerColor: colors.secondary.lighter,
    },
  };
};
