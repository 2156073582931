/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";

import {
  AutomationsIconSVG,
  BillingSubscriptionIconSVG,
  BrainSVG,
  BranchIconSVG,
  BranchReportsSVG,
  CalendarSVG,
  CatalogSVG,
  CategoriesSVG,
  ConferenceSVG,
  CourseReportsSVG,
  CoursesIconSVG,
  CourseStoreSVG,
  CustomReportsSVG,
  EcommerceSVG,
  GamificationIconSVG,
  GearIconSVG,
  GradebookSVG,
  GroupReportsSVG,
  GroupsSVG,
  HelpSVG,
  HomeSVG,
  ImportExportIconSVG,
  IntegrationsIconSVG,
  LearningActivitiesSVG,
  MoreIconSVG,
  MyCoursesSVG,
  NotificationSVG,
  PortalIconSVG,
  ReportsSVG,
  SecurityIconSVG,
  TimelineSVG,
  TrainingMatrixSVG,
  UserAvatarSVG,
  UserReportsSVG,
  UsersIconSVG,
  UserTypeIconSVG,
} from "@epignosis_llc/gnosis/icons";

import { LockIconSolidSVG, LockOpenSolidSVG } from "@assets/images"; // TODO: Replace with gnosis icons after react18 bump in prod

import { URLS } from "@constants/urls";

import { MenuEntry } from "@components/MainNav/types";

import { CustomHomepageType } from "@views/CustomHomePage/types";
import { MainDrawerContentEnum } from "types/common";

const getIconAfter = (
  isTrialEnabled: boolean,
  canEnableReverseTrial: boolean,
): JSX.Element | null => {
  if (isTrialEnabled) {
    return <LockOpenSolidSVG height={24} />;
  }

  if (canEnableReverseTrial) {
    return <LockIconSolidSVG height={24} />;
  }

  return null;
};

const getCustomPages = (cms: CustomHomepageType[] | undefined): MenuEntry[] => {
  return cms?.map((page) => ({
    title: page.title,
    url: page.type === "url" ? page.url : `${URLS.customHomePage.privateDynamic(page.url)}`,
    icon: null,
    type: page.type,
    drawerKey: "customPage",
  })) as MenuEntry[];
};

export const getMenuEntries = ({
  cms,
  isTrialEnabled,
  canEnableReverseTrial,
}: {
  cms: CustomHomepageType[] | undefined;
  isTrialEnabled: boolean;
  canEnableReverseTrial: boolean;
}): MenuEntry[][] => {
  const iconAfter = getIconAfter(isTrialEnabled, canEnableReverseTrial);

  return [
    [
      {
        title: "mainMenu.home",
        url: URLS.dashboard,
        icon: <HomeSVG height={32} />,
        drawerKey: "dashboard",
      },
      {
        title: "mainMenu.users",
        url: URLS.users.users,
        icon: <UserAvatarSVG height={32} />,
        drawerKey: "users",
      },
      {
        title: "mainMenu.myCourses",
        url: URLS.user.courses,
        icon: <MyCoursesSVG height={32} />,
        drawerKey: "myCourses",
      },
      {
        title: "mainMenu.courses",
        url: URLS.courses.courses,
        icon: <MyCoursesSVG height={32} />,
        drawerKey: "courses",
      },
      {
        title: "mainMenu.courseStore",
        url: URLS.courseStore.index,
        icon: <CourseStoreSVG height={32} />,
        drawerKey: "courseStore",
        iconAfter: iconAfter,
      },
      {
        title: "mainMenu.courseCatalog",
        url: URLS.catalog.index,
        icon: <CatalogSVG height={32} />,
        drawerKey: "catalog",
      },
      {
        title: "mainMenu.groups",
        url: URLS.groups.groups,
        icon: <GroupsSVG height={32} />,
        drawerKey: "groups",
      },
      {
        title: "mainMenu.gradingHub",
        url: URLS.gradingHub.gradingHub,
        icon: <GradebookSVG height={32} />,
        drawerKey: "gradingHub",
      },
      {
        title: "mainMenu.branches",
        url: URLS.branches.branches,
        icon: <BranchIconSVG height={32} />,
        drawerKey: "branches",
        iconAfter: iconAfter,
      },
      {
        title: "general.automations",
        url: URLS.automations.automations,
        icon: <AutomationsIconSVG height={32} />,
        drawerKey: "automations",
        iconAfter: iconAfter,
      },
      {
        title: "general.notifications",
        url: URLS.notifications.notifications,
        icon: <NotificationSVG height={32} />,
        drawerKey: "notifications",
      },
      {
        title: "conferences.conferences",
        url: URLS.conferences.index,
        icon: <ConferenceSVG height={32} />,
        drawerKey: "conferences",
      },

      {
        title: "mainMenu.reports",
        url: URLS.reports.reports,
        icon: <ReportsSVG height={32} />,
        drawerKey: "reports",
        submenu: [
          {
            title: "mainMenu.reportsSubmenu.userReports",
            url: URLS.reports.userReports,
            icon: <UserReportsSVG height={32} />,
            drawerKey: "userReports",
          },
          {
            title: "mainMenu.reportsSubmenu.courseReports",
            url: URLS.reports.courseReports,
            icon: <CourseReportsSVG height={32} />,
            drawerKey: "courseReports",
          },
          {
            title: "mainMenu.reportsSubmenu.branchReports",
            url: URLS.reports.branchReports,
            icon: <BranchReportsSVG height={32} />,
            drawerKey: "branchReports",
          },
          {
            title: "mainMenu.reportsSubmenu.groupReports",
            url: URLS.reports.groupReports,
            icon: <GroupReportsSVG height={32} />,
            drawerKey: "groupReports",
          },
          {
            title: "mainMenu.reportsSubmenu.learningActivities",
            url: URLS.reports.learningActivities,
            icon: <LearningActivitiesSVG height={32} />,
            drawerKey: "learningActivities",
          },
          {
            title: "mainMenu.reportsSubmenu.trainingMatrix",
            url: URLS.reports.reports,
            icon: <TrainingMatrixSVG height={32} />,
            drawerKey: "trainingMatrix",
          },
          {
            title: "mainMenu.reportsSubmenu.timeline",
            url: URLS.reports.reports,
            icon: <TimelineSVG height={32} />,
            drawerKey: "timeline",
          },
          {
            title: "mainMenu.reportsSubmenu.customReports",
            url: URLS.reports.customReports,
            icon: <CustomReportsSVG height={32} />,
            drawerKey: "customReports",
          },
        ],
      },
      {
        title: "skills.skills",
        url: URLS.skills.skills,
        icon: <BrainSVG height={32} />,
        drawerKey: "skills",
        iconAfter: iconAfter,
      },
      {
        title: "general.accountAndSettings",
        url: URLS.accountAndSettings.accountAndSettings,
        icon: <GearIconSVG height={32} />,
        drawerKey: "accountAndSettings",
        submenu: [
          {
            title: "general.portal",
            url: URLS.accountAndSettings.accountAndSettings,
            icon: <PortalIconSVG height={32} />,
            drawerKey: "portalSettings",
          },
          {
            title: "general.users",
            url: URLS.accountAndSettings.accountAndSettings,
            icon: <UsersIconSVG height={32} />,
            drawerKey: "usersSettings",
          },
          {
            title: "mainMenu.accountsAndSettings.userTypes",
            url: URLS.accountAndSettings.accountAndSettings,
            icon: <UserTypeIconSVG height={32} />,
            drawerKey: "userTypeSettings",
          },
          {
            title: "general.courses",
            url: URLS.accountAndSettings.accountAndSettings,
            icon: <CoursesIconSVG height={32} />,
            drawerKey: "coursesSettings",
          },
          {
            title: "general.categories",
            url: URLS.accountAndSettings.accountAndSettings,
            icon: <CategoriesSVG height={32} />,
            drawerKey: "categoriesSettings",
          },
          {
            title: "skills.skills",
            url: URLS.accountAndSettings.accountAndSettings,
            icon: <BrainSVG height={32} />,
            drawerKey: "skillSettings",
          },
          {
            title: "general.gamification",
            url: URLS.accountAndSettings.accountAndSettings,
            icon: <GamificationIconSVG height={32} />,
            drawerKey: "gamificationSettings",
          },
          {
            title: "mainMenu.accountsAndSettings.ecommerce",
            url: URLS.accountAndSettings.accountAndSettings,
            icon: <EcommerceSVG height={32} />,
            drawerKey: "ecommerceSettings",
          },
          {
            title: "mainMenu.accountsAndSettings.integrations",
            url: URLS.accountAndSettings.accountAndSettings,
            icon: <IntegrationsIconSVG height={32} />,
            drawerKey: "integrationSettings",
          },
          {
            title: "mainMenu.accountsAndSettings.security",
            url: URLS.accountAndSettings.accountAndSettings,
            icon: <SecurityIconSVG height={32} />,
            drawerKey: "securitySettings",
          },
          {
            title: "mainMenu.accountsAndSettings.importExport",
            url: URLS.accountAndSettings.accountAndSettings,
            icon: <ImportExportIconSVG height={32} />,
            drawerKey: "importExportSettings",
          },
          // {
          //   Title: "mainMenu.accountsAndSettings.billingAndSubscription",
          //   Url: URLS.accountAndSettings.accountAndSettings,
          //   Icon: <BillingSubscriptionIconSVG height={32} />,
          //   DrawerKey: "billingAndSubscriptionSettings",
          // },
        ],
      },
      {
        title: "mainMenu.accountsAndSettings.billingAndSubscription",
        url: URLS.subscription.subscription,
        icon: <BillingSubscriptionIconSVG height={32} />,
        drawerKey: "billingAndSubscriptionSettings",
      },
      {
        title: "mainMenu.calendar",
        url: URLS.user.calendar,
        icon: <CalendarSVG height={32} />,
        drawerKey: "calendar",
      },
      {
        title: "skills.mySkills.skills",
        url: URLS.skills.mySkills,
        icon: <BrainSVG height={32} />,
        drawerKey: "mySkills",
      },
      {
        title: "mainMenu.more",
        url: "#",
        icon: <MoreIconSVG height={32} />,
        drawerKey: "customPages",
        submenu: getCustomPages(cms),
      },
    ],
    [
      {
        title: "mainMenu.helpCenter",
        url: null,
        icon: <HelpSVG className="help-icon" height={32} />,
        drawerKey: MainDrawerContentEnum.HELP_CENTER,
      },
    ],
  ];
};
