import Color from "color";

import { ThemePlusTypes } from "@styles/types";
import { getProtectedColor, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["subscription"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    buttonBackground: Color(colors.primary.lightest).alpha(0.25).string(),
    headerBackground: colors.secondary.lighter,
    headerText: protectColorContrast("darkest", colors.primary.base),
    plans: {
      textColor: colors.secondary.darker,
      textColorDisabled: colors.secondary.light,
      priceColor: protectColorContrast("darkest", colors.primary.base),
      borderColor: colors.secondary.light,
      borderColorSelected: protectColorContrast(
        "darker",
        Color(colors.primary.lightest).alpha(0.5).string(),
      ),
      toggleButtonColor: protectColorContrast(
        "darker",
        Color(colors.primary.lightest).alpha(0.25).string(),
      ),
      soonTag: Color(colors.green.light).alpha(0.15).string(),
    },
  };
};
