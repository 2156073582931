import Color from "color";

import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["leaderboard"] => {
  return {
    selectedUserBackground: Color(colors.primary.lightest).alpha(0.25).string(),
  };
};
