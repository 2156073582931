import React, { CSSProperties, FC } from "react";

import { SerializedStyles } from "@emotion/react";

import { tabWidget } from "@views/WidgetsDashboard/components/TabWidget/styles";

type TabWidgetProps = {
  style?: CSSProperties;
  isGamificationDrawer?: boolean;
  children?: React.ReactNode;
};

const TabWidget: FC<TabWidgetProps> = ({ style, isGamificationDrawer = false, children }) => {
  return (
    <div css={(): SerializedStyles => tabWidget(isGamificationDrawer)} style={style}>
      {children}
    </div>
  );
};

export default TabWidget;
