import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["activeButton"] => {
  return {
    background: colors.primary.light,
    borderColor: colors.primary.light,
    color: colors.white,
  };
};
