import React, { FC, useState } from "react";

import { SerializedStyles } from "@emotion/react";
import { Button, Dropdown, DropdownItem, Heading, Text, Tooltip } from "@epignosis_llc/gnosis";
import { FilterSVG } from "@epignosis_llc/gnosis/icons";
import { useToggle } from "ahooks";

import { tableActionsStyles } from "@components/CustomTable/components/styles";

import { getActiveFilters } from "@components/CustomTable/components/helpers";
import { useApplyTranslations } from "@hooks";
import { getSortingDropdownOptions } from "@utils/helpers";

import { MIN_FILTER_NUMBER } from "@components/CustomTable/constants";

import { DropdownButtonWithArrow, SearchInput } from "@components";
import ActiveFilters from "@components/CustomTable/components/Filters/ActiveFilters";
import { CustomTableActionsProps } from "@components/CustomTable/types";
import { MassActionType, SortingDropdown } from "@components/ReusableComponents";
import MassAction from "@components/ReusableComponents/MassActions/MassActions";

const CustomTableActions: FC<CustomTableActionsProps> = ({
  filterDropdownOptions = [],
  selectedFilters = [],
  resetInput,
  hideFilters = false,
  maxFilterWidth = 200,
  columns,
  sortingFilters,
  searchValue = "",
  tableTitle = "",
  selectedSorting,
  tableHasData = true,
  searchPlaceholder,
  massActionsProps,
  showShortingComponent = true,
  handleSorting,
  onFilterShow,
  onSearchChange,
  onResetAllFilters,
  onFilterSelect,
  onFilterRemove,
  fixPlacement,
  areFiltersCategorized = true,
  disabled = false,
  children,
  onSelectedItemsChange,
}) => {
  const { t } = useApplyTranslations();
  const {
    countRequest,
    massActionRequest,
    cleanState,
    handleInvalidateQueryMassActions,
    toggleMassActionDrawer,
    toggleGradeMassActionDrawer,
    getGroupsByUsersRequest,
    getBranchesByUsersRequest,
    handleShowOnCatalog,
    handlePreventSyncCompleted,
    originTableName,
    selectedRows,
    massActionsOptions = [],
    allowMassActions,
    enrollMode,
    renderOptionsAsButton = false,
    showOnCatalogState,
    preventSyncCompletedState,
    itemNameLabel,
  } = massActionsProps ?? {};

  const [massActionType, setMassActionType] = useState<MassActionType | null>(null);
  const [isMassActionModalOpen, { toggle: toggleMassActionModalOpen }] = useToggle(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const showResetFiltersButton = selectedFilters.length > 1 && onResetAllFilters;

  const allFilters = areFiltersCategorized
    ? filterDropdownOptions.map((filter) => filter.items ?? []).flat()
    : filterDropdownOptions;

  const shouldShowSearch = allFilters.length > MIN_FILTER_NUMBER;
  const activeFilters = getActiveFilters(allFilters, selectedFilters);

  const hasSortingComponent = columns && handleSorting;
  const shouldShowSortingComponent = hasSortingComponent && showShortingComponent;

  const shouldShowMassActionsButton =
    allowMassActions &&
    massActionsOptions.length > 0 &&
    ((typeof selectedRows === "number" && selectedRows > 0) || selectedRows === "all");

  const shouldShowMassActionsModals =
    massActionType && countRequest && massActionRequest && handleInvalidateQueryMassActions;

  const handleMassActionSelect = (item: DropdownItem): void => {
    if (disabled) return;

    if (item.value === "send_message") {
      toggleMassActionDrawer && toggleMassActionDrawer();
      return;
    }

    if (item.value === "grade") {
      toggleGradeMassActionDrawer && toggleGradeMassActionDrawer();
      return;
    }

    setMassActionType(item.value as MassActionType);
    toggleMassActionModalOpen();
  };

  const resetMassAction = (): void => {
    setMassActionType(null);
  };

  const handleFilterToggle = (): void => {
    setIsFilterOpen((f) => !f);
  };

  const handleResetFilters = (): void => {
    onResetAllFilters?.();
  };

  if (selectedFilters.length === 0 && !tableHasData) return <></>;

  return (
    <>
      <div
        css={(theme): SerializedStyles => tableActionsStyles(theme, { isFilterOpen })}
        className="actions-container"
      >
        <div className="start-actions-container">
          {/* Render sorting component */}
          {shouldShowSortingComponent && (
            <SortingDropdown
              sortingDropdownOptions={getSortingDropdownOptions(columns)}
              selectedSorting={selectedSorting}
              filters={sortingFilters}
              onSortingSelect={handleSorting}
              disabled={disabled}
            />
          )}
          {Boolean(onFilterShow) && (
            <Button
              onClick={(): void => {
                onFilterShow?.();
                handleFilterToggle();
              }}
              className="filters-button"
            >
              {t("general.filters")}
            </Button>
          )}
          {onSearchChange ? (
            <SearchInput
              id="table-search"
              value={searchValue}
              placeholder={searchPlaceholder ?? t("general.search")}
              onInputChanged={onSearchChange}
              resetInput={resetInput}
              className={"search-input-container"}
              disabled={disabled}
            />
          ) : (
            tableTitle && <Heading>{tableTitle}</Heading>
          )}
          {/* Render mass actions button */}
          {shouldShowMassActionsButton && (
            <DropdownButtonWithArrow
              label={t("general.massActions")}
              selectedRows={selectedRows}
              options={massActionsOptions}
              renderOptionsAsButton={renderOptionsAsButton}
              handleChange={handleMassActionSelect}
            />
          )}
          {/* Should hide filters if there are not filter options to populate. */}
          {!hideFilters && filterDropdownOptions.length > 0 && (
            <Dropdown
              list={filterDropdownOptions}
              onListItemSelect={onFilterSelect}
              fixPlacement={fixPlacement}
              isSearchable={shouldShowSearch}
              disabled={disabled}
            >
              <Tooltip
                content={t("general.filters")}
                disabled={disabled}
                parentProps={{ "aria-label": t("general.filters") }}
              >
                <Button
                  data-testid="filter-button"
                  color="primary"
                  variant="ghost"
                  as="div"
                  className="filter-button"
                  disabled={disabled}
                  noGutters
                >
                  <FilterSVG height={32} />
                </Button>
              </Tooltip>
            </Dropdown>
          )}
          <ActiveFilters
            activeFilters={activeFilters}
            onFilterRemove={onFilterRemove}
            maxFilterWidth={maxFilterWidth}
          />
          {showResetFiltersButton && (
            <Button
              aria-label={t("general.resetToDefault")}
              variant="link"
              className="reset-table-filters-button"
              data-testid="reset-table-filters-button"
              onClick={handleResetFilters}
            >
              <Text as="span" fontSize="xs">
                {t("general.resetToDefault")}
              </Text>
            </Button>
          )}
        </div>
        {children && <div className="end-actions-container">{children}</div>}
      </div>
      {shouldShowMassActionsModals && (
        <MassAction
          toggleMassActionModalOpen={toggleMassActionModalOpen}
          resetMassActionType={resetMassAction}
          type={massActionType}
          isOpen={isMassActionModalOpen}
          originTableName={originTableName}
          showOnCatalogState={showOnCatalogState}
          preventSyncCompletedState={preventSyncCompletedState}
          itemNameLabel={itemNameLabel}
          getGroupsByUsersRequest={getGroupsByUsersRequest}
          getBranchesByUsersRequest={getBranchesByUsersRequest}
          countRequest={countRequest}
          massActionRequest={massActionRequest}
          handleInvalidateQueryMassActions={handleInvalidateQueryMassActions}
          cleanState={cleanState}
          handleShowOnCatalog={handleShowOnCatalog}
          handlePreventSyncCompleted={handlePreventSyncCompleted}
          enrollMode={enrollMode}
          onSelectedItemsChange={onSelectedItemsChange}
        />
      )}
    </>
  );
};

export default CustomTableActions;
