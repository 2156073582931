import React, { FC } from "react";

import { SerializedStyles } from "@emotion/react";

import { verticalDividerStyles } from "@components/ReusableComponents/Divider/styles";

export type VerticalDividerProps = {
  height?: number;
};

const VerticalDivider: FC<VerticalDividerProps> = ({ height = 60 }) => {
  return (
    <span css={(theme): SerializedStyles => verticalDividerStyles(theme, { height })}>
      <div className="line" />
    </span>
  );
};

export default VerticalDivider;
