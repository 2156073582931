import React, { FC } from "react";

import { SerializedStyles } from "@emotion/react";
import { Heading, Text } from "@epignosis_llc/gnosis";
import { BadgesSVG, PercentageSVG, PointsSVG } from "@epignosis_llc/gnosis/icons";

import { rewardsTabContainer } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/styles";

import { useApplyTranslations } from "@hooks";
import { useConfigurationStore } from "@stores";
import { getProgress } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/helpers";

import RewardItem from "@views/WidgetsDashboard/components/Widgets/Leaderboard/RewardsItem";
import { RewardsTabProps } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/types";

const RewardsTab: FC<RewardsTabProps> = ({ userRewards, global }) => {
  const { t } = useApplyTranslations();
  const { points, badges, level } = userRewards ?? {};
  const { userProfileData, userStatistics } = useConfigurationStore();
  const userPoints = userProfileData?.points ?? 0;
  const userLevel = userProfileData?.level ?? 0;
  const userTotalBadges = userStatistics?.badges?.total ?? 0;

  return (
    <div css={(theme): SerializedStyles => rewardsTabContainer(theme)}>
      <section className="global-discount">
        <Text fontSize="sm" as="span">
          {t("rewards.biggestDiscount")}
        </Text>
      </section>

      <section className="my-reward-wrapper">
        {points?.threshold && (
          <RewardItem
            header={t("rewards.points")}
            userData={userPoints?.toString()}
            progress={getProgress(points.threshold, userPoints)}
            icon={<PointsSVG height={16} />}
            type="points"
            points={points}
          />
        )}

        {badges?.threshold && (
          <RewardItem
            header={t("rewards.badges")}
            userData={userTotalBadges?.toString()}
            progress={getProgress(badges.threshold, userTotalBadges)}
            icon={<BadgesSVG height={16} />}
            type="badges"
            badges={badges}
          />
        )}

        {level?.threshold && (
          <RewardItem
            header={t("rewards.level")}
            userData={userLevel?.toString()}
            progress={getProgress(level.threshold, userLevel)}
            icon={<PointsSVG height={16} />}
            type="level"
            level={level}
          />
        )}

        {Boolean(global) && (
          <div className="reward-container">
            <div className="text-container">
              <div className="text-icon-container">
                <div className="avatar" id="percentage-icon">
                  <PercentageSVG height={32} />
                </div>
                <Heading as="h3" size="sm" className="header-text">
                  {t("rewards.extra")}
                </Heading>
              </div>
            </div>
            <div className="text-progress-bar-container">
              <div className="text-points-container">
                <div className="reward-label">
                  <Text fontSize="sm">
                    {t("rewards.drawer.globalDiscount", {
                      percentage: global,
                    })}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default RewardsTab;
