import { AvailableTabs } from "@constants/tabs";
import { URLS } from "@constants/urls";

import { ArticleFilters } from "@components/Drawers/HelpCenter/types";

export const FILTERS: ArticleFilters = {
  section_id: null,
};

export enum HelpContent {
  article = "article",
  contactSupport = "contactSupport",
  ticket = "ticket_submission",
}

export enum TabKey {
  helpArticles = "help-articles",
  contactSupport = "contact-support",
}

export enum ArticleSearchingField {
  automation = "Automation Notifications",
  basicSettings = "Basic account themes cms domain",
  branch = "Branch",
  branchReports = "Branch reports",
  calendar = "Calendar",
  categoriesSettings = "Category Add category create category update category",
  certificatesSettings = "Certifications account",
  conference = "Conference",
  course = "Course",
  courseBranches = "Branches course",
  courseCatalog = "catalog",
  courseFiles = "Files course",
  courseGroups = "Groups course",
  courseReports = "Course reports",
  courseUsers = "Users course",
  coursesSettings = "Catalog certificate",
  createBranch = "Create branch",
  createConference = "Create conference",
  createCourse = "Create course",
  createCustomReports = "custom create reports",
  createDiscussion = "Create discussion",
  createGroup = "Create group",
  createMessages = "Create messages",
  createNotification = "Notification create notifications",
  createUser = "Create user",
  customCourseFields = "Custom course fields",
  customDomain = "Domain account",
  customizeBadges = "Customize badges account",
  customReports = "Custom reports",
  customUserFields = "Custom user fields",
  discussions = "Discussion",
  ecommerceSettings = "E-commerce account",
  editCustomReports = "custom edit reports",
  editNotification = "Notification edit notifications",
  gamificationSettings = "Gamification account",
  gradingHub = "Assignment ILT unit",
  group = "Group",
  groupReports = "Group reports",
  homepageSettings = "cms account",
  importExportFtp = "Import Export FTP",
  integrationSettings = "Integration connect",
  learningActivities = "Learning activities test reports survey reports assignment reports ILT reports Scorm reports",
  marketplace = "Marketplace",
  messages = "Messages",
  notification = "Notification",
  notificationHistory = "Notification history notifications",
  notificationPending = "Notification pending notifications",
  outgoingMessages = "Outgoing messages",
  profileUser = "Profile user",
  progressReports = "Progress reports",
  reports = "Reports",
  rulesCourse = "Rules course",
  runCustomReports = "custom run reports",
  securitySettings = "Password security account",
  ssoSettings = "SSO",
  subscriptionSettings = "Subscription account",
  systemNotifications = "Notification system notifications",
  talentLibrary = "Talent library marketplace",
  themesSettings = "Themes account",
  timeline = "Timeline reports",
  trainingMatrix = "Training matrix reports",
  updateBranch = "Update branch",
  updateCourse = "Update course",
  updateGroup = "Update group",
  updateUser = "Update user",
  user = "User",
  userBranches = "Branches user",
  userCourses = "Courses user",
  userFiles = "Files user",
  userGroups = "Groups user",
  userPayments = "Payments user",
  userProgress = "Progress user",
  userReports = "User reports",
  usersSettings = "Users account",
  userTypeSettings = "User types create user types update user types",
}

export enum ArticleSearchingFieldSpanish {
  automation = "Automatización Notificaciones",
  basicSettings = "Ajustes básicos temas cms dominio",
  branch = "Division",
  branchReports = "Division Informes",
  calendar = "Calendario",
  categoriesSettings = "Categoría añadir categoría crear categoría actualizar categoría",
  certificatesSettings = "Certificados cuenta",
  conference = "Conferencia",
  course = "Curso",
  courseBranches = "Curso division",
  courseCatalog = "Catálogo",
  courseFiles = "Archivos curso",
  courseGroups = "Grupos curso",
  courseReports = "Curso informes",
  courseUsers = "Usuarios curso",
  coursesSettings = "Catálogo Certificado",
  createBranch = "Crear division",
  createConference = "Crear conference",
  createCourse = "Crear curso",
  createCustomReports = "Personalizado crear informes",
  createDiscussion = "Crear discusión",
  createGroup = "Crear grupo",
  createMessages = "Crear mensajes",
  createNotification = "Notificación crear notificaciones",
  createUser = "Crear usuario",
  customCourseFields = "Campos de curso personalizados",
  customDomain = "Dominio cuenta",
  customizeBadges = "Personalizar insignias cuenta",
  customReports = "Informes",
  customUserFields = "Campos de usuario personalizados",
  discussions = "Discusión",
  ecommerceSettings = "E-commerce cuenta",
  editCustomReports = "Personalizado editar informes",
  editNotification = "Notificación editar notificaciones",
  gamificationSettings = "Gamificación cuenta",
  gradingHub = "Tarea ilts unidad",
  group = "Grupo",
  groupReports = "Grupo informes",
  homepageSettings = "cms cuenta",
  importExportFtp = "Importar exportar FTP",
  integrationSettings = "Integración conectar",
  learningActivities = "Actividades de aprendizaje informes pruebas encuestas asignacion ILT scorm",
  marketplace = "Marketplace",
  messages = "Mensajes",
  notification = "Notificación",
  notificationHistory = "Notificación historial notificaciones",
  notificationPending = "Notificación pendiente notificaciones",
  outgoingMessages = "Mensajes salientes",
  profileUser = "Perfil usuario",
  progressReports = "Progreso informes",
  reports = "Informes",
  rulesCourse = "Reglas curso",
  runCustomReports = "Personalizado ejecutar informes",
  securitySettings = "Contraseña seguridad cuenta",
  ssoSettings = "SSO",
  subscriptionSettings = "Suscripción cuenta",
  systemNotifications = "Notificación notificaciones sistema",
  talentLibrary = "Talent library marketplace",
  themesSettings = "Temas cuenta",
  timeline = "Línea de tiempo",
  trainingMatrix = "Matriz de formación informes",
  updateBranch = "Editar division",
  updateCourse = "Editar curso",
  updateGroup = "Editar grupo",
  updateUser = "Editar usuario",
  user = "Usuario",
  userBranches = "Divisiones usuario",
  userCourses = "cursos usuario",
  userFiles = "archivos usuario",
  userGroups = "groupos usuario",
  userPayments = "Pagos usuario",
  userProgress = "Progress user",
  userReports = "Usuario informes",
  usersSettings = "Usuarios cuenta",
  userTypeSettings = "Tipos de usuario crear tipos de usuario editar tipos de usuario",
}

const urlToArticleFieldMap: { [key: string]: ArticleSearchingField } = {
  [URLS.accountAndSettings.accountAndSettings]: ArticleSearchingField.basicSettings,
  [URLS.accountAndSettings.customizeTheming]: ArticleSearchingField.themesSettings,
  [URLS.customHomePage.customHomePage]: ArticleSearchingField.homepageSettings,
  [URLS.customHomePage.builder]: ArticleSearchingField.homepageSettings,
  [URLS.accountAndSettings.customDomain]: ArticleSearchingField.customDomain,
  [URLS.accountAndSettings.checkSSOConfig]: ArticleSearchingField.ssoSettings,
  [URLS.accountAndSettings.customUserFields]: ArticleSearchingField.customUserFields,
  [URLS.accountAndSettings.customCourseFields]: ArticleSearchingField.customCourseFields,
  [URLS.subscription.subscription]: ArticleSearchingField.subscriptionSettings,
  [URLS.subscription.subscriptionOptions]: ArticleSearchingField.subscriptionSettings,
  [URLS.accountAndSettings.certificates]: ArticleSearchingField.certificatesSettings,
  [URLS.user.profile]: ArticleSearchingField.profileUser,
  [URLS.user.customizeTheming]: ArticleSearchingField.themesSettings,
  [URLS.user.calendar]: ArticleSearchingField.calendar,
  // TODO: certificates are in URLS.user.progress and we have not "tab=" query param. we need to revisit this
  // [URLS.user.certificates]: ArticleSearchingField.certificatesSettings,
  [URLS.user.files]: ArticleSearchingField.userFiles,
  [URLS.user.badges]: ArticleSearchingField.customizeBadges,
  [URLS.user.branches]: ArticleSearchingField.userBranches,
  [URLS.user.courses]: ArticleSearchingField.userCourses,
  [URLS.user.messages]: ArticleSearchingField.messages,
  [URLS.user.newMessage]: ArticleSearchingField.createMessages,
  [URLS.user.discussions]: ArticleSearchingField.discussions,
  [URLS.user.newDiscussion]: ArticleSearchingField.createDiscussion,
  [URLS.user.groups]: ArticleSearchingField.userGroups,
  [URLS.user.payments]: ArticleSearchingField.userPayments,
  [URLS.user.progress]: ArticleSearchingField.userProgress,
  [URLS.catalog.index]: ArticleSearchingField.courseCatalog,
  [URLS.catalog.course]: ArticleSearchingField.courseCatalog,
  [URLS.courses.courses]: ArticleSearchingField.course,
  [URLS.courses.users]: ArticleSearchingField.courseUsers,
  [URLS.courses.edit]: ArticleSearchingField.updateCourse,
  [URLS.courses.singleCourse]: ArticleSearchingField.course,
  [URLS.branches.branches]: ArticleSearchingField.branch,
  [URLS.branches.branch]: ArticleSearchingField.branch,
  [URLS.branches.edit]: ArticleSearchingField.updateBranch,
  [URLS.groups.create]: ArticleSearchingField.createGroup,
  [URLS.groups.groups]: ArticleSearchingField.group,
  [URLS.groups.group]: ArticleSearchingField.group,
  [URLS.groups.edit]: ArticleSearchingField.updateGroup,
  [URLS.categories.categories]: ArticleSearchingField.categoriesSettings,
  [URLS.automations.automations]: ArticleSearchingField.automation,
  [URLS.notifications.notifications]: ArticleSearchingField.notification,
  [URLS.notifications.notificationHistory]: ArticleSearchingField.notificationHistory,
  [URLS.notifications.notificationHistoryItem]: ArticleSearchingField.notificationPending,
  [URLS.notifications.notificationsPending]: ArticleSearchingField.notificationPending,
  [URLS.notifications.notificationPendingItem]: ArticleSearchingField.notificationPending,
  [URLS.notifications.systemNotification]: ArticleSearchingField.systemNotifications,
  [URLS.users.users]: ArticleSearchingField.user,
  [URLS.users.create]: ArticleSearchingField.createUser,
  [URLS.users.user]: ArticleSearchingField.user,
  [URLS.users.edit]: ArticleSearchingField.updateUser,
  [URLS.reports.reports]: ArticleSearchingField.reports,
  [URLS.reports.branchReports]: ArticleSearchingField.branchReports,
  [URLS.reports.branch]: ArticleSearchingField.branchReports,
  [URLS.reports.courseReports]: ArticleSearchingField.courseReports,
  [URLS.reports.course]: ArticleSearchingField.courseReports,
  [URLS.reports.userReports]: ArticleSearchingField.userReports,
  [URLS.reports.user]: ArticleSearchingField.userReports,
  [URLS.reports.groupReports]: ArticleSearchingField.groupReports,
  [URLS.reports.group]: ArticleSearchingField.groupReports,
  [URLS.reports.customReports]: ArticleSearchingField.customReports,
  [URLS.reports.learningActivities]: ArticleSearchingField.learningActivities,
  [URLS.gradingHub.gradingHub]: ArticleSearchingField.gradingHub,
  [URLS.courseStore.index]: ArticleSearchingField.talentLibrary,
  [URLS.courseStore.course]: ArticleSearchingField.talentLibrary,
  [URLS.user.inboxMessage]: ArticleSearchingField.messages,
  [AvailableTabs.portalSettings]: ArticleSearchingField.basicSettings,
  [AvailableTabs.usersSettings]: ArticleSearchingField.usersSettings,
  [AvailableTabs.userTypeSettings]: ArticleSearchingField.userTypeSettings,
  [AvailableTabs.coursesSettings]: ArticleSearchingField.coursesSettings,
  [AvailableTabs.categoriesSettings]: ArticleSearchingField.categoriesSettings,
  [AvailableTabs.gamificationSettings]: ArticleSearchingField.gamificationSettings,
  [AvailableTabs.integrationSettings]: ArticleSearchingField.integrationSettings,
  [AvailableTabs.securitySettings]: ArticleSearchingField.securitySettings,
  [AvailableTabs.importExportSettings]: ArticleSearchingField.importExportFtp,
  [AvailableTabs.subscription]: ArticleSearchingField.subscriptionSettings,
  [AvailableTabs.trainingMatrix]: ArticleSearchingField.trainingMatrix,
  [AvailableTabs.timeline]: ArticleSearchingField.timeline,
  [AvailableTabs.userReports]: ArticleSearchingField.userReports,
  [AvailableTabs.courseReports]: ArticleSearchingField.courseReports,
  [AvailableTabs.branchReports]: ArticleSearchingField.branchReports,
  [AvailableTabs.groupReports]: ArticleSearchingField.groupReports,
  [AvailableTabs.learningActivities]: ArticleSearchingField.learningActivities,
  [AvailableTabs.customReports]: ArticleSearchingField.customReports,
  [AvailableTabs.notificationsOverview]: ArticleSearchingField.notification,
  [AvailableTabs.notificationsHistory]: ArticleSearchingField.notificationHistory,
  [AvailableTabs.notificationsPending]: ArticleSearchingField.notificationPending,
  [AvailableTabs.notificationsSystem]: ArticleSearchingField.systemNotifications,
};

const urlToArticleFieldMapSpanish: { [key: string]: ArticleSearchingFieldSpanish } = {
  [URLS.accountAndSettings.accountAndSettings]: ArticleSearchingFieldSpanish.basicSettings,
  [URLS.accountAndSettings.customizeTheming]: ArticleSearchingFieldSpanish.themesSettings,
  [URLS.customHomePage.builder]: ArticleSearchingFieldSpanish.homepageSettings,
  [URLS.accountAndSettings.customDomain]: ArticleSearchingFieldSpanish.customDomain,
  [URLS.accountAndSettings.checkSSOConfig]: ArticleSearchingFieldSpanish.ssoSettings,
  [URLS.accountAndSettings.customUserFields]: ArticleSearchingFieldSpanish.customUserFields,
  [URLS.accountAndSettings.customCourseFields]: ArticleSearchingFieldSpanish.customCourseFields,
  [URLS.subscription.subscription]: ArticleSearchingFieldSpanish.subscriptionSettings,
  [URLS.subscription.subscriptionOptions]: ArticleSearchingFieldSpanish.subscriptionSettings,
  [URLS.accountAndSettings.certificates]: ArticleSearchingFieldSpanish.certificatesSettings,
  [URLS.user.profile]: ArticleSearchingFieldSpanish.profileUser,
  [URLS.user.customizeTheming]: ArticleSearchingFieldSpanish.themesSettings,
  [URLS.user.calendar]: ArticleSearchingFieldSpanish.calendar,
  // TODO: certificates are in URLS.user.progress and we have not "tab=" query param. we need to revisit this
  // [URLS.user.certificates]: ArticleSearchingFieldSpanish.certificatesSettings,
  [URLS.user.files]: ArticleSearchingFieldSpanish.userFiles,
  [URLS.user.badges]: ArticleSearchingFieldSpanish.customizeBadges,
  [URLS.user.branches]: ArticleSearchingFieldSpanish.userBranches,
  [URLS.user.courses]: ArticleSearchingFieldSpanish.userCourses,
  [URLS.user.messages]: ArticleSearchingFieldSpanish.messages,
  [URLS.user.newMessage]: ArticleSearchingFieldSpanish.createMessages,
  [URLS.user.discussions]: ArticleSearchingFieldSpanish.discussions,
  [URLS.user.newDiscussion]: ArticleSearchingFieldSpanish.createDiscussion,
  [URLS.user.groups]: ArticleSearchingFieldSpanish.userGroups,
  [URLS.user.payments]: ArticleSearchingFieldSpanish.userPayments,
  [URLS.user.progress]: ArticleSearchingFieldSpanish.userProgress,
  [URLS.catalog.index]: ArticleSearchingFieldSpanish.courseCatalog,
  [URLS.catalog.course]: ArticleSearchingFieldSpanish.courseCatalog,
  [URLS.courses.courses]: ArticleSearchingFieldSpanish.course,
  [URLS.courses.users]: ArticleSearchingFieldSpanish.courseUsers,
  [URLS.courses.edit]: ArticleSearchingFieldSpanish.updateCourse,
  [URLS.courses.singleCourse]: ArticleSearchingFieldSpanish.course,
  [URLS.branches.branches]: ArticleSearchingFieldSpanish.branch,
  [URLS.branches.branch]: ArticleSearchingFieldSpanish.branch,
  [URLS.branches.edit]: ArticleSearchingFieldSpanish.updateBranch,
  [URLS.groups.create]: ArticleSearchingFieldSpanish.createGroup,
  [URLS.groups.groups]: ArticleSearchingFieldSpanish.group,
  [URLS.groups.group]: ArticleSearchingFieldSpanish.group,
  [URLS.groups.edit]: ArticleSearchingFieldSpanish.updateGroup,
  [URLS.categories.categories]: ArticleSearchingFieldSpanish.categoriesSettings,
  [URLS.automations.automations]: ArticleSearchingFieldSpanish.automation,
  [URLS.notifications.notifications]: ArticleSearchingFieldSpanish.notification,
  [URLS.notifications.notificationHistory]: ArticleSearchingFieldSpanish.notificationHistory,
  [URLS.notifications.notificationHistoryItem]: ArticleSearchingFieldSpanish.notificationPending,
  [URLS.notifications.notificationsPending]: ArticleSearchingFieldSpanish.notificationPending,
  [URLS.notifications.notificationPendingItem]: ArticleSearchingFieldSpanish.notificationPending,
  [URLS.notifications.systemNotification]: ArticleSearchingFieldSpanish.systemNotifications,
  [URLS.users.users]: ArticleSearchingFieldSpanish.user,
  [URLS.users.create]: ArticleSearchingFieldSpanish.createUser,
  [URLS.users.user]: ArticleSearchingFieldSpanish.user,
  [URLS.users.edit]: ArticleSearchingFieldSpanish.updateUser,
  [URLS.reports.reports]: ArticleSearchingFieldSpanish.reports,
  [URLS.reports.branchReports]: ArticleSearchingFieldSpanish.branchReports,
  [URLS.reports.branch]: ArticleSearchingFieldSpanish.branchReports,
  [URLS.reports.courseReports]: ArticleSearchingFieldSpanish.courseReports,
  [URLS.reports.course]: ArticleSearchingFieldSpanish.courseReports,
  [URLS.reports.userReports]: ArticleSearchingFieldSpanish.userReports,
  [URLS.reports.user]: ArticleSearchingFieldSpanish.userReports,
  [URLS.reports.groupReports]: ArticleSearchingFieldSpanish.groupReports,
  [URLS.reports.group]: ArticleSearchingFieldSpanish.groupReports,
  [URLS.reports.customReports]: ArticleSearchingFieldSpanish.customReports,
  [URLS.reports.learningActivities]: ArticleSearchingFieldSpanish.learningActivities,
  [URLS.gradingHub.gradingHub]: ArticleSearchingFieldSpanish.gradingHub,
  [URLS.courseStore.index]: ArticleSearchingFieldSpanish.talentLibrary,
  [URLS.courseStore.course]: ArticleSearchingFieldSpanish.talentLibrary,
  [URLS.user.inboxMessage]: ArticleSearchingFieldSpanish.messages,
  [AvailableTabs.portalSettings]: ArticleSearchingFieldSpanish.basicSettings,
  [AvailableTabs.usersSettings]: ArticleSearchingFieldSpanish.usersSettings,
  [AvailableTabs.userTypeSettings]: ArticleSearchingFieldSpanish.userTypeSettings,
  [AvailableTabs.coursesSettings]: ArticleSearchingFieldSpanish.coursesSettings,
  [AvailableTabs.categoriesSettings]: ArticleSearchingFieldSpanish.categoriesSettings,
  [AvailableTabs.gamificationSettings]: ArticleSearchingFieldSpanish.gamificationSettings,
  [AvailableTabs.integrationSettings]: ArticleSearchingFieldSpanish.integrationSettings,
  [AvailableTabs.securitySettings]: ArticleSearchingFieldSpanish.securitySettings,
  [AvailableTabs.importExportSettings]: ArticleSearchingFieldSpanish.importExportFtp,
  [AvailableTabs.subscription]: ArticleSearchingFieldSpanish.subscriptionSettings,
  [AvailableTabs.trainingMatrix]: ArticleSearchingFieldSpanish.trainingMatrix,
  [AvailableTabs.timeline]: ArticleSearchingFieldSpanish.timeline,
  [AvailableTabs.userReports]: ArticleSearchingFieldSpanish.userReports,
  [AvailableTabs.courseReports]: ArticleSearchingFieldSpanish.courseReports,
  [AvailableTabs.branchReports]: ArticleSearchingFieldSpanish.branchReports,
  [AvailableTabs.groupReports]: ArticleSearchingFieldSpanish.groupReports,
  [AvailableTabs.learningActivities]: ArticleSearchingFieldSpanish.learningActivities,
  [AvailableTabs.customReports]: ArticleSearchingFieldSpanish.customReports,
  [AvailableTabs.notificationsOverview]: ArticleSearchingFieldSpanish.notification,
  [AvailableTabs.notificationsHistory]: ArticleSearchingFieldSpanish.notificationHistory,
  [AvailableTabs.notificationsPending]: ArticleSearchingFieldSpanish.notificationPending,
  [AvailableTabs.notificationsSystem]: ArticleSearchingFieldSpanish.systemNotifications,
};

export const getArticleSearchingField = (
  pathname: string,
  locale: string,
): ArticleSearchingField | ArticleSearchingFieldSpanish | undefined => {
  if (locale === "es-ES") {
    return urlToArticleFieldMapSpanish[pathname];
  }
  return urlToArticleFieldMap[pathname];
};
