import { TableProps } from "@epignosis_llc/gnosis";

import { useUserPreferencesStore } from "@stores";
import { tableFilterSearch } from "@utils/helpers/filters";
import { defineSortOrder } from "@utils/helpers/view";

import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

export type DefaultStateType = {
  pagination: IPagination;
  filters: QueryFilter[];
  sorting: TableProps["sorting"];
  searchValue?: string;
  url?: string;
};

const handlePaginatedTableState = ({
  sorting,
  filters,
  pagination,
  url,
}: DefaultStateType): DefaultStateType => {
  if (!url) {
    return {
      pagination: {
        number: pagination.number,
        size: pagination.size,
      },
      filters,
      sorting,
      searchValue: tableFilterSearch(filters),
      url,
    };
  }

  const userStore = useUserPreferencesStore;
  const { getState: getUserPreferencesState } = userStore();
  const { settings } = getUserPreferencesState() ?? {};

  const sortSetting = settings[url]?.sorting?.[0];
  const sortOrder = sortSetting && defineSortOrder(sortSetting);

  // Build default values from user preferences
  const defaultSorting = sortOrder
    ? {
        column: sortOrder.str,
        isDescending: sortOrder.hasMinus,
      }
    : sorting;

  const filtersTable = settings[url]?.filters as QueryFilter[];
  const defaultFilters = filtersTable?.length ? filtersTable : filters;
  const defaultPagination = settings[url]?.pagination ?? pagination;

  return {
    pagination: {
      number: defaultPagination.number,
      size: defaultPagination.size,
    },
    filters: defaultFilters,
    sorting: defaultSorting,
    searchValue: tableFilterSearch(defaultFilters),
    url,
  };
};

export default handlePaginatedTableState;
