import { config } from "@config";

export default {
  skills: true,
  ai: true,
  openSesame: true,
  // NOT_PRODUCTION_READY
  groupProvisioning: false,
  fetchOnWindowFocus: false,
  enrollmentRequests: true,
  unitUsersMassActions: !config.isProd(),
  ai_captions: true,
} as const;
