import { COLORS } from "@styles/constants";
import { getGnosisTheme } from "@styles/themes/gnosis";
import { getPlusTheme } from "@styles/themes/plus";
import { palletColorsGenerator } from "@styles/utils";

export const THEME_COLOURS = {
  ...COLORS,
  primary: "#F20000",
};

const colors = palletColorsGenerator(THEME_COLOURS);
const defaultRed = { ...getGnosisTheme(colors), ...getPlusTheme(colors) };

export default defaultRed;
