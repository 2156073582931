import { t } from "@utils/i18n";
import { yup } from "@utils/validation/validation";

const forbiddenKeywords = ["first name", "last name", "email"];

export const CustomUserFieldSchema = yup.object().shape({
  type: yup.string(),
  dropdown_items: yup
    .array()
    .nullable()
    .when("type", {
      is: "dropdown",
      then: yup
        .array()
        .of(
          yup.object().shape({
            item: yup.string().max(150),
            default: yup.boolean(),
          }),
        )
        .min(1)
        .max(5000),
    }),
  max_length: yup.number().nullable(),
  name: yup
    .string()
    .required()
    .trim()
    .max(80)
    .test(
      "is-forbidden-keyword",
      ({ value }) =>
        t("accountAndSettings.usersSettings.customUserFieldsError", { value: value.toLowerCase() }),
      (value) =>
        !forbiddenKeywords.some((keyword) => value?.toLowerCase() === keyword.toLowerCase()),
    ),
});
