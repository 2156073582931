import { handleCourseErrors } from "@errors";
import { handleAssignmentErrors } from "@errors/assignmentErrors";
import {
  handleBranchesMassActionsErrors,
  handleCustomReportErrors,
  handleGenericMassActionsErrors,
  handleGroupUsersMassActionsErrors,
  handleUsersMassActionsErrors,
} from "@errors/errors";
import {
  handleAssignSkillsToUsersErrors,
  handleUnassignSkillsToUsersErrors,
} from "@views/Skills/errors/errors";

import { submissionsMassActionsWordings } from "@components/Submissions/constants";
import { branchesMassActionWordings } from "@views/Branches/constants";
import { enrollUsersToCourseWordings } from "@views/Course/CourseUsers/components/constants";
import {
  courseApprovalRequestsWordings,
  courseUsersWordings,
} from "@views/Course/CourseUsers/constants";
import { coursesMassActionWordings } from "@views/Courses/constants";
import { courseStoreMassActionsWordings } from "@views/CourseStore/constants";
import { groupUsersMassActionsWordings } from "@views/Group/components/GroupUsers/constants";
import { groupsMassActionWordings } from "@views/Groups/constants";
import { customReportMassActionsWordings } from "@views/Reports/CustomReports/components/constants";
import { userUnitsMassActionsWordings } from "@views/Reports/UnitReports/Unit/constants";
import { assignSkillsToUsersWordings } from "@views/Skills/components/SkillsGrid/components/AssignSkillsDrawer/components/constants";
import { unassignSkillsToUsersWordings } from "@views/Skills/components/UsersTab/constants";
import { enrollUsersToSessionWordings } from "@views/UnitEdit/components/IltUnit/components/AddUsersTable/constants";
import { userCoursesWordings } from "@views/User/components/Courses/constants";
import { usersMassActionsWordings } from "@views/Users/constants";

import { TableItemsReturn } from "@components/ReusableComponents/MassActions/types";
import { ConfirmationButtonColor } from "@components/ReusableModals/ConfirmationModal";

type MassActionWordings = {
  progressHeader: string;
  progressMessage: string;
  confirmationHeader: string;
  confirmationButton?: string;
  successMessage: string;
  confirmationBodyText?: string;
  confirmationBodyTitle?: string;
  confirmationButtonColor?: ConfirmationButtonColor;
  limitReachedMessage?: string;
  prerequisiteWarningMessage?: string;
  showOnCatalog?: string;
  preventSyncCompleted?: string;
};

export type Wordings = Record<string, MassActionWordings>;

export const getTableItems = (tableItem: string): TableItemsReturn => {
  switch (tableItem) {
    case "courses":
      return {
        wordings: coursesMassActionWordings,
        errorHandler: handleCourseErrors,
      };
    case "branches":
      return {
        wordings: branchesMassActionWordings,
        errorHandler: handleBranchesMassActionsErrors,
      };
    case "groups":
      return {
        wordings: groupsMassActionWordings,
        errorHandler: handleGenericMassActionsErrors,
      };
    case "courseUsers":
      return {
        wordings: courseUsersWordings,
        errorHandler: handleCourseErrors,
      };
    case "enrollCourseUsers":
      return {
        wordings: enrollUsersToCourseWordings,
        errorHandler: handleCourseErrors,
      };
    case "users":
      return {
        wordings: usersMassActionsWordings,
        errorHandler: handleUsersMassActionsErrors,
      };
    case "unitUsers":
      return {
        wordings: userUnitsMassActionsWordings,
        errorHandler: handleGenericMassActionsErrors,
      };
    case "groupUsers":
      return {
        wordings: groupUsersMassActionsWordings,
        errorHandler: handleGroupUsersMassActionsErrors,
      };
    case "assignments":
      return {
        wordings: submissionsMassActionsWordings,
        errorHandler: handleAssignmentErrors,
      };
    case "courseStore":
      return {
        wordings: courseStoreMassActionsWordings,
        errorHandler: handleCourseErrors,
      };
    case "customReport":
      return {
        wordings: customReportMassActionsWordings,
        errorHandler: handleCustomReportErrors,
      };
    case "addToSession":
      return {
        wordings: enrollUsersToSessionWordings,
        errorHandler: handleAssignmentErrors,
      };
    case "assignSkills":
      return {
        wordings: assignSkillsToUsersWordings,
        errorHandler: handleAssignSkillsToUsersErrors,
      };
    case "unassignSkills":
      return {
        wordings: unassignSkillsToUsersWordings,
        errorHandler: handleUnassignSkillsToUsersErrors,
      };
    case "enrollmentRequests":
      return {
        wordings: courseApprovalRequestsWordings,
        errorHandler: handleCourseErrors,
      };
    case "userCourses":
      return {
        wordings: userCoursesWordings,
        errorHandler: handleCourseErrors,
      };
    default:
      return {
        wordings: undefined,
        errorHandler: undefined,
      };
  }
};
export const coursesSearchQueryObject = {
  pagination: { number: 1, size: 10000 },
  filters: [{ key: "[status]", value: "active" }],
  sorting: ["name"],
};

// Here add the types that trigger count on first modal open
export const triggerCountOnMountTypes = [
  "activate",
  "deactivate",
  "delete",
  "show_in_catalog",
  "hide_from_catalog",
  "enroll",
  "unenroll",
  "reset",
  "sync",
  "complete",
  "update_certificates",
  "set_expiration_date",
  "complete_units",
  "reset_units",
  "add_users_to_group_courses",
  "remove_users_from_group_courses",
  "course_store_mass_acquire",
  "custom_reports_delete_users",
  "remove_from_session",
  "add_to_session",
  "assign_skills",
  "unassign_skills",
  "approve",
  "decline",
];
