import React, { FC } from "react";

import { SerializedStyles } from "@emotion/react";
import { Text, Tooltip } from "@epignosis_llc/gnosis";
import { t } from "i18next";

import { gamificationLeaderboardContainer } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/styles";

import { icons } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/constants";

import { OverviewStatisticsPanelProps } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/types";

const OverviewStatisticsPanel: FC<OverviewStatisticsPanelProps> = ({ overviewData }) => (
  <div css={(theme): SerializedStyles => gamificationLeaderboardContainer(theme)} tabIndex={0}>
    {Object.keys(overviewData).map((key) => {
      const GamificationIcon = icons[key];
      const value = overviewData[key].user?.score ?? overviewData[key].user?.length;

      return (
        <div key={key}>
          <Tooltip
            content={t(
              `widgetsDashboard.achievements.overview.${
                overviewData[key] && overviewData[key].title
              }`,
            )}
          >
            <div className="stat-container">
              <GamificationIcon height={32} />
              <Text fontSize="lg" weight="700" className="stat-value">
                {value ?? 0}
              </Text>
            </div>
          </Tooltip>
        </div>
      );
    })}
  </div>
);

export default OverviewStatisticsPanel;
