import React, { FC } from "react";

import { useApplyTranslations } from "@hooks";

import { footerButtonTranslations } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/constants";

import { ActionDrawer } from "@components";
import CollectMorePoints from "@views/WidgetsDashboard/components/Widgets/Leaderboard/CollectMorePoints";
import { GamificationInfoDrawerProps } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/types";

const GamificationInfoDrawer: FC<GamificationInfoDrawerProps> = ({
  currentTab,
  isOpen,
  onClose,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionDrawer
      isOpen={isOpen}
      headerTitle={t(footerButtonTranslations[currentTab])}
      onClose={onClose}
      size="md"
      hasBackButton
      showFooter={false}
    >
      <CollectMorePoints isPoints={currentTab === "points"} />
    </ActionDrawer>
  );
};

export default GamificationInfoDrawer;
