import { GnosisTheme } from "@epignosis_llc/gnosis";

import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): GnosisTheme["modal"] => ({
  iconHover: colors.secondary.base,
  color: colors.black,
  border: colors.secondary.lighter,
  content: colors.white,
});
