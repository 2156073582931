import { css } from "@emotion/react";

import { getPadding } from "@views/WidgetsDashboard/components/Widgets/components/helpers";

export const emptyStateContainer = ({ icon, widgets }) => css`
  position: relative;

  .empty-state-container {
    position: absolute;
    top: 3rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;

    .empty-state {
      &-icon {
        display: flex;
        justify-content: center;
        color: ${icon.primary};
      }

      &-body {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      &-description {
        color: ${widgets.descriptionColor};
      }

      &-footer {
        margin-top: 1rem;
      }
    }
  }
`;

export const summaryPanelStyles = (
  { colors },
  isReportPage,
  isInsideAchievementsWidget,
  isQuickActions,
) => css`
  height: 100%;

  .all-stats-container {
    height: ${isReportPage && !isInsideAchievementsWidget ? "16.25rem" : "unset"};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .icon-container {
      display: flex;
      padding: 0.5rem;
    }

    .stats-item-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: ${getPadding(isQuickActions, isReportPage, isInsideAchievementsWidget)};
      align-items: center;
      cursor: ${isQuickActions ? "pointer" : "default"};

      &:hover {
        background-color: ${isQuickActions ? colors.secondary.lighter : "transparent"};
        border-radius: 0.3125rem;

        .hover-icon {
          display: ${isQuickActions ? "unset" : "none"};
        }
      }

      .hover-icon {
        display: none;
      }

      .stats-icon-text-container {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
  }
`;
