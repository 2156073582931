import React, { FC, PropsWithChildren } from "react";

import { CourseEditLayoutStyles } from "@views/CourseEdit/components/CourseEditLayout/styles";

import Content, { ContentProps } from "@views/CourseEdit/components/CourseEditLayout/Content";
import Footer from "@views/CourseEdit/components/CourseEditLayout/Footer";
import Main, { MainProps } from "@views/CourseEdit/components/CourseEditLayout/Main";
import Sidebar, { SidebarProps } from "@views/CourseEdit/components/CourseEditLayout/Sidebar";

type CourseEditLayoutProps = {
  Sidebar: SidebarProps;
  Main: MainProps;
  Content: ContentProps;
  Footer: FC<PropsWithChildren>;
};

type CourseEditLayoutPropsWithChildren = {
  children?: React.ReactNode;
};

const CourseEditLayout: FC<CourseEditLayoutPropsWithChildren> & CourseEditLayoutProps = ({
  children,
}) => <div css={CourseEditLayoutStyles}>{children}</div>;

CourseEditLayout.Sidebar = Sidebar;
CourseEditLayout.Main = Main;
CourseEditLayout.Content = Content;
CourseEditLayout.Footer = Footer;

export default CourseEditLayout;
