import React, { useEffect, useState } from "react";
import { components, InputProps } from "react-select";

import { CloseSVG } from "@epignosis_llc/gnosis/icons";

import { customInputContainer } from "@components/CustomReactSelect/styles";

import { useApplyTranslations } from "@hooks";

import { Option } from "@components/MainSearch/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomInput = (props: InputProps<any>): JSX.Element => {
  const { t } = useApplyTranslations();
  const [defaultValue, setDefaultValue] = useState<Option | null>(null);

  const { hasValue, selectProps, setValue, clearValue, "aria-label": ariaLabel } = props;

  const { value, inputValue, onChange, onInputChange } = selectProps; // Select props
  const showClearButton = hasValue && JSON.stringify(value) !== JSON.stringify(defaultValue); // Here we check if the value that the input already has is the same as the default value

  const handleInputClear = (): void => {
    // If we have a default value reset to this
    if (defaultValue) {
      const { value, label } = defaultValue;
      const selectedOption = { label, value };

      onChange(selectedOption, { action: "select-option", option: null });
      onInputChange("", { action: "input-change", prevInputValue: "" });
    } else {
      setValue("", "select-option");
      clearValue();
    }
  };

  // Check if there is a value, means there is a default value set to the select
  useEffect(() => {
    if (value) {
      setDefaultValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={customInputContainer}>
      <components.Input
        data-lpignore="true"
        {...props}
        aria-label={ariaLabel}
        className="custom-input"
      />

      {(showClearButton || Boolean(inputValue)) && (
        <button
          type="button"
          className="close-btn"
          onClick={handleInputClear}
          aria-label={`${t("general.clear")} ${ariaLabel}`}
        >
          <CloseSVG height={16} />
        </button>
      )}
    </div>
  );
};

export default CustomInput;
