import { css } from "@emotion/react";

export const textWithStatusContainer = css`
  .column-description {
    display: flex;
    align-items: center;
    margin-inline-end: 1rem;

    .overflow-text {
      word-break: break-word;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .multiple-status-tags {
      display: flex;
      gap: 0.5rem;
      margin: 0 0.5rem;
    }

    .status-tag {
      padding: 0.2rem 0.5rem;
      white-space: nowrap;
      border-radius: 2.5px;
      -webkit-margin-start: 0.5rem;
      margin-inline-start: 0.5rem;
    }

    .instructor-icon-container {
      line-height: 0;
      padding-inline-start: 0.5rem;
    }
  }
`;
