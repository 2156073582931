import { useMemo } from "react";
import { useQuery } from "react-query";

import { getMyBadges, getUserCertificates } from "@api/app";
import { getMyCourses } from "@api/courses";
import { getLeaderboard } from "@api/gamification";
import { getUserRewards } from "@api/user";
import { useConfigurationStore } from "@stores";
import { getFetchingStatus } from "@utils/helpers";
import permissions from "@utils/permissions";
import authService from "@utils/services/AuthService";

import queryKeys from "@constants/queryKeys";
import {
  MAX_BADGES,
  MAX_CERTIFICATES,
} from "@layouts/DashboardLayout/components/TopBarMenu/constants";

import {
  PermissionsType,
  TabName,
  UseGamificationReturn,
} from "@views/WidgetsDashboard/components/Widgets/Leaderboard/types";

export const useGamification = (): UseGamificationReturn => {
  const { gamificationSettings } = useConfigurationStore();
  const { catalogSettings } = useConfigurationStore();
  const { global_discount: globalDiscount } = catalogSettings ?? {};

  const { canAccessLearnerReports } = permissions.reportsPermissions;
  const { canAccessGamification } = permissions.gamificationPermissions;

  const allowProgressView = canAccessLearnerReports();
  const allowGamificationView = canAccessGamification();
  const defaultRole = authService.getDefaultRole();
  const isAdmin = defaultRole === "administrator";

  const {
    badges: badgesSettings,
    points: pointsSettings,
    levels: levelsSettings,
    rewards: rewardsSettings,
    leaderboard: leaderboardSettings,
  } = gamificationSettings ?? {};

  const allowRewards = Boolean(globalDiscount) || rewardsSettings?.enabled;

  const leaderboardPermissions = useMemo(
    () => ({
      badges: badgesSettings?.enabled && leaderboardSettings?.show_badges,
      points: pointsSettings?.enabled && leaderboardSettings?.show_points,
      levels: levelsSettings?.enabled && leaderboardSettings?.show_levels,
      courses: leaderboardSettings?.show_courses,
      certificates: leaderboardSettings?.show_certifications,
      rewards: allowRewards,
    }),
    [
      badgesSettings?.enabled,
      leaderboardSettings?.show_badges,
      leaderboardSettings?.show_certifications,
      leaderboardSettings?.show_courses,
      leaderboardSettings?.show_levels,
      leaderboardSettings?.show_points,
      levelsSettings?.enabled,
      pointsSettings?.enabled,
      allowRewards,
    ],
  );

  const badgesQuery = `?page[number]=1&page[size]=${MAX_BADGES}&filter[type]=awarded&sort=-type,-criteria`;
  const coursesQuery = "?filter[progress_status][in]=not_attempted,incomplete";
  const certificatesQuery = `?page[number]=1&page[size]=${MAX_CERTIFICATES}&filter[type]=course_name`;

  const {
    data: leaderboardData,
    status: leaderboardStatus,
    error: leaderboardError,
  } = useQuery([queryKeys.leaderboard, leaderboardSettings], getLeaderboard, {
    select: (res) => res._data,
    enabled: Boolean(allowGamificationView && gamificationSettings?.enabled),
  });

  const {
    data: badgesData,
    status: badgesStatus,
    error: badgesError,
  } = useQuery([queryKeys.myBadges, badgesSettings], () => getMyBadges(badgesQuery), {
    select: (res) => res._data,
    enabled: Boolean(
      allowGamificationView && badgesSettings?.enabled && gamificationSettings?.enabled,
    ),
  });

  const badgesErrorEnabled = badgesError && gamificationSettings?.enabled;

  const {
    data: coursesData,
    status: coursesStatus,
    error: coursesError,
  } = useQuery([queryKeys.myCourses], () => getMyCourses(coursesQuery), {
    select: (res) => res._data,
    enabled: !isAdmin,
  });

  const {
    data: rewardsData,
    status: rewardsStatus,
    error: rewardsError,
  } = useQuery([queryKeys.rewards, rewardsSettings], getUserRewards, {
    select: (res) => res._data,
    enabled: Boolean(allowGamificationView && rewardsSettings?.enabled),
  });

  const {
    data: certificates,
    status: certificatesStatus,
    error: certificatesError,
  } = useQuery([queryKeys.myCertificates], () => getUserCertificates(certificatesQuery), {
    select: (res) => res._data,
    enabled: allowProgressView,
  });

  const gamificationStatus = getFetchingStatus([
    coursesStatus,
    certificatesStatus,
    rewardsStatus,
    badgesStatus,
    leaderboardStatus,
  ]);

  const hasGamificationError = [
    rewardsError,
    certificatesError,
    coursesError,
    badgesErrorEnabled,
    leaderboardError,
  ].some((error) => error);

  const gamificationPermissions: PermissionsType = useMemo(
    () => ({
      badges: Boolean(badgesSettings?.enabled),
      points: Boolean(pointsSettings?.enabled),
      levels: Boolean(levelsSettings?.enabled),
      certificates: allowProgressView,
      courses: allowProgressView,
    }),
    [badgesSettings?.enabled, pointsSettings?.enabled, levelsSettings?.enabled, allowProgressView],
  );

  const leaderboardTabs = useMemo(() => {
    if (!leaderboardData) return [];
    return Object.entries(leaderboardData)
      .map(([title, data]) => ({
        title: title as TabName,
        data,
      }))
      .filter(({ data, title }) => data && leaderboardPermissions[title]);
  }, [leaderboardData, leaderboardPermissions]);

  const showCertificates = allowProgressView || coursesData?.some((course) => course.certificate);

  return {
    data: {
      leaderboard: leaderboardData,
      badges: badgesData,
      courses: coursesData,
      rewards: rewardsData,
      certificates: certificates,
    },
    leaderboardTabs,
    gamificationStatus,
    hasGamificationError,
    permissions: gamificationPermissions,
    settings: {
      gamification: gamificationSettings,
      globalDiscount,
      showCertificates,
      allowRewards,
      allowGamificationView,
      allowProgressView,
    },
  };
};
