import { t } from "@utils/i18n";
import { yup } from "@utils/validation/validation";

const translation = "billingAndSubscription.cancellationSurvey.fieldLabel.requiredField";

export const cancellationSurveyValidationSchema = yup.object().shape({
  reason: yup.string().required(() => t(translation)),
  support_rating: yup.number().required(() => t(translation)),
  reason_justification: yup
    .string()
    .trim()
    .max(5000)
    .when("reason", {
      is: (val: string) => ["other"].includes(val),
      then: yup.string().required(() => t(translation)),
    }),
  reason_for_initial_purchase: yup
    .string()
    .trim()
    .max(5000)
    .required(() => t(translation)),
  improvements: yup
    .string()
    .trim()
    .max(5000)
    .required(() => t(translation)),
});
