import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["arrowButton"] => {
  return {
    text: {
      color: colors.black,
    },
  };
};
