import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Text } from "@epignosis_llc/gnosis";

import { upsellStateStyles } from "@components/Drawers/HelpCenter/Drawers/styles";

import { MainMenuDrawer } from "@layouts/DashboardLayout/components";

import { useApplyTranslations } from "@hooks";

import { URLS } from "@constants/urls";

import EmptyState from "@views/WidgetsDashboard/components/Widgets/components/EmptyState";

type UpsellDrawerProps = {
  headerTitle: string;
  upsellText: string;
  icon: JSX.Element;
  isOpen: boolean;
  toggleDrawer: () => void;
};
const UpsellDrawer: FC<UpsellDrawerProps> = ({
  headerTitle,
  upsellText,
  icon,
  isOpen,
  toggleDrawer,
}) => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();

  const onClose = (): void => {
    toggleDrawer();
  };

  const handleUpgrade = (): void => {
    navigate(URLS.subscription.subscription);
  };

  return (
    <MainMenuDrawer
      id="upsell-state-drawer"
      isOpen={isOpen}
      headerTitle={headerTitle}
      size="lg"
      onClose={onClose}
      hasBackButton
      showFooter={false}
    >
      <div css={upsellStateStyles}>
        <EmptyState
          icon={icon}
          title={
            <Text fontSize="lg" weight="700" dangerouslySetInnerHTML={{ __html: upsellText }} />
          }
          footer={
            <Button data-testid="upgrade-button" onClick={handleUpgrade}>
              {t("general.upgrade")}
            </Button>
          }
        />
      </div>
    </MainMenuDrawer>
  );
};

export default UpsellDrawer;
