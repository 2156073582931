import React, { FC } from "react";

import { UserPlusSVG } from "@epignosis_llc/gnosis/icons";

import { useApplyTranslations } from "@hooks";

import ActionButton from "@components/CustomTable/components/TableActions/ActionButton";
import { GenericActionProps } from "@components/CustomTable/components/TableActions/types";

const EnrollAction: FC<GenericActionProps> = ({
  tooltipContent,
  entity,
  icon: Icon = UserPlusSVG,
  iconHeight = 32,
  disabled = false,
  onClick,
  ...rest
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.actions.add")}
      onClick={onClick}
      data-testid="enroll-action"
      disabled={disabled}
      aria-label={t("general.actions.enrollEntity", { entity })}
      {...rest}
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default EnrollAction;
