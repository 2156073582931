import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Text, Tooltip } from "@epignosis_llc/gnosis";
import { LinkedUnitChipChildSVG, LinkedUnitChipParentSVG } from "@epignosis_llc/gnosis/icons";

import { useApplyTranslations } from "@hooks";

import { URLS } from "@constants/urls";

import { MyUnit } from "types/entities";

type LinkedUnitTagProps = {
  linkedUnits: number;
  sourceUnit?: MyUnit["source_unit"];
  toggleLinkedUnitsDrawer: () => void;
};

const LinkedUnitTag: FC<LinkedUnitTagProps> = ({
  linkedUnits,
  sourceUnit,
  toggleLinkedUnitsDrawer,
}) => {
  const navigate = useNavigate();
  const { t } = useApplyTranslations();
  const isParentLinkedUnit = !sourceUnit && linkedUnits > 0;
  const isChildrenLinkedUnit = Boolean(sourceUnit);
  const courseId = sourceUnit?.course_id.toString();

  const handleLinkedUnitClickParent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e.stopPropagation();
    toggleLinkedUnitsDrawer();
  };

  const handleLinkedUnitClickParentKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
    e.stopPropagation();
    if (e.key === "Enter") toggleLinkedUnitsDrawer();
  };

  const handleLinkedUnitChildClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e.stopPropagation();
    navigate(URLS.courses.getCourseEditLink({ courseId }));
  };

  const handleLinkedUnitChildeKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    if (e.key === "Enter") navigate(URLS.courses.getCourseEditLink({ courseId }));
  };

  return (
    <>
      {isParentLinkedUnit && (
        <div className="linked-unit-tag-parent-container">
          <Tooltip content={t("courseEdit.linkedUnitTagTooltipParent")}>
            <div
              className="linked-unit-tag-parent"
              tabIndex={0}
              onClick={handleLinkedUnitClickParent}
              onKeyDown={handleLinkedUnitClickParentKeyDown}
            >
              <LinkedUnitChipParentSVG height={12} />
              <Text as="span" weight="700" fontSize="2xs" className="linked-unit-tag-parent-text">
                {linkedUnits}
              </Text>
            </div>
          </Tooltip>
        </div>
      )}

      {isChildrenLinkedUnit && (
        <div
          className="linked-unit-tag-child"
          tabIndex={0}
          onClick={handleLinkedUnitChildClick}
          onKeyDown={handleLinkedUnitChildeKeyDown}
        >
          <Tooltip
            content={t("courseEdit.linkedUnitTagTooltipChildren")}
            parentProps={{ "aria-label": t("courseEdit.linkedUnitTagTooltipChildren") }}
          >
            <LinkedUnitChipChildSVG height={16} />
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default LinkedUnitTag;
