import React, { FC } from "react";

import { SerializedStyles } from "@emotion/react";

import { RTLIconStyles } from "@components/ReusableComponents/RTLIcon/styles";

import { i18n } from "@utils/i18n";

import { IconType } from "types/common";

type RTLIconProps = {
  icon: IconType;
  height?: number;
};

const RTLIcon: FC<RTLIconProps> = ({ icon: Icon, height = 32 }) => {
  const isRtl = i18n.dir() === "rtl";

  return <Icon height={height} css={(): SerializedStyles => RTLIconStyles(isRtl)} />;
};

export default RTLIcon;
