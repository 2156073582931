import React, { FC } from "react";
import { useMutation } from "react-query";

import { DropdownItem } from "@epignosis_llc/gnosis";
import { AxiosError } from "axios";

import { handleUserCoursesErrors } from "@errors/errors";

import { synchronizeProgress } from "@api/courses";
import { useApplyTranslations } from "@hooks";
import { getFormattedUserName } from "@utils/helpers";

import { ButtonWithDropdown, ConfirmationModal } from "@components";

import { CourseUser } from "@views/Course/CourseUsers/types";

export type SyncProgressModalProps = {
  selectedUser: CourseUser;
  courseId: string;
  handleInvalidateQuery: () => void;
  setIsSyncProgressModalOpen: (open: boolean) => void;
  isSyncProgressModalOpen: boolean;
};

const SyncProgressModal: FC<SyncProgressModalProps> = ({
  selectedUser,
  courseId,
  handleInvalidateQuery,
  setIsSyncProgressModalOpen,
  isSyncProgressModalOpen,
}) => {
  const { t } = useApplyTranslations();
  const { id, name, surname, login, has_certificate } = selectedUser;
  const username = getFormattedUserName({ name, surname, login });
  const userId = id.toString();
  const syncDropdown: DropdownItem[] = [
    {
      label: t("users.courses.syncAndRemoveCertificate"),
    },
  ];

  const list = has_certificate ? syncDropdown : [];

  const { mutate: syncProgressMutation } = useMutation(
    (removeCertificate: boolean | undefined) =>
      synchronizeProgress(courseId, userId, removeCertificate),
    {
      onError: (error: AxiosError) => {
        handleUserCoursesErrors(error);
      },
      onSettled: () => {
        handleInvalidateQuery();
      },
    },
  );

  const handleSyncProgress = (removeCertificate: boolean | undefined): void => {
    syncProgressMutation(removeCertificate);
    setIsSyncProgressModalOpen(false);
  };

  return (
    <ConfirmationModal
      id={id}
      header={t("users.courses.synchronizeUserProgress")}
      isOpen={isSyncProgressModalOpen}
      bodyTitle={
        <div
          dangerouslySetInnerHTML={{
            __html: t("users.courses.confirmSynchronizeUserProgress", { username }),
          }}
        />
      }
      footerButton={
        <ButtonWithDropdown
          list={list}
          color="primary"
          onButtonClick={(): void => {
            handleSyncProgress(false);
          }}
          onListItemClick={(): void => {
            handleSyncProgress(true);
          }}
        >
          {t("general.synchronize")}
        </ButtonWithDropdown>
      }
      onClose={(): void => setIsSyncProgressModalOpen(false)}
      closeOnOutsideClick={true}
    />
  );
};

export default SyncProgressModal;
