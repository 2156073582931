import { css } from "@emotion/react";

import { mq } from "@utils/breakpoints";

export const FileListStyle = ({ fileList }) => css`
  & > table {
    width: 100%;
  }

  .files-container {
    display: flex;
    flex-direction: column;

    .file {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem 0.5rem;
      border-radius: 5px;

      &:not(.readonly):hover {
        background: ${fileList.hover};
      }

      &.previewable {
        cursor: pointer;
      }

      .file-name-container {
        display: flex;
        flex-direction: column;

        ${mq["sm"]} {
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;
        }

        .file-info-wrapper {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          min-height: 2.5rem;
        }

        .file-name {
          margin-inline-end: 1rem;
        }

        .upper-dot::after {
          content: "";
          position: absolute;
          background-color: ${fileList.readonly};
          width: 2px;
          height: 2px;
          border-radius: 50%;
        }

        .type,
        .size {
          color: ${fileList.readonly};
        }
      }
    }
  }

  .view-all-wrapper {
    text-align: center;
    margin-top: 1rem;
  }
`;

export const fileActionsStyles = () => css`
  .hover-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    opacity: 0;
  }

  .is-visible {
    opacity: 1;
  }
`;

export const FileTagStyles = ({ fileCard }, size) => css`
  background: ${fileCard.fileType.background} !important;
  color: ${fileCard.fileType.color} !important;
  border-radius: 3px;
  width: 100%;
  justify-content: center;
  padding: ${size === "sm" ? "0.2rem 0.5rem" : "0.5rem 0.75rem 0.4rem"};
  cursor: default;
`;
