import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Modal, Text } from "@epignosis_llc/gnosis";

import {
  modalBodyStyles,
  modalFooterStyles,
} from "@components/ReverseTrial/TrialEndingModal/TrialEndingModal.styles";

import { dismissTrialEndingModal } from "@components/ReverseTrial/helpers";
import { useApplyTranslations } from "@hooks";

import { images } from "@constants/assets";
import { URLS } from "@constants/urls";

type TrialEndingModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
};

export const TrialEndingModal: FC<TrialEndingModalProps> = ({ isOpen, toggleModal }) => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();

  const handleOnClose = (): void => {
    dismissTrialEndingModal();
    toggleModal();
  };

  const handleUpgradeNow = (): void => {
    dismissTrialEndingModal();
    navigate(URLS.subscription.subscription);
  };

  return (
    <Modal
      data-testid="reverse-trial-ending-modal"
      isOpen={isOpen}
      onClose={handleOnClose}
      closeOnOutsideClick={false}
    >
      <Modal.Header>
        <Text fontSize="xl" weight="700">
          {t("reverseTrial.endOfTrialPopUp")}
        </Text>
      </Modal.Header>
      <Modal.Body css={modalBodyStyles}>
        <img src={images.reverseTrial.dashboardEnding} alt={t("reverseTrial.trialEndingImgAlt")} />
        <div className="body-text">
          <Text
            fontSize="md"
            dangerouslySetInnerHTML={{ __html: t("reverseTrial.trialEndingBodyFirst") }}
            className="reverse-trial-modal-unlock-description"
          />
          <Text
            fontSize="md"
            dangerouslySetInnerHTML={{ __html: t("reverseTrial.trialEndingBodySecond") }}
            className="reverse-trial-modal-unlock-description"
          />
          <Text
            fontSize="md"
            dangerouslySetInnerHTML={{ __html: t("reverseTrial.trialEndingBodyThird") }}
            className="reverse-trial-modal-unlock-description"
          />
        </div>
      </Modal.Body>
      <Modal.Footer css={modalFooterStyles}>
        <Button
          data-testid="reverse-trial-modal-yes-button"
          variant="solid"
          onClick={handleUpgradeNow}
        >
          {t("general.upgradeNow")}
        </Button>
        <Button
          data-testid="reverse-trial-modal-no-button"
          color="secondary"
          variant="solid"
          onClick={handleOnClose}
        >
          {t("reverseTrial.notRightNow")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
