import React, { FC } from "react";

import { Button, Tooltip } from "@epignosis_llc/gnosis";
import { UserExitSVG } from "@epignosis_llc/gnosis/icons";

import { useReturnToLegacy } from "@layouts/DashboardLayout/components/TopBarMenu/components/ProfileMenu/components/ProfileSubMenu/hooks";

import { useApplyTranslations } from "@hooks";

// This is the back to admin button when you are impersonating another user.
const ImpersonationBackButton: FC = () => {
  const { handleReturnToLegacy: handleReturnToAdmin } = useReturnToLegacy();
  const { t } = useApplyTranslations();

  return (
    <Tooltip content={t("general.backToAdmin")}>
      <Button
        data-testid="topbar-impersonation-back"
        variant="ghost"
        className="impersonation-back-btn action-btn"
        color="primary"
        noGutters
        onClick={(): void => handleReturnToAdmin()}
        aria-label={t("general.backToAdmin")}
      >
        <UserExitSVG height={32} />
      </Button>
    </Tooltip>
  );
};

export default ImpersonationBackButton;
