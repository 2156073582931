import React, { FC } from "react";

import { pointsContainer } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/styles";

import { useConfigurationStore } from "@stores";
import { filterValidEntries } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/helpers";

import ListItem from "@views/WidgetsDashboard/components/Widgets/Leaderboard/ListItem";
import { CollectMorePointsProps } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/types";

const CollectMorePoints: FC<CollectMorePointsProps> = ({ isPoints }) => {
  const { gamificationSettings } = useConfigurationStore();
  const { points, levels } = gamificationSettings ?? {};

  const type = isPoints ? "points" : "levels";
  const data = isPoints ? points : levels;

  const items = filterValidEntries(data, type);

  return (
    <div css={pointsContainer}>
      <ul>
        {items.map(({ key, value, translationKey }) => (
          <ListItem key={key} value={value} translationKey={translationKey} type={type} />
        ))}
      </ul>
    </div>
  );
};

export default CollectMorePoints;
