import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["widget"] => {
  return {
    background: {
      primary: colors.white,
      secondary: colors.secondary.lighter,
    },
    color: colors.primary.darkest,
    borderColor: colors.secondary.light,
  };
};
