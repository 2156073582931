import React, { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { Drawer, Loader } from "@epignosis_llc/gnosis";
import { domMax, LazyMotion } from "framer-motion";

import { unitLayoutContainer } from "@layouts/UnitLayout/styles";

import { GamificationDrawer } from "@layouts/DashboardLayout/components";
import UnitMainDrawer from "@layouts/UnitLayout/components/UnitMainDrawer";

import { useIsPublicCourse, useScreenSizeAlert } from "@hooks";

import userRoles from "@constants/userRoles";

import { ScreenSizeAlert } from "@components/ReusableComponents";

const notLearnerRoles = [userRoles.ADMINISTRATOR, userRoles.INSTRUCTOR];
const UnitLayout: FC = () => {
  const isPublicCourse = useIsPublicCourse();

  const shouldShowScreenSizeAlert = useScreenSizeAlert(notLearnerRoles);

  return (
    <main css={unitLayoutContainer}>
      <Suspense fallback={<Loader fullScreen />}>
        <Drawer.Root />
        <UnitMainDrawer />
        {!isPublicCourse && <GamificationDrawer />}
        <LazyMotion features={domMax}>
          {shouldShowScreenSizeAlert ? <ScreenSizeAlert /> : <Outlet />}
        </LazyMotion>
      </Suspense>
    </main>
  );
};

export default UnitLayout;
