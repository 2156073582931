import React, { FC } from "react";

import { RadioGroup, Text } from "@epignosis_llc/gnosis";

import { userRoleSwitchStyles } from "@layouts/DashboardLayout/components/TopBarMenu/components/ProfileMenu/components/UserRoleSwitch/UserRoleSwitch.styles";

import { UserPages } from "@layouts/DashboardLayout/components/TopBarMenu/components/ProfileMenu/components/UserPages";

import { useApplyTranslations } from "@hooks";

import { userRole } from "@layouts/DashboardLayout/components/TopBarMenu/components/ProfileMenu/constants";

type UserRoleSwitchProps = {
  roleOptions: { label: string; value: string }[];
  currentUserRole: string;
  isImpersonated: boolean | undefined;
  onRoleSelect: (selectedValue: string | React.FormEvent<HTMLFieldSetElement>) => void;
};

const UserRoleSwitch: FC<UserRoleSwitchProps> = ({
  roleOptions,
  currentUserRole,
  onRoleSelect,
  isImpersonated,
}) => {
  const { t } = useApplyTranslations();
  const hasMoreThanOneRole = roleOptions.length > 1;
  const userRoleItem = userRole(currentUserRole);
  const roleLabel = hasMoreThanOneRole ? t("topBar.switchRole") : t("general.role");

  // If the user is not in impersonation mode and doesn't have more than one role, don't render the role switcher.
  if (!hasMoreThanOneRole && !isImpersonated) return null;

  return (
    <div css={userRoleSwitchStyles} className="user-roles">
      <Text as="div" className="sub-menu-item" fontSize="xs" weight="700">
        {roleLabel}
      </Text>

      {hasMoreThanOneRole || (hasMoreThanOneRole && isImpersonated) ? (
        <RadioGroup
          id="role"
          data-testid="role-switcher"
          className="role-switcher"
          groupname="role"
          options={roleOptions}
          initialValue={currentUserRole}
          onChange={(selectedValue): void => {
            onRoleSelect(selectedValue);
          }}
        />
      ) : (
        <UserPages pageItems={userRoleItem} />
      )}
      <div className="divider" />
    </div>
  );
};

export default UserRoleSwitch;
