import Color from "color";

import { ThemePlusTypes } from "@styles/types";
import { isShadeOfWhite, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["announcements"] => {
  const isLightTheme = isShadeOfWhite(colors.primary.base);

  return {
    internal: {
      background: isLightTheme
        ? colors.primary.base
        : Color(colors.primary.lightest).alpha(0.25).string(),
    },
    external: {
      background: colors.white,
    },
    admin: {
      background: isLightTheme
        ? colors.primary.base
        : Color(colors.primary.lightest).alpha(0.25).string(),
    },
    color: colors.black,
    borderColor: colors.secondary.base,
  };
};
