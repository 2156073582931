import React, { FC } from "react";

import { UserMinusSVG } from "@epignosis_llc/gnosis/icons";

import { useApplyTranslations } from "@hooks";

import ActionButton from "@components/CustomTable/components/TableActions/ActionButton";
import { GenericActionProps } from "@components/CustomTable/components/TableActions/types";

const UnenrollAction: FC<GenericActionProps> = ({
  tooltipContent,
  entity,
  icon: Icon = UserMinusSVG,
  iconHeight = 32,
  disabled = false,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.unenroll")}
      onClick={onClick}
      data-testid="unenroll-action"
      disabled={disabled}
      aria-label={t("general.actions.unenrollEntity", { entity })}
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default UnenrollAction;
