import yup from "@utils/validation/yup-extended";

export const GenerateCSRKeySchema = yup.object().shape({
  country: yup.string().trim().required(),
  state: yup.string().trim().max(85),
  locality: yup.string().trim().max(85),
  organization: yup.string().trim().max(85).required(),
  organizational_unit: yup.string().trim().max(85),
  email: yup.string().trim().required(),
  bits: yup.string().trim().required(),
});
