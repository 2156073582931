import { AnyObjectSchema } from "yup";

import { t } from "@utils/i18n";
import { yup } from "@utils/validation/validation";
type ModeType = "all_users" | "branch" | "group" | "user";

const ALPHANUMERIC_NO_SPACES_REGEX = /^[a-zA-Z0-9]*$/;

export const EcommerceSettingsSchema = (isFloatCurrency: boolean): AnyObjectSchema =>
  yup.object().shape({
    invoices: yup.object().shape({
      enabled: yup.boolean().required(),
      message: yup
        .string()
        .trim()
        .max(500, () => t("errors.general.valueTooLong", { count: 500 }))
        .when("enabled", {
          is: true,
          then: yup
            .string()
            .trim()
            .max(500, () => t("errors.general.valueTooLong", { count: 500 }))
            .required(),
          otherwise: yup.string().nullable(),
        }),
    }),
    global_discount: yup.object().shape({
      enabled: yup.boolean().required(),
      percentage: yup.number().when("enabled", {
        is: true,
        then: yup
          .number()
          .min(0.01, () => t("validationMessages.minNumber", { number: 0.01 }))
          .max(99, () => t("validationMessages.maxNumber", { number: 99 }))
          .required(),
        otherwise: yup.number().nullable(),
      }),
    }),
    subscription: yup.object().shape({
      enabled: yup.boolean().required(),
      fee: yup
        .number()
        .min(0)
        .when("enabled", {
          is: true,
          then: yup
            .number()
            .required()
            .transform(
              (value, originalValue) => (originalValue === "" ? null : value), // Convert empty string to null
            )
            .maxDigitsAfterDecimal(isFloatCurrency ? 2 : 0),
          otherwise: yup.number().nullable(),
        }),
    }),
  });

export const EcommerceCreditsSchema = yup.object().shape({
  amount: yup.number().when("$action", {
    is: (action: string) => action === "GIVE",
    then: yup.number().required().min(0.001).max(1000000).positive().maxDigitsAfterDecimal(3),
    otherwise: yup.number().notRequired(),
  }),
  mode: yup.string().oneOf(["all_users", "branch", "group", "user"]).required(),
  entity_id: yup
    .number()
    .nullable()
    .when("mode", {
      is: (mode: ModeType) => mode !== "all_users",
      then: yup.number().required(),
    }),
});

export const EcommerceCouponsSchema = (isPercentageDiscount: boolean): AnyObjectSchema => {
  return yup.object().shape({
    code: yup
      .string()
      .required(() => t("validationMessages.requiredField"))
      .max(50, () => t("validationMessages.maxCharacters", { number: 50 }))
      .matches(
        ALPHANUMERIC_NO_SPACES_REGEX,
        t("accountAndSettings.ecommerce.coupons.validationMessages.notValidCode"),
      ),
    discount: isPercentageDiscount
      ? yup.object({
          percentage: yup
            .number()
            .required(() => t("validationMessages.requiredField"))
            .min(0, () => t("validationMessages.minNumber", { number: 0 }))
            .max(100, () => t("validationMessages.maxNumber", { number: 100 })),
        })
      : yup.object({
          fixed_amount: yup.number().required(() => t("validationMessages.requiredField")),
          currency: yup.string().when("fixed_amount", {
            is: (fixedAmount: number | null | undefined) =>
              fixedAmount !== null && fixedAmount !== undefined,
            then: yup.string().required(() => t("validationMessages.requiredField")),
            otherwise: yup.string(),
          }),
        }),
    valid_from: yup.string().required(() => t("validationMessages.requiredField")),
    valid_to: yup.string().required(() => t("validationMessages.requiredField")),
    redemptions: yup
      .object()
      .nullable()
      .shape({
        max: yup
          .string()
          .nullable()
          .matches(/^(?:[1-9]\d*|null)$/)
          .min(1, () => t("validationMessages.minNumber", { number: 1 })),
      }),
  });
};
