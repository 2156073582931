import { urlsToSections } from "@components/ReusableModals/FeedbackModal/constants";

import { ContactSupportEnum } from "@components/Drawers/HelpCenter/types";

import { MainDrawerContentEnum } from "types/common";

const observeElement = (selector: string, dispatchEvent: (element: HTMLElement) => void): void => {
  const observer = new MutationObserver(() => {
    const element = document.getElementById(selector);
    if (element) {
      observer.disconnect();
      dispatchEvent(element);
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });
};

const findElement = (selector: string, dispatchEvent: (element: HTMLElement) => void): void => {
  const element = document.getElementById(selector);
  if (element) {
    dispatchEvent(element);
    return;
  }
  observeElement(selector, dispatchEvent);
};

export const openSupportTicket = (steps: (MainDrawerContentEnum | ContactSupportEnum)[]): void => {
  const dispatchEvent = (element: HTMLElement): void => {
    element.dispatchEvent(new Event("click", { bubbles: true }));
  };

  steps.forEach((step) => {
    findElement(step, dispatchEvent);
  });
};

// Detects the current section based on the URL pathname with an option to return translated or raw values
export const resolveCurrentSection = (isTranslated = true): string => {
  const locationPathname = window.location.pathname;

  // Find the first matching section
  const matchedKey = Object.keys(urlsToSections()).find((url) => locationPathname.includes(url));

  return matchedKey ? urlsToSections()[matchedKey][isTranslated ? "translated" : "raw"] : "";
};
