import ReactFroalaEditor from "froala-editor";

import { CustomIcon } from "@components/FormElements/Editor/customCommands/types";

export const defineEditorCustomIcons = (): void => {
  ReactFroalaEditor.DefineIcon(CustomIcon.InsertImage, {
    NAME: "folder-image",
    template: "my_fa",
  });

  ReactFroalaEditor.DefineIcon(CustomIcon.UploadImage, {
    NAME: "cloud-arrow-up",
    template: "my_fa",
  });

  ReactFroalaEditor.DefineIcon(CustomIcon.InsertSmartTag, {
    NAME: "tag",
    template: "my_fa",
  });

  ReactFroalaEditor.DefineIcon(CustomIcon.MagicWand, {
    NAME: "write-ai",
    template: "my_fa",
  });

  ReactFroalaEditor.DefineIcon(CustomIcon.Sparkles, { NAME: "sparkles", template: "my_fa" });
};
