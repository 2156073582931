import React, { FC } from "react";

import { Modal } from "@epignosis_llc/gnosis";

import { modalHeader, previewModal } from "@components/ReusableModals/FileModal/styles";

import { downloadFile } from "@utils/helpers";

import FilePreview from "@components/ReusableModals/FileModal/components/FilePreview";
import Footer from "@components/ReusableModals/FileModal/components/Footer";

import { File } from "types/entities";

type FileProps = Pick<File, "id" | "name" | "url" | "mime_type" | "preview_url">;

export type FileModalProps = FileProps & {
  isOpen: boolean;
  header?: string | JSX.Element;
  showFooter?: boolean;
  hasMaxHeight?: boolean;
  customFooter?: JSX.Element;
  isLoading?: boolean;
  onClose: () => void;
};

const FileModal: FC<FileModalProps> = ({
  id,
  name,
  url,
  mime_type,
  preview_url,
  isOpen,
  header,
  showFooter = true,
  hasMaxHeight = false,
  customFooter,
  isLoading = false,
  onClose,
}) => {
  const title = header ?? <div css={modalHeader}>{name}</div>;

  const handleDownloadFile = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    await downloadFile({ fileUrl: url, fileName: name, fileId: id });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        <div css={previewModal}>
          <FilePreview
            url={preview_url}
            mime_type={mime_type}
            fileName={name}
            hasMaxHeight={hasMaxHeight}
            isLoading={isLoading}
          />
        </div>
      </Modal.Body>
      <Footer
        showFooter={showFooter}
        customFooter={customFooter}
        onDownloadClick={handleDownloadFile}
        onClose={onClose}
      />
    </Modal>
  );
};

export default FileModal;
