import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Button, DropdownItem, Row, TableHandlers, Text } from "@epignosis_llc/gnosis";
import { RequestSVG, UserPlusSVG } from "@epignosis_llc/gnosis/icons";
import { useResponsive, useToggle } from "ahooks";
import { AxiosError } from "axios";
import { format } from "date-fns";

import { courseUsersTableContainer } from "@views/Course/CourseUsers/styles";

import { featureFlags } from "@config";
import { handlePreviewCertificateErrors, handleUserCoursesErrors } from "@errors/errors";

import { getBranches } from "@api/branch";
import { previewCertificate, unenrollUser } from "@api/courses";
import { getGroups } from "@api/group";
import { courseUsersMassActions, courseUsersMassActionsCount } from "@api/massActions/courses";
import { useApplyTranslations, useExportToCSV, useSearchQuery } from "@hooks";
import usePaginatedStateReducer, {
  PaginatedState,
  PaginatedStateActions,
} from "@hooks/usePaginatedStateReducer";
import { useConfigurationStore } from "@stores";
import {
  applyQueryFilter,
  generalNotification,
  getFetchingStatus,
  getFilterDropdownOptions,
  getFormattedUserName,
  handlePaginatedTableState,
  mapTableToSelectSorting,
  truncate,
} from "@utils/helpers";
import { hasPermissionsChanged } from "@utils/helpers/filters";
import { buildPaginatedSearchQuery } from "@utils/helpers/url";
import permissions from "@utils/permissions";
import authService from "@utils/services/AuthService";
import {
  editCourseUser,
  EditCourseUserData,
  getCourseUsers,
  getCourseUsersTableExport,
} from "@views/Course/CourseUsers/api";
import { resolveMassActionOptions } from "@views/Course/helpers";

import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";
import userRoles from "@constants/userRoles";
import {
  CourseUsersFilters,
  DEFAULT_FILTERS,
  DEFAULT_STATE,
  getCourseUsersInitialColumns,
} from "@views/Course/CourseUsers/constants";

import {
  CustomTable,
  CustomTableActions,
  ExportToCsvModal,
  ProgressStatusCell,
  TextWithStatus,
} from "@components";
import { buildEmptyStateProps, emptyState } from "@components/CustomTable";
import RoleCell from "@components/CustomTable/components/Cells/DropdownRoleCell/DropdownRoleCell";
import { MassActionParam, MassActionType } from "@components/ReusableComponents/";
import MassActionsCount from "@components/ReusableComponents/MassActionsCount/MassActionsCount";
import ConfirmationWithDateModal from "@components/ReusableModals/ConfirmationWithDateModal";
import DateCell from "@views/Course/CourseUsers/components/Cells/DateCell";
import CertificateModal from "@views/Course/CourseUsers/components/CertificateModal";
import CompleteCourseModal from "@views/Course/CourseUsers/components/CompleteCourseModal";
import CourseUsersActions from "@views/Course/CourseUsers/components/CourseUsersActions";
import EnrollmentRequestsDrawer from "@views/Course/CourseUsers/components/EnrollmentRequestsDrawer";
import EnrollUsersDrawer from "@views/Course/CourseUsers/components/EnrollUsersDrawer";
import ResetProgressModal from "@views/Course/CourseUsers/components/ResetProgressModal";
import SyncProgressModal from "@views/Course/CourseUsers/components/SyncProgressModal";
import SendMessageMassActionDrawer from "@views/Users/components/SendMessageMassActionDrawer";

import { CourseUser } from "@views/Course/CourseUsers/types";
import { Course } from "types/entities";
import { UserRoles } from "types/entities/User";
import { CountMassActionResponse, MassActionResultResponse } from "types/responses";

type CourseUsersProps = {
  course?: Course;
  menuPortalTargetId?: string;
  opensFromDrawer?: boolean;
};

type SearchQueryFilters = {
  action: "showEnrollmentRequests";
};

const filtersSearchQuery = buildPaginatedSearchQuery({
  pagination: { number: 1, size: 10000 },
  sorting: ["name"],
});

const CourseUsers: FC<CourseUsersProps> = ({
  course,
  menuPortalTargetId = "",
  opensFromDrawer = false,
}) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const { courseId } = useParams() as { courseId: string };
  const navigate = useNavigate();
  const { sm, lg } = useResponsive();
  const { action } = useSearchQuery() as SearchQueryFilters;

  const { policies } = course ?? {};
  const {
    can_enroll_users: canEnrollUsers,
    can_mass_unenroll_users: canMassUnenrollUsers,
    can_mass_reset_progress: canMassResetProgress,
    can_mass_sync_progress: canMassSyncProgress,
    can_mass_enroll_users: canMassEnrollUsers,
  } = policies ?? {};

  const [resetInput, setResetInput] = useState(false);
  const [hoveredRow, setHoveredRow] = useState<Row | null>(null);
  const [isConfirmationModalOpen, { toggle: toggleConfirmationModal }] = useToggle(false);
  const [isResetProgressModalOpen, setIsResetProgressModalOpen] = useState(false);
  const [isCompleteCourseModalOpen, setIsCompleteCourseModalOpen] = useState(false);
  const [isSyncProgressModalOpen, setIsSyncProgressModalOpen] = useState(false);
  const [isCertificateModalOpen, setIsCertificateModalOpen] = useState(false);
  const [isEnrollUsersDrawerOpen, { toggle: toggleEnrollUsersDrawer }] = useToggle(false);
  const [selectedUser, setSelectedUser] = useState<CourseUser | null>(null);
  const [previousUser, setPreviousUser] = useState<CourseUser | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [hideUpdateCertificate, setHideUpdateCertificate] = useState(false);
  const [isRequestsDrawerOpen, setRequestsDrawerOpen] = useState(false);
  const drawerSize = opensFromDrawer ? "lg" : "md";
  const openDrawerFullscreen = opensFromDrawer && !lg;

  const defaultValues = {
    sorting: DEFAULT_STATE.sorting,
    filters: DEFAULT_STATE.filters,
    pagination: DEFAULT_STATE.pagination,
    url: `${URLS.courses.singleCourse}/users`,
  };

  const defaultState = handlePaginatedTableState(defaultValues);

  const [coursesState, coursesDispatch] = usePaginatedStateReducer(defaultState);
  const { pagination, sorting: tableSorting, filters } = coursesState;

  const sorting = tableSorting?.column ? [mapTableToSelectSorting(tableSorting)] : [];
  const searchQuery = buildPaginatedSearchQuery({ pagination, sorting, filters });

  // Permissions
  const { domainSettings, userProfileData } = useConfigurationStore();
  const { main_portal: isMainPortal } = domainSettings ?? {};
  const currentRole = authService.getDefaultRole();
  const isAdmin = currentRole === UserRoles.ADMINISTRATOR;
  const [canViewUser, setCanViewUser] = useState(true);
  const canAdminAccessBranches = isAdmin && Boolean(isMainPortal);
  const hideFilters = !isAdmin;
  const { canReadBranches } = permissions.branchPermissions;
  const { canReadGroups } = permissions.groupPermissions;
  const { canCreateMessages } = permissions.messagesPermissions;
  const { canAccessUsers } = permissions.usersPermissions;
  const allowReadBranches = canReadBranches();
  const allowReadGroups = canReadGroups();
  const allowReadUsers = canAccessUsers();

  // Mass actions
  const tableRef = React.useRef<TableHandlers>(null);
  const [isSendMessageDrawerOpen, { toggle: toggleSendMessageDrawer }] = useToggle(false);
  const [userIds, setUserIds] = useState<number[]>([]);
  const [preventSyncCompleted, { toggle: togglePreventSyncCompleted }] = useToggle(false); // Used for sync progress mass action, if true prevents the sync action on users with completed progress status

  const allowMassActions =
    (canMassResetProgress || canMassUnenrollUsers || canMassSyncProgress || canCreateMessages()) &&
    userIds.length > 0;

  const drawerElement = document.getElementById(menuPortalTargetId);
  const menuPortalTarget = opensFromDrawer && menuPortalTargetId ? drawerElement : document.body;

  const {
    status: courseUsersStatus,
    data: courseUsersData,
    error: courseUsersError,
  } = useQuery(
    [queryKeys.courses.users, courseId, searchQuery],
    () => getCourseUsers(courseId, searchQuery),
    {
      select: (users) => ({
        data: users._data,
        meta: users._meta,
        pagination: users._meta?.pagination,
      }),
    },
  );

  const { data: groups = [] } = useQuery(
    [queryKeys.allGroups],
    () => getGroups(filtersSearchQuery),
    {
      select: (groups) => groups._data,
      enabled: isAdmin,
    },
  );

  const { data: branches = [] } = useQuery(
    [queryKeys.branches.branches],
    () => getBranches(filtersSearchQuery),
    {
      select: (branches) => branches._data,
      enabled: canAdminAccessBranches,
    },
  );

  const status = getFetchingStatus([courseUsersStatus]);
  const error = courseUsersError;
  const tableStatus = { status, error };

  const activeFilters = useMemo(() => {
    return Object.keys(filters).filter((key) => filters[key] !== null);
  }, [filters]);

  const canViewCertificatePreview =
    selectedUser && selectedUser.has_certificate && selectedUser.download_certificate_url;

  // Restore to default filters if permissions changes while you have a filter applied
  useEffect(() => {
    // Check which permission changed and if the user has a filter applied related to this permission
    hasPermissionsChanged(filters, handleRestoreDefault, allowReadBranches, allowReadGroups);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowReadBranches, allowReadGroups]);

  useEffect(() => {
    if (action === "showEnrollmentRequests" && courseUsersData?.meta.enrollment_requests) {
      setRequestsDrawerOpen(true);

      const url = new URL(window.location.href);
      url.searchParams.delete("action");
      window.history.replaceState({}, "", url.toString());
    }
  }, [action, courseUsersData?.meta.enrollment_requests]);

  const handleRestoreDefault = (): void => {
    coursesDispatch({
      type: PaginatedStateActions.filters,
      payload: { filters: DEFAULT_FILTERS },
    });
    handleSortingChanged(DEFAULT_STATE.sorting);
    setResetInput(true);
  };

  const handleSortingChanged = useCallback(
    (sorting: PaginatedState["sorting"]): void => {
      coursesDispatch({ type: PaginatedStateActions.sorting, payload: { sorting } });
    },
    [coursesDispatch],
  );

  const handlePaginationPageChange = (value: number): void => {
    coursesDispatch({ type: PaginatedStateActions.paginationPage, payload: { number: value } });
  };

  const handlePaginationPageSizeChange = (value: number): void => {
    coursesDispatch({ type: PaginatedStateActions.paginationPageSize, payload: { size: value } });
  };

  const handleSearchChanged = (searchValue: string): void => {
    setResetInput(false);
    const filter = { key: "[keyword][like]", value: searchValue };
    const newFilters = applyQueryFilter({ filters, filter });
    coursesDispatch({ type: PaginatedStateActions.filters, payload: { filters: newFilters } });
  };

  const handleFiltersChanged = ({ category, value }: DropdownItem): void => {
    // Shouldn't have selected both branch and group filter
    const currentFilters = filters.filter((item) => {
      if (category === CourseUsersFilters.branch) {
        return item.key !== CourseUsersFilters.group;
      } else if (category === CourseUsersFilters.group) {
        return item.key !== CourseUsersFilters.branch;
      } else {
        return true;
      }
    });

    const filter = { key: category as string, value: value as string };
    const newFilters = applyQueryFilter({ filters: currentFilters, filter });
    coursesDispatch({ type: PaginatedStateActions.filters, payload: { filters: newFilters } });
  };

  const handleFilterRemove = ({ category, value }: DropdownItem): void => {
    const newFilters = filters.filter((item) => !(category === item.key && value === item.value));
    coursesDispatch({ type: PaginatedStateActions.filters, payload: { filters: newFilters } });
  };

  const emptyStateProps = buildEmptyStateProps({
    onClick: toggleEnrollUsersDrawer,
    opensDrawer: true,
    permission: canEnrollUsers,
    filters: activeFilters,
    tableType: "users",
    isDrawer: false,
    handleRestoreDefault,
  });

  const handleRowHover = useCallback(
    (row: Row | null): void => {
      const policies = row?.policies as CourseUser["policies"];

      setCanViewUser(policies?.can_view && allowReadUsers);
      setHoveredRow(row);
    },
    [allowReadUsers],
  );

  const massActionsOptions: DropdownItem[] = resolveMassActionOptions({
    canMassUnenrollUsers,
    canMassResetProgress,
    canMassSyncProgress,
    canCreateMessages,
  });

  const countRequest = useCallback(
    (type: MassActionType, data: MassActionParam): Promise<CountMassActionResponse> => {
      return courseUsersMassActionsCount(type, courseId, userIds, data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(userIds)],
  );

  const massActionRequest = useCallback(
    (type: MassActionType, data: MassActionParam): Promise<MassActionResultResponse> => {
      return courseUsersMassActions(type, courseId, userIds, data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(userIds)],
  );

  const cleanState = (): void => {
    setUserIds([]);
    tableRef.current?.resetSelected();
  };

  const handleInvalidateQueryMassActions = (): void => {
    queryClient.invalidateQueries([queryKeys.courses.unenrolledCourseUsers, courseId, searchQuery]);
    handleInvalidateQuery();
  };

  const handleRowSelect = (rows: Row[]): void => {
    const selectedRows = rows.map((row) => Number(row.id));
    setUserIds(selectedRows);
  };

  const handleRowClick = useCallback(
    (row: Row): void => {
      const policies = row?.policies as CourseUser["policies"];
      const userId = row.id.toString();

      if (policies?.can_view && canAccessUsers()) {
        navigate(URLS.users.getUserLink({ userId }));
      }
    },
    [canAccessUsers, navigate],
  );

  const handleInvalidateQuery = useCallback((): void => {
    queryClient.invalidateQueries([queryKeys.courses.users, courseId, searchQuery]);
    queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
  }, [courseId, queryClient, searchQuery]);

  const paginationData = {
    paginationRes: courseUsersData?.pagination,
    paginationState: {
      number: pagination.number,
      size: pagination.size,
    },
    onPageChange: handlePaginationPageChange,
    onPageSizeChange: handlePaginationPageSizeChange,
  };

  const handlePreventSyncCompleted = (): boolean => {
    togglePreventSyncCompleted();
    return preventSyncCompleted;
  };

  const massActionsProps = {
    allowMassActions,
    massActionsOptions,
    preventSyncCompletedState: preventSyncCompleted,
    originTableName: "courseUsers",
    countRequest,
    massActionRequest,
    cleanState,
    handleInvalidateQueryMassActions,
    toggleMassActionDrawer: toggleSendMessageDrawer,
    selectedRows: userIds.length,
    handlePreventSyncCompleted,
    itemNameLabel: course?.name,
  };

  const { mutate: patchCourseUser } = useMutation(
    [queryKeys.courses.patchUser],
    (data: EditCourseUserData) => editCourseUser(data),
    {
      onSuccess: async () => {
        generalNotification("success", <p>{t("notifications.users.expirationDateUpdated")}</p>);
        handleInvalidateQuery();
      },
      onError: () => {
        generalNotification("error", <p>{t("notifications.users.expirationDateUpdateFailed")}</p>);
      },
    },
  );

  const { mutate: unEnrollmentMutation } = useMutation(
    (userId: string) => unenrollUser(courseId, userId),
    {
      onSuccess: (_, userId) => {
        if (userProfileData?.id.toString() === userId) {
          queryClient.invalidateQueries([queryKeys.course, courseId]);
          if (!isAdmin) {
            navigate(URLS.courses.courses);
          }
        }
      },
      onError: (error: AxiosError) => {
        handleUserCoursesErrors(error);
      },
      onSettled: () => {
        handleInvalidateQuery();
      },
    },
  );

  const { mutate: certificatePreviewMutation } = useMutation(
    (userId: string) => previewCertificate(courseId, userId),
    {
      onSuccess: (res) => {
        setPreviewUrl(res._data.preview_url);
      },
      onError: (err) => {
        const error = err as AxiosError;
        handlePreviewCertificateErrors(error);
      },
    },
  );

  const handleUnenroll = (userId: string): void => {
    unEnrollmentMutation(userId);
  };

  const handleCertificatePreview = (userId: string): void => {
    if (previousUser == null || selectedUser != previousUser) {
      setPreviewUrl(null);
      certificatePreviewMutation(userId);
      setPreviousUser(selectedUser);
      setHideUpdateCertificate(false);
    }
  };

  const bodyText = t("courses.changeExpirationDateConfirmation", {
    course: course?.name,
    user: getFormattedUserName({
      name: selectedUser?.name ?? "",
      surname: selectedUser?.surname ?? "",
      login: selectedUser?.login ?? "",
    }),
  });

  const minDate = selectedUser?.enrolled_date ? new Date(selectedUser.enrolled_date) : undefined;
  const selectedDate = selectedUser?.expiration_date
    ? new Date(selectedUser.expiration_date)
    : undefined;

  const handleConfirm = (): void => {
    if (selectedUser && selectedUser?.expiration_date && course) {
      patchCourseUser({
        courseId: course?.id.toString(),
        userId: selectedUser?.id.toString(),
        options: {
          date: format(new Date(selectedUser?.expiration_date), "yyyy-MM-dd"),
        },
      });
    }
  };

  const handleCloseSendMessageDrawer = (): void => {
    toggleSendMessageDrawer();
    cleanState();
  };

  const onUpdateExpirationDate = (userId: string): void => {
    const user = courseUsersData?.data.find((user) => user.id.toString() === userId);
    if (user) {
      setSelectedUser(user);
      toggleConfirmationModal();
    }
  };

  const onUnenrollUser = (userId: string): void => {
    const user = courseUsersData?.data.find((user) => user.id.toString() === userId);
    if (user) {
      setSelectedUser(user);
      handleUnenroll(userId);
    }
  };

  const onPreviewCertificate = (userId: string): void => {
    const user = courseUsersData?.data.find((user) => user.id.toString() === userId);
    if (user) {
      setSelectedUser(user);
      handleCertificatePreview(userId);
      setIsCertificateModalOpen(true);
    }
  };

  const onSyncProgress = (userId: string): void => {
    const user = courseUsersData?.data.find((user) => user.id.toString() === userId);
    if (user) {
      setSelectedUser(user);
      setIsSyncProgressModalOpen(true);
    }
  };

  const onResetProgress = (userId: string): void => {
    const user = courseUsersData?.data.find((user) => user.id.toString() === userId);
    if (user) {
      setSelectedUser(user);
      setIsResetProgressModalOpen(true);
    }
  };

  const onCompleteCourse = (userId: string): void => {
    const user = courseUsersData?.data.find((user) => user.id.toString() === userId);
    if (user) {
      setSelectedUser(user);
      setIsCompleteCourseModalOpen(true);
    }
  };

  const rows =
    courseUsersData?.data?.map((user) => {
      const {
        id,
        name,
        role_in_course,
        completion_status,
        surname,
        completion_date,
        enrolled_date,
        available_roles_in_course,
        expiration_date,
        completion_percentage,
        login,
        is_active: isActive,
        has_certificate: hasCertificate,
        policies,
      } = user;

      const tagText = !isActive ? t("myCourses.instructor.filters.inactive") : "";

      const formattedUserName = getFormattedUserName({ name, surname, login });
      const isHovered = hoveredRow?.id === user.id && sm;
      const {
        can_reset_progress,
        can_synchronize_progress,
        can_change_user_role: canChangeUserRole,
        can_view: canView,
      } = policies ?? {};

      const userId = id.toString();
      const isLearnerInCourse = role_in_course === userRoles.LEARNER;
      const isInstructorInCourse = role_in_course === userRoles.INSTRUCTOR;
      const canResetProgress = can_reset_progress && isLearnerInCourse;
      const canSyncProgress = can_synchronize_progress && isLearnerInCourse;
      const canViewUserPolicy = canView && allowReadUsers;

      return {
        ...user,
        formatted_name: () => (
          <TextWithStatus
            tagText={tagText}
            content={
              <Text fontSize="sm" as="div" className="overflow-text" style={{ maxWidth: "auto" }}>
                {truncate(formattedUserName, 40)}
              </Text>
            }
            variant={isActive ? "ready" : "inactive"}
          />
        ),
        role_in_course: () => (
          <RoleCell
            roleInCourse={role_in_course}
            menuPortalTarget={menuPortalTarget}
            availableRoles={available_roles_in_course}
            userId={userId}
            courseId={courseId}
            invalidateQueries={handleInvalidateQuery}
            isTable={true}
            canChangeUserRolePolicy={canChangeUserRole}
          />
        ),
        progress_status: (
          <ProgressStatusCell
            isInstructorInCourse={isInstructorInCourse}
            completionStatus={completion_status}
            completionPercentage={completion_percentage ?? 0}
          />
        ),
        enrolled: <DateCell expirationDate={enrolled_date} />,
        completion_date: <DateCell expirationDate={completion_date} />,
        expiration_date: (
          <DateCell
            expirationDate={expiration_date}
            hasIcon={true}
            completionStatus={completion_status}
            completionDate={completion_date}
          />
        ),
        actions: (): JSX.Element => (
          <CourseUsersActions
            user={user}
            policies={policies}
            hasCertificate={hasCertificate}
            canSyncProgress={canSyncProgress}
            canResetProgress={canResetProgress}
            canViewUser={canViewUserPolicy}
            isHovered={isHovered}
            onUpdateExpirationDate={onUpdateExpirationDate}
            onUnenrollUser={onUnenrollUser}
            onPreviewCertificate={onPreviewCertificate}
            onSyncProgress={onSyncProgress}
            onResetProgress={onResetProgress}
            onCompleteCourse={onCompleteCourse}
            onRowClick={handleRowClick}
          />
        ),
        id,
      };
    }) ?? [];

  const tableData = {
    rows,
    columns: getCourseUsersInitialColumns(),
    emptyState: emptyState(emptyStateProps),
    sortable: true,
    sorting: tableSorting,
    onSortingChanged: handleSortingChanged,
    onHoveredRowChange: handleRowHover,
    onRowClick: handleRowClick,
    selectable: true,
    autohide: true,
    onRowSelect: handleRowSelect,
  };

  const {
    isExportModalOpen,
    exportProgress,
    handleExportModalClose,
    deleteTaskMutationLoading,
    handleExportToCSV,
  } = useExportToCSV({
    queryKey: queryKeys.tableExports.courseUsersTable,
    handleExport: getCourseUsersTableExport,
    exportId: courseId,
    searchQuery,
  });

  const enrollmentRequestsButton = (): JSX.Element => {
    return (
      <Button
        iconBefore={RequestSVG}
        noGutters
        variant="link"
        onClick={(): void => setRequestsDrawerOpen(!isRequestsDrawerOpen)}
        className="requests-btn"
        data-testid="enrollment-requests-button"
      >
        <div className="info-container">
          {t("general.enrollmentRequests", { count: 2 })}{" "}
          <MassActionsCount count={courseUsersData?.meta.enrollment_requests ?? 0} />
        </div>
      </Button>
    );
  };

  const hasEnrollmentRequests =
    Boolean(courseUsersData?.meta.enrollment_requests) &&
    (courseUsersData?.meta.enrollment_requests ?? 0) > 0;

  return (
    <div css={courseUsersTableContainer(canViewUser)}>
      {featureFlags.enrollmentRequests &&
        !courseUsersData?.data.length &&
        hasEnrollmentRequests && (
          <div className="no-data-button-container">{enrollmentRequestsButton()}</div>
        )}

      <CustomTableActions
        filterDropdownOptions={getFilterDropdownOptions({
          groups,
          branches,
          showStatusOptions: true,
        })}
        selectedFilters={filters}
        onResetAllFilters={handleRestoreDefault}
        tableHasData={Boolean(courseUsersData?.data.length)}
        resetInput={resetInput}
        searchValue={defaultState.searchValue}
        onSearchChange={handleSearchChanged}
        onFilterSelect={handleFiltersChanged}
        onFilterRemove={handleFilterRemove}
        hideFilters={hideFilters}
        massActionsProps={massActionsProps}
      >
        <div className="user-actions-btns">
          {featureFlags.enrollmentRequests && hasEnrollmentRequests && enrollmentRequestsButton()}

          {canEnrollUsers && (
            <Button
              noGutters
              iconBefore={UserPlusSVG}
              variant="link"
              onClick={toggleEnrollUsersDrawer}
              data-testid="enroll-user-to-course-button"
            >
              {t("users.courses.enrollToCourse")}
            </Button>
          )}
        </div>
      </CustomTableActions>

      <CustomTable
        id="course-users"
        tableProps={tableData}
        tableStatus={tableStatus}
        paginationProps={paginationData}
        tableRef={tableRef}
        handleExportToCSV={handleExportToCSV}
      />

      {/* Change expiration date confirmation modal */}
      <ConfirmationWithDateModal
        isConfirmationModalOpen={isConfirmationModalOpen}
        toggleConfirmationModal={toggleConfirmationModal}
        modalId={Number(selectedUser?.id)}
        title={t("courses.changeExpirationDate")}
        bodyText={bodyText}
        inputLabel={t("courses.expirationDate")}
        minDate={minDate}
        selectedDate={selectedDate}
        onConfirm={handleConfirm}
        onDateChange={(value: Date | null): void => {
          if (selectedUser) {
            setSelectedUser({
              ...selectedUser,
              expiration_date: value && value?.toUTCString(),
            });
          }
        }}
      />

      {/* Reset user progress confirmation modal */}
      {selectedUser && (
        <ResetProgressModal
          selectedUser={selectedUser}
          courseId={courseId}
          handleInvalidateQuery={handleInvalidateQuery}
          setIsResetProgressModalOpen={setIsResetProgressModalOpen}
          isResetProgressModalOpen={isResetProgressModalOpen}
        />
      )}

      {/* Complete user progress confirmation modal */}
      {selectedUser && course && isCompleteCourseModalOpen && (
        <CompleteCourseModal
          selectedUser={selectedUser}
          courseId={courseId}
          handleInvalidateQuery={handleInvalidateQuery}
          setIsCompleteCourseModalOpen={setIsCompleteCourseModalOpen}
          isCompleteCourseModalOpen={isCompleteCourseModalOpen}
        />
      )}

      {/* Sync user progress confirmation modal */}
      {selectedUser && (
        <SyncProgressModal
          selectedUser={selectedUser}
          courseId={courseId}
          handleInvalidateQuery={handleInvalidateQuery}
          setIsSyncProgressModalOpen={setIsSyncProgressModalOpen}
          isSyncProgressModalOpen={isSyncProgressModalOpen}
        />
      )}

      {/* Preview certificate modal */}
      {canViewCertificatePreview && (
        <CertificateModal
          selectedUser={selectedUser}
          previewUrl={previewUrl}
          courseId={courseId}
          isModalOpen={isCertificateModalOpen}
          hideUpdateCertificate={hideUpdateCertificate}
          setModalOpen={setIsCertificateModalOpen}
          setPreviewUrl={setPreviewUrl}
          handleInvalidateQuery={handleInvalidateQuery}
          certificatePreviewMutation={certificatePreviewMutation}
          setHideUpdateCertificate={setHideUpdateCertificate}
        />
      )}

      {isEnrollUsersDrawerOpen && (
        <EnrollUsersDrawer
          isDrawerOpen={isEnrollUsersDrawerOpen}
          canMassEnrollUsers={canMassEnrollUsers ?? false}
          toggleDrawer={toggleEnrollUsersDrawer}
          handleInvalidateQuery={handleInvalidateQuery}
          hasBackButton={opensFromDrawer}
          openDrawerFullscreen={openDrawerFullscreen}
          size={drawerSize}
        />
      )}

      {isSendMessageDrawerOpen && (
        <SendMessageMassActionDrawer
          isDrawerOpen={isSendMessageDrawerOpen}
          userIds={userIds ?? []}
          onClose={handleCloseSendMessageDrawer}
          hasBackButton={opensFromDrawer}
          openDrawerFullscreen={openDrawerFullscreen}
          size={drawerSize}
        />
      )}

      {isExportModalOpen && (
        <ExportToCsvModal
          isOpen={isExportModalOpen}
          progress={exportProgress ?? 0}
          isBeingCancelled={deleteTaskMutationLoading}
          onClose={handleExportModalClose}
        />
      )}

      {isRequestsDrawerOpen && (
        <EnrollmentRequestsDrawer
          isOpen={isRequestsDrawerOpen}
          canMassEnrollUsers={canMassEnrollUsers ?? false}
          onClose={(): void => setRequestsDrawerOpen(false)}
          openDrawerFullscreen={openDrawerFullscreen}
          size={drawerSize}
          hasBackButton={opensFromDrawer}
        />
      )}
    </div>
  );
};

export default CourseUsers;
