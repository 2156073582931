import React, { FC } from "react";

import { SerializedStyles } from "@emotion/react";
import { Tag, Text } from "@epignosis_llc/gnosis";

import { FileTagStyles } from "@components/ReusableComponents/FileList/styles";
import { TypographyLevels } from "@styles/utils";

import { FileType } from "types/entities";

type FileTagProps = {
  type: FileType;
  size?: "sm" | "md";
  fontSize?: TypographyLevels;
};

const FileTag: FC<FileTagProps> = ({ type, size = "sm", fontSize = "2xs" }) => {
  return (
    <Tag className="file-tag" css={(theme): SerializedStyles => FileTagStyles(theme, size)}>
      <Text fontSize={fontSize} as="div">
        {type}
      </Text>
    </Tag>
  );
};

export default FileTag;
