import React, { FC, useCallback, useMemo } from "react";

import { CertificateSmSVG } from "@epignosis_llc/gnosis/icons";

import { useApplyTranslations } from "@hooks";

import {
  ActionsContainer,
  CompleteCourseAction,
  PreviewAction,
  ResetAction,
  SynchronizeAction,
  UnenrollAction,
  UpdateExpirationDateAction,
} from "@components";

import { CourseUser, UserPolicies } from "@views/Course/CourseUsers/types";

type CourseUsersActionsProps = {
  user: CourseUser;
  policies: UserPolicies;
  hasCertificate: boolean;
  canSyncProgress: boolean;
  canResetProgress: boolean;
  canViewUser: boolean;
  isHovered: boolean;
  onUpdateExpirationDate: (userId: string) => void;
  onUnenrollUser: (userId: string) => void;
  onPreviewCertificate: (userId: string) => void;
  onSyncProgress: (userId: string) => void;
  onResetProgress: (userId: string) => void;
  onCompleteCourse: (userId: string) => void;
  onRowClick: (user: CourseUser) => void;
};

const CourseUsersActions: FC<CourseUsersActionsProps> = ({
  user,
  policies,
  hasCertificate,
  canSyncProgress,
  canResetProgress,
  canViewUser,
  isHovered,
  onUpdateExpirationDate,
  onUnenrollUser,
  onPreviewCertificate,
  onSyncProgress,
  onResetProgress,
  onCompleteCourse,
  onRowClick,
}): JSX.Element => {
  const { id, name } = user;
  const userId = id.toString();
  const { t } = useApplyTranslations();

  const {
    can_edit_expiration: canEditExpiration,
    can_unenroll: canUnenroll,
    can_preview_certificate: canPreviewCertificate,
    can_complete: canCompleteCourse,
  } = policies ?? {};

  const handleUpdateExpirationDate = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>): void => {
      e.stopPropagation();
      onUpdateExpirationDate(userId);
    },
    [onUpdateExpirationDate, userId],
  );

  const handleUnenrollUser = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>): void => {
      e.stopPropagation();
      onUnenrollUser(userId);
    },
    [onUnenrollUser, userId],
  );

  const handleCertificatePreview = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>): void => {
      e.stopPropagation();
      onPreviewCertificate(userId);
    },
    [onPreviewCertificate, userId],
  );

  const handleSyncProgress = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>): void => {
      e.stopPropagation();
      onSyncProgress(userId);
    },
    [onSyncProgress, userId],
  );

  const handleResetProgress = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>): void => {
      e.stopPropagation();
      onResetProgress(userId);
    },
    [onResetProgress, userId],
  );

  const handleCompleteCourse = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>): void => {
      e.stopPropagation();
      onCompleteCourse(userId);
    },
    [onCompleteCourse, userId],
  );

  const actions = useMemo(
    () => [
      canViewUser && (
        <PreviewAction
          entity={name}
          key="course-user-preview"
          onClick={(event): void => {
            event.stopPropagation();
            onRowClick(user);
          }}
        />
      ),
      canUnenroll && (
        <UnenrollAction key="unenroll-user-action" entity={name} onClick={handleUnenrollUser} />
      ),
      canSyncProgress && (
        <SynchronizeAction key="sync-user-action" entity={name} onClick={handleSyncProgress} />
      ),
      canEditExpiration && (
        <UpdateExpirationDateAction
          key="change-expiration-date"
          entity={name}
          onClick={handleUpdateExpirationDate}
        />
      ),
      canCompleteCourse && (
        <CompleteCourseAction
          entity={name}
          key="complete-course-action"
          onClick={handleCompleteCourse}
        />
      ),
      canPreviewCertificate && hasCertificate && (
        <PreviewAction
          icon={CertificateSmSVG}
          entity={name}
          key="preview-certificate-action"
          onClick={handleCertificatePreview}
          tooltipContent={t("general.previewCertificate")}
        />
      ),
      canResetProgress && (
        <ResetAction entity={name} key="reset-user-action" onClick={handleResetProgress} />
      ),
    ],
    [
      t,
      canViewUser,
      canEditExpiration,
      handleUpdateExpirationDate,
      canUnenroll,
      handleUnenrollUser,
      canCompleteCourse,
      handleCompleteCourse,
      canPreviewCertificate,
      hasCertificate,
      handleCertificatePreview,
      canSyncProgress,
      handleSyncProgress,
      canResetProgress,
      handleResetProgress,
      onRowClick,
      user,
      name,
    ],
  );

  return <ActionsContainer actions={actions} isHovered={isHovered} />;
};

export default CourseUsersActions;
