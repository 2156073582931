import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";

import { RequestResponse } from "@views/Course/CourseUsers/types";
import {
  CourseUserRes,
  EnrollmentRequestsRes,
  TableCSVExportRes,
  UnenrolledUserRes,
} from "types/responses";

export type EditCourseUserData = {
  courseId: string;
  userId: string;
  options?: {
    role?: string;
    date?: string | null;
  };
};

export const getCourseUsers = async (courseId: string, queryStr = ""): Promise<CourseUserRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.courses.users(courseId)}${queryStr}`);

  return res.data;
};

export const getUnenrolledCourseUsers = async (
  courseId: string,
  queryStr = "",
): Promise<UnenrolledUserRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.courses.unenrolledUsers(courseId)}${queryStr}`);

  return res.data;
};

export const editCourseUser = async (data: EditCourseUserData): Promise<void> => {
  const { options, courseId, userId } = data;
  const { role: course_role, date: expiration_date } = options ?? {};

  await HttpClient.patch(ENDPOINTS.courses.user.edit(courseId, userId), {
    course_role,
    expiration_date,
  });
};

export const getCourseUsersTableExport = async (
  courseId: string,
  queryStr = "",
): Promise<TableCSVExportRes> => {
  const endpoint = `${ENDPOINTS.tableExports.courseUsers(courseId)}${queryStr}`;
  const res = await HttpClient.post(endpoint);

  return res.data;
};

export const getEnrollmentRequests = async (
  courseId: string,
  queryStr: string,
): Promise<EnrollmentRequestsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.courses.enrollmentRequests(courseId)}${queryStr}`);

  return res.data;
};

export const exportEnrollmentRequests = async (
  courseId: string,
  queryStr = "",
): Promise<TableCSVExportRes> => {
  const res = await HttpClient.post(
    `${ENDPOINTS.tableExports.enrollmentRequestsExport(courseId)}${queryStr}`,
  );
  return res.data;
};

export const postProcessEnrollmentRequest = async (
  courseId: string,
  requestId: string,
  data: RequestResponse,
): Promise<EnrollmentRequestsRes> => {
  const res = await HttpClient.post(
    ENDPOINTS.courses.processEnrollmentRequest(courseId, requestId),
    { status: data },
  );

  return res.data;
};
