import React, { FC } from "react";

import { SerializedStyles } from "@emotion/react";
import { Button, Tooltip } from "@epignosis_llc/gnosis";
import { ChevronArrowLeftSVG, ChevronArrowRightSVG } from "@epignosis_llc/gnosis/icons";

import { ArrowButtonStyles } from "@components/ReusableComponents/ArrowButton/styles";

import { i18n } from "@utils/i18n";

export type ArrowButtonProps = React.HTMLProps<HTMLButtonElement> & {
  buttonText: string;
  tooltipText?: string;
  tooltipEnabled?: boolean;
  testId?: string;
  ariaLabel?: string;
  onClick?: () => void;
};

const ArrowButton: FC<ArrowButtonProps> = ({
  buttonText,
  disabled,
  tooltipText,
  tooltipEnabled,
  testId = "arrow-button",
  ariaLabel,
  onClick,
}) => {
  const isRtl = i18n.dir() === "rtl";

  return (
    <Tooltip content={tooltipText} disabled={!tooltipEnabled}>
      <Button
        id={testId}
        css={(theme): SerializedStyles => ArrowButtonStyles(theme)}
        type="button"
        variant="ghost"
        block
        noGutters
        disabled={disabled}
        onClick={onClick}
        iconAfter={isRtl ? ChevronArrowLeftSVG : ChevronArrowRightSVG}
        data-testid={testId}
        aria-label={ariaLabel}
      >
        {buttonText}
      </Button>
    </Tooltip>
  );
};

export default ArrowButton;
