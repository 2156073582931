import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Checkbox, Loader } from "@epignosis_llc/gnosis";
import { useToggle } from "ahooks";
import { AxiosError } from "axios";

import { getCategories } from "@api";

import { getBranches } from "@api/branch";
import { getCertificateTemplates } from "@api/certificates";
import { getCourses } from "@api/courses";
import { getGroups } from "@api/group";
import {
  resetConfirmationButton,
  resolveDisableButton,
  resolveKey,
  resolveSelectOptions,
  resolveValue,
  syncConfirmationButton,
} from "@components/ReusableComponents/MassActions/helpers";
import { useApplyTranslations } from "@hooks";
import {
  buildCoursesForGroupSelect,
  buildPaginatedSearchQuery,
  generalNotification,
} from "@utils/helpers";
import { createCategoriesWithLevels } from "@views/Categories/helpers";

import {
  coursesSearchQueryObject,
  getTableItems,
  triggerCountOnMountTypes,
} from "@components/ReusableComponents/MassActions/constants";
import queryKeys from "@constants/queryKeys";
import userRoles from "@constants/userRoles";
import { filtersSearchQueryParams } from "@views/Course/CourseGroups/constants";

import { ConfirmationModal } from "@components";
import AnimatedProgressBar from "@components/ReusableComponents/AnimatedProgressBar/AnimatedProgressBar";
import CompleteMassActionModalBody from "@components/ReusableComponents/MassActions/components/CompleteMassActionModalBody";
import ModalBody from "@components/ReusableComponents/MassActions/components/ModalBody";
import SetExpirationMassActionModalBody from "@components/ReusableComponents/MassActions/components/SetExpirationMassActionModalBody";
import {
  GroupUsersCountType,
  InternalStateType,
  MassActionEnum,
  MassActionModalProps,
  MassActionParam,
} from "@components/ReusableComponents/MassActions/types";
import { EnrollModeType } from "@views/Group/components/GroupUsers/types";

import { GroupedOption, SelectOption } from "types/common";
import { BranchByUsers, GroupByUsers } from "types/entities";
import { CountMassActionResponse, MassActionResultResponse } from "types/responses";

const MassAction: FC<MassActionModalProps> = ({
  type,
  isOpen,
  originTableName = "",
  showOnCatalogState,
  preventSyncCompletedState,
  itemNameLabel,
  getBranchesByUsersRequest,
  getGroupsByUsersRequest,
  handleInvalidateQueryMassActions,
  massActionRequest,
  countRequest,
  toggleMassActionModalOpen,
  resetMassActionType,
  cleanState,
  handleShowOnCatalog,
  enrollMode,
  onSelectedItemsChange,
  handlePreventSyncCompleted,
}) => {
  const { t } = useApplyTranslations();
  const filtersSearchQuery = buildPaginatedSearchQuery(filtersSearchQueryParams);
  const coursesSearchQuery = buildPaginatedSearchQuery(coursesSearchQueryObject);
  const shouldTriggerCount = triggerCountOnMountTypes.includes(type);
  const { wordings, errorHandler } = getTableItems(originTableName);

  const tableSpecificWording = wordings?.[type];
  const {
    confirmationButton,
    confirmationHeader,
    progressHeader,
    progressMessage,
    confirmationButtonColor = "primary",
    confirmationBodyTitle,
    confirmationBodyText,
    limitReachedMessage,
    successMessage,
    prerequisiteWarningMessage,
    showOnCatalog,
    preventSyncCompleted,
  } = tableSpecificWording ?? {};
  const cancelMassAction = useRef(false);
  const [isProgressModalOpen, { toggle: toggleProgressModalOpen }] = useToggle(false);
  const [selectedItemName, setSelectedItemName] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<number | number[] | null>();
  const [optionsByUsers, setOptionsByUsers] = useState<(BranchByUsers | GroupByUsers)[]>([]);
  const [prerequisiteWarning, setPrerequisiteWarning] = useState<string>("");
  const trainerRole = userRoles.TRAINER as EnrollModeType;
  const learnerRole = userRoles.LEARNER as EnrollModeType;
  const [groupUsersCount, setGroupUsersCount] = useState<GroupUsersCountType>({
    total_unique_users: 0,
    total_unique_courses: 0,
  });
  const [hasReachedMaxUsers, sethasReachedMaxUsers] = useState(false);
  const [internalState, setInternalState] = useState<InternalStateType>({
    total: 0,
    chunks: 0,
    processed: 0,
    currentChunk: 0,
  });
  const [setExpirationMassActionDate, setSetExpirationMassActionDate] = useState<
    string | undefined
  >(undefined);
  const [completeMassActionEnrollmentDate, setCompleteMassActionEnrollmentDate] = useState<
    string | undefined
  >(undefined);
  const [completeMassActionCompletionDate, setCompleteMassActionCompletionDate] = useState<
    string | undefined
  >(undefined);

  const { total, chunks, processed, currentChunk } = internalState;

  const queryClient = useQueryClient();

  const { data: branches = [] } = useQuery(
    [queryKeys.myBranches],
    () => getBranches(filtersSearchQuery),
    {
      select: (branches) => branches._data,
      enabled:
        type === "add_to_branches" || type === "add_to_branch" || type === "remove_from_branch",
    },
  );

  const { data: groups = [] } = useQuery(
    [queryKeys.myGroups],
    () => getGroups(filtersSearchQuery),
    {
      select: (groups) => groups._data,
      enabled: type === "add_to_groups" || type === "add_to_group" || type === "remove_from_group",
    },
  );

  const { data: categoryTree = [] } = useQuery(
    [queryKeys.categories.categories],
    () => getCategories(),
    {
      select: (sections) => sections._data,
      enabled: type === "add_to_category",
    },
  );

  const categoriesWithLevels = categoryTree && createCategoriesWithLevels(categoryTree);

  const { data: certificateTemplates = [] } = useQuery(
    [queryKeys.certificates.templates],
    getCertificateTemplates,
    { select: (res) => res._data, enabled: type === "assign_certification" },
  );

  const isCoursesQueryEnabled =
    type === "add_course_to_all_branches" ||
    type === "remove_course_from_all_branches" ||
    type === "add_course_to_all_groups" ||
    type === "remove_course_from_all_groups" ||
    type === "add_to_course" ||
    type === "remove_from_course" ||
    type === "custom_reports_reset_progress";

  const { data: courses } = useQuery(
    [queryKeys.courses.courses, coursesSearchQuery],
    () => getCourses(coursesSearchQuery),
    {
      select: (courses) => ({
        data: courses._data,
      }),
      enabled: isCoursesQueryEnabled,
    },
  );

  // Wrap in useCallback
  const calculateSelectOptions = useCallback((): SelectOption[] | GroupedOption[] => {
    switch (type) {
      case "add_to_groups":
      case "add_to_group":
      case "remove_from_group":
        return resolveSelectOptions(groups);
      case "add_to_branches":
      case "add_to_branch":
      case "remove_from_branch":
        return resolveSelectOptions(branches);
      case "add_to_category":
        return categoriesWithLevels;
      case "remove_from_branches":
        if (originTableName === "courses") {
          return resolveSelectOptions(branches);
        }
        return resolveSelectOptions(optionsByUsers);
      case "remove_from_groups":
        if (originTableName === "courses") {
          return resolveSelectOptions(groups);
        }
        return resolveSelectOptions(optionsByUsers);
      case "add_course_to_all_branches":
      case "remove_course_from_all_branches":
      case "add_course_to_all_groups":
      case "remove_course_from_all_groups":
      case "add_to_course":
      case "remove_from_course":
      case "custom_reports_reset_progress":
        return buildCoursesForGroupSelect(courses?.data ?? []);
      case "assign_certification":
        return resolveSelectOptions(certificateTemplates);
      default:
        return [];
    }
  }, [
    type,
    groups,
    branches,
    originTableName,
    optionsByUsers,
    courses?.data,
    certificateTemplates,
    categoriesWithLevels,
  ]);

  const resetCount = (): void => {
    setInternalState((state) => {
      return { ...state, total: 0, chunks: 0, processed: 0, currentChunk: 0 };
    });
  };

  const handleConfirmMassAction = async (confirmationButtonItem?: boolean): Promise<void> => {
    toggleProgressModalOpen();
    toggleMassActionModalOpen();
    const key = resolveKey(type);

    for (let i = 0; i < chunks; i++) {
      if (cancelMassAction.current) {
        cancelMassAction.current = false;
        return;
      }

      if (key.length > 0) {
        if (!(typeof confirmationButton === "string")) {
          if (type === "sync") {
            await massActionMutation({
              remove_certificates: confirmationButtonItem,
              chunk_index: i,
              prevent_completed: preventSyncCompletedState,
            });
          } else {
            await massActionMutation({ [key]: confirmationButtonItem });
          }
        } else {
          await massActionMutation({ [key]: selectedItems });
        }
      } else {
        const massActionData = ((): MassActionParam => {
          switch (type) {
            case MassActionEnum.setExpirationDate:
              return { expiration_date: setExpirationMassActionDate };
            case MassActionEnum.complete:
              return {
                enrollment_date: completeMassActionEnrollmentDate,
                completion_date: completeMassActionCompletionDate,
              };
            default:
              return {};
          }
        })();

        const response = await massActionMutation(massActionData);
        const processed = response._data.processed;
        const halfChunks = chunks / 2 - 1;
        if (type == "add_users_to_group_courses" && enrollMode) {
          if (processed === 0) {
            if (enrollMode.current === trainerRole) {
              i = halfChunks;
              setInternalState((state) => {
                return { ...state, currentChunk: halfChunks + 1 };
              });
              enrollMode.current = learnerRole;
              continue;
            } else {
              setInternalState((state) => {
                return { ...state, currentChunk: chunks };
              });
              break;
            }
          }
        }
      }
    }
  };

  const handleCloseConfirmMassAction = (): void => {
    toggleMassActionModalOpen();
    resetMassActionType();
  };

  const handleCloseProgressModal = (): void => {
    toggleProgressModalOpen();
    handleInvalidateQueryMassActions?.();
    resetMassActionType();
    cleanState?.();
    resetCount();
    cancelMassAction.current = true;
  };

  const typesToGetLabel = [
    "add_to_category",
    "add_course_to_all_branches",
    "remove_course_from_all_branches",
    "add_course_to_all_groups",
    "remove_course_from_all_groups",
  ];

  const handleItemSelect = (selectedItems: SelectOption | SelectOption[]): void => {
    const key = resolveKey(type);
    const value = resolveValue(selectedItems);

    if (typesToGetLabel.includes(type)) {
      const label = !Array.isArray(selectedItems)
        ? (selectedItems as SelectOption).label
        : selectedItems[0].label;
      setSelectedItemName(label);
    }

    const isNullOrEmpty = value === null || (Array.isArray(value) && value.length === 0);
    if (isNullOrEmpty) {
      onSelectedItemsChange && onSelectedItemsChange(null);
      setSelectedItems(null);
      resetCount();
      return;
    }

    onSelectedItemsChange && onSelectedItemsChange(value);
    setSelectedItems(value);
    countMutation({ [key]: value });
  };

  const resolveModalConfirmationButton = (): JSX.Element | string => {
    switch (type) {
      case "reset":
        return resetConfirmationButton(
          handleConfirmMassAction,
          shouldDisableApplyButton || total <= 0,
          originTableName == "assignments",
        );
      case "sync":
        return syncConfirmationButton(
          handleConfirmMassAction,
          shouldDisableApplyButton || total <= 0,
          originTableName == "assignments",
        );
      default:
        return t(confirmationButton ?? "general.apply");
    }
  };

  const handleExpirationDateChange = (date: string): void => {
    setSetExpirationMassActionDate(date);
  };

  const handleEnrollmentDateChange = (date: string): void => {
    setCompleteMassActionEnrollmentDate(date);
  };

  const handleCompletionDateChange = (date: string): void => {
    setCompleteMassActionCompletionDate(date);
  };

  const resolveModalText = (): JSX.Element => {
    let modalText = t(confirmationBodyText ?? "", { count: total ?? 0 });

    // For courses table deactivate_prerequisites is not supported
    if (prerequisiteWarning) {
      modalText += "<br><br>" + t(prerequisiteWarning);
    }

    if (isCountLoading) {
      return <Loader size="md" />;
    }

    if (showOnCatalog) {
      return (
        <>
          <div dangerouslySetInnerHTML={{ __html: modalText }} />
          {!shouldDisableApplyButton && (
            <Checkbox
              id="show-on-catalog"
              name={t(showOnCatalog)}
              label={t(showOnCatalog)}
              value={showOnCatalog.toString()}
              onChange={handleShowOnCatalog}
              checked={showOnCatalogState}
            />
          )}
        </>
      );
    }

    if (preventSyncCompleted) {
      return (
        <>
          <div dangerouslySetInnerHTML={{ __html: modalText }} />
          <Checkbox
            id="prevent-completed"
            name={t(preventSyncCompleted)}
            label={t(preventSyncCompleted)}
            value={preventSyncCompleted.toString()}
            onChange={handlePreventSyncCompleted}
            checked={preventSyncCompletedState}
          />
        </>
      );
    }

    if (type === MassActionEnum.setExpirationDate && total > 0) {
      return <SetExpirationMassActionModalBody handleExpirationDate={handleExpirationDateChange} />;
    }

    if (type === MassActionEnum.complete && total > 0) {
      return (
        <CompleteMassActionModalBody
          handleEnrollmentDate={handleEnrollmentDateChange}
          handleCompletionDate={handleCompletionDateChange}
        />
      );
    }

    return <div dangerouslySetInnerHTML={{ __html: modalText }} />;
  };

  const resolveProgressBodyTitle = (): JSX.Element => {
    const { total_unique_users, total_unique_courses } = groupUsersCount;

    if (total_unique_users && total_unique_courses) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t(progressMessage, {
              count: total_unique_users,
              totalCourses: total_unique_courses,
            }),
          }}
        />
      );
    }
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: t(progressMessage, {
            count: total,
            selectedItems: Array.isArray(selectedItems) ? selectedItems.length : 1,
          }),
        }}
      />
    );
  };
  const onProgressComplete = (): void => {
    if (processed === 0) {
      handleCloseProgressModal();
      return;
    }

    let successMessageParams: { [key: string]: string | number } = { count: processed };
    // Add_to_category is not multi select, we can show the name on the notification
    if (typesToGetLabel.includes(type)) {
      successMessageParams = { count: processed, itemName: selectedItemName };
    } else if (selectedItems) {
      successMessageParams = {
        count: processed,
        selectedItems: Array.isArray(selectedItems) ? selectedItems.length : 1,
      };
    }

    if (type === MassActionEnum.course_store_mass_acquire) {
      queryClient.invalidateQueries([queryKeys.reverseTrial.hasTalentLibraryCollection]);
    }

    generalNotification(
      "success",
      <div dangerouslySetInnerHTML={{ __html: t(successMessage, successMessageParams) }}></div>,
    );

    handleCloseProgressModal();
  };

  const { mutate: getGroupsByUsersMutation } = useMutation(() => getGroupsByUsersRequest!(), {
    onSuccess: (res) => {
      setOptionsByUsers(res._data);
    },
  });

  const { mutate: getBranchesByUsersMutation } = useMutation(() => getBranchesByUsersRequest!(), {
    onSuccess: (res) => {
      setOptionsByUsers(res._data);
    },
  });

  const { mutate: countMutation, isLoading: isCountLoading } = useMutation(
    (data: MassActionParam) => countRequest(type, data),
    {
      onSuccess: (res: CountMassActionResponse) => {
        if (res._data.will_reach_plan_limit) {
          generalNotification("error", t(limitReachedMessage ?? ""));
          return;
        }

        // For courses table deactivate_prerequisites is not supported
        if (res._data.deactivating_prerequisites && prerequisiteWarningMessage) {
          setPrerequisiteWarning(prerequisiteWarningMessage);
        } else {
          setPrerequisiteWarning("");
        }

        if (res._data.will_reach_capacity) {
          setPrerequisiteWarning(prerequisiteWarningMessage ?? "");
          sethasReachedMaxUsers(true);
        }

        if (res._data.total_unique_users && res._data.total_unique_courses) {
          setGroupUsersCount({
            total_unique_users: res._data.total_unique_users,
            total_unique_courses: res._data.total_unique_courses,
          });
        }

        const { total, chunks } = res._data;
        setInternalState((state) => {
          return { ...state, total, chunks };
        });
      },
      onError: (error: AxiosError) => {
        errorHandler?.(error);
      },
    },
  );

  const { mutateAsync: massActionMutation } = useMutation(
    (data: MassActionParam) => massActionRequest(type, data),
    {
      onSuccess: (res: MassActionResultResponse) => {
        setInternalState((state) => {
          return { ...state, processed: state.processed + res._data.processed };
        });
      },
      onError: (error: AxiosError) => {
        errorHandler?.(error);
      },
      onSettled: () => {
        setInternalState((state) => {
          return { ...state, currentChunk: state.currentChunk + 1 };
        });
      },
    },
  );

  const shouldDisableApplyButton = resolveDisableButton(
    total,
    hasReachedMaxUsers,
    shouldTriggerCount,
    selectedItems,
  );

  useEffect(() => {
    if (originTableName === "users") {
      if (type === "remove_from_branches") {
        getBranchesByUsersRequest && getBranchesByUsersMutation();
      }

      if (type === "remove_from_groups") {
        getGroupsByUsersRequest && getGroupsByUsersMutation();
      }
    }
  }, [
    type,
    getGroupsByUsersMutation,
    getBranchesByUsersMutation,
    getBranchesByUsersRequest,
    getGroupsByUsersRequest,
    originTableName,
  ]);

  useEffect(() => {
    if (shouldTriggerCount) {
      type === "sync"
        ? countMutation({ prevent_completed: preventSyncCompletedState })
        : countMutation({});
    }
  }, [countMutation, shouldTriggerCount, type, preventSyncCompletedState]);

  return (
    <>
      {isProgressModalOpen && (
        <ConfirmationModal
          id={`mass-action-${type}-progress-modal`}
          header={t(progressHeader)}
          isOpen={isProgressModalOpen}
          bodyTitle={resolveProgressBodyTitle()}
          bodyText={
            <AnimatedProgressBar
              width={(currentChunk / chunks) * 100}
              onProgressComplete={onProgressComplete}
            />
          }
          onClose={handleCloseProgressModal}
        />
      )}
      {isOpen && (
        <ConfirmationModal
          id={`mass-action-${type}-confirmation-modal`}
          header={t(confirmationHeader)}
          isOpen={isOpen}
          hasOverflow={false}
          bodyTitle={
            <ModalBody
              total={total}
              type={type}
              confirmationBodyTitle={confirmationBodyTitle ?? ""}
              itemNameLabel={itemNameLabel}
              isCountLoading={isCountLoading}
              shouldTriggerCount={shouldTriggerCount}
              handleItemSelect={handleItemSelect}
              calculateSelectOptions={calculateSelectOptions}
            />
          }
          bodyText={resolveModalText()}
          confirmationButtonColor={confirmationButtonColor}
          footerButton={resolveModalConfirmationButton()}
          onConfirm={(): void => {
            handleConfirmMassAction();
          }}
          disableConfirmButton={shouldDisableApplyButton}
          onClose={handleCloseConfirmMassAction}
          shouldCallOnClose={false}
          closeOnOutsideClick={true}
        />
      )}
    </>
  );
};

export default MassAction;
