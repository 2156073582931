import { t } from "@utils/i18n";
import { yup } from "@utils/validation/validation";

import {
  requiredHoursEvents,
  SchedulerConditionEvent,
} from "@views/Notifications/NotificationsOverview/constants";

const eventTypeOptions = Object.values(SchedulerConditionEvent);
const isRequiredHoursEvent = (type: string): boolean => {
  const eventType: SchedulerConditionEvent = type as SchedulerConditionEvent;
  return requiredHoursEvents.includes(eventType);
};

const isRequiredConditionLevel = (type: string): boolean => {
  const eventType: SchedulerConditionEvent = type as SchedulerConditionEvent;
  return SchedulerConditionEvent.LevelReached === eventType;
};

export const NotificationsSchemas = yup.object().shape({
  body: yup.string().required(),
});

export const PreviewNotificationSchema = yup.object().shape({
  recipients: yup.array().of(yup.string().email().label("Email")).min(1).required(),
});

export const EmailFooterSchema = yup.object().shape({
  body: yup.string().trim().required(),
});

export const NotificationSchemas = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(() => t("validationMessages.requiredField"))
    .max(100),
  body: yup
    .string()
    .required(() => t("validationMessages.requiredField"))
    .max(15000),
  event: yup.object().shape({
    type: yup
      .string()
      .required(() => t("validationMessages.requiredField"))
      .nullable(false)
      .oneOf(eventTypeOptions, () => t("validationMessages.requiredField")),
    condition: yup
      .object()
      .when("type", {
        is: isRequiredHoursEvent,
        then: yup.object().shape({
          hours: yup
            .number()
            .required(() => t("validationMessages.requiredField"))
            .min(1, () => t("validationMessages.minNumber", { number: 1 }))
            .max(100000, () => t("validationMessages.maxNumber", { number: 100000 })),
        }),
        otherwise: yup.object().shape({
          hours: yup.number().nullable(true),
        }),
      })
      .when("type", {
        is: (type: string) => isRequiredConditionLevel(type),
        then: yup.object().shape({
          level: yup
            .number()
            .required(() => t("validationMessages.requiredField"))
            .min(2, () => t("validationMessages.minNumber", { number: 2 }))
            .max(20, () => t("validationMessages.maxNumber", { number: 20 }))
            .nullable(false),
        }),
        otherwise: yup.object().shape({
          level: yup.string().nullable(true),
        }),
      }),
  }),

  specific_recipients: yup
    .array()
    .of(yup.string().email())
    .when("recipient", {
      is: (recipient: string) => recipient === "specific_recipients",
      then: yup
        .array()
        .required(() => t("validationMessages.requiredField"))
        .min(1),
      otherwise: yup.array().nullable(),
    }),
  recipients: yup.array().of(yup.string().email()),
});
