import { COLORS } from "@styles/constants";
import { getGnosisTheme } from "@styles/themes/gnosis";
import { getPlusTheme } from "@styles/themes/plus";
import { palletColorsGenerator } from "@styles/utils";

export const THEME_COLOURS = {
  ...COLORS,
};

const colors = palletColorsGenerator(THEME_COLOURS);
const defaultBlue = { ...getGnosisTheme(colors), ...getPlusTheme(colors) };

export type CustomTheme = typeof defaultBlue;

export default defaultBlue;
