import { config } from "@config";

import { MetricsType } from "@utils/services/LogDeviceMetricsService/types";

export const keyBase64 = config.METRICS_KEY;
export const logDebugger = false;

// Feature flags for metrics.
export const enabledMetrics = {
  device: true,
};

// Log Device Metrics Settings.
export const metrics: MetricsType = {
  device: () => ({
    screen: {
      width: screen.width,
      height: screen.height,
    },
  }),
};
