import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import localStorageKeys from "@constants/localStorageKeys";
import { URLS } from "@constants/urls";

type PendingCatalogEnrollment = {
  pending: boolean;
  courseId?: string;
};

type CourseNavigation = () => void;

const useCourseNavigation = (): CourseNavigation => {
  const navigate = useNavigate();

  return useCallback(() => {
    const storageValue = localStorage.getItem(localStorageKeys.PENDING_CATALOG_ENROLLMENT);

    let parsedStorage: PendingCatalogEnrollment | null = null;
    if (storageValue) {
      try {
        parsedStorage = JSON.parse(storageValue) as PendingCatalogEnrollment;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Invalid JSON in localStorage:", error);
      }
    }

    if (parsedStorage?.pending) {
      const courseId = parsedStorage.courseId ?? "";
      const redirectUrl = `${URLS.catalog.createCourseLink({ courseId })}?action=enrollment`;

      navigate(redirectUrl);
      return;
    }

    navigate(URLS.dashboard);
  }, [navigate]);
};

export default useCourseNavigation;
