import React, { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { SerializedStyles } from "@emotion/react";
import { Button, Heading, Tabs } from "@epignosis_llc/gnosis";

import { leaderBoardContainer } from "@views/WidgetsDashboard/components/Widgets/Leaderboard/styles";

import { getLeaderboard } from "@api/gamification";
import { useApplyTranslations } from "@hooks";
import useLeaderboardState from "@hooks/useLeaderboardState";
import { useConfigurationStore } from "@stores";
import { isLeaderBoardEnabled, sortLeaders } from "@utils/helpers";
import permissions from "@utils/permissions";

import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";

import { Skeletons, UserStats } from "@components";
import InfoModal from "@components/UserStats/InfoModal";
import TabWidget from "@views/WidgetsDashboard/components/TabWidget/TabWidget";

import { GamificationSettings, Leaderboard as LeaderboardType } from "types/entities";

export type LeaderboardTabsData = { title: string; data: LeaderboardType | null }[];

type LeaderboardProps = {
  showTitle?: boolean;
  showBorder?: boolean;
  isGamificationDrawer?: boolean;
};

const TAB_TITLES = {
  courses: "dashboard.leaderboardTabs.completedCourses",
  points: "general.points",
  levels: "general.levels",
  certificates: "general.certificates",
  badges: "general.badges",
};

const footerButtonTranslations = {
  badges: "dashboard.badgeGallery",
  points: "gamification.collectMorePoints",
  levels: "gamification.howToLevelUp",
};

const hasFooterContent = (
  leaderboardSelectedTab: string,
  gamificationSettings: GamificationSettings | null,
  isGamificationDrawer: boolean,
): boolean => {
  if (!isGamificationDrawer) return false;
  if (!["points", "levels", "badges"].some((tab) => tab === leaderboardSelectedTab)) return false;
  const { points, levels, badges } = gamificationSettings ?? {};

  return Boolean(points?.enabled || levels?.enabled || badges?.enabled);
};

const Leaderboard: FC<LeaderboardProps> = ({ showTitle, isGamificationDrawer = false }) => {
  const { t } = useApplyTranslations();
  const gamificationSettings = useConfigurationStore((state) => state.gamificationSettings);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeTitle, setActiveTitle] = useState<string | null>(null);
  const leaderboardIsEnabled = isLeaderBoardEnabled(gamificationSettings);
  const { canAccessGamification } = permissions.gamificationPermissions;
  const allowGamificationView = canAccessGamification();

  const {
    data: leaderboardData,
    status: leaderboardStatus,
    error: leaderboardDataError,
  } = useQuery([queryKeys.leaderboard, leaderboardIsEnabled], getLeaderboard, {
    select: (res) => res._data,
    enabled: Boolean(allowGamificationView && leaderboardIsEnabled),
  });

  const leaderboardTabs = useLeaderboardState(leaderboardData);

  const [hasFooter, setHasFooter] = useState(() =>
    hasFooterContent(
      leaderboardTabs[selectedTab]?.title,
      gamificationSettings,
      isGamificationDrawer,
    ),
  );

  useEffect(() => {
    setHasFooter(
      hasFooterContent(
        leaderboardTabs[selectedTab]?.title,
        gamificationSettings,
        isGamificationDrawer,
      ),
    );
  }, [gamificationSettings, selectedTab, leaderboardTabs, isGamificationDrawer]);

  // Reset active tab when gamification settings change, to not point in a hidden tab
  useEffect(() => {
    if (selectedTab === leaderboardTabs.length) {
      setSelectedTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaderboardTabs.length]);

  const handleFooterClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const title = e.currentTarget.id;

    if (title === "badges") {
      navigate(URLS.user.badges);
    } else {
      setActiveTitle(title);
      setIsOpen(true);
    }
  };

  const handleClickClose = (): void => {
    setActiveTitle(null);
    setIsOpen(false);
  };

  return (
    <div css={(): SerializedStyles => leaderBoardContainer()}>
      <Skeletons.Loader status={leaderboardStatus} error={leaderboardDataError}>
        {showTitle && <Heading className="header-container">{t("general.leaderboard")}</Heading>}
        <div className="content-container">
          {leaderboardTabs.length > 0 && (
            <Tabs
              selectedTab={selectedTab}
              className="leaderboard-tabs"
              onChangeTab={setSelectedTab}
              tabs={leaderboardTabs.map(({ title, data }) => {
                const isNotCouresAndCertificates = title !== "courses" && title !== "certificates";
                const showFooter = !isGamificationDrawer && isNotCouresAndCertificates;

                return {
                  title: t(TAB_TITLES[title]),
                  content: (
                    <TabWidget
                      isGamificationDrawer={isGamificationDrawer}
                      style={{ paddingTop: "0.5rem" }}
                    >
                      <UserStats
                        title={title}
                        stats={sortLeaders(data as LeaderboardType)}
                        isGamificationDrawer={isGamificationDrawer}
                        hasFooter={hasFooter}
                      />
                      {showFooter && (
                        <footer>
                          <Button
                            className="view-all-certificates"
                            onClick={handleFooterClick}
                            id={title}
                          >
                            {t(footerButtonTranslations[title])}
                          </Button>
                        </footer>
                      )}
                    </TabWidget>
                  ),
                };
              })}
            />
          )}
        </div>
        <InfoModal isOpen={isOpen} title={activeTitle ?? ""} onClose={handleClickClose} />
      </Skeletons.Loader>
    </div>
  );
};

export default Leaderboard;
