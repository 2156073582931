import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["rewardsTabWidget"] => {
  return {
    borderBottomColor: colors.secondary.lighter,
    iconBackgroundColor: colors.white,
    titleColor: colors.secondary.base,
    points: {
      iconBorderColor: colors.blue.base,
    },
    badges: {
      iconBorderColor: colors.green.base,
    },
    level: {
      iconBorderColor: colors.orange.base,
    },
    extra: {
      iconBorderColor: colors.black,
    },
    drawer: {
      globalDiscountColor: colors.secondary.darker,
    },
  };
};
