import React, { FC, MouseEvent, useEffect, useState } from "react";

import { Button, Checkbox, Modal, Text } from "@epignosis_llc/gnosis";

import { modalBody, modalFooter } from "@components/ReusableModals/styles";

import { sanitizeData } from "@components/ReusableModals/utils";
import { useApplyTranslations } from "@hooks";
import useUIStore from "@stores/ui";

export type ModalStyles = {
  content?: React.CSSProperties | undefined;
  overlay?: React.CSSProperties | undefined;
};

export type ConfirmationButtonColor = "primary" | "secondary" | "success" | "danger";

export type ConfirmationModalProps = {
  id: number | string;
  header: string;
  bodyTitle: JSX.Element | string;
  bodyText?: JSX.Element | string;
  footerButton?: JSX.Element | string;
  isOpen: boolean;
  hasDontAskAgainCheckbox?: boolean;
  closeOnOutsideClick?: boolean;
  isLoading?: boolean;
  restrictedBody?: boolean;
  confirmationButtonColor?: ConfirmationButtonColor;
  disableConfirmButton?: boolean;
  shouldCallOnClose?: boolean;
  hasOverflow?: boolean;
  onClose: () => void | Promise<void>;
  onConfirm?: (id: number | string) => void | Promise<void>;
  onAfterOpen?: () => void;
  style?: ModalStyles;
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  id,
  header,
  bodyTitle,
  bodyText,
  footerButton,
  isOpen,
  isLoading = false,
  hasDontAskAgainCheckbox = false,
  closeOnOutsideClick = false,
  disableConfirmButton = false,
  restrictedBody = false,
  confirmationButtonColor = "danger",
  shouldCallOnClose = true,
  hasOverflow = true,
  onClose,
  onConfirm,
  onAfterOpen,
  style,
}) => {
  const { t } = useApplyTranslations();
  const [shouldAskAgain, setShouldAskAgain] = useState(false);
  const { shouldShowConfirmationModal, setShouldShowConfirmationModal } = useUIStore();

  const overflow = hasOverflow ? "auto" : "";

  useEffect(() => {
    if (!shouldShowConfirmationModal && hasDontAskAgainCheckbox) {
      id && onConfirm?.(id);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    id && onConfirm?.(id);

    hasDontAskAgainCheckbox && setShouldShowConfirmationModal(shouldAskAgain);
    shouldCallOnClose && onClose();
  };

  const handleDontAsk = (): void => {
    setShouldAskAgain(!shouldAskAgain);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOutsideClick={closeOnOutsideClick}
      onAfterOpen={onAfterOpen}
      style={{
        ...style,
        overlay: { zIndex: 9998 },
      }}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Body style={{ maxHeight: 600, overflow }}>
        <div css={modalBody(restrictedBody)}>
          <Text fontSize="sm" as="div" className="subject-text">
            {sanitizeData(bodyTitle)}
          </Text>
          <Text fontSize="sm" as="div" className="body-text">
            {sanitizeData(bodyText)}
          </Text>

          {hasDontAskAgainCheckbox && (
            <div className="dontaskagain-checkbox">
              <Checkbox
                id="dont-ask-again"
                name={t("general.dontAskAgain")}
                label={t("general.dontAskAgain")}
                value={shouldAskAgain.toString()}
                onChange={handleDontAsk}
                checked={shouldAskAgain}
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div css={modalFooter}>
          {footerButton && typeof footerButton === "string" ? (
            <Button
              isLoading={isLoading}
              onClick={handleConfirm}
              color={confirmationButtonColor}
              disabled={disableConfirmButton}
              aria-label={footerButton}
            >
              {footerButton}
            </Button>
          ) : (
            footerButton
          )}
          <Button
            color="secondary"
            onClick={onClose}
            className="cancel-btn"
            aria-label={t("general.cancel")}
          >
            {t("general.cancel")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
