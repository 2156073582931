import React, { FC, MouseEventHandler } from "react";
import { To } from "react-router";
import { Link } from "react-router-dom";

import { Button, Heading, Tooltip } from "@epignosis_llc/gnosis";
import { ArrowLeftSVG, RedirectLinkSVG } from "@epignosis_llc/gnosis/icons";

import { headerComponentStyles } from "@components/Drawers/HelpCenter/components/styles";

type HeaderComponentProps = {
  headerTitle?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  link?: To;
};
const HeaderComponent: FC<HeaderComponentProps> = ({ onClick, headerTitle, link }) => {
  const isTitleOverTooltipThreshold = headerTitle && headerTitle.length > 51;
  return (
    <div css={headerComponentStyles}>
      <Button color="secondary" variant="link" onClick={onClick} noGutters className="back-button">
        <ArrowLeftSVG height={28} />
      </Button>

      <Heading as="h3" data-testid="main-drawer-header" className="header-content">
        <div className="header-container">
          {headerTitle && (
            <Tooltip content={headerTitle} disabled={!isTitleOverTooltipThreshold}>
              <div className="header-title">{headerTitle}</div>
            </Tooltip>
          )}

          {link && (
            <Link to={link} target="_blank" className="article-link">
              <RedirectLinkSVG height={32} className="link-icon" />
            </Link>
          )}
        </div>
      </Heading>
    </div>
  );
};

export default HeaderComponent;
