import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["toggleButtonsView"] => {
  return {
    backgroundColor: colors.white,
    borderBottomColor: colors.secondary.lighter,
    textColor: colors.black,
  };
};
