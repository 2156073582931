import { ThemePlusTypes } from "@styles/types";
import { isShadeOfWhite, PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["stats"] => {
  const isLightTheme = isShadeOfWhite(colors.primary.base);

  return {
    numericStat: {
      background: colors.secondary.lighter,
      color: colors.primary.darkest,
      textColor: isLightTheme ? colors.primary.darkest : colors.white,
      lastLoginDifColor: colors.green.base,
    },
  };
};
