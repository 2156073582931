import Color from "color";

import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["referral"] => {
  return {
    reward: {
      figureBackground: colors.secondary.lighter,
      pillBackgroundPending: Color(colors.orange.base).alpha(0.1).string(),
      pillTextPending: colors.orange.base,
      pillBackgroundIneligible: Color(colors.blue.base).alpha(0.1).string(),
      pillTextIneligible: colors.blue.base,
      pillBackgroundClaimed: Color(colors.green.base).alpha(0.1).string(),
      pillTextClaimed: colors.green.base,
      itemHeading: colors.black,
      contentColor: colors.secondary.base,
    },
    rewardContainer: {
      background: colors.white,
      borderColor: colors.secondary.light,
    },
  };
};
