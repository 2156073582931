import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useResponsive } from "ahooks";

import authService from "@utils/services/AuthService";

import { URLS } from "@constants/urls";

const useScreenSizeAlert = (includedRoles: string[] = []): boolean => {
  const [shouldHaveScreenSizeAlert, setShouldHaveScreenSizeAlert] = useState(false);
  const { md } = useResponsive();
  const location = useLocation();
  const userRole = authService.getDefaultRole();
  const isRestrictedRole = includedRoles.includes(userRole);

  useEffect(() => {
    // Screen Size Alert Page paths
    // Here you can add the path that you might want to have a screen size alert.
    // TODO: if it gets big, lets feed this component with a prop of those paths
    const includeScreenSizeAlertPaths = [
      URLS.courses.courses,
      URLS.courses.edit,
      URLS.courses.singleCourse,
      URLS.units.edit,
      URLS.branches.branches,
      URLS.branches.branch,
      URLS.users.users,
      URLS.users.user,
      URLS.users.edit,
      URLS.users.create,
      URLS.reports.reports,
      URLS.groups.groups,
      URLS.groups.group,
      URLS.groups.create,
      URLS.categories.categories,
      URLS.automations.automations,
      URLS.notifications.notifications,
      URLS.customHomePage.builder,
      URLS.skills.skills,
    ];

    // Screen Size Alert Page paths to be excluded
    // TODO: if it gets big, lets feed this component with a prop of those paths
    const excludeScreenSizeAlertPaths: string[] = [URLS.customHomePage.preview];

    const checkScreenSizeAlert = (): void => {
      const pathname = window.location.pathname;
      // Check if the current path is in the list of paths that should have a screen size alert
      const shouldAlert =
        includeScreenSizeAlertPaths.some((pattern) => pathname.startsWith(pattern)) ||
        includeScreenSizeAlertPaths.some((pattern) => pathname.startsWith(`/plus${pattern}`));
      // Check if the current path is in the list of paths that should NOT have a screen size alert
      const shouldExclude =
        excludeScreenSizeAlertPaths.some((pattern) => pathname.startsWith(pattern)) ||
        excludeScreenSizeAlertPaths.some((pattern) => pathname.startsWith(`/plus${pattern}`));

      if (shouldExclude) {
        setShouldHaveScreenSizeAlert(false);
        return;
      }

      setShouldHaveScreenSizeAlert(shouldAlert);
    };
    // Initial check on mount
    checkScreenSizeAlert();

    // Listen for route changes and update the alert status
    const handleRouteChange = (): void => {
      checkScreenSizeAlert();
    };

    window.addEventListener("popstate", handleRouteChange);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return isRestrictedRole && !md && shouldHaveScreenSizeAlert;
};

export default useScreenSizeAlert;
