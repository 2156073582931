import React, { FC } from "react";

import { Select } from "@epignosis_llc/gnosis";

import { resolveLabel } from "@components/ReusableComponents/MassActions/helpers";

import { MassActionEnum, MassActionType } from "@components/ReusableComponents/MassActions/types";

import { GroupedOption, SelectOption } from "types/common";

type ItemSelectType = {
  type: MassActionType;
  selectOptions: SelectOption[] | GroupedOption[];
  handleSelect: (options: SelectOption | SelectOption[]) => void;
};

const hasNotMultiSelect = [
  MassActionEnum.addCourseToAllBranches,
  MassActionEnum.addCourseToAllGroups,
  MassActionEnum.removeCourseFromAllBranches,
  MassActionEnum.removeCourseFromAllGroups,
  MassActionEnum.add_to_category,
  MassActionEnum.add_to_branch,
  MassActionEnum.remove_from_branch,
  MassActionEnum.add_to_group,
  MassActionEnum.remove_from_group,
  MassActionEnum.add_to_course,
  MassActionEnum.remove_from_course,
  MassActionEnum.custom_reports_reset_progress,
  MassActionEnum.assign_certification,
];

const ItemSelect: FC<ItemSelectType> = ({ type, handleSelect, selectOptions }) => {
  const label = resolveLabel(type);
  const isMulti = !hasNotMultiSelect.includes(type);

  return (
    <Select
      label={label}
      aria-label={label}
      options={selectOptions}
      isMulti={isMulti}
      menuPlacement="auto"
      onChange={(options): void => {
        handleSelect(options as SelectOption[]);
      }}
      closeMenuOnSelect={true}
    />
  );
};

export default ItemSelect;
