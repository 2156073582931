import { useConfigurationStore } from "@stores";
import permissions from "@utils/permissions";
import authService from "@utils/services/AuthService";

import userRoles from "@constants/userRoles";

export const useCanSeeUpgradeButton = (): boolean => {
  const { userProfileData, domainSettings } = useConfigurationStore();

  const canEnableReverseTrial = userProfileData?.policies.can_enable_reverse_trial ?? false;
  const isInTrial = domainSettings?.is_in_trial ?? false;
  const isOnFreePlan = domainSettings?.isFreePlan ?? false;
  const { canAccessSubscription } = permissions.accountPermissions;
  const userRole = authService.getDefaultRole();
  const isAdmin = userRole === userRoles.ADMINISTRATOR;

  return !canEnableReverseTrial && !isInTrial && isOnFreePlan && canAccessSubscription() && isAdmin;
};
