import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["pdfPreview"] => {
  return {
    toolbar: {
      background: colors.black,
      color: colors.white,
    },
  };
};
