import React, { FC } from "react";

import { Button, Modal } from "@epignosis_llc/gnosis";

import { modalFooter } from "@components/ReusableModals/FileModal/styles";

import { useApplyTranslations } from "@hooks";

type FooterProps = {
  showFooter?: boolean;
  customFooter?: JSX.Element;
  onDownloadClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
};

const Footer: FC<FooterProps> = ({ showFooter = true, customFooter, onDownloadClick, onClose }) => {
  const { t } = useApplyTranslations();

  if (!showFooter) return <></>;

  return (
    <Modal.Footer>
      <div css={modalFooter}>
        {customFooter ? (
          customFooter
        ) : (
          <>
            <Button color="primary" onClick={onDownloadClick}>
              {t("general.download")}
            </Button>
            <Button color="secondary" onClick={onClose} className="cancel-btn">
              {t("general.cancel")}
            </Button>
          </>
        )}
      </div>
    </Modal.Footer>
  );
};

export default Footer;
