import { isDaylightSavingTime, isWithinTimeIntervalOnWeekdays } from "@utils/helpers/date-time";
import { t } from "@utils/i18n";

import {
  ArticleFilters,
  BasicSettings,
  ContactSupportEnum,
  SearchQueryOptionsWithFilters,
  SupportHours,
} from "@components/Drawers/HelpCenter/types";

export const buildAPIHelpArticlesFilters = (filters?: ArticleFilters): string => {
  if (!filters) return "";

  const { section_id: sectionId } = filters;

  const sectionFilter = sectionId ? `&filter[section_id][eq]=${sectionId}` : "";

  return `${sectionFilter}`;
};

export const buildHelpArticlesSearchQuery = ({
  filters,
  search,
}: SearchQueryOptionsWithFilters): string => {
  let query = `?${buildAPIHelpArticlesFilters(filters)}`;

  // Add search to query
  if (search) {
    query += `&filter[keyword][like]=${encodeURIComponent(search)}`;
  }

  return query;
};

export const fullTrim = (snippet: string): string => {
  // Remove all occurrences of &nbsp;
  snippet = snippet.replace(/&nbsp;/g, "");

  // Remove leading/trailing whitespace from each line
  snippet = snippet.replace(/(^\s+|\s+$|\n\s+|\s+\n)/g, "");

  // Replace multiple spaces with a single space
  snippet = snippet.replace(/\s+/g, " ");

  // Replace all occurrences of <em> and </em> with <strong> and </strong>
  snippet = snippet.replace(/<em>/g, "<strong>").replace(/<\/em>/g, "</strong>");

  return snippet;
};

export const convertPatternToBreaks = (body: string): string => {
  // Replace specific pattern of </p>\n<p> with </p><br><p
  body = body.replace(/<\/p>\n<p/g, "</p><br><p");

  return body;
};

export const getPathSegments = (path: string): string => {
  // Split the path into parts and filter out numeric segments
  const parts = path.split("/").filter((part) => part && isNaN(Number(part)));

  // Join the remaining parts and return them as a path
  return `/${parts.join("/")}`;
};

const dateToCheck = new Date();

const isDST = isDaylightSavingTime(dateToCheck);

const supportHoursConfig = {
  phoneSupport: {
    dst: { opening: 14, closing: 22 },
    standard: { opening: 15, closing: 23 },
  },
  liveChat: {
    dst: { opening: 5, closing: 22 },
    standard: { opening: 6, closing: 23 },
  },
  phoneSupportText: {
    dst: { opening: 2, closing: 10 },
    standard: { opening: 3, closing: 11 },
  },
  liveChatText: {
    dst: { opening: 5, closing: 10 },
    standard: { opening: 6, closing: 11 },
  },
};

const getAllSupportHours = (
  isDST: boolean,
): Record<keyof typeof supportHoursConfig, SupportHours> => {
  const timeType = isDST ? "dst" : "standard";
  return Object.fromEntries(
    Object.entries(supportHoursConfig).map(([key, hours]) => [key, hours[timeType]]),
  ) as Record<keyof typeof supportHoursConfig, SupportHours>;
};

const { phoneSupport, liveChat, phoneSupportText, liveChatText } = getAllSupportHours(isDST);

const { opening: phoneOpening, closing: phoneClosing } = phoneSupport;
const { opening: chatOpening, closing: chatClosing } = liveChat;
const { opening: phoneSupportOpening, closing: phoneSupportClosing } = phoneSupportText;
const { opening: liveChatOpening, closing: liveChatClosing } = liveChatText;

const isWithinPhoneSupportHours = isWithinTimeIntervalOnWeekdays(
  dateToCheck,
  phoneOpening,
  phoneClosing,
);
const isWithinLiveChatHours = isWithinTimeIntervalOnWeekdays(dateToCheck, chatOpening, chatClosing);

export const getSupportServices = (
  isLiveChatVisible: boolean,
  isPhoneSupportVisible: boolean,
  phoneSupportInPlan?: boolean,
): BasicSettings[] => {
  return [
    {
      title: t("helpCenter.openTicket"),
      description: t("helpCenter.openTicketInfo"),
      placeholder: t("helpCenter.openTicketPlaceholder"),
      name: "submit_ticket",
      testId: ContactSupportEnum.SUBMIT_TICKET,
    },
    {
      title: t("helpCenter.liveChat"),
      description: t("helpCenter.liveChatInfo", {
        openingHour: liveChatOpening,
        closingHour: liveChatClosing,
      }),
      placeholder: isLiveChatVisible
        ? t("helpCenter.hideChat")
        : t("helpCenter.liveChatPlaceholder"),
      tooltipText: t("helpCenter.liveChatTooltip"),
      tooltipEnabled: !isWithinLiveChatHours,
      name: "live_chat",
      testId: ContactSupportEnum.LIVE_CHAT,
    },
    {
      title: t("helpCenter.phoneSupport"),
      description: t("helpCenter.phoneSupportInfo", {
        openingHour: phoneSupportOpening,
        closingHour: phoneSupportClosing,
      }),
      placeholder: isPhoneSupportVisible
        ? t("helpCenter.hideCall")
        : t("helpCenter.phoneSupportPlaceholder"),
      tooltipText: t("helpCenter.phoneSupportTooltip"),
      tooltipEnabled: !isWithinPhoneSupportHours && phoneSupportInPlan,
      name: "phone_support",
      testId: ContactSupportEnum.PHONE_SUPPORT,
    },
    {
      title: t("helpCenter.accountManager"),
      description: t("helpCenter.accountManagerInfo"),
      placeholder: t("helpCenter.accountManagerPlaceholder"),
      name: "account_manager",
      testId: ContactSupportEnum.ACCOUNT_MANAGER,
    },
  ];
};
