import React, { FC } from "react";

import { tocStyles } from "@components/ToC/styles";

import Header, { HeaderProps } from "@components/ToC/components/Header";
import ListContainer, { ListContainerProps } from "@components/ToC/components/ListContainer";
import ListItem, { ListItemProps } from "@components/ToC/components/ListItem";
import ListTitle, { ListTitleProps } from "@components/ToC/components/ListTitle";
import Top, { topProps } from "@components/ToC/components/Top";

type ToCCompoundProps = {
  Top: FC<topProps>;
  Header: FC<HeaderProps>;
  ListContainer: FC<ListContainerProps>;
  ListTitle: FC<ListTitleProps>;
  ListItem: FC<ListItemProps>;
};

type ToCProps = {
  children?: React.ReactNode;
};

const ToC: FC<ToCProps> & ToCCompoundProps = ({ children }) => (
  <div css={tocStyles}>{children}</div>
);

ToC.Top = Top;
ToC.Header = Header;
ToC.ListContainer = ListContainer;
ToC.ListTitle = ListTitle;
ToC.ListItem = ListItem;

export default ToC;
