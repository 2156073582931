import React, { ReactNode } from "react";

import { Heading } from "@epignosis_llc/gnosis";
import { FailedIconSVG, LevelUpIconSVG, PointsIconSVG } from "@epignosis_llc/gnosis/icons";

import { badgeIconStyle } from "@components/ReusableComponents/Badges/styles";

import { GAMIFICATION_NOTIFICATIONS } from "@channels/gamificationNotifications";
import { NOTIFICATIONS } from "@channels/notifications";

import { t } from "@utils/i18n";

import { BadgeFigure } from "@components/ReusableComponents";

import { GamificationBadge, MyUnit, ScormUnit } from "types/entities";

export const showCompleteUnitNotification = (
  unit: MyUnit | ScormUnit,
  type: "success" | "error",
): void => {
  const content = {
    success: (
      <Heading
        as="h5"
        size="xs"
        dangerouslySetInnerHTML={{
          __html: t("assignment.unitCompletedSuccessfully", {
            unitName: unit.name,
          }),
        }}
      />
    ),
    error: (
      <Heading
        as="h5"
        size="xs"
        dangerouslySetInnerHTML={{
          __html: t("assignment.unitNotComplete", {
            unitName: unit.name,
          }),
        }}
      />
    ),
  };

  PubSub.publish(NOTIFICATIONS, { type, content: content[type] });
};

export const featureNotReadyNotification = (): void => {
  PubSub.publish(NOTIFICATIONS, {
    content: <p>{t("notifications.featureNotAvailable")}</p>,
  });
};

export const generalNotification = (
  type: "info" | "success" | "warning" | "error",
  content: ReactNode,
  autoClose: number | boolean = 6000,
): void => {
  // If the notification type is "success" and autoClose is not explicitly provided, set it to 4000
  const finalAutoClose = type === "success" && autoClose === 6000 ? 4000 : autoClose;

  PubSub.publish(NOTIFICATIONS, {
    type: type,
    autoClose: finalAutoClose,
    content: content,
  });
};

type IconType = "points" | "level" | "badge" | "failed";

type GamificationIconProps = {
  iconType: IconType;
  badge: GamificationBadge | undefined;
};

const getGamificationIcon = ({ iconType, badge }: GamificationIconProps): JSX.Element | null => {
  const badgeFigure = badge ? (
    <div css={badgeIconStyle}>
      <BadgeFigure
        type={badge.type}
        image={badge.image}
        awarded={true}
        criteria={badge.criteria}
        name={badge.name}
      />
    </div>
  ) : null;
  switch (iconType) {
    case "points":
      return <PointsIconSVG height={50} />;
    case "level":
      return <LevelUpIconSVG height={50} />;
    case "badge":
      return badgeFigure;
    case "failed":
      return <FailedIconSVG height={50} />;
    default:
      return null;
  }
};

export type GamificationNotificationProps = {
  type: "success" | "error" | "info";
  iconType: IconType;
  content: string;
  heading?: string;
  badge?: GamificationBadge;
  autoClose?: number | boolean;
};

export const gamificationNotification = ({
  type,
  iconType,
  heading,
  content,
  badge,
  autoClose = 4000,
}: GamificationNotificationProps): void => {
  PubSub.publish(GAMIFICATION_NOTIFICATIONS, {
    type: type,
    icon: getGamificationIcon({ iconType, badge }),
    autoClose: autoClose,
    heading: heading,
    content: <p>{content}</p>,
  });
};

export const errorNotification = (
  errorMessage: string,
  autoClose: number | boolean = 6000,
): void => {
  PubSub.publish(NOTIFICATIONS, {
    type: "warning",
    autoClose: autoClose,
    content: <p>{errorMessage}</p>,
  });
};
