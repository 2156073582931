import { Column, TableProps } from "@epignosis_llc/gnosis";

import { t } from "@utils/i18n";

import { QueryFilter, SelectOption } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

// Table data
export const getGroupColumns = ({ isAdmin }: { isAdmin: boolean }): Column[] => {
  return [
    {
      accessor: "name",
      cell: t("general.groups_one"),
      classNames: ["name"],
      headerWidth: 300,
    },
    {
      accessor: "description",
      cell: t("groups.description"),
      classNames: ["description"],
      maxWidth: 200,
      sortOrder: "desc" as Column["sortOrder"],
    },
    {
      accessor: "branch",
      cell: t("groups.branch_one"),
      classNames: ["branch"],
      maxWidth: 200,
      sortOrder: "desc" as Column["sortOrder"],
      hidden: !isAdmin,
    },
    {
      accessor: "price",
      cell: t("groups.price"),
      classNames: ["price"],
      sortOrder: "desc" as Column["sortOrder"],
    },
  ];
};

export const getMassActionsOptions = (): SelectOption[] => {
  return [
    { label: t("groups.addCourseToAllGroups"), value: "add_course_to_all_groups" },
    { label: t("groups.removeCourseFromAllGroups"), value: "remove_course_from_all_groups" },
  ];
};

export const actionsColumn = {
  accessor: "actions",
  cell: "",
  classNames: ["hidden"],
  sortableHeader: false,
};

export enum GroupsFilters {
  branch = "[branch_id]",
}

export const groupsMassActionWordings = {
  add_course_to_all_groups: {
    progressHeader: "groups.addCourseToAllGroups",
    progressMessage: "groups.addingCourseToAllGroups",
    confirmationBodyText: "groups.groupsAffectedByMassAction",
    confirmationHeader: "groups.addCourseToAllGroups",
    confirmationButton: "general.actions.add",
    successMessage: "groups.addCourseToAllGroupsSuccess",
  },
  remove_course_from_all_groups: {
    progressHeader: "groups.removeCourseFromAllGroups",
    progressMessage: "groups.removingCourseFromAllGroups",
    confirmationHeader: "groups.removeCourseFromAllGroups",
    confirmationButton: "general.actions.remove",
    successMessage: "groups.removeCourseFromAllGroupsSuccess",
    confirmationBodyText: "groups.groupsAffectedByMassAction",
  },
};

// Sorting ,pagination and filters
export const SORTING: TableProps["sorting"] = { column: "name", isDescending: false };
export const PAGINATION: IPagination = { number: 1, size: 10 };
export const DEFAULT_FILTERS: QueryFilter[] = [];
