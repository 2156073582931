import { DropdownItem } from "@epignosis_llc/gnosis";
import { BranchesFilterSVG, GroupsFilterSVG, UserFilterSVG } from "@epignosis_llc/gnosis/icons";

import { t } from "@utils/i18n";
import permissions from "@utils/permissions";

import { SessionFilter } from "@views/Reports/types";
import { QueryFilter } from "types/common";
import { Branch, Group } from "types/entities";
import { SearchFilters } from "types/entities/Common";

export enum Filters {
  Status = "[status]",
  UnitType = "[type]",
  Branch = "[branch_id]",
  Group = "[group_id]",
  CompletionStatus = "[completion_status]",
  Session = "[session_id]",
  Skill = "[skill_id]",
}

type FilterOptions = {
  groups?: Group[];
  branches?: Branch[];
  sessions?: SessionFilter[];
  activeStatusIcon?: JSX.Element | SVGAElement;
  inactiveStatusIcon?: JSX.Element | SVGAElement;
  showStatusOptions?: boolean;
  showUnitTypeOptions?: boolean;
  showCompletionStatusOptions?: boolean;
};

// This helper function is used to get the list of filters for branches, groups and statuses for tables
export const getFilterDropdownOptions = ({
  groups = [],
  branches = [],
  sessions = [],
  showStatusOptions = false,
  activeStatusIcon = UserFilterSVG,
  inactiveStatusIcon = UserFilterSVG,
  showUnitTypeOptions = false,
  showCompletionStatusOptions = false,
}: FilterOptions): DropdownItem[] => {
  const dropdownOptions: DropdownItem[] = [];
  const { canReadBranches } = permissions.branchPermissions;
  const { canReadGroups } = permissions.groupPermissions;
  const {
    canAccessAssignmentReports,
    canAccessIltReports,
    canAccessSurveyReports,
    canAccessTestReports,
  } = permissions.reportsPermissions;
  const allowReadBranches = canReadBranches();
  const allowReadGroups = canReadGroups();

  const statusOptions: DropdownItem[] = [
    {
      label: t("myCourses.instructor.filters.active"),
      value: "active",
      category: Filters.Status,
      icon: activeStatusIcon,
    },
    {
      label: t("myCourses.instructor.filters.inactive"),
      value: "inactive",
      category: Filters.Status,
      icon: inactiveStatusIcon,
    },
  ];

  const unitTypeOptions: DropdownItem[] = [
    ...(canAccessAssignmentReports()
      ? [
          {
            label: t("general.assignment"),
            value: "assignment",
            category: Filters.UnitType,
          },
        ]
      : []),
    ...(canAccessIltReports()
      ? [
          {
            label: "ILT",
            value: "ilt",
            category: Filters.UnitType,
          },
        ]
      : []),
    ...(canAccessTestReports()
      ? [
          {
            label: "SCORM", // Not translatable
            value: "scorm",
            category: Filters.UnitType,
          },
        ]
      : []),
    ...(canAccessSurveyReports()
      ? [
          {
            label: t("general.survey"),
            value: "survey",
            category: Filters.UnitType,
          },
        ]
      : []),
    ...(canAccessTestReports()
      ? [
          {
            label: t("general.test"),
            value: "test",
            category: Filters.UnitType,
          },
        ]
      : []),
  ];

  const completionStatusOptions: DropdownItem[] = [
    {
      label: t("general.progress.passed"),
      value: "completed",
      category: Filters.CompletionStatus,
    },
    {
      label: t("general.progress.notPassed"),
      value: "failed",
      category: Filters.CompletionStatus,
    },
    {
      label: t("general.progress.inProgress"),
      value: "incomplete",
      category: Filters.CompletionStatus,
    },
    {
      label: t("general.progress.notAttempted"),
      value: "not_attempted",
      category: Filters.CompletionStatus,
    },
    {
      label: t("general.progress.pending"),
      value: "pending",
      category: Filters.CompletionStatus,
    },
  ];

  if (showStatusOptions) {
    dropdownOptions.push({
      label: t("myCourses.instructor.filters.status"),
      value: "status",
      category: Filters.Status,
      items: statusOptions,
    });
  }

  if (showUnitTypeOptions) {
    dropdownOptions.push({
      label: t("general.type"),
      value: "type",
      category: Filters.UnitType,
      items: unitTypeOptions,
    });
  }

  if (showCompletionStatusOptions) {
    dropdownOptions.push({
      label: t("general.progressStatus"),
      value: "completion_status",
      category: Filters.CompletionStatus,
      items: completionStatusOptions,
    });
  }

  if (sessions.length > 0) {
    const sessionsOptions: DropdownItem[] = sessions.map((session) => {
      return {
        label: session.name,
        value: session.id.toString(),
        category: Filters.Session,
      };
    });

    dropdownOptions.push({
      label: t("ilt.session", { count: 2 }),
      value: "sessions",
      category: Filters.Session,
      items: sessionsOptions,
    });
  }

  if (branches.length > 0 && allowReadBranches) {
    const branchOptions: DropdownItem[] = branches.map((branch) => {
      return {
        label: branch.name,
        value: branch.id.toString(),
        category: Filters.Branch,
        icon: BranchesFilterSVG,
      };
    });

    dropdownOptions.push({
      label: t("general.branches_one", { count: 2 }),
      value: "branches",
      category: Filters.Branch,
      items: branchOptions,
      icon: BranchesFilterSVG,
    });
  }

  if (groups.length > 0 && allowReadGroups) {
    const groupOptions: DropdownItem[] = groups.map((group) => {
      return {
        label: group.name,
        value: group.id.toString(),
        category: Filters.Group,
        icon: GroupsFilterSVG,
      };
    });

    dropdownOptions.push({
      label: t("general.groups_one", { count: 2 }),
      value: "groups",
      category: Filters.Group,
      items: groupOptions,
      icon: GroupsFilterSVG,
    });
  }

  return dropdownOptions;
};

export const hasPermissionsChanged = (
  filters: QueryFilter[],
  handleRestoreDefault: () => void,
  branchPermission?: boolean,
  groupPermission?: boolean,
): void => {
  if (!branchPermission) {
    const hasBranchFilter = filters.some((item) => item.key === "[branch_id]");
    hasBranchFilter && handleRestoreDefault();
  }

  if (!groupPermission) {
    const hasGroupFilter = filters.some((item) => item.key === "[group_id]");
    hasGroupFilter && handleRestoreDefault();
  }
};

export const tableFilterSearch = (filters: QueryFilter[]): string => {
  const relevantKeys = [
    SearchFilters.KEYWORD,
    SearchFilters.NAME,
    SearchFilters.DESCRIPTION,
    SearchFilters.SKILL,
  ];

  const matchedFilter = filters.find((item) => relevantKeys.includes(item.key as SearchFilters));

  return matchedFilter?.value || "";
};
