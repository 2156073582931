import { AnyObjectSchema } from "yup";

import { t } from "@utils/i18n";
import { yup } from "@utils/validation/validation";

export const GroupFormValidationSchema = (isFloatCurrency: boolean): AnyObjectSchema =>
  yup.object().shape(
    {
      name: yup.string().trim().required().max(80),
      description: yup.string().max(500, () => t("errors.groups.descriptionMaxLength")),
      key: yup.string().when("key", (value) => {
        if (value) {
          return yup
            .string()
            .min(6, t("errors.groups.groupKeyMinLength"))
            .max(20, t("errors.groups.groupKeyMaxLength"));
        } else {
          return yup
            .string()
            .transform((value, originalValue) => {
              // Convert empty values to null
              if (!value) {
                return null;
              }
              return originalValue;
            })
            .nullable()
            .optional();
        }
      }),
      max_key_redemptions: yup
        .string()
        .matches(/^[1-9]\d*$|^\s*$/, () => "errors.groups.redemptionsIsNotValid")
        .transform((value) =>
          Number(value) > 16777215 ? "errors.groups.redemptionsIsNotValid" : value,
        ),
      price: yup
        .number()
        .nullable()
        .transform(
          (value, originalValue) => (originalValue === "" ? null : value), // Convert empty string to null
        )
        .maxDigitsAfterDecimal(isFloatCurrency ? 2 : 0),
    },
    [["key", "key"]],
  );

export const JoinGroupValidationSchema = yup.object().shape({
  groupKey: yup.string().trim().required(),
});
