import { css } from "@emotion/react";

import { images } from "@constants/assets";

export const badgeFigure = css`
  margin: 0;
  width: 80px;
  height: 80px;

  div {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    opacity: 0.6;
    background-size: cover;

    &.awarded {
      filter: grayscale(0%);
      opacity: inherit;
    }
  }

  .set-1 {
    background-image: url(${images.badges.set1});
  }
  .set-2 {
    background-image: url(${images.badges.set2});
  }
  .set-3 {
    background-image: url(${images.badges.set3});
  }
  .set-4 {
    background-image: url(${images.badges.set4});
  }
  .set-5 {
    background-image: url(${images.badges.set5});
  }
  .set-6 {
    background-image: url(${images.badges.set6});
  }
  .badge-activity-1 {
    background-position: 0 0%;
  }
  .badge-activity-2 {
    background-position: 0 1.587302%;
  }
  .badge-activity-3 {
    background-position: 0 3.174603%;
  }
  .badge-activity-4 {
    background-position: 0 4.761905%;
  }
  .badge-activity-5 {
    background-position: 0 6.349206%;
  }
  .badge-activity-6 {
    background-position: 0 7.936508%;
  }
  .badge-activity-7 {
    background-position: 0 9.52381%;
  }
  .badge-activity-8 {
    background-position: 0 11.111111%;
  }
  .badge-assignment-1 {
    background-position: 0 12.698413%;
  }
  .badge-assignment-2 {
    background-position: 0 14.285714%;
  }
  .badge-assignment-3 {
    background-position: 0 15.873016%;
  }
  .badge-assignment-4 {
    background-position: 0 17.460317%;
  }
  .badge-assignment-5 {
    background-position: 0 19.047619%;
  }
  .badge-assignment-6 {
    background-position: 0 20.634921%;
  }
  .badge-assignment-7 {
    background-position: 0 22.222222%;
  }
  .badge-assignment-8 {
    background-position: 0 23.809524%;
  }
  .badge-certification-1 {
    background-position: 0 25.396825%;
  }
  .badge-certification-2 {
    background-position: 0 26.984127%;
  }
  .badge-certification-3 {
    background-position: 0 28.571429%;
  }
  .badge-certification-4 {
    background-position: 0 30.15873%;
  }
  .badge-certification-5 {
    background-position: 0 31.746032%;
  }
  .badge-certification-6 {
    background-position: 0 33.333333%;
  }
  .badge-certification-7 {
    background-position: 0 34.920635%;
  }
  .badge-certification-8 {
    background-position: 0 36.507937%;
  }
  .badge-communication-1 {
    background-position: 0 38.095238%;
  }
  .badge-communication-2 {
    background-position: 0 39.68254%;
  }
  .badge-communication-3 {
    background-position: 0 41.269841%;
  }
  .badge-communication-4 {
    background-position: 0 42.857143%;
  }
  .badge-communication-5 {
    background-position: 0 44.444444%;
  }
  .badge-communication-6 {
    background-position: 0 46.031746%;
  }
  .badge-communication-7 {
    background-position: 0 47.619048%;
  }
  .badge-communication-8 {
    background-position: 0 49.206349%;
  }
  .badge-learning-1 {
    background-position: 0 50.793651%;
  }
  .badge-learning-2 {
    background-position: 0 52.380952%;
  }
  .badge-learning-3 {
    background-position: 0 53.968254%;
  }
  .badge-learning-4 {
    background-position: 0 55.555556%;
  }
  .badge-learning-5 {
    background-position: 0 57.142857%;
  }
  .badge-learning-6 {
    background-position: 0 58.730159%;
  }
  .badge-learning-7 {
    background-position: 0 60.31746%;
  }
  .badge-learning-8 {
    background-position: 0 61.904762%;
  }
  .badge-perfectionism-1 {
    background-position: 0 63.492063%;
  }
  .badge-perfectionism-2 {
    background-position: 0 65.079365%;
  }
  .badge-perfectionism-3 {
    background-position: 0 66.666667%;
  }
  .badge-perfectionism-4 {
    background-position: 0 68.253968%;
  }
  .badge-perfectionism-5 {
    background-position: 0 69.84127%;
  }
  .badge-perfectionism-6 {
    background-position: 0 71.428571%;
  }
  .badge-perfectionism-7 {
    background-position: 0 73.015873%;
  }
  .badge-perfectionism-8 {
    background-position: 0 74.603175%;
  }
  .badge-survey-1 {
    background-position: 0 76.190476%;
  }
  .badge-survey-2 {
    background-position: 0 77.777778%;
  }
  .badge-survey-3 {
    background-position: 0 79.365079%;
  }
  .badge-survey-4 {
    background-position: 0 80.952381%;
  }
  .badge-survey-5 {
    background-position: 0 82.539683%;
  }
  .badge-survey-6 {
    background-position: 0 84.126984%;
  }
  .badge-survey-7 {
    background-position: 0 85.714286%;
  }
  .badge-survey-8 {
    background-position: 0 87.301587%;
  }
  .badge-test-1 {
    background-position: 0 88.888889%;
  }
  .badge-test-2 {
    background-position: 0 90.47619%;
  }
  .badge-test-3 {
    background-position: 0 92.063492%;
  }
  .badge-test-4 {
    background-position: 0 93.650794%;
  }
  .badge-test-5 {
    background-position: 0 95.238095%;
  }
  .badge-test-6 {
    background-position: 0 96.825397%;
  }
  .badge-test-7 {
    background-position: 0 98.412698%;
  }
  .badge-test-8 {
    background-position: 0 100%;
  }
`;

export const badgeIconStyle = css`
  figure {
    height: 50px;
    width: 50px;
  }
`;
